import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { IIssueNoteModel } from './issue-note-model.interface';

@Injectable({
  providedIn: 'root',
})
export class IssueNoteClientService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Egy hibajegy komment törlése
   * @param id A törlendő hibajegy komment azonosítója
   * @returns A törlés eredménye
   */
  deleteIssueNote(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.issueNote) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy hibajegy komment létrehozása
   * @param issueNote Az új hibajegy komment adatai
   * @returns Az új hibajegy komment adatai
   */
  createIssueNote(issueNote: IIssueNoteModel): Observable<IIssueNoteModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(this.apiService.getUrlFor(API_ENDPOINTS.issueNotes), issueNote, {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IIssueNoteModel)
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'IssueNoteClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform issue operation'));
  };
}
