import { Component, ViewChild } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { CheckinTableRowComponent } from '../../components/tables/checkin-table-row/checkin-table-row.component';
import { CheckinEditorComponent } from '../../components/modal-editors/checkin-editor/checkin-editor.component';
import { CheckinClientService } from '../../services/api/checkin/checkin-client.service';
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../../services/utils/log.service';
import { ICheckinModel } from '../../services/api/checkin/checkin-model.interface';
import { UiTablePagerComponent } from '../../components/atomic-ui-components/table-pager/table-pager.component';
import { ToastrService } from 'ngx-toastr';
import { RouterLink } from '@angular/router';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';

@Component({
  selector: 'checkins',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    CheckinTableRowComponent,
    CheckinEditorComponent,
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    RouterLink,
    TableEmptyStateComponent,
  ],
  templateUrl: './checkins.component.html',
  styleUrl: './checkins.component.css',
})
export class CheckinsComponent {
  @ViewChild(CheckinEditorComponent)
  editor!: CheckinEditorComponent;
  selectedCheckin!: ICheckinModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  checkins = [] as ICheckinModel[];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private checkinService: CheckinClientService,
    private log: LogService,
    private toastr: ToastrService,
  ) {}

  handleEdit(pitem: ICheckinModel) {
    this.selectedCheckin = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: ICheckinModel) {
    this.selectedCheckin = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ICheckinModel) {
    this.selectedCheckin = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.checkinService.deleteCheckin(this.selectedCheckin.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.checkins = this.checkins.filter(
          (item) => item.id !== this.selectedCheckin.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.checkins.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Checkin deleted');
      },
      error: (error) => {
        // ha nem, feldobunk egy hibaüzenetet
        this.toastr.error('Error deleting checkin!');
        this.log.error('Error deleting checkin!');
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedCheckin = {
      id: 0,
      name: '',
      email_address: '',
      country_code: '',
      city: '',
      county: '',
      street_line_1: '',
      street_line_2: '',
      po_code: '',
      billing_name: '',
      billing_company_name: '',
      billing_tax_number: '',
      billing_country: '',
      billing_county: '',
      billing_po_code: '',
      billing_town: '',
      billing_street_line_1: '',
      billing_street_line_2: '',
      newsletter_signup: false,
    } as ICheckinModel;
  }

  refresh(page?: number) {
    this.log.info('Refreshing checkins');
    this.isLoading = true;
    this.checkinService.getCheckins().subscribe((data) => {
      this.checkins = data;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
      }, 100);
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.checkinService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
