import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { ICleaningRequestModel } from '../../../services/api/cleaning-request/cleaning-request-model.interface';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { CleaningPackageClientService } from '../../../services/api/cleaning-package/cleaning-package-client.service';
import { CleaningRequestClientService } from '../../../services/api/cleaning-request/cleaning-request-client.service';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'cleaning-request-editor',
  standalone: true,
  imports: [ReactiveFormsModule, UiSwitchComponent, NgSelectModule],
  templateUrl: './cleaning-request-editor.component.html',
  styleUrl: './cleaning-request-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningRequestEditorComponent {
  @Input() cleaningRequest!: ICleaningRequestModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  flatSelectItems: IUiSelectItem[] = [];
  packageSelectItems: IUiSelectItem[] = [];

  cleaningRequestForm: FormGroup = this.fb.group({
    start_date: [''],
    flat: [''],
    cleaningPackage: [''],
    paid: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private cleaningRequestService: CleaningRequestClientService,
    private packageService: CleaningPackageClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges() {
    this.log.debug('CleaningRequestEditor Changed', this.cleaningRequest);
    this.cleaningRequestForm.reset();
    if (this.mode === 'view') {
      this.cleaningRequestForm.disable();
    } else {
      this.cleaningRequestForm.enable();
    }
    this.cleaningRequestForm.patchValue(this.cleaningRequest);

    // Lakások és csomagok kezelése a dropdownban
    this.cleaningRequestForm
      .get('flat')
      ?.setValue(this.cleaningRequest.flat?.['@id']);
    this.cleaningRequestForm
      .get('cleaningPackage')
      ?.setValue(this.cleaningRequest.cleaningPackage?.['@id']);
  }

  ngOnInit() {
    // Betöltjük a lakásokat a dropdownhoz
    this.silo.getFlatSelectItems().subscribe({
      next: (flats) => {
        this.flatSelectItems = flats;
        this.log.debug('Flats loaded into select:', this.flatSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading flats into select:', err);
      },
    });

    // Betöltjük a takarítási csomagokat a dropdownhoz
    // TODO: SILO-ba szervezni
    this.packageService.getCleaningPackages().subscribe({
      next: (packages) => {
        this.packageSelectItems = packages.map((cleaningPackage) => ({
          val: cleaningPackage['@id'],
          title: cleaningPackage.name,
          // TODO: Árat formázva megjeleníteni: + ' (' + cleaningPackage.price + ' '+ cleaningPackage.currency?.symbol +')'
        }));
        this.packageSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('Cleaning packages loaded:', this.packageSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading cleaning packages:', err);
      },
    });
  }

  saveCleaningRequest() {
    this.log.info('Saving cleaning request');
    if (this.cleaningRequestForm.valid && this.mode !== 'view') {
      const updatedUntypedCleaningRequest: { [key: string]: any } = {};

      // csak a módosított mezőket küldjük
      Object.keys(this.cleaningRequestForm.controls).forEach((key) => {
        const control = this.cleaningRequestForm.get(key);
        if (control?.dirty) {
          updatedUntypedCleaningRequest[key] = control.value;
        }
      });

      if (Object.keys(updatedUntypedCleaningRequest).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedCleaningRequest =
        updatedUntypedCleaningRequest as ICleaningRequestModel;
      if (this.mode === 'edit') {
        updatedCleaningRequest.id = this.cleaningRequest.id;
        this.cleaningRequestService
          .updateCleaningRequest(updatedCleaningRequest)
          .subscribe({
            next: (data) => {
              this.toastr.success('Cleaning request updated');
              this.log.debug('Cleaning request updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error while updating cleaning request');
              this.log.error('Error updating cleaning request:', err);
            },
          });
      } else {
        this.cleaningRequestService
          .createCleaningRequest(updatedCleaningRequest)
          .subscribe({
            next: (data) => {
              this.toastr.success('Cleaning request created');
              this.log.debug('Cleaning request created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error while creating cleaning request');
              this.log.error('Error creating cleaning request:', err);
            },
          });
      }
    }
  }
}
