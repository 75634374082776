<div class="border-b px-2 sm:px-4 py-2 sm:py-4" id="billing">
  <div class="flex w-full justify-between my-2 mb-6">
    @if(billing){
    <div class="text-xl mb-2 font-semibold">Billing #{{ billing.id }}</div>
    <!-- Add BillingItem -->
    <div class="flex flex-wrap sm:flex-nowrap justify-end gap-2">
      <ui-button size="xsmall" (click)="issueInvoice()">Issue now</ui-button>
      <ui-button size="xsmall" (click)="prepareBillingItemCreation()"
        >Add Billing Item</ui-button
      >
      <ui-button
        btnType="outline-negative"
        data-hs-overlay="#hs-remove-billing-confirmation-modal"
        size="xsmall"
        (click)="deleteBilling()"
        >Delete Billing</ui-button
      >
    </div>
    <!-- End Add BillingItem -->
    } @else{
    <div class="text-2xl font-semibold">New Billing</div>
    }
  </div>
  <!-- START BILLING ROW -->
  <!-- TODO: ellenőrizni ennek a formnak a class-jait -->
  <form
    id="billing-form"
    [formGroup]="billingForm"
    (ngSubmit)="saveBilling()"
    class="flex flex-wrap justify-start gap-4 items-center"
  >
    <div class="flex flex-wrap items-center w-full md:w-auto mb-2">
      <label
        for="billing-start-date"
        class="text-sm text-left sm:text-right text-gray-500 mr-2 grow shrink basis-full sm:basis-auto"
        >Billing Date</label
      >
      <input
        class="text-sm border border-gray-200 p-2 rounded-lg w-full sm:w-56"
        type="date"
        placeholder=""
        name="billing-start-date"
        formControlName="first_billing_date"
      />
    </div>
    <div class="text-center flex flex-wrap w-full items-center md:w-auto mb-2">
      <label
        class="text-sm text-left sm:text-right text-gray-500 mr-2 grow shrin basis-full sm:basis-auto"
        for="billingSchedule"
        >Recurrence</label
      >
      <ng-select
        class="w-full sm:w-56 m-auto text-left"
        [items]="scheduleTypeSelectItems"
        bindLabel="title"
        bindValue="val"
        [multiple]="false"
        [clearable]="false"
        loadingText="Loading..."
        placeholder="Select Schedule Type"
        formControlName="billingSchedule"
      ></ng-select>
    </div>
    <div class="flex flex-wrap items-center w-full md:w-auto mb-2">
      <label
        for="billing-start_date"
        class="text-sm text-left sm:text-right text-gray-500 mr-2 grow shrink basis-full sm:basis-auto"
        >To be paid in</label
      >
      <ng-select
        class="w-full sm:w-56 m-auto text-left"
        [items]="currencySelectItems"
        bindLabel="title"
        bindValue="val"
        [multiple]="false"
        [clearable]="false"
        loadingText="Loading..."
        placeholder="Select Currency"
        formControlName="currency"
      ></ng-select>
    </div>
  </form>
  <!-- END BILLING ROW -->
  <!-- START SAVE CONTROLS-->
  @if(!billingForm.pristine || !billing){
  <div class="flex w-full justify-center gap-4 mt-4">
    <ui-button
      type="submit"
      form="billing-form"
      (click)="saveBilling()"
      iconSrc="assets/img/save.svg"
      iconClass="invert  saturate-0  brightness-0 contrast-125"
      size="xsmall"
      >Save</ui-button
    >
    @if(billing){
    <ui-button btnType="outline-negative" size="xsmall" (click)="cancelEdit()"
      >Cancel</ui-button
    >}@else {
    <ui-button
      btnType="outline-negative"
      size="xsmall"
      (click)="cancelCreation()"
      >Cancel</ui-button
    >
    }
  </div>
  <!-- END SAVE CONTROLS-->
  } @if(billing){
  <!-- Timeline -->
  <div class="mt-4 bg-slate-50 px-2 md:px-4 py-2 rounded"> <!-- overflow-x-auto overflow-y-visible -->
    @if(billing.billingItems?.length){
    <!-- Heading -->
    <div class="ps-2 my-4">
      <h3 class="text-xs font-medium uppercase text-blue-500">Billing Items</h3>
    </div>
    <!-- End Heading -->
    }
    <ng-container
      *ngFor="let item of billing?.billingItems; let last = last; index as i"
      ><billing-item
        [billingItem]="item"
        [isLast]="last"
        [availableMeters]="availableMeters"
        [billing]="billing"
        (onDelete)="deleteBillingItem($event)"
      ></billing-item>
    </ng-container>

    @if(!billing.billingItems?.length && !isBillingItemCreatorVisible){
    <!-- Empty State -->
    <div class="flex justify-center items-center h-28 text-gray-500">
      <i-lucide
        name="calendar-check-2"
        class="w-12 h-12"
        [strokeWidth]="1"
      ></i-lucide>
      <p class="text-sm">No billing items yet.</p>
    </div>
    } @if(isBillingItemCreatorVisible){
    <!-- Add BillingItem -->
    <billing-item
      [billing]="billing"
      [availableMeters]="availableMeters"
      (onCreationCanceled)="cancelBillingItemCreation()"
      (onCreate)="handleBillingItemCreated($event)"
      mode="create"
    ></billing-item>
    <!-- End Timeline -->
    }
  </div>
  }
</div>
