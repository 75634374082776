<div
  id="hs-checkin-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Checkin"
              : mode === "edit"
              ? "Edit Checkin"
              : "View Checkin"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-checkin-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="checkin-form"
            [formGroup]="checkinForm"
            (ngSubmit)="saveCheckin()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinName"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Name
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.name }}
                    </p>
                    } @else {
                    <input
                      id="checkinName"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="checkin.name || ''"
                      formControlName="name"
                    />
                    }
                  </div>
                </div>
                <!-- // Grid -->
                <!-- Email Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinEmail"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Email
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.email_address }}
                    </p>
                    } @else {
                    <input
                      id="checkinEmail"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="email_address"
                      [value]="checkin.email_address || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End  Grid -->
                <!-- CountryCode Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinCountryCode"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Country Code
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                @if (mode === 'view'){
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.country_code }}
                    </p>
                    } @else {
                    <div>
                      <ng-select
                        [items]="countries"
                        bindLabel="title"
                        bindValue="val"
                        formControlName="country_code"
                        [virtualScroll]="true"
                        placeholder="Select Country"
                      >
                        <ng-template ng-label-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.title }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                          <div>
                            <div class="flex items-center">
                              <div class="me-2">
                                <img
                                  class="inline-block size-4 rounded-full"
                                  [src]="item.options?.icon"
                                  [alt]="item.options?.description"
                                />
                              </div>
                              <div class="text-gray-800">
                                {{ item.title }}
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </div>

                    }

                  </div>
                </div>
                <!-- End  Grid -->
                <!-- City Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinCity"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      City
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.city }}
                    </p>
                    } @else {
                    <input
                      id="checkinCity"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="city"
                      [value]="checkin.city || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End  Grid -->
                <!-- County Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinCount"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      County
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.county }}
                    </p>
                    } @else {
                    <input
                      id="checkinCounty"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="county"
                      [value]="checkin.county || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Street Line 1 Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinStreetLine1"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Street Line 1
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.street_line_1 }}
                    </p>
                    } @else {
                    <input
                      id="checkinStreetLine1"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="street_line_1"
                      [value]="checkin.street_line_1 || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Street Line 2 Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinStreetLine2"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Street Line 2
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.street_line_2 }}
                    </p>
                    } @else {
                    <input
                      id="checkinStreetLine2"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="street_line_2"
                      [value]="checkin.street_line_2 || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Postal Code Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinPostalCode"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Postal Code
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.po_code }}
                    </p>
                    } @else {
                    <input
                      id="checkinPostalCode"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="po_code"
                      [value]="checkin.po_code || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingName"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Name
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_name }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingName"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_name"
                      [value]="checkin.billing_name || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Company Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingCompanyName"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Company Name
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_company_name }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingCompanyName"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_company_name"
                      [value]="checkin.billing_company_name || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Tax Number Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingTaxNumber"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Tax Number
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_tax_number }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingTaxNumber"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_tax_number"
                      [value]="checkin.billing_tax_number || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Country Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingCountry"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Country
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_country }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingCountry"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_country"
                      [value]="checkin.billing_country || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing County Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingCounty"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing County
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_county }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingCounty"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_county"
                      [value]="checkin.billing_county || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing PO Code Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingPOCode"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Postal Code
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_po_code }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingPOCode"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_po_code"
                      [value]="checkin.billing_po_code || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Town Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingTown"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Town
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_town }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingTown"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_town"
                      [value]="checkin.billing_town || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Street Line 1 Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingStreetLine1"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Street Line 1
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_street_line_1 }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingStreetLine1"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_street_line_1"
                      [value]="checkin.billing_street_line_1 || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Billing Street Line 2 Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinBillingStreetLine2"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Billing Street Line 2
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ checkin.billing_street_line_2 }}
                    </p>
                    } @else {
                    <input
                      id="checkinBillingStreetLine2"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="billing_street_line_2"
                      [value]="checkin.billing_street_line_2 || ''"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Newsletter  Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="checkinNewsletter"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Newsletter Signup
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                      id="checkinNewsletter"
                      [checked]="!!checkin.newsletter_signup"
                      [disabled]=true
                      formControlName="newsletter_signup"
                    ></ui-switch>
                    </p>
                    } @else {
                    <ui-switch
                      id="checkinNewsletter"
                      [checked]="!!checkin.newsletter_signup"
                      formControlName="newsletter_signup"
                    ></ui-switch>
                    }
                  </div>
                </div>
                <!-- End Name Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-checkin-editor-modal"
        >
          Close
        </button>
        @if (mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="checkinForm.invalid"
          form="checkin-form"
          data-hs-overlay="#hs-checkin-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
