import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { II11Transformer } from '../generic-api-client.service';
import { IFlatModel } from './flat-model.interface';
import { IMeterModel } from '../meter/meter-model.interface';

@Injectable({
  providedIn: 'root',
})
export class FlatTransformService implements II11Transformer<IFlatModel> {
  constructor(private log: LogService) {}

  transformItem(flat: IFlatModel): IFlatModel {
    return flat;
  }

  serializeItem(flat: IFlatModel): IFlatModel {
    const serializedFlat: any = { ...flat };

    // Többi mező stringgé alakítása
    if (serializedFlat.flatType) {
      serializedFlat.flatType = serializedFlat.flatType['@id'];
    }
    if (serializedFlat.floor) {
      serializedFlat.floor = serializedFlat.floor['@id'];
    }
/*     if (serializedFlat.building) {
      serializedFlat.building = serializedFlat.building['@id'];
    } */
    if (serializedFlat.meters) {
      serializedFlat.meters = serializedFlat.meters.map((meter: IMeterModel) => meter['@id']);
    }
    if (serializedFlat.contracts) {
      serializedFlat.contracts = serializedFlat.contracts.map((contract: any) => contract['@id']);
    }

    return serializedFlat;
  }
}
