<div
  id="hs-invoice-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Invoice"
              : mode === "edit"
              ? "Edit Invoice"
              : "View Invoice"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-invoice-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="invoice-form"
            [formGroup]="invoiceForm"
            (ngSubmit)="saveInvoice()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!--- **************************************** -->
                <!-- Paid Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoicePaid"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Paid
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                        id="invoicePaid"
                        [checked]="!!invoice.paid"
                        [disabled]="true"
                      ></ui-switch>
                    </p>
                    } @else {
                    <ui-switch
                      id="invoicePaid"
                      [checked]="!!invoice.paid"
                    ></ui-switch>
                    }
                  </div>
                </div>
                <!-- End Paid Grid -->

                <!-- Invoice Number Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="InvoiceNumber"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Invoice Number
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        invoice.invoice_number === undefined
                          ? "-"
                          : invoice.invoice_number
                      }}
                    </p>
                    } @else {
                    <input
                      id="InvoiceNumber"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      disabled
                      [value]="
                        invoice.invoice_number === undefined
                          ? '-'
                          : invoice.invoice_number
                      "
                    />
                    }
                  </div>
                </div>
                <!-- End Invoice Number Grid -->
                <!-- Invoice Status Grid -->
                @if (mode === 'view' || mode === 'edit') {
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="InvoiceNumber"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Invoice Status
                    </label>
                  </div>
                  <div class="sm:col-span-8 h-9 content-center">
                    <ui-badge
                    [color]="
                      invoice.invoiceStatus?.id == 1
                        ? 'black'
                        : invoice.invoiceStatus?.id == 2
                        ? 'yellow'
                        : invoice.invoiceStatus?.id == 3
                        ? 'red'
                        : invoice.invoiceStatus?.id == 4
                        ? 'green' : 'blue'
                    "
                    badgeType="soft"
                    >{{ invoice.invoiceStatus?.name ?? 'N/A' }}</ui-badge
                  >
                  </div>
                </div>
                }
                <!-- End Invoice Status Grid -->
                <!-- Fulfillment Date Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceFulFillmentDate"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Fulfillment Date
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        invoice.fulfillment_date === undefined
                          ? "-"
                          : invoice.fulfillment_date
                      }}
                    </p>
                    } @else {
                    <input
                      id="invoiceFulFillmentDate"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="invoice.fulfillment_date"
                      formControlName="fulfillment_date"
                    />
                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- End Grid -->

                <!-- Customer Name Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceCustomerName"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Customer Name
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ invoice.customer_user?.name }}
                    </p>
                    } @else {
                    <input
                      id="invoiceCustomerName"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      disabled
                      [value]="invoice.customer_user?.name"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->

                <!-- Customer Email Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceCustomerEmail"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Customer Email
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ invoice.customer_user?.email }}
                    </p>
                    } @else {
                    <input
                      id="invoiceCustomerEmail"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      disabled
                      [value]="invoice.customer_user?.email"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->

                <!-- issueUser Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceIssueUser"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Issuer Email
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ invoice.issuer_user?.email }}
                    </p>
                    } @else {
                    <input
                      id="invoiceIssueUser"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      disabled
                      [value]="invoice.issuer_user?.email"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->

                <!-- Net Total Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoicenet_total"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Net Total
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-mono font-semibold">
                      {{
                        invoice.net_total
                          | number : "1.2-2"
                          | correctCurrencyFormat
                      }}
                    </p>
                    } @else {
                    <input
                      id="invoicenet_total"
                      type="number"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="net_total"
                      [value]="invoice.net_total"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- VAT Total Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceVatTotal"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Vat Total
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-mono font-semibold">
                      {{
                        invoice.vat_total
                          | number : "1.2-2"
                          | correctCurrencyFormat
                      }}
                    </p>
                    } @else {
                    <input
                      id="invoiceVatTotal"
                      type="number"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="vat_total"
                      [value]="invoice.vat_total"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Gross Total Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceGrossTotal"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Gross Total
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-mono font-semibold">
                      {{
                        invoice.gross_total
                          | number : "1.2-2"
                          | correctCurrencyFormat
                      }}
                    </p>
                    } @else {
                    <input
                      id="invoiceGrossTotal"
                      type="number"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      formControlName="gross_total"
                      [value]="invoice.gross_total"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->

                <!-- currency Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="invoiceCurrency"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Currency
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ invoice.currency?.name }}
                    </p>
                    } @else { @if(!currencySelectItems){ placeholder } @else {
                    <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    selectId="invoice-currency-select"
                    [items]="currencySelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="currency"
                  />                    
                    } }
                  </div>
                </div>
                <!-- End Grid -->

                <!--  *************************************************************** -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-invoice-editor-modal"
        >
          Close
        </button>
        @if(mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="invoiceForm.invalid"
          form="invoice-form"
          data-hs-overlay="#hs-invoice-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
