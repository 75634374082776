import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';

export const API_CALL_TIMEOUT = 3000;
export const API_CALL_RETRY_COUNT = 3;

export const API_ENDPOINTS = {
  properties: '/admin/api/properties',
  dashboards: '/admin/api/dashboards',
  billingAddresses: '/admin/api/billing_addresses',
  billingAddress: '/admin/api/billing_addresses/',
  checkins: '/admin/api/checkins',
  checkin: '/admin/api/checkins/',
  invoices: '/admin/api/invoices',
  invoice: '/admin/api/invoices/',
  users: '/admin/api/users',
  user: '/admin/api/users/',
  currencies: '/admin/api/currencies',
  currency: '/admin/api/currencies/',
  paymentTransactions: '/admin/api/payment_transactions',
  paymentTransaction: '/admin/api/payment_transactions/',
  paymentProviders: '/admin/api/payment_providers',
  paymentProvider: '/admin/api/payment_providers/',
  parkingRequests: '/admin/api/parking_requests',
  parkingRequest: '/admin/api/parking_requests/',
  bookings: '/admin/api/bookings',
  booking: '/admin/api/bookings/',
  bookingStatuses: '/admin/api/booking_statuses',
  bookingStatus: '/admin/api/booking_statuses/',
  bookingSources: '/admin/api/booking_sources',
  bookingSource: '/admin/api/booking_sources/',
  flats: '/admin/api/flats',
  flat: '/admin/api/flats/',
  flatTypes: '/admin/api/flat_types',
  flatType: '/admin/api/flat_types/',
  buildings: '/admin/api/buildings',
  cleaningRequests: '/admin/api/cleaning_requests',
  cleaningRequest: '/admin/api/cleaning_requests/',
  cleaningSchedules: '/admin/api/cleaning_schedules',
  cleaningSchedule: '/admin/api/cleaning_schedules/',
  cleaningPackages: '/admin/api/cleaning_packages',
  cleaningPackage: '/admin/api/cleaning_packages/',
  cleaningTypes: '/admin/api/cleaning_types',
  cleaningType: '/admin/api/cleaning_types/',
  issues: '/admin/api/issues',
  issue: '/admin/api/issues/',
  issueNotes: '/admin/api/issue_notes',
  issueNote: '/admin/api/issue_notes/',
  issueStatuses: '/admin/api/issue_statuses',
  issueStatus: '/admin/api/issue_statuses/',
  issueTypes: '/admin/api/issue_types',
  issueType: '/admin/api/issue_types/',
  issueUrgencies: '/admin/api/issue_urgencies',
  issueUrgency: '/admin/api/issue_urgencies/',
  entryCodes: '/admin/api/entry_codes',
  entryCode: '/admin/api/entry_codes/',
  login: '/admin/api/login_check',
  inventoryItems: '/admin/api/inventory_items',
  inventoryItem: '/admin/api/inventory_items/',
  inventoryItemTypes: '/admin/api/inventory_item_types',
  inventoryItemType: '/admin/api/inventory_item_types/',
  inventoryFile: '/inventory/',
  contracts: '/admin/api/contracts',
  contract: '/admin/api/contracts/',
  tenants: '/admin/api/tenants',
  tenant: '/admin/api/tenants/',
  floors: '/admin/api/floors',
  floor: '/admin/api/floors/',
  taxes: '/admin/api/taxes',
  tax: '/admin/api/taxes/',
  meters: '/admin/api/meters',
  meter: '/admin/api/meters/',
  meterTypes: '/admin/api/meter_types',
  meterType: '/admin/api/meter_types/',
  units: '/admin/api/units',
  unit: '/admin/api/units/',
  billingSchedules: '/admin/api/billing_schedules',
  billingSchedule: '/admin/api/billing_schedules/',
  billingItems: '/admin/api/billing_items',
  billingItem: '/admin/api/billing_items/',
  billings: '/admin/api/billings',
  billing: '/admin/api/billings/',
  billingItemTypes: '/admin/api/billing_item_types',
  billingItemType: '/admin/api/billing_item_types/',
  readings: '/admin/api/readings',
  reading: '/admin/api/readings/',

  passwordReset: '/api/login_forgotten_password',
};

/**
 * Entitások lekérdezéséhez használt dátum feltételek wrapper interfésze
 * @param before Az entitásokat a megadott dátum előtt kéri le, a megadott dátumot is tartalmazza
 * @param after Az entitásokat a megadott dátum után kéri le, a megadott dátumot is tartalmazza
 * @param strictly_before Az entitásokat a megadott dátum előtt kéri le, de a dátumot nem tartalmazza
 * @param strictly_after Az entitásokat a megadott dátum után kéri le, de a dátumot nem tartalmazza
 */
export interface IApiQueryDateRange {
  before?: string;
  after?: string;
  strictly_before?: string;
  strictly_after?: string;
}

export interface IApiQueryOrdering {
  field: string;
  direction: 'asc' | 'desc';
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  getUrlFor(endpoint: string): string {
    return environment.baseUrl + endpoint;
  }
  getBaseUrl(): string {
    return environment.baseUrl;
  }

  /**
   * A dátum/idő query paraméterek hozzáadása a HttpParams-hoz
   * @param params A már meglévő HttpParams
   * @param paramName A hozzáadandó query paraméter neve
   * @param value A hozzáadandó query paraméter értéke
   * @returns A kiegészített HttpParams
   */
  appendDateParams(
    params: HttpParams,
    paramName: string,
    value: string | IApiQueryDateRange | undefined
  ): HttpParams {
    if (typeof value === 'string') {
      params = params.append(paramName, value);
    } else if (typeof value === 'object' && value !== null) {
      if (value.before) {
        params = params.append(paramName + '[before]', value.before);
      }
      if (value.after) {
        params = params.append(paramName + '[after]', value.after);
      }
      if (value.strictly_before) {
        params = params.append(
          paramName + '[strictly_before]',
          value.strictly_before
        );
      }
      if (value.strictly_after) {
        params = params.append(
          paramName + '[strictly_after]',
          value.strictly_after
        );
      }
    }
    return params;
  }
}
