import { Injectable } from '@angular/core';
import { GenericApiClientService } from '../generic-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import { API_ENDPOINTS, ApiService } from '../api.service';
import { IBillingModel } from './billing-model.interface';
import { Observable } from 'rxjs';
import { BillingTransformService } from './billing-transform.service';

@Injectable({
  providedIn: 'root',
})
export class BillingClientService extends GenericApiClientService<IBillingModel> {
  constructor(
    http: HttpClient,
    apiService: ApiService,
    log: LogService,
    private billingTransformService: BillingTransformService
  ) {
    super(http, apiService, log);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.billings);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.billing);
  }

  override updateItem(item: IBillingModel): Observable<IBillingModel> {
    return super.updateItem(this.billingTransformService.serializeBilling(item));
  }
}
