import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICleaningOverviewModel } from '../cleaning-overview-model.interface';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe, SlicePipe } from '@angular/common';

@Component({
  selector: 'cleaning-overview-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiButtonComponent,
    UiBadgeComponent,
    SlicePipe,
    DatePipe,
  ],
  templateUrl: './cleaning-overview-table-row.component.html',
  styleUrl: './cleaning-overview-table-row.component.css',
})
export class CleaningOverviewTableRowComponent {
  @Input() pitem: ICleaningOverviewModel = {} as ICleaningOverviewModel;
  @Output() onEdit = new EventEmitter<ICleaningOverviewModel>();
  @Output() onDone = new EventEmitter<ICleaningOverviewModel>();

  handleEdit(pitem: ICleaningOverviewModel) {
    this.onEdit.emit(pitem);
  }

  handleMarkDone(pitem: ICleaningOverviewModel) {
    this.onDone.emit(pitem);
  }

  flagColor(priority: number): string {
    let color: string = '';

    if (priority === 0) {
      color = 'bg-red-500';
    } else if (priority === 1) {
      color = 'bg-orange-300';
    } else {
      color = 'bg-gray-500';
    }

    return color;
  }
}
