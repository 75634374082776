import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { LogService } from '../utils/log.service';
import { API_CALL_RETRY_COUNT, ApiService } from './api.service';
import {
  retry,
  catchError,
  Observable,
  map,
  throwError,
  forkJoin,
  switchMap,
} from 'rxjs';
import { II11Model } from './i11-model.interface';

export interface II11Transformer<T extends II11Model>{
  transformItem(item: any): T;
  serializeItem(item: T): any;
}

@Injectable({
  providedIn: 'root',
})
export abstract class GenericApiClientService<T extends II11Model> {
  protected totalItems!: number;
  protected itemsUrl = '';
  protected singleItemUrl = '';

  constructor(
    protected http: HttpClient,
    protected apiService: ApiService,
    protected log: LogService,
    protected transformer?: II11Transformer<T>,
  ) {}

  /**
   * Elemek lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns Az elemek lekért oldala, nyers válaszban
   */
  protected fetchItems(page?: number): any {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.itemsUrl, {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri az elemek első oldalát, hogy megtudja az összes elem számát
   * @returns Az összes elem számossága
   **/
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchItems().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Az összes elem lekérése
   * @returns Az összes elem adatai
   */
  getAllItems(): Observable<T[]> {
    // megtudjuk hány elem van összesen
    return this.getTotalItems().pipe(
      switchMap((totalItems) => {
        // kiszámoljuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];
        // minden oldalra kérünk egy-egy lekérést
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getItems(i));
        }
        // visszaadjuk az elemekat
        return forkJoin(requests).pipe(
          map((results) => {
            let items: T[] = [];
            for (let result of results) {
              items.push(...result);
            }
            return items;
          })
        );
      })
    );
  }

  /**
   * Elemek lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns Az elemek listája
   */
  getItems(page?: number): Observable<T[]> {
    return this.fetchItems(page).pipe(
      map((response: any) => {
        if(this.transformer){
          return response['hydra:member'].map((item: any) => this.transformer!.transformItem(item));
        }
        return response['hydra:member'];
      })
    );
  }

  /**
   * Elem lekérése azonosító alapján
   * @param id Az elem azonosítója
   * @returns Az elem adatai
   */
  getItem(id: number): Observable<T> {
    return this.http.get(this.singleItemUrl + id).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((response: any) => {
        if(this.transformer){
          return this.transformer.transformItem(response);
        }
        return response;
      })
    );
  }

  /**
   * Elem lekérése IRI alapján
   * @param iri Az elem IRI-ja
   * @returns Az elem adatai
   */
  getItemByIri(iri: string): Observable<T> {
    return this.http.get(this.apiService.getBaseUrl() + iri).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((response: any) => {
        if(this.transformer){
          return this.transformer.transformItem(response);
        }
        return response as T;
      })
    );
  }

  /**
   * Egy elem törlése
   * @param id A törlendő elem azonosítója
   * @returns A törlés eredménye
   */
  deleteItem(id: number): Observable<any> {
    return this.http
      .delete(this.singleItemUrl + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy elem létrehozása
   * @param item A létrehozandó elem adatai
   * @returns A létrejött elem adatai
   */
  createItem(item: any): Observable<T> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(this.itemsUrl, this.transformer? this.transformer.serializeItem(item) : item, {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => {
          if (this.transformer) {
            return this.transformer.transformItem(response as T);
          } else {
            return response as T;
          }
        })
      );
  }

  /**
   * Egy elem módosítása
   * @param item A módosítandó elem adatai
   * @returns A módosított elem adatai
   */
  updateItem(item: T): Observable<T> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(this.singleItemUrl + item.id, this.transformer? this.transformer.serializeItem(item) : item, {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => {
          if (this.transformer) {
            return this.transformer.transformItem(response as T);
          } else {
            return response as T;
          }
        })
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  protected handleError(error: HttpErrorResponse) {
    this.log.error(
      'ItemClientService error',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform Item operation'));
  }
}
