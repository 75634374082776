import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { IssuesTableComponent } from '../../components/tables/issues-table/issues-table.component';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'issues',
  standalone: true,
  imports: [
    LayoutMainComponent,
    IssuesTableComponent,
    RouterLink,
  ],
  templateUrl: './issues.component.html',
  styleUrl: './issues.component.css'
})
export class IssuesComponent {

}
