import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { IUiSelectItem } from './ui-select-item.interface';
import { LogService } from '../../../services/utils/log.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-select',
  standalone: true,
  imports: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
  ],
  templateUrl: './ui-select.component.html',
  styleUrl: './ui-select.component.css',
})
export class UiSelectComponent implements OnInit, ControlValueAccessor {
  @Input() selectId: string = 'unidentified-select-id';
  @Input() selectItems: IUiSelectItem[] = [];
  // Ez a külső value, amit a formControlban értékként használunk
  @Input() selectedOptionValue: any;
  @Input() disabled: boolean = false;

  // Ez a belső állapot nyilvántartás
  private selectedItem: IUiSelectItem = { val: '', title: '' };
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private log: LogService) {}

  ngOnInit(): void {
    //this.log.debug('UiSelectComponent: ' + this.selectId + ' initialized');
  }

  changed(event: any): void {
    this.log.debug(
      'UiSelectComponent: ' + this.selectId + ' changed: ',
      event.target.value
    );
    this.onChange(event.target.value);
  }

  writeValue(value: string): void {
    this.log.debug(
      'UiSelectComponent: ' + this.selectId + ' writeValue: ',
      value
    );
      // a selecteditem-t beállítjuk a selectItems sömb azon elemére, amelynek a val értéke megegyezik a value-val
      this.selectedItem = this.selectItems.find(
        (item) => item.val === value
      ) || {
        val: '',
        title: '',
      };
      // a külső nyilvántartást is frissítjük
      this.selectedOptionValue = this.selectedItem.val;
  }

  registerOnChange(fn: any): void {
    //this.log.debug('UiSelectComponent: ' + this.selectId + ' registerOnChange');
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    //this.log.debug('UiSelectComponent: ' + this.selectId + ' registerOnTouched');
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
