<div
  id="hs-issue-creator-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">Add New Issue</h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-issue-creator-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="issue-create-form"
            [formGroup]="issueForm"
            (ngSubmit)="createIssue()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Creator User Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="issueReporterUser"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Reported by
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      selectId="issueReporterUser"
                      [items]="userSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="issuerUser"
                      [virtualScroll]="true"
                    ></ng-select>
                  </div>
                </div>
                <!-- End Creator User Grid -->

                <!-- Flat Grid-->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="issueFlat"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Flat
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      selectId="issueFlat"
                      [items]="flatSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="flat"
                      [virtualScroll]="true"
                    ></ng-select>
                  </div>
                </div>
                <!-- End Flat Grid -->

                <!-- Issue Status Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="issueStatus"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Issue Status
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <ng-select
                      selectId="issueStatus"
                      [items]="statusSelectItems"
                      bindLabel="title"
                      bindValue="val"
                      formControlName="issueStatus"
                    ></ng-select>
                  </div>
                </div>
                <!-- End Issue Status Grid -->

                <!-- Note Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="issueNote"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Note
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    <textarea
                      id="issueNote"
                      type="text"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="issue.note"
                      formControlName="note"
                    ></textarea>
                  </div>
                </div>
                <!-- End Note Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-issue-creator-modal"
        >
          Close
        </button>

        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="issueForm.invalid"
          form="issue-create-form"
          data-hs-overlay="#hs-issue-creator-modal"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</div>
