import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { ICleaningTypeModel } from '../../../../services/api/cleaning-type/cleaning-type-model.interface';

@Component({
  selector: 'cleaning-type-table-row',
  standalone: true,
  imports: [
    UiButtonComponent,
  ],
  templateUrl: './cleaning-type-table-row.component.html',
  styleUrl: './cleaning-type-table-row.component.css'
})
export class CleaningTypeTableRowComponent {
  @Input() pitem: ICleaningTypeModel = {} as ICleaningTypeModel;
  @Output() onDelete = new EventEmitter<ICleaningTypeModel>();
  @Output() onEdit = new EventEmitter<ICleaningTypeModel>();
  @Output() onView = new EventEmitter<ICleaningTypeModel>();

  constructor(
  ) {}
  
  handleEdit(pitem: ICleaningTypeModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: ICleaningTypeModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: ICleaningTypeModel) {
    this.onDelete.emit(pitem);
  }

}
