import { Component } from '@angular/core';
import { LogService } from '../../../services/utils/log.service';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import {
  BookingClientService,
  IBookingQueryParams,
} from '../../../services/api/booking/booking-client.service';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import { ArrivalsRowComponent } from './arrivals-row/arrivals-row.component';
import { IArrivalModel } from './arrival-model.interface';
import { format, isToday, parseISO } from 'date-fns';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';


@Component({
  selector: 'arrivals',
  standalone: true,
  imports: [ArrivalsRowComponent, TableEmptyStateComponent],
  templateUrl: './arrivals.component.html',
  styleUrl: './arrivals.component.css',
})
export class ArrivalsComponent {
  arrivals = [] as IArrivalModel[];
  bookings = [] as IBookingModel[];
  isPreparing = true;

  constructor(
    private log: LogService,
    private bookingService: BookingClientService,
    private formatter: ModelFormatterService,
    private hardCodedConfig: HardCodedConfigService
  ) {}

  ngOnInit() {
    this.isPreparing = true;
    // Azokat a foglalásokat kérjük le, amiknek a start_date-je ma van
    const today = new Date();
    const queryParams = {
      startDate: format(today, 'yyyy-MM-dd'),
    } as IBookingQueryParams;
    this.bookingService.getAllBookings(queryParams).subscribe({
      next: (bookings) => {
        this.log.info('Bookings loaded');
        this.bookings = bookings;
        setTimeout(() => {
          this.prepareArrivals();
        }, 100);
      },
    });
  }

  prepareArrivals() {
    this.log.info('Preparing arrival overview');
    const arrivalList = [];
    for (const element of this.bookings.filter(
      (booking) =>
        isToday(parseISO(booking.start_date ?? '1970-01-01')) &&
        booking.bookingStatus?.id ===
          this.hardCodedConfig.FINALIZED_BOOKING_STATUS_ID
    )) {
      const flats: IFlatModel[] = element.flats ?? [];
      const listItem: IArrivalModel = {
        id: element.id,
        flats: flats.map((flat) => {
          return {
            id: flat.id,
            building: flat.building?.name ? flat.building.name : '',
            room: this.formatter.getFormattedFlat(flat, false),
          };
        }),
        startDate: element.start_date ?? '',
        endDate: element.end_date ?? '',
      };
      arrivalList.push(listItem);
    }
    this.arrivals = arrivalList.sort((a, b) =>
      a.flats[0]?.building?.localeCompare(b.flats[0]?.building)
    );
    this.isPreparing = false;
  }
}
