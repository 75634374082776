import * as config from '../app-config.development.json'

export const environment = {
  production: false,
  baseUrl: 'https://teszt.i11.hu',
  logLevel: 1,
  version: 'TESZT 2024-10-09',
  smsApiKey: config.smsApiKey,
  smsApiUrl: config.smsApiUrl,
};
