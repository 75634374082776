import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryItemClientService } from '../../../services/api/inventory-item/inventory-item-client.service';
import { LogService } from '../../../services/utils/log.service';
import { IInventoryItemTypeModel } from '../../../services/api/inventory-item-type/inventory-item-type-model.interface';
import { IInventoryItemModel } from '../../../services/api/inventory-item/inventory-item-model.interface';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { IContractModel } from '../../../services/api/contract/contract-model.interface';
import { ToastrService } from 'ngx-toastr';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';

@Component({
  selector: 'upload-documents',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css'],
})
export class UploadDocumentsComponent implements OnChanges {
  @Input() inventoryCategory?: IInventoryItemTypeModel | null;
  @Input() flatId?: string;
  @Input() contractId?: string;
  @Output() onUploadSuccess: EventEmitter<void> = new EventEmitter<void>();

  cardTitle: string = 'Upload Documents';
  selectedFile: File | null = null;
  isLoading = false;
  maxfileSize = 2; // MB

  constructor(
    private log: LogService,
    private inventoryClient: InventoryItemClientService,
    private toastr: ToastrService,
    private hardCodedConfig: HardCodedConfigService,
  ) {
    this.maxfileSize = this.hardCodedConfig.MAX_FILE_SIZE_MB;
  }

  ngOnChanges(): void {
    this.log.debug('Inventory category changed:', this.inventoryCategory);
    if (this.inventoryCategory?.name) {
      this.cardTitle = 'Upload Documents into ' + this.inventoryCategory.name;
    }
  }

  onFileSelected(event: any) {
    this.isLoading = true;
    this.selectedFile = event.target.files[0];
    this.log.debug('Selected file: ' + this.selectedFile?.name);
    if (this.selectedFile) {
      this.inventoryClient
        .createInventoryItem({
          id: 0,
          name: this.selectedFile.name,
          inventoryItemType: this.inventoryCategory?.['@id'],
          flats: [{ '@id': this.flatId } as IFlatModel],
          contracts: [{ '@id': this.contractId } as IContractModel],
        } as IInventoryItemModel)
        .subscribe({
          next: (inventoryItem) => {
            this.log.debug('Inventory item created:', inventoryItem);
            if (this.selectedFile) {
              this.inventoryClient
                .uploadInventoryItemFile(inventoryItem.id, this.selectedFile)
                .subscribe({
                  next: (response) => {
                    this.log.debug('Upload successful:', response);
                    this.toastr.success('Document uploaded successfully!');
                    this.onUploadSuccess.emit();
                    this.isLoading = false;
                  },
                  error: (error) => {
                    this.log.error('Upload failed, starting cleanup', error);
                    this.inventoryClient
                      .deleteInventoryItem(inventoryItem.id)
                      .subscribe({
                        next: () => {
                          this.log.debug(
                            'Inventory item deleted after a failed upload'
                          );
                          this.toastr.error('Document upload failed!');
                          this.isLoading = false;
                        },
                        error: (deleteError) => {
                          this.log.error(
                            'Inventory item cleanup failed too:',
                            deleteError
                          );
                          this.toastr.error('Document upload failed!');
                          this.isLoading = false;
                        },
                      });
                  },
                });
            } else {
              this.log.error('No file selected!');
              this.isLoading = false;
            }
          },
          error: (error) => {
            this.log.error('Inventory item creation failed:', error);
            this.isLoading = false;
          },
        });
    } else {
      this.log.error('No file selected!');
      this.isLoading = false;
    }
  }
}
