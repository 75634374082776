import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'table-empty-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-empty-state.component.html',
  styleUrls: ['./table-empty-state.component.css'],
})
export class TableEmptyStateComponent {
  @Input() noDataTitle: string = 'No Data Found';
  @Input() noDataDescription: string = 'No data found for the selected filters';
  @Input() modalIdToOpen: string = 'modal';
  @Input() ctaText: string = '';
  @Output() ctaClick: EventEmitter<void> = new EventEmitter<void>();

  onCtaClick(): void {
    this.ctaClick.emit();
  }
}
