import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { IPaymentTransactionModel } from '../../../../services/api/payment-transaction/payment-transaction-model.interface';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CurrencyValueCorrectorPipe } from '../../../../services/utils/currency-value-corrector.pipe';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'payment-transaction-table-row',
  standalone: true,
  templateUrl: './payment-transaction-table-row.component.html',
  styleUrl: './payment-transaction-table-row.component.css',
  imports: [
    UiSwitchComponent,
    DecimalPipe,
    CurrencyValueCorrectorPipe,
    DatePipe,
    UiButtonComponent,
  ],
})
export class PaymentTransactionTableRowComponent {
  @Input() pitem: IPaymentTransactionModel = {} as IPaymentTransactionModel;
  @Output() onDelete = new EventEmitter<IPaymentTransactionModel>();
  @Output() onEdit = new EventEmitter<IPaymentTransactionModel>();
  @Output() onView = new EventEmitter<IPaymentTransactionModel>();

  constructor() {}

  handleEdit(pitem: IPaymentTransactionModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IPaymentTransactionModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IPaymentTransactionModel) {
    this.onDelete.emit(pitem);
  }
}
