import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { InvoiceTableComponent } from '../../components/tables/invoice-table/invoice-table.component';
import { BillingAddressTableComponent } from '../../components/tables/billing-adress-table/billing-address-table.component';
import { PaymentTransactionTableComponent } from '../../components/tables/payment-transaction-table/payment-transaction-table.component';
import { UiButtonComponent } from "../../components/atomic-ui-components/button/ui-button.component";
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'invoicing',
    standalone: true,
    templateUrl: './invoicing.component.html',
    styleUrl: './invoicing.component.css',
    imports: [
        LayoutMainComponent,
        InvoiceTableComponent,
        BillingAddressTableComponent,
        PaymentTransactionTableComponent,
        UiButtonComponent,
        ConfirmationDialogComponent,
        RouterLink,
    ]
})
export class InvoicingComponent {


}
