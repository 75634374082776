<!--  **** USER MAIN **** -->
<div class="mb-4">
    <div class="bg-white border shadow-sm sm:rounded-xl">
      <div class="flex justify-between mb-4">
        <div class="m-4">
          <h2 class="font-bold text-lg">Users</h2>
        </div>
        <!-- Create User Button -->
        <div class="flex items-center gap-x-2 mr-4">
          <ui-button
            size="xsmall"
            iconSrc="assets/img/arrow-up-tray.svg"
            iconClass="invert  saturate-0  brightness-0 contrast-125"
            data-hs-overlay="#hs-user-creator-modal"
            (click)="handleCreate()"
          >
            Add User
          </ui-button>
        </div>
      </div>
      <div class="overflow-x-auto sm:mx-4">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200 table-auto mb-2">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Name
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>    
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Email
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>                       
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Last Login
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Billing Address
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Roles
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    User Own Flats
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    User Rents Flats
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    User Owns Buildings
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>
  
                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
  
                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>
  

  
 
  
              <th scope="col" class="px-4 py-2 leading-4 w-72">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown## relative ml-auto w-52">
                  <button
                    id="hs-pro-dutactions"
                    type="button"
                    class="w-full text-gray-500 uppercase text-xs justify-end flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Actions
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div
                class="w-full flex justify-center content-center mt-16 min-h-28"
              >
                <img
                  src="assets/img/loading.gif"
                  alt="loading"
                  class="h-8 w-auto"
                />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>
  
          } @else {@for (item of users; track item.id) {
          <users-table-row
            (onDelete)="prepareDelete($event)"
            [pitem]="item"
            class="divide-y divide-gray-200"
          >
          </users-table-row>
          } @empty {
          <!-- EMPTY, a colspan értékét az adott táblázathoz kell beállítani  -->
          <tr>
            <td class="text-center" colspan="100">
              <table-empty-state noDataTitle="No Users.">
              </table-empty-state>
            </td>
          </tr>
          } }
        </table>
      </div>
      <div class="m-4">
        <table-pager
          [totalItems]="totalItems"
          [isLoading]="isPagerLoading"
          (onPageChange)="handlePagination($event)"
        ></table-pager>
      </div>
    </div>
  </div>
  
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- User Creator Modal -->
  <!-- TODO: cserélni, mert ez itt nem user creator
  <users-creator [user]="selectedUser" (onCreate)="refresh(currentPage)">
  </users-creator> -->
  <!-- End User Creator Modal -->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-delete-user-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete user {{
      selectedUser.id
    }}? This action cannot be undone."
    confirmButtonText="Yes, Delete"
    (confirmed)="performDelete()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
  