import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { IUnitModel } from '../../../../services/api/unit/unit-model.interface';

@Component({
  selector: 'unit-table-row',
  standalone: true,
  imports: [UiButtonComponent],
  templateUrl: './unit-table-row.component.html',
  styleUrl: './unit-table-row.component.css',
})
export class UnitTableRowComponent {
  @Input() pitem: IUnitModel = {} as IUnitModel;
  @Output() onDelete = new EventEmitter<IUnitModel>();
  @Output() onEdit = new EventEmitter<IUnitModel>();
  @Output() onView = new EventEmitter<IUnitModel>();

  constructor() {}

  handleEdit(pitem: IUnitModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IUnitModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IUnitModel) {
    this.onDelete.emit(pitem);
  }
}
