import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { IParkingRequestModel } from '../../../../services/api/parking-request/parking-request-model.interface';
import { BookingClientService } from '../../../../services/api/booking/booking-client.service';
import { LogService } from '../../../../services/utils/log.service';
import { FlatClientService } from '../../../../services/api/flat/flat-client.service';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'parking-requests-table-row',
  standalone: true,
  templateUrl: './parking-requests-table-row.component.html',
  styleUrl: './parking-requests-table-row.component.css',
  imports: [
    UiSwitchComponent, 
    UiBadgeComponent,
    UiButtonComponent
  ],
})
export class ParkingRequestsTableRowComponent {
  @Input() pitem: IParkingRequestModel = {} as IParkingRequestModel;
  @Output() onDelete = new EventEmitter<IParkingRequestModel>();
  @Output() onEdit = new EventEmitter<IParkingRequestModel>();
  @Output() onView = new EventEmitter<IParkingRequestModel>();


  constructor(
    private bookingService: BookingClientService,
    private flatService: FlatClientService,
    private log: LogService
  ) {}

  handleEdit(pitem: IParkingRequestModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IParkingRequestModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IParkingRequestModel) {
    this.onDelete.emit(pitem);
  }

}
