import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MeterClientService } from '../../services/api/meter/meter-client.service';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { MeterReadingTableComponent } from '../../components/tables/meter-reading-table/meter-reading-table.component';
import { LogService } from '../../services/utils/log.service';

@Component({
  selector: 'meter-detail',
  standalone: true,
  imports: [LayoutMainComponent, RouterLink, MeterReadingTableComponent],
  templateUrl: './meter-detail.component.html',
  styleUrl: './meter-detail.component.css',
})
export class MeterDetailComponent {
  meter?: IMeterModel;

  constructor(
    private meterService: MeterClientService,
    private route: ActivatedRoute,
    private router: Router,
    private log: LogService
  ) {}

  ngOnInit() {
    this.log.debug('MeterDetailComponent init');
    this.route.params.subscribe((params) => {
      this.meterService.getItem(params['id']).subscribe({
        next: (meter) => {
          if (meter) {
            this.meter = meter;
          } else {
            //irány a 404
            this.router.navigate(['/error']);
          }
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }
}
