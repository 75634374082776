import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { ICleaningRequestModel } from './cleaning-request-model.interface';
import { CleaningRequestTransformService } from './cleaning-request-transform.service';

@Injectable({
  providedIn: 'root',
})
export class CleaningRequestClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: CleaningRequestTransformService
  ) {}

  /**
   * A takarítási kérelmek lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A takarítási kérelmek lekért oldala, nyers válaszban
   */
  private fetchCleaningRequests(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.cleaningRequests), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a takarítási kérelmek első oldalát, hogy megtudja az összes takarítási kérelem számát
   * @returns Az összes takarítási kérelem számossága
   */
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchCleaningRequests().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  private getRawCleaningRequests(
    page?: number
  ): Observable<ICleaningRequestModel[]> {
    return this.fetchCleaningRequests(page).pipe(
      map((response: any) => {
        const cleaningRequests = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        return cleaningRequests;
      })
    );
  }

  /**
   * Lekéri a takarítási kérelmeket
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A takarítási kérelmek oldala
   */
  getCleaningRequests(page?: number): Observable<ICleaningRequestModel[]> {
    return this.getRawCleaningRequests(page).pipe(
      map((requests: any) => {
        return requests.map((request: any) =>
          this.transformer.transformCleaningRequest(request)
        );
      })
    );
  }

  /**
   * Egy takarítási kérelem törlése
   * @param id A törlendő takarítási kérelem azonosítója
   * @returns A törlés eredménye
   */
  deleteCleaningRequest(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.cleaningRequest) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy takarítási kérelem létrehozása
   * @param cleaningRequest A létrehozandó takarítási kérelem adatai
   * @returns A létrehozott takarítási kérelem
   */
  createCleaningRequest(
    cleaningRequest: ICleaningRequestModel
  ): Observable<ICleaningRequestModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.cleaningRequests),
        this.transformer.serializeCleaningRequest(cleaningRequest),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as ICleaningRequestModel;
        })
      );
  }

  /**
   * Egy takarítási kérelem módosítása
   * @param cleaningRequest A módosítandó takarítási kérelem adatai
   * @returns A módosított takarítási kérelem
   */
  updateCleaningRequest(
    cleaningRequest: ICleaningRequestModel
  ): Observable<ICleaningRequestModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.cleaningRequest) +
          cleaningRequest.id,
        this.transformer.serializeCleaningRequest(cleaningRequest),
        { headers }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as ICleaningRequestModel;
        })
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'CleaningRequestClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform cleaning request operation')
    );
  };
}
