import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { ICheckinModel } from '../../../services/api/checkin/checkin-model.interface';
import { RouterLink } from '@angular/router';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'checkin-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent, 
    RouterLink, 
    UiButtonComponent,
  ],
  templateUrl: './checkin-table-row.component.html',
  styleUrl: './checkin-table-row.component.css'
})
export class CheckinTableRowComponent {
  @Input() pitem: ICheckinModel = {} as ICheckinModel ;
  @Output() onDelete = new EventEmitter<ICheckinModel>();
  @Output() onEdit = new EventEmitter<ICheckinModel>();
  @Output() onView = new EventEmitter<ICheckinModel>();


  handleEdit(pitem: ICheckinModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: ICheckinModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: ICheckinModel) {
    this.onDelete.emit(pitem);
  }

}
