import { Component, EventEmitter, Output } from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { LogService } from '../../../services/utils/log.service';
import { CheckoutsOverviewRowComponent } from './checkouts-overview-row/checkouts-overview-row.component';
import { ICheckoutOverviewItem } from './checkout-overview-item.interface';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import {
  BookingClientService,
  IBookingQueryParams,
} from '../../../services/api/booking/booking-client.service';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { format, parseISO, isToday } from 'date-fns';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';

@Component({
  selector: 'checkouts-overview',
  standalone: true,
  imports: [
    UiButtonComponent,
    CheckoutsOverviewRowComponent,
    TableEmptyStateComponent,
    UiTablePagerComponent
  ],
  templateUrl: './checkouts-overview.component.html',
  styleUrl: './checkouts-overview.component.css',
})
export class CheckoutsOverviewComponent {
  @Output() checkoutsCount = new EventEmitter<number>();
  checkouts: ICheckoutOverviewItem[] = [];
  isLoading = true;
  totalItems = 0;
  isPagerLoading = false;

  constructor(
    private bookingService: BookingClientService,
    private log: LogService,
    private formatter: ModelFormatterService,
    private hardCodedConfig: HardCodedConfigService
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.log.info('Loading bookings ending today');

    // Azokat a foglalásokat kérjük le, amiknek az end_date-je ma van
    const today = new Date();
    const queryParams = {
      endDate: format(today, 'yyyy-MM-dd'),
    } as IBookingQueryParams;

    this.bookingService.getAllBookings(queryParams).subscribe({
      next: (data) => {
        this.checkouts = data
          // Nem kellenek csak a ma távozó foglalások, amik persze elfogadottak
          .filter(
            (booking) =>
              isToday(parseISO(booking.end_date ?? '9999-12-31')) &&
              booking.bookingStatus?.id ===
              this.hardCodedConfig.FINALIZED_BOOKING_STATUS_ID
          )
          // Nem kell az egész booking, átalakítjuk a megjelenítéshez szükséges formátumra
          .map((booking: IBookingModel) => {
            return {
              id: booking.id,
              name: booking.name ?? '',
              flats: booking.flats?.map((flat) => {
                return {
                  id: flat.id,
                  building: flat.building?.name ? flat.building.name : '',
                  room: this.formatter.getFormattedFlat(flat, false),
                };
              }),
              startDate: booking.start_date ?? '',
              endDate: booking.end_date ?? '',
              checkoutDate: booking.checkout_time ?? '',
              isCheckedOut: !!booking.checked_out,
            };
          })
          .sort((a, b) => {
            // ellenőrzések. Ha nincs flats tömb, vagy 0 hosszú, akkor a másik jön előrébb. Ha mindkettőnél ez van, akkor meg 0-t ad vissza.
            if (!a.flats || a.flats.length === 0) {
              if (!b.flats || b.flats.length === 0) return 0;
              return -1;
            }
            if (!b.flats || b.flats.length === 0) return 1;
            return (a.flats[0]?.building || '').localeCompare(
              b.flats[0]?.building || ''
            );
          });
        this.totalItems = this.checkouts.length;
        this.checkoutsCount.emit(this.totalItems);
        this.isLoading = false;
        this.log.debug('Loaded checkouts', this.checkouts);
      },
      error: (error) => {
        this.log.error('Error loading checkouts', error);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.bookingService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });

  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  checkoutBooking(bookingId: number) {
    this.log.info('Checking out manually');
    this.bookingService.checkoutBooking(bookingId).subscribe({
      next: (data) => {
        this.log.debug('Checkout success', data);
        this.refresh();
      },
      error: (error) => {
        this.log.error('Checkout error', error);
      },
    });
  }

  cancelCheckout(bookingId: number) {
    this.log.info('Cancelling checkout manually');
    this.bookingService.unCheckoutBooking(bookingId).subscribe({
      next: (data) => {
        this.log.debug('Checkout cancelled', data);
        this.refresh();
      },
      error: (error) => {
        this.log.error('Checkout cancel error', error);
      },
    });
  }
}
