import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { CleaningTypeTableComponent } from '../../components/tables/cleaning-type-table/cleaning-type-table.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cleaning-type',
  standalone: true,
  imports: [LayoutMainComponent, CleaningTypeTableComponent, RouterLink],
  templateUrl: './cleaning-type.component.html',
  styleUrl: './cleaning-type.component.css',
})
export class CleaningTypeComponent {}
