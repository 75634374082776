import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-badge.component.html',
  styleUrl: './ui-badge.component.css',
})
export class UiBadgeComponent {
  @Input() iconSrc: string = '';
  @Input() iconAlt: string = '';
  @Input() iconClass: string = '';
  @Input() iconPos: 'left' | 'right' = 'left';
  @Input() badgeType: 'solid' | 'outline' | 'soft' = 'soft';
  @Input() color: 'black' | 'gray' | 'teal' | 'blue' | 'red' | 'yellow' | 'green' | 'orange' | 'white' =
    'black';
  @Input() cornerType: 'rounded' | 'full' = 'rounded';

  getBadgeClasses() {
    /** Alap osztályok */
    let classes =
      'inline-flex items-center gap-x-1.5 py-1.5 px-3 text-xs font-medium';

    /** Méret szerinti osztályok */
    switch (this.cornerType) {
      case 'rounded':
        classes += ' rounded-md ';
        break;
      case 'full':
        classes += ' rounded-full ';
        break;
      default:
        classes += ' rounded-md ';
        break;
    }

    /** Szín szerinti osztályok */
    if (this.badgeType === 'solid') {
      classes += ' text-white ';
      switch (this.color) {
        case 'black':
          classes += ' bg-gray-800 ';
          break;
        case 'gray':
          classes += ' bg-gray-500 ';
          break;
        case 'teal':
          classes += ' bg-teal-500 ';
          break;
        case 'blue':
          classes += ' bg-blue-600 ';
          break;
        case 'red':
          classes += ' bg-red-500 ';
          break;
        case 'yellow':
          classes += ' bg-yellow-500 ';
          break;
        case 'green':
          classes += ' bg-green-500 ';
          break;
        case 'orange':
          classes += ' bg-orange-500 ';
          break;
        case 'white':
          classes += ' bg-white shadow-sm ';
          break;

      }
    } else if (this.badgeType === 'outline') {
      switch (this.color) {
        case 'black':
          classes += ' border border-gray-800 text-gray-800 ';
          break;
        case 'gray':
          classes += ' border border-gray-500 text-gray-500 ';
          break;
        case 'teal':
          classes += ' border border-teal-500 text-teal-500 ';
          break;
        case 'blue':
          classes += ' border border-blue-600 text-blue-600 ';
          break;
        case 'red':
          classes += ' border border-red-500 text-red-500 ';
          break;
        case 'yellow':
          classes += ' border border-yellow-500 text-yellow-500 ';
          break;
        case 'green':
          classes += ' border border-green-500 text-green-500 ';
          break;
        case 'orange':
          classes += ' border border-orange-500 text-orange-500 ';
          break;
        case 'white':
          classes += ' bg-white border-gray-100 shadow-sm ';
          break;
      }
    } else if (this.badgeType === 'soft') {
      switch (this.color) {
        case 'black':
          classes += ' bg-gray-100 text-gray-800 ';
          break;
        case 'gray':
          classes += ' bg-gray-50 text-gray-500 ';
          break;
        case 'teal':
          classes += ' bg-teal-100 text-teal-800 ';
          break;
        case 'blue':
          classes += ' bg-blue-100 text-blue-800 ';
          break;
        case 'red':
          classes += ' bg-red-100 text-red-800 ';
          break;
        case 'yellow':
          classes += ' bg-yellow-100 text-yellow-800 ';
          break;
        case 'green':
          classes += ' bg-green-100 text-green-800 ';
          break;
        case 'orange':
          classes += ' bg-orange-100 text-orange-800 ';
          break;
        case 'white':
          classes += ' bg-white border-gray-300 shadow-md ';
         
          break;
      }
    }
    return classes;
  }

  getIconClasses() {
    //TODO: megcsinálni
    let classes = '';
    classes = this.iconClass;
    return classes;
  }
}
