import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { IPaymentTransactionModel } from './payment-transaction-model.interface';
import { PaymentTransactionTransformService } from './payment-transaction-transform.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentTransactionClientService {
  private totalItems!: number;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: PaymentTransactionTransformService
  ) {}

  /**
   * A fizetési tranzakciók lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A fizetési tranzakciók lekért oldala, nyers válaszban
   */
  private fetchPaymentTransactions(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.paymentTransactions), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a fizetési tranzakciók első oldalát, hogy megtudja az összes fizetési tranzakció számát
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns Az összes fizetési tranzakció számossága
   */
  getTotalItems(page?: number): Observable<number> {
    if (!this.totalItems) {
      return this.fetchPaymentTransactions(page).pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Lekéri a fizetési tranzakciókat úgy, hogy a dátum/idő mezőket átalakítja
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A fizetési tranzakciók lekért oldala, átalakított dátum/idő mezőkkel
   */
  getPaymentTransactions(
    page?: number
  ): Observable<IPaymentTransactionModel[]> {
    return this.getRawPaymentTransactions(page).pipe(
      map((transactions) => {
        return transactions.map((paymentTransaction: any) => {
          return this.transformer.transformPaymentTransaction(
            paymentTransaction
          );
        });
      })
    );
  }

  /**
   * Egy fizetési tranzakció törlése
   * @param id A törlendő fizetési tranzakció azonosítója
   * @returns A törlés eredménye
   */
  deletePaymentTransaction(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.paymentTransaction) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy fizetési tranzakció létrehozása
   * @param paymentTransaction Az új fizetési tranzakció adatai
   * @returns Az új fizetési tranzakció adatai
   */
  createPaymentTransaction(
    paymentTransaction: IPaymentTransactionModel
  ): Observable<IPaymentTransactionModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.paymentTransactions),
        this.transformer.serializePaymentTransaction(paymentTransaction),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => {
          return this.transformer.transformPaymentTransaction(response);
        })
      );
  }

  /**
   * Egy fizetési tranzakció módosítása
   * @param paymentTransaction Az módosítandó fizetési tranzakció adatai
   * @returns Az módosított fizetési tranzakció adatai
   */
  updatePaymentTransaction(
    paymentTransaction: IPaymentTransactionModel
  ): Observable<IPaymentTransactionModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.paymentTransaction) +
          paymentTransaction.id,
        this.transformer.serializePaymentTransaction(paymentTransaction),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => {
          return this.transformer.transformPaymentTransaction(response);
        })
      );
  }

  private getRawPaymentTransactions(page?: number) {
    return this.fetchPaymentTransactions(page).pipe(
      map((response: any) => {
        const paymentTransactions = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        return paymentTransactions;
      })
    );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'PaymentTransactionClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform payment transaction operation')
    );
  };
}
