export interface MenuItem {
  id: number;
  name: string;
  icon: string;
  link: string;
  subMenuItems: SubMenuItem[];
  isCategoryHeader?: boolean;
}

export interface SubMenuItem {
  id: number;
  name: string;
  link: string;
}

export const menuItems: MenuItem[] = [
  /*   {
    _id: 0,
    name: 'Dashboard',
    icon: 'house',
    link: '/',
    subMenuItems: []
  }, */
  {
    id: 1,
    name: 'Short-term rental',
    icon: 'house',
    link: '',
    subMenuItems: [
      {
        id: 0,
        name: 'Overview',
        link: '/overview',
      },
      {
        id: 1,
        name: 'Booking Overview',
        link: '/booking-overview',
      },
      {
        id: 2,
        name: 'Bookings',
        link: '/bookings',
      },
      {
        id: 3,
        name: 'Checkins',
        link: '/checkins',
      },
      {
        id: 4,
        name: 'Invoicing',
        link: '/invoicing',
      },
    ],
  },
  {
    id: 2,
    name: 'Long-term leasing  ',
    icon: 'building-2',
    link: '',
    subMenuItems: [
      {
        id: 0,
        name: 'Overview',
        link: '/',
      },
      {
        id: 1,
        name: 'Properties',
        link: '/properties',
      },
      {
        id: 2,
        name: 'Tenants',
        link: '/tenants',
      },
    ],
  },
  {
    id: 3,
    name: 'Services',
    icon: 'tags',
    link: '',
    subMenuItems: [
      {
        id: 0,
        name: 'Parking',
        link: '/parking',
      },
      {
        id: 1,
        name: 'Cleaning',
        link: '/cleaning',
      },
      {
        id: 2,
        name: 'Arrival & Departure',
        link: '/arrival-departure',
      },
    ],
  },
  {
    id: 4,
    name: 'Issues',
    icon: 'clipboard-check',
    link: '/issues',
    subMenuItems: [],
  },
  {
    id: 5,
    name: 'Management',
    icon: '',
    link: '',
    subMenuItems: [],
    isCategoryHeader: true,
  },
  {
    id: 6,
    name: 'Users',
    icon: 'users',
    link: '/users',
    subMenuItems: [],
  },
  {
    id: 7,
    name: 'Infrastucture',
    icon: 'lightbulb',
    link: '',
    subMenuItems: [
      {
        id: 0,
        name: 'Flat Types',
        link: '/flat-type',
      },
      {
        id: 1,
        name: 'Cleaning Types',
        link: '/cleaning-type',
      },  
      {
        id: 2,
        name: 'Issue Statuses',
        link: '/issue-status',
      }, 
      {
        id: 3,
        name: 'Floors',
        link: '/floor',
      },  
      {
        id: 4,
        name: 'Taxes',
        link: '/tax',
      },
      {
        id: 5,
        name: 'Units',
        link: '/unit',
      },  
      {
        id: 6,
        name: 'Meters',
        link: '/meters',
      },                             
    ],
  },
  {
    id: 8,
    name: 'New Property',
    icon: 'users',
    link: '/new-property',
    subMenuItems: [],
  }, 
  /* {
    id: 8,
    name: 'Properties',
    icon: 'building-2',
    link: '',
    subMenuItems: [
      {
        id: 0,
        name: 'New Property',
        link: '', // link: '/properties/add'
      },
      {
        id: 1,
        name: 'Property Manager',
        link: '',
      },
      {
        id: 2,
        name: 'Document Inventory',
        link: '/document-inventory',
      },
    ],
  }, */
];
