import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { ICleaningScheduleModel } from '../../../../services/api/cleaning-schedule/cleaning-schedule-model.interface';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { SlicePipe } from '@angular/common';
import { FlatFormatterPipe } from '../../../../services/utils/flat-formatter.pipe';

@Component({
  selector: 'cleaning-schedule-table-row',
  standalone: true,
  templateUrl: './cleaning-schedule-table-row.component.html',
  styleUrl: './cleaning-schedule-table-row.component.css',
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
    SlicePipe,
    FlatFormatterPipe,
  ],
})
export class CleaningScheduleTableRowComponent implements OnChanges {
  @Input() pitem: ICleaningScheduleModel = {} as ICleaningScheduleModel;
  @Output() onDelete = new EventEmitter<ICleaningScheduleModel>();
  @Output() onEdit = new EventEmitter<ICleaningScheduleModel>();
  @Output() onView = new EventEmitter<ICleaningScheduleModel>();

  displayedBuilding: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pitem && this.pitem.building) {
      this.displayedBuilding = this.pitem.building.name || '';
    } else if (this.pitem && this.pitem.flat) {
      this.displayedBuilding = this.pitem.flat.building?.name || '';
    } else {
      this.displayedBuilding = '';
    }
  }

  handleEdit(pitem: ICleaningScheduleModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: ICleaningScheduleModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: ICleaningScheduleModel) {
    this.onDelete.emit(pitem);
  }
}
