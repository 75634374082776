<tr>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm font-bold text-gray-800 align-middle text-right"
  >
    {{ pitem.id }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <ui-switch
      [checked]="!!pitem.paid"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <ui-badge
      [color]="
        pitem.invoiceStatus?.id == 1
          ? 'black'
          : pitem.invoiceStatus?.id == 2
          ? 'yellow'
          : pitem.invoiceStatus?.id == 3
          ? 'red'
          : pitem.invoiceStatus?.id == 4
          ? 'green'
          : 'green'
      "
      badgeType="soft"
    >
      @if(pitem.invoice_number){
      {{ pitem.invoice_number }}
      }@else{
      {{ pitem.invoiceStatus?.name ?? "N/A" }}
      }</ui-badge
    >
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.fulfillment_date }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-blue-600 align-middle font-bold"
  >
    @if(pitem.bookings?.[0]?.id){
    <span class="cursor-pointer"
      [routerLink]="'/bookings/' +  pitem.bookings?.[0]?.id"
      >{{ pitem.bookings?.[0]?.bookingSource?.name }}</span
    >
    <span
      class="block text-blue-400 text-xs font-normal cursor-pointer"
      [routerLink]="'/bookings/' +  pitem.bookings?.[0]?.id"
      >{{
    pitem.bookings?.[0]?.foreign_reservation_code
      }}</span
    >} @if(pitem.parkingRequests?.length){ <span class="text-gray-800">Parking</span>}
    @if(pitem.cleaningRequests?.length){ <span class="text-gray-800">Cleaning</span>}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle font-bold"
  >
    @if(pitem.customerBillingAddress){
    @if(pitem.customerBillingAddress.company_name){
    {{ pitem.customerBillingAddress.company_name }}
    <br />
    {{ pitem.customerBillingAddress.name }}
    }@else{
    {{ pitem.customerBillingAddress.name }}
    }
    <span class="block text-gray-600 text-xs font-normal">{{
      pitem.customerBillingAddress | billingAddress
    }}</span>
    }
  </td>

  <td
    class="px-4 py-3 leading-4 text-right whitespace-nowrap font-mono font-bold text-sm text-gray-800 align-middle"
  >
    {{ pitem.gross_total | number : "1.2-2" | correctCurrencyFormat }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.currency?.code }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-end text-sm align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
        btnType="inline"
        (click)="handleView(pitem)"
        inlinePos="left"
        iconSrc="assets/img/eye.svg"
        data-hs-overlay="#hs-invoice-editor-modal"
      >
        <span class="shrink-0 text-xs"> View </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-invoice-editor-modal"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>
      <!--   -->
      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-invoice-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
