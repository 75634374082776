import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { IInventoryItemModel } from '../../../services/api/inventory-item/inventory-item-model.interface';
import { DatePipe } from '@angular/common';
import { API_ENDPOINTS, ApiService } from '../../../services/api/api.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';

@Component({
  selector: 'document-inventory-table-row',
  standalone: true,
  templateUrl: './document-inventory-table-row.component.html',
  styleUrl: './document-inventory-table-row.component.css',
  imports: [
    DatePipe,
    UiSwitchComponent,
  ],
})
export class DocumentInventoryTableRowComponent {
  @Input() item: IInventoryItemModel = {} as IInventoryItemModel;
  @Output() onDelete: EventEmitter<IInventoryItemModel> = new EventEmitter();

  constructor(private apiService: ApiService, private renderer: Renderer2) {}

  handleDownload() {
    const url =
      this.apiService.getUrlFor(API_ENDPOINTS.inventoryFile) +
      this.item.filename;
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', this.item.filename ?? 'file');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  handleDelete() {
    this.onDelete.emit(this.item);
  }
}
