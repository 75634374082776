import { Component, ViewChild } from '@angular/core';
import { TaxTableRowComponent } from './tax-table-row/tax-table-row.component';
import { TaxEditorComponent } from '../../modal-editors/tax-editor/tax-editor.component';
import { ITaxModel } from '../../../services/api/tax/tax-model.interface';
import { TaxClientService } from '../../../services/api/tax/tax-client.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'tax-table',
  standalone: true,
  imports: [
    UiButtonComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
    TaxEditorComponent,
    TaxTableRowComponent,
  ],
  templateUrl: './tax-table.component.html',
  styleUrl: './tax-table.component.css',
})
export class TaxTableComponent {
  @ViewChild(TaxEditorComponent)
  editor!: TaxEditorComponent;
  selectedTax!: ITaxModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  tax: ITaxModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private taxClientService: TaxClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: ITaxModel) {
    this.selectedTax = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ITaxModel) {
    this.selectedTax = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.taxClientService.deleteItem(this.selectedTax.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('Tax deleted');
        this.tax = this.tax.filter((pr) => pr.id !== this.selectedTax.id);
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.tax.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Tax deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting Tax');
        this.log.error('Error deleting tax', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedTax = {
      '@id': '',
      id: 0,
      name: '',
      percentage: 0,
    } as ITaxModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.taxClientService.getItems(page).subscribe({
      next: (tax) => {
        this.log.debug('Tax requests fetched', tax);
        this.tax = tax;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);

        setTimeout(() => {
          this.log.info('Tax table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching tax', err);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.taxClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
