<div class="">
  <div class="p-2">
    <form
      id="booking-reservation-form"
      [formGroup]="bookingReservationForm"
      (ngSubmit)="saveBookingReservationInfo()"
    >
      <!--  form select   -->
      <label for="booking-reservation-status" class="block font-bold"
        >Booking Status</label
      >
      <ng-select
        class="w-full max-w-lg inline-block mb-4"
        labelForId="booking-reservation-status"
        [items]="bookingStatusSelectItems"
        bindLabel="title"
        bindValue="val"
        [clearable]="false"
        formControlName="bookingStatus"
        placeholder="Select booking status"
      >
      </ng-select>

      <!--  form date  -->
      <div class="sm:flex gap-x-4 max-w-lg">
        <div class="basis-1/2">
          <label
            for="booking-reservation-start_date"
            class="block mb-2 font-bold"
            >Start Date</label
          >
          <input
            class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2"
            type="date"
            placeholder=""
            name="booking-reservation-start_date"
            [value]="booking?.start_date"
            formControlName="start_date"
          />
        </div>
        <div class="basis-1/2">
          <label for="booking-reservation-end_date" class="block mb-2 font-bold"
            >End Date</label
          >
          <input
            class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2"
            type="date"
            placeholder=""
            name="booking-reservation-end_date"
            [value]="booking?.end_date"
            formControlName="end_date"
          />
        </div>
      </div>
      <!--  // form date  -->

      <!--  form number  -->
      <label
        for="booking-reservation-adult_occupants"
        class="block mb-2 font-bold"
        >Adult Occupants</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2"
        type="number"
        placeholder=""
        name="booking-reservation-adult_occupants"
        [value]="booking?.adult_occupants"
        formControlName="adult_occupants"
      />
      <!--  // form number -->

      <!--  form number  -->
      <label
        for="booking-reservation-child_occupants"
        class="block mb-2 font-bold"
        >Child Occupants</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2"
        type="number"
        placeholder=""
        name="booking-reservation-child_occupants"
        [value]="booking?.child_occupants"
        formControlName="child_occupants"
      />
      <!--  // form number -->

      <!--  form number  -->
      <label
        for="booking-reservation-number_of_rooms"
        class="block mb-2 font-bold"
        >Number of rooms</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-1/2"
        type="number"
        placeholder=""
        name="booking-reservation-number_of_rooms"
        [value]="booking?.number_of_rooms"
        formControlName="number_of_rooms"
      />
      <!--  // form number  -->

      <!--  form text  -->
      <label for="booking-reservation-code" class="block mb-2 font-bold"
        >Reservation Code</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg bg-gray-200"
        type="text"
        placeholder=""
        name="booking-reservation-code"
        disabled
        [value]="booking?.reservation_code"
      />
      <!--  // form text  -->

      <!--  form text  -->
      <label
        for="booking-reservation-foreign_reservation_code"
        class="block mb-2 font-bold"
        >Foreign Reservation Code</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
        type="text"
        placeholder=""
        name="booking-reservation-foreign_reservation_code"
        [value]="
          booking?.foreign_reservation_code === undefined
            ? ''
            : booking?.foreign_reservation_code
        "
        formControlName="foreign_reservation_code"
      />
      <!--  // form text  -->

      <div class="w-full max-w-lg text-right">
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          form="booking-reservation-form"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
