import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { IBuildingModel } from './building-model.interface';

@Injectable({
  providedIn: 'root',
})
export class BuildingClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * az épületek lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns az épületek lekért oldala, nyers válaszban
   */
  private fetchBuildings(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.buildings), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri az épületek első oldalát, hogy megtudja az összes épület számát
   * @returns Az összes épület számossága
   */
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchBuildings().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Lekéri az épületekat
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns az épületek oldala
   */
  getBuildings(page?: number): Observable<IBuildingModel[]> {
    return this.fetchBuildings(page).pipe(
      map((response: any) => {
        const buildings = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        return buildings;
      })
    );
  }

  /**
   * Egy épület törlése
   * @param id A törlendő épület azonosítója
   * @returns A törlés eredménye
   */
/*   deleteBuilding(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.building) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  } */

  /**
   * Egy épület létrehozása
   * @param building A létrehozandó épület adatai
   * @returns A létrehozott épület
   */
/*   createBuilding(
    building: IBuildingModel
  ): Observable<IBuildingModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.buildings),
        building,
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as IBuildingModel;
        })
      );
  } */

  /**
   * Egy épület módosítása
   * @param building A módosítandó épület adatai
   * @returns A módosított épület
   */
/*   updateBuilding(
    building: IBuildingModel
  ): Observable<IBuildingModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.building) +
          building.id,
        building,
        { headers }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as IBuildingModel;
        })
      );
  } */

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'buildingClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform cleaning package operation')
    );
  };
}
