import { Component, ViewChild } from '@angular/core';
import { CleaningRequestTableRowComponent } from './cleaning-request-table-row/cleaning-request-table-row.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { CleaningRequestEditorComponent } from '../../modal-editors/cleaning-request-editor/cleaning-request-editor.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ICleaningRequestModel } from '../../../services/api/cleaning-request/cleaning-request-model.interface';
import { CleaningRequestClientService } from '../../../services/api/cleaning-request/cleaning-request-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';


@Component({
  selector: 'cleaning-request-table',
  standalone: true,
  templateUrl: './cleaning-request-table.component.html',
  styleUrl: './cleaning-request-table.component.css',
  imports: [
    CleaningRequestTableRowComponent,
    UiButtonComponent,
    CleaningRequestEditorComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
})
export class CleaningRequestTableComponent {
  @ViewChild(CleaningRequestEditorComponent)
  editor!: CleaningRequestEditorComponent;
  selectedCleaningRequest!: ICleaningRequestModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  cleaningRequests: ICleaningRequestModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private log: LogService,
    private cleaningRequestService: CleaningRequestClientService,
    private toastr: ToastrService,
  ) {}

  handleEdit(pitem: ICleaningRequestModel) {
    this.selectedCleaningRequest = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: ICleaningRequestModel) {
    this.selectedCleaningRequest = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ICleaningRequestModel) {
    this.selectedCleaningRequest = pitem;
  }

  // a törlés megerősítése után töröljük a kijelölt elemet
  performDelete() {
    this.cleaningRequestService
      .deleteCleaningRequest(this.selectedCleaningRequest.id)
      .subscribe({
        next: () => {
          // ha sikeres volt a törlés, akkor elhisszük, de nem kérjük le az összes elemet újra
          this.log.info('Cleaning request deleted successfully');
          this.cleaningRequests = this.cleaningRequests.filter(
            (item) => item.id !== this.selectedCleaningRequest.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if(this.cleaningRequests.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Cleaning request deleted successfully');
        },
        error: (err) => {
          this.toastr.error('Error deleting cleaning request');
          this.log.error('Error deleting cleaning request', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedCleaningRequest = {
      '@id': '',
      id: 0,
      paid: false,
      paymentTransactions: [],
      cleaningSchedules: [],
      createdA_a: '',
      updated_at: '',
    } as ICleaningRequestModel;
  }

  refresh(page?: number) {
      // lekérjük a takarítási kérelmeket
    this.isLoading = true;
    this.cleaningRequestService
      .getCleaningRequests(page)
      .subscribe((requests) => {
        this.cleaningRequests = requests;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.cleaningRequestService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
