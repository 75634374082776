import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { IContractModel } from '../../../services/api/contract/contract-model.interface';
import { ContractClientService } from '../../../services/api/contract/contract-client.service';
import { format, formatISO, parse } from 'date-fns';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';

@Component({
  selector: 'contract-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgSelectModule,
    CommonModule,
    UiSwitchComponent,
  ],
  templateUrl: './contract-editor.component.html',
  styleUrl: './contract-editor.component.css',
})
export class ContractEditorComponent {
  @Input() contract?: IContractModel;
  @Input() tenantOnly: boolean = false;
  @Input() property?: IFlatModel;
  @Input() mode: 'create' | 'edit' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  tenantSelectItems: IUiSelectItem[] = [];
  isOpenEnded = true;
  contractForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private contractService: ContractClientService,
    private toastr: ToastrService,
    private silo: SelectItemLoaderService
  ) {
    this.contractForm = this.fb.group({
      contract_number: [''],
      contract_date: [''],
      tenancy_starts_at: [''],
      open_ended: [true],
      tenancy_ends_at: [''],
      tenants: [[]],
    });
  }

  ngOnChanges() {
    this.log.debug('ConstractEditor Changed', this.contract);
    this.contractForm.reset();
    this.reset();
    if (this.mode === 'edit') {
      this.contractForm.controls['contract_number'].setValue(
        this.contract?.contract_number ?? ''
      );
      this.contractForm.controls['contract_date'].setValue(
        this.contract?.contract_date ?? ''
      );
      this.contractForm.controls['tenancy_starts_at'].setValue(
        this.contract?.tenancy_starts_at ?? ''
      );
      this.contractForm.controls['open_ended'].setValue(
        !!this.contract?.open_ended
      );
      this.contractForm.controls['tenancy_ends_at'].setValue(
        this.contract?.tenancy_ends_at ?? ''
      );
      const tenantID = this.contract?.tenants?.map((t) => t['@id']) ?? [];
      if (this.contract?.tenants) {
        this.contractForm.controls['tenants'].setValue(tenantID);
      }
    }
  }

  reset() {
    // a contract_date és a tenancy_starts_at mezőknek a mai dátumot kell tartalmazniuk
    this.contractForm.controls['contract_date'].setValue(
      format(new Date(), 'yyyy-MM-dd')
    );
    this.contractForm.controls['tenancy_starts_at'].setValue(
      format(new Date(), 'yyyy-MM-dd')
    );
    this.contractForm.controls['open_ended'].setValue(true);
    //letiltjuk ez end mezőt
    this.contractForm.controls['tenancy_ends_at'].disable();
  }

  ngOnInit() {
    // Betöltjük a bérlőket
    this.silo.getTenantSelectItems().subscribe({
      next: (items) => {
        this.tenantSelectItems = items;
        this.log.debug('Tenants loaded');
      },
      error: (err) => {
        this.log.error('Could not load tenants ', err);
      },
    });

    this.reset();

    // feliratkozunk a contractForm open_ended mező változásaira
    this.contractForm.controls['open_ended'].valueChanges.subscribe((value) => {
      //this.log.debug('Contract form open end changed: ', value);
      // ha a határozatlan idejű checkbox változik, akkor elmentjük az értékét
      if (value !== undefined && value !== null) {
        this.isOpenEnded = value;
        //tenancy_ends_at mezőt csak akkor kell engedélyezni, ha nem határozatlan idejű a szerződés
        if (this.isOpenEnded) {
          this.contractForm.controls['tenancy_ends_at'].disable();
          // ha disabled, akkor töröljük a mező értékéet, mintha mi sem történt volna
          this.contractForm.controls['tenancy_ends_at'].setValue('');
        } else {
          this.contractForm.controls['tenancy_ends_at'].enable();
          this.contractForm.controls['tenancy_ends_at'].setValue(
            format(new Date(), 'yyyy-MM-dd')
          );
        }
      }
    });
  }

  saveContract() {
    this.log.info('Saving Contract');
    if (this.contractForm.valid && this.property) {
      const updatedUntypedContract: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.contractForm.controls).forEach((key) => {
        const control = this.contractForm.get(key);
/*        if (control?.dirty || this.mode === 'create') {
           if(key === 'tenants' && !updatedUntypedContract[key]) {
            
          } */
          updatedUntypedContract[key] = control?.value;
      });
      if (Object.keys(updatedUntypedContract).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      // Ha szerkesztés, akkor az id-t is küldjük
      if(this.mode === 'edit' && this.contract?.id) {
        updatedUntypedContract['id'] = this.contract?.id
      }
      // Ha van property, akkor a szerződéshez hozzáadjuk
      if (this.property && this.mode === 'create') {
        updatedUntypedContract['flats'] = [this.property['@id']];
      }
      const updatedContract = updatedUntypedContract as IContractModel;
      this.log.debug('Contract request:', updatedContract);

      if (this.mode === 'edit') {
        this.contractService.updateItem(updatedContract).subscribe({
          next: (data) => {
            this.toastr.success('Contract updated');
            this.log.debug('Contract updated:', data);
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error updating Contract');
            this.log.error('Error updating Contract:', err);
          },
        });
      } else {
        this.contractService.createItem(updatedContract).subscribe({
          next: (data) => {
            this.toastr.success('Contract created');
            this.log.debug('Contract created:', data);
            this.onCreate.emit();
          },
          error: (err) => {
            this.toastr.error('Error creating Contract ');
            this.log.error('Error creating Contract:', err);
          },
        });
      }
    }  else {
      this.log.debug('Contract form is invalid or property is missing');
    }
  }
}
