import { Component, Input, OnChanges } from '@angular/core';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { LogService } from '../../../services/utils/log.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { countries } from '../../atomic-ui-components/select/country-data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'billing-data-tab',
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule],
  templateUrl: './billing-data-tab.component.html',
  styleUrl: './billing-data-tab.component.css',
})
export class BillingDataTabComponent implements OnChanges {
  @Input() booking?: IBookingModel;

  countries = countries;

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private bookingService: BookingClientService,
    private toastr: ToastrService,
  ) {}

  bookingBillingForm = this.fb.group({
    billing_name: [''],
    billing_country: [''],
    billing_pocode: [''],
    billing_county: [''],
    billing_city: [''],
    billing_address_line1: [''],
    billing_address_line2: [''],
  });

  ngOnChanges() {
    this.log.debug('Booking changed', this.booking);
    if (this.booking) {
      this.bookingBillingForm.reset();
      this.bookingBillingForm.patchValue({
        billing_name: this.booking.billing_name,
        billing_country: this.booking.billing_country,
        billing_pocode: this.booking.billing_pocode,
        billing_county: this.booking.billing_county,
        billing_city: this.booking.billing_city,
        billing_address_line1: this.booking.billing_address_line1,
        billing_address_line2: this.booking.billing_address_line2,
      });
    }
  }

  saveBookingBillingInfo(): void {
    this.log.info('Saving booking billing info');
    if (this.booking && this.bookingBillingForm.valid) {
      const updatedUntypedBooking: { [key: string]: any } = {};

      // Csak a módosított mezőket küldjük el
      Object.keys(this.bookingBillingForm.controls).forEach((key) => {
        const control = this.bookingBillingForm.get(key);
        if (control?.dirty) {
          updatedUntypedBooking[key] = control.value;
        }
      });

      if (Object.keys(updatedUntypedBooking).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedBooking = updatedUntypedBooking as IBookingModel;
      this.log.debug('Saving Booking:', updatedBooking);
      updatedBooking.id = this.booking?.id ?? 0;

      this.bookingService.updateBooking(updatedBooking).subscribe({
        next: (data) => {
          this.toastr.success('Booking updated');
          this.log.debug('Booking updated:', data);
        },
        error: (error) => {
          this.toastr.error('Error while updating booking');
          this.log.error('Error while updating booking:', error);
        },
      });
    }
  }
}
