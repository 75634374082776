import { Component, Input, OnChanges } from '@angular/core';
import { UiSelectComponent } from '../../atomic-ui-components/select/ui-select.component';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'rooms-apartments-tab',
  standalone: true,
  imports: [UiSelectComponent, ReactiveFormsModule, NgSelectModule],
  templateUrl: './rooms-apartments-tab.component.html',
  styleUrl: './rooms-apartments-tab.component.css',
})
export class RoomsApartmentsComponent implements OnChanges {
  @Input() booking?: IBookingModel;
  flatSelectItems = [] as IUiSelectItem[];

  constructor(
    private log: LogService,
    private silo: SelectItemLoaderService,
    private fb: FormBuilder,
    private bookingService: BookingClientService,
    private toaster: ToastrService,
  ) {}

  bookingFlatForm = this.fb.group({
    flats: [[''], Validators.required],
  });

  ngOnChanges() {
    this.log.debug('Booking changed', this.booking);
    if (this.booking) {
      this.bookingFlatForm.reset();
      this.bookingFlatForm.patchValue({
        flats: this.getFlatIds(this.booking.flats),
      });
    }
  }

  private getFlatIds(flats?: IFlatModel[]): string[] {
    if (flats) {
      return flats.map((flat) => flat['@id']);
    }
    return [];
  }

  ngOnInit() {
    // Betöltjük a lakásokat a dropdownhoz
    this.silo.getFlatSelectItems().subscribe({
      next: (items) => {
        this.flatSelectItems = new Array(...items);
      },
      error: (err) => {
        this.log.error('Error loading flat select items:', err);
      },
    });
  }

  saveBookingFlatInfo() {
    this.log.info('Saving booking flat info');
    if (
      this.booking &&
      this.bookingFlatForm.valid &&
      this.bookingFlatForm.dirty
    ) {
      const updatedBooking = {
        id: this.booking.id,
        flats: this.bookingFlatForm.value.flats,
      } as IBookingModel;
      this.log.debug('Updated booking:', updatedBooking);
      this.bookingService.updateBooking(updatedBooking).subscribe({
        next: (data) => {
          this.toaster.success('Booking updated successfully');
          this.log.debug('Booking updated:', data);
        },
        error: (err) => {
          this.toaster.error('Error updating booking');
          this.log.error('Error updating booking:', err);
        },
      });
    }
  }
}
