@if(totalItems != 0){
<!-- Table Footer -->
<div class="mt-5 flex flex-wrap justify-between items-center gap-2">
  @if(isLoading){
  <p class="text-sm text-stone-800 animate-pulse">
    <span class="font-medium inline-block bg-gray-200 rounded w-4 h-4"> </span>
    <span class="text-stone-500 inline-block ml-1">results</span>
  </p>
  } @else {
  <p class="text-sm text-stone-800">
    <span class="font-medium">{{ totalItems }}</span>
    <span class="text-stone-500"> results</span>
  </p>
  }

  <!-- Pagination -->
  <nav class="flex justify-end items-center gap-x-1">
    <button
    type="button"
    (click)="handlePageStart()"
    class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-stone-800 hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100"
  >
    <img src="assets/img/first_page.svg" class="h-5" >
    <span aria-hidden="true" class="sr-only">Previous</span>
  </button>    
    <button
      type="button"
      (click)="handlePagePrev()"
      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-stone-800 hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100"
    >
      <svg
        class="flex-shrink-0 size-3.5"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m15 18-6-6 6-6" />
      </svg>
      <span aria-hidden="true" class="sr-only">Previous</span>
    </button>
    <div class="flex items-center gap-x-1">
      <span
        class="min-h-[38px] min-w-[38px] flex justify-center items-center bg-stone-100 text-stone-800 py-2 px-3 text-sm rounded-lg disabled:opacity-50 disabled:pointer-events-none"
        >{{ currentPage }}</span
      >
      <span
        class="min-h-[38px] flex justify-center items-center text-stone-500 py-2 px-1.5 text-sm"
        >of</span
      >
      @if(isLoading){
      <div
        class="min-h-[38px] flex justify-center items-center bg-gray-200 animate-pulse py-2 px-1.5 text-sm w-8"
      ></div>
      } @else {
      <span
        class="min-h-[38px] flex justify-center items-center text-stone-500 py-2 px-1.5 text-sm"
        >{{ maxPage }}</span
      >}
    </div>
    <button
      type="button"
      (click)="handlePageNext()"
      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-stone-800 hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100"
    >
      <span aria-hidden="true" class="sr-only">Next</span>

      <svg
        class="flex-shrink-0 size-3.5"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="m9 18 6-6-6-6" />
      </svg>
    </button>
    <button
    type="button"
    (click)="handlePageEnd()"
    class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-stone-800 hover:bg-stone-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-stone-100"
  >
    <span aria-hidden="true" class="sr-only">Next</span>

    <img src="assets/img/last_page.svg" class="h-5" >
  </button>    
  </nav>
  <!-- End Pagination -->
</div>
<!-- End Table Footer -->
}
