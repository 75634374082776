import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/httpinterceptors';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import {
  Building2,
  CalendarCheck2,
  ChevronLeft,
  ClipboardCheck,
  Droplet,
  Gauge,
  House,
  Lightbulb,
  LucideAngularModule,
  Pin,
  Plug,
  Plus,
  Router,
  ScrollText,
  Tags,
  ThermometerSnowflake,
  ThermometerSun,
  Users,
} from 'lucide-angular';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    httpInterceptorProviders,
    provideAnimations(), // required animations providers
    provideToastr(), // Toastr providers
    importProvidersFrom(
      // Itt kell felsorolni azokat az ikonokat, amiket használni szeretnénk
      LucideAngularModule.pick({
        Building2,
        CalendarCheck2,
        ChevronLeft,
        ClipboardCheck,
        Droplet,
        Gauge,
        House,
        Lightbulb,
        Pin,
        Plug,
        Plus,
        Router,
        ScrollText,
        Tags,
        ThermometerSnowflake,
        ThermometerSun,
        Users,
      })
    ),
  ],
};
