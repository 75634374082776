import { Component } from '@angular/core';
import { PropertiesTableRowComponent } from './properties-table-row/properties-table-row.component';
import { PropertyRowModel } from './properties-table-row/properties-table-row-interface';
import { PropertyClientService } from '../../../services/api/property/property-client.service';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';


@Component({
  selector: 'properties-table',
  standalone: true,
  imports: 
  [
    PropertiesTableRowComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
  ], //
  templateUrl: './properties-table.component.html',
  styleUrl: './properties-table.component.css',
})
export class PropertiesTableComponent {
  propertiesRows: PropertyRowModel[] = [];
  pitem: PropertyRowModel = {} as PropertyRowModel;
  isPagerLoading = false;
  isLoading = true;
  totalItems = 0;

  constructor(
    private pcService: PropertyClientService) {}

  ngOnInit() {
    this.pcService.getAllProperties().subscribe((properties) => {
      //TODO: ez csak dummy adatsor
      this.propertiesRows = properties.slice(0, 10);
      this.totalItems = properties.length;
      this.isLoading = false;
    });
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  refresh(page?: number) {
  /*  this.isLoading = true;
    this.propertiesClientService
      .getProperties('', '', page)
      .subscribe({
        next: (properties) => {
          this.log.debug('Pproperties fetched', properties);
          this.properties = properties;
          setTimeout(() => {
            this.isLoading = false;
          }, 100);

          setTimeout(() => {
            this.log.info('Properties table initialized');
            window.HSStaticMethods.autoInit('overlay');
          }, 100);
        },
        error: (err) => {
          this.log.error('Error fetching properties', err);
          this.isLoading = false;
        },
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.propertiesClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });*/
  }

}
