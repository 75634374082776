<div class="p-4">
  <h2 class="font-bold text-lg">Parking overview</h2>
  <div>A quick glance about the parking lot utilization</div>
  <hr class="my-4" />
  <h4 class="font-bold text-lg mb-4">Lehel</h4>
  <div class="flex overflow-x-scroll pb-4">
    <div class="flex flex-row">
      <div class="w-12 text-center">
        <div class="text-sm p-2">&nbsp;</div>
        <div class="text-sm p-2 w-12">AM</div>
        <div class="text-sm p-2 w-12">PM</div>
      </div>

      @for(item of lehelOccupancyData; track item.date){
      <div class="w-12 text-center">
        <div class="text-sm p-2 text-gray-500">{{ item.date }}</div>
        <div
          class="text-sm font-medium p-2 border border-green-400"
          [ngClass]="
            item.morning < 0
              ? 'bg-red-400'
              : item.morning === 0
              ? 'bg-yellow-100'
              : item.morning < lehelMax / 2
              ? 'bg-green-300'
              : 'bg-green-600'
          "
        >
          {{ item.morning }}
        </div>
        <div
          class="text-sm font-medium p-2 border border-green-400"
          [ngClass]="
            item.afternoon < 0
              ? 'bg-red-400'
              : item.afternoon === 0
              ? 'bg-yellow-100'
              : item.afternoon < lehelMax / 2
              ? 'bg-green-300'
              : 'bg-green-600'
          "
        >
          {{ item.afternoon }}
        </div>
      </div>
      }
    </div>
  </div>
  <hr class="my-4" />
  <h4 class="font-bold text-lg mb-4">Kunigunda</h4>
  <div class="flex overflow-x-scroll pb-4">
    <div class="flex flex-row">
      <div class="w-12 text-center">
        <div class="text-sm p-2">&nbsp;</div>
        <div class="text-sm p-2 w-12">AM</div>
        <div class="text-sm p-2 w-12">PM</div>
      </div>
      @for(item of kunigundaOccupancyData; track item.date){
      <div class="w-12 text-center">
        <div class="text-sm p-2 text-gray-500">{{ item.date }}</div>
        <div
          class="text-sm p-2 font-medium border border-green-400"
          [ngClass]="
            item.morning < 0
              ? 'bg-red-400'
              : item.morning === 0
              ? 'bg-yellow-100'
              : item.morning < lehelMax / 2
              ? 'bg-green-300'
              : 'bg-green-600'
          "
        >
          {{ item.morning }}
        </div>
        <div
          class="text-sm p-2 font-medium border border-green-400"
          [ngClass]="
            item.afternoon < 0
              ? 'bg-red-400'
              : item.afternoon === 0
              ? 'bg-yellow-100'
              : item.afternoon < lehelMax / 2
              ? 'bg-green-300'
              : 'bg-green-600'
          "
        >
          {{ item.afternoon }}
        </div>
      </div>
      }
    </div>
  </div>
  <hr class="my-4" />
  <br />
  <div id="chart">
    <apx-chart
      [chart]="chartOptions.chart ? chartOptions.chart : { type: 'rangeBar' }"
      [series]="chartOptions.series ?? []"
      [dataLabels]="chartOptions.dataLabels ?? {}"
      [colors]="chartOptions.colors"
      [fill]="chartOptions.fill"
      [grid]="chartOptions.grid"
      [title]="chartOptions.title ?? {}"
      [labels]="chartOptions.labels"
      [tooltip]="chartOptions.tooltip ?? {}"
      [plotOptions]="chartOptions.plotOptions ?? {}"
      [xaxis]="chartOptions.xaxis ?? {}"
      [stroke]="chartOptions.stroke ?? {}"
    ></apx-chart>
  </div>
</div>
