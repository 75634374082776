import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { IssueTransformService } from './issue-transform.service';
import { IIssueModel } from './issue-model.interface';
import { IIssueStatusModel } from '../issue-status/issue-status-model.interface';

// TODO: normális filter kezelés
/**
 * A hibajegyek lekérdezésének szűrője. A kétféle filtert ne használd egyszerre.
 * @param status A hibajegy státuszának szűrésé
 * Megjegyzés: Az 'ALL' érték azt jelenti, hogy minden hibajegyet lekér
 * Jelenlegi szerver értékek status.id-re:
 * 1: 'Új'
 * 2: 'Megoldva'
 * @param flatId A lakás, aminke a hibajegyeid le akarjuk kérni
 */
export interface IIssueFilterType {
  status?: IIssueStatusModel | 'ALL';
  flatId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class IssueClientService {
  private totalItems!: number;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: IssueTransformService
  ) {}

  /**
   * A hibajegyek lekérése a szerverről nyers formában
   * @param filter A lekérdezés szűrője, alapértelmezetten minden hibajegyet lekér
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A hibajegyek lekért oldala, nyers válaszban
   */
  private fetchIssues(filter: IIssueFilterType, page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();

    let url = this.apiService.getUrlFor(API_ENDPOINTS.issues);
    if(filter.status !== 'ALL' && filter.status?.id) {
      url = this.apiService.getUrlFor(API_ENDPOINTS.issueStatus + filter.status.id + '/issues');
    }else if(filter.flatId) {
      url = this.apiService.getUrlFor(API_ENDPOINTS.flat + filter.flatId + '/issues');
    }
    return this.http
      .get(url, {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a hibajegyek első oldalát, hogy megtudja az összes hibajegy számát
   * @param filter A lekérdezés szűrője, alapértelmezetten minden hibajegyet lekér
   * @returns Az összes hibajegy számossága
   */
  getTotalItems(
    filter: IIssueFilterType = { status: 'ALL' }
  ): Observable<number> {
    this.log.debug('IssueClientService: getTotalItems:', filter);
    if (!this.totalItems || filter.status !== 'ALL') {
      return this.fetchIssues(filter).pipe(
        map((response: any) => {
          if (filter.status === 'ALL') {
            this.totalItems = response['hydra:totalItems'];
          }
          return response['hydra:totalItems'];
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Egy hibajegy törlése
   * @param id A törlendő hibajegy azonosítója
   * @returns A törlés eredménye
   */
  deleteIssue(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.issue) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy hibajegy létrehozása
   * @param issue Az új hibajegy adatai
   * @returns Az új hibajegy adatai
   */
  createIssue(issue: IIssueModel): Observable<IIssueModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.issues),
        this.transformer.serializeIssue(issue),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IIssueModel)
      );
  }

  /**
   * Egy hibajegy módosítása
   * @param issue Az módosítandó hibajegy adatai
   * @returns Az módosított hibajegy adatai
   */
  updateIssue(issue: IIssueModel): Observable<IIssueModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.issue) + issue.id,
        this.transformer.serializeIssue(issue),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IIssueModel)
      );
  }

  /**
   * Hibajegyek lekérése nyers formában, a dátum/idő információk eredeti formátumban
   * @param filter A lekérdezés szűrője, alapértelmezetten minden hibajegyet lekér
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A hibajegyek lekért oldala, nyers formában
   */
  private getrawIssues(
    filter: IIssueFilterType,
    page?: number
  ): Observable<IIssueModel[]> {
    return this.fetchIssues(filter, page).pipe(
      map((response: any) => {
        const issues = response['hydra:member'];
        return issues;
      })
    );
  }

  /**
   * Egy hibajegy lekérése
   * @param id A hibajegy azonosítója
   * @returns A hibajegy adatai
   */
  getIssue(id: number): Observable<IIssueModel> {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.issue) + id)
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((issue) => this.transformer.transformIssue(issue))
      );
  }

  /**
   * A hibajegyek lekérése úgy, hogy a dátum/idő információk helyes formátumban legyenek
   * @param filter A lekérdezés szűrője, alapértelmezetten minden hibajegyet lekér
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A hibajegyek lekért oldala
   */
  getIssues(
    filter: IIssueFilterType,
    page?: number
  ): Observable<IIssueModel[]> {
    return this.getrawIssues(filter, page).pipe(
      map((issues) => {
        return issues.map((issue) => this.transformer.transformIssue(issue));
      })
    );
  }

  /**
   * Az összes hibajegy lekérése
   * @param filter A lekérdezés szűrője, alapértelmezetten minden hibajegyet lekér
   * @returns Az összes hibajegy adatai
   */
  getAllIssues(
    filter: IIssueFilterType = { status: 'ALL' }
  ): Observable<IIssueModel[]> {
    // use the totalItems to get all issues, because a page contains 30 issues
    return this.getTotalItems(filter).pipe(
      switchMap((totalItems) => {
        // create an array for the observables
        let requests = [];
        // calculate the number of pages
        let pages = Math.ceil(totalItems / 30);
        // get each page and push the observable to the array
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getIssues(filter, i));
        }
        // use forkJoin to combine all observables and return the result
        return forkJoin(requests).pipe(
          map((results) => {
            let issues = [] as IIssueModel[];
            for (let result of results) {
              issues.push(...result);
            }
            return issues;
          })
        );
      })
    );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'IssueClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform issue operation'));
  };
}
