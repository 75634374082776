import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { ICleaningScheduleModel } from './cleaning-schedule-model.interface';

@Injectable({
  providedIn: 'root',
})
export class CleaningScheduleTransformService {
  constructor(private log: LogService) {}

  transformCleaningSchedule(
    cleaningSchedule: ICleaningScheduleModel
  ): ICleaningScheduleModel {
    try {
      if (cleaningSchedule.cleaning_date !== undefined) {
        cleaningSchedule.cleaning_date = format(
          parse(
            cleaningSchedule.cleaning_date,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: startDate ', error);
    }
    try {
      if (cleaningSchedule.cleaning_done_at !== undefined) {
        cleaningSchedule.cleaning_done_at = format(
          parse(
            cleaningSchedule.cleaning_done_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd HH:mm'
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: endDate ', error);
    }
    try {
      if (cleaningSchedule.created_at !== undefined) {
        cleaningSchedule.created_at = format(
          parse(
            cleaningSchedule.created_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: createdAt ', error);
    }
    try {
      if (cleaningSchedule.updated_at !== undefined) {
        cleaningSchedule.updated_at = format(
          parse(
            cleaningSchedule.updated_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: updatedAt ', error);
    }
    return cleaningSchedule;
  }

  serializeCleaningSchedule(cleaningSchedule: ICleaningScheduleModel): any {
    const serializedCleaningSchedule = { ...cleaningSchedule };

    // Dátum mezők átalakítása ISO stringgé
    try {
      if (serializedCleaningSchedule.cleaning_date !== undefined) {
        serializedCleaningSchedule.cleaning_date = format(
          parse(
            serializedCleaningSchedule.cleaning_date,
            'yyyy-MM-dd',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: startDate ', error);
    }
    try {
      if (serializedCleaningSchedule.cleaning_done_at !== undefined) {
        if (serializedCleaningSchedule.cleaning_done_at === '') {
          serializedCleaningSchedule.cleaningDoneAt = null;
        } else {
          serializedCleaningSchedule.cleaningDoneAt = format(
            parse(
              serializedCleaningSchedule.cleaning_done_at,
              'yyyy-MM-dd HH:mm',
              new Date()
            ),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          );
        }
      }
    } catch (error) {
      this.log.error(
        'CleaningScheduleTransformService: cleaning_done_at ',
        error
      );
    }
    try {
      if (serializedCleaningSchedule.created_at !== undefined) {
        serializedCleaningSchedule.created_at = format(
          parse(
            serializedCleaningSchedule.created_at,
            'yyyy-MM-dd',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: createdAt ', error);
    }
    try {
      if (serializedCleaningSchedule.updated_at !== undefined) {
        serializedCleaningSchedule.updated_at = format(
          parse(
            serializedCleaningSchedule.updated_at,
            'yyyy-MM-dd',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningScheduleTransformService: updatedAt ', error);
    }
    return serializedCleaningSchedule;
  }
}
