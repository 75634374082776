import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  ReactiveFormsModule,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { CleaningTypeClientService } from '../../../services/api/cleaning-type/cleaning-type-client.service';
import { ToastrService } from 'ngx-toastr';
import { ICleaningTypeModel } from '../../../services/api/cleaning-type/cleaning-type-model.interface';

@Component({
  selector: 'cleaning-type-editor',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './cleaning-type-editor.component.html',
  styleUrl: './cleaning-type-editor.component.css',
})
export class CleaningTypeEditorComponent {
  @Input() cleaningType!: ICleaningTypeModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  cleaningTypeForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private cleaningTypeService: CleaningTypeClientService,
    private toastr: ToastrService
  ) {}

  saveCleaningType() {
    this.log.info('Saving Cleaning Type');
    if (this.cleaningTypeForm.valid && this.mode !== 'view') {
      const updatedUntypedCleaningType: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.cleaningTypeForm.controls).forEach((key) => {
        const control = this.cleaningTypeForm.get(key);
        if (control?.dirty) {
          updatedUntypedCleaningType[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedCleaningType).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedCleaningType =
        updatedUntypedCleaningType as ICleaningTypeModel;
      this.log.debug('Cleaning Type request:', updatedCleaningType);

      if (this.mode === 'edit') {
        updatedCleaningType.id = this.cleaningType.id;
        this.cleaningTypeService.updateItem(updatedCleaningType).subscribe({
          next: (data) => {
            this.toastr.success('Cleaning Type updated');
            this.log.debug('Cleaning Type updated:', data);
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error updating Cleaning Type');
            this.log.error('Error updating Cleaning Type:', err);
          },
        });
      } else {
        this.cleaningTypeService.createItem(updatedCleaningType).subscribe({
          next: (data) => {
            this.toastr.success('Cleaning Type created');
            this.log.debug('Cleaning Type created:', data);
            this.onCreate.emit();
          },
          error: (err) => {
            this.toastr.error('Error creating Cleaning Type ');
            this.log.error('Error creating Cleaning Type:', err);
          },
        });
      }
    }
  }
}
