<tr>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <div class="align-middle flex justify-between items-center mb-2">
      <b>{{ pitem.name }}</b>
      @if(pitem.bookingSource == 'Expedia'){
      <ui-badge
        [color]="'orange'"
        badgeType="soft"
        cornerType="rounded"
        iconSrc="assets/img/alert-triangle.svg"
        iconClass="h-4"
        class="ml-2 block flex-shrink-0"
        >{{ pitem.bookingSource }}</ui-badge
      >
      } @else {
      <ui-badge
        color="gray"
        badgeType="soft"
        cornerType="rounded"
        iconClass="h-4"
        class="ml-2 block flex-shrink-0"
        >{{ pitem.bookingSource }}</ui-badge
      >
      }
    </div>
    <div>
      {{ pitem.email }}
      <br />
      {{ pitem.phone }}
      @if(pitem.bookingUser?.last_login){
      <br />
      <span class="text-xs text-gray-500 block mt-1"
        >Last login:
        {{ pitem.bookingUser?.last_login | date : "yyyy-MM-dd HH:mm" }}</span
      >
      }
    </div>
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @for (flat of pitem.flats; track flat.id) {
    <div class="mb-1">
      <ui-badge
        [color]="
          flat.building === 'Lehel'
            ? 'blue'
            : flat.building === 'Kunigunda'
            ? 'yellow'
            : 'black'
        "
        badgeType="soft"
        cornerType="rounded"
        class="w-24 inline-block"
        >{{ flat.building }}</ui-badge
      >
      <span class="inline-block ml-2">{{ flat.room }}</span>
    </div>
    }
  </td>

  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm font-bold text-gray-600 font-mono align-middle"
  >
    @if(pitem.isLoadingCodes){
    <div class="h-4 bg-gray-200 rounded w-full mb-1 animate-pulse"></div>
    } @else { @for (code of pitem.entryCodes; track code) {
    <p class="mb-1">{{ code }}</p>
    } }
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.startDate | date : "yyyy-MM-dd" }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.endDate | date : "yyyy-MM-dd" }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
        iconSrc="assets/img/document-text.svg"
        iconPos="left"
        iconClass="h-4"
        btnType="inline"
        inlinePos="left"
        size="xsmall"
        (click)="navigateToBooking(pitem.id)"
        >Megtekint</ui-button
      >
      <ui-button
        iconSrc="assets/img/door-open.svg"
        iconPos="left"
        iconClass="h-4"
        btnType="inline"
        size="xsmall"
        (click)="sendEmail(pitem.id)"
        [disabled]="pitem.isLoadingCodes || pitem.entryCodes.length === 0"
        >Kód e-mail</ui-button
      >
      <ui-button
        iconSrc="assets/img/door-open.svg"
        iconPos="left"
        iconClass="h-4"
        btnType="inline"
        size="xsmall"
        [disabled]="pitem.isLoadingCodes || pitem.entryCodes.length === 0"
        (click)="sendSms(pitem.id)"
        >Kód sms</ui-button
      >
      <ui-button
        iconSrc="assets/img/dollar-sign.svg"
        iconPos="left"
        iconClass="h-4"
        btnType="inline"
        size="xsmall"
        inlinePos="right"
        (click)="generateInvoice(pitem.id)"
        >Számla</ui-button
      >
    </div>
  </td>
</tr>
