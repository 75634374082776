import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import {
  Observable,
  catchError,
  forkJoin,
  from,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { isBefore, parse } from 'date-fns';
import { IEntryCodeModel } from './entry-code-model.interface';

@Injectable({
  providedIn: 'root',
})
export class EntryCodeClientService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * A beléptető kódok lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A beléptető kódok lekért oldala, nyers válaszban
   */
  private fetchEntryCodes(flatID?: number, page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    if (flatID) {
      return this.http
        .get(
          this.apiService.getUrlFor(API_ENDPOINTS.flat) +
            flatID +
            '/entry_codes',
          {
            params,
          }
        )
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
    } else {
      return this.http
        .get(this.apiService.getUrlFor(API_ENDPOINTS.entryCodes), {
          params,
        })
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
    }
  }

  /**
   * Lekéri a beléptető kódok első oldalát, hogy megtudja az összes beléptető kód számát
   * @returns Az összes beléptető kód számossága
   **/
  getTotalItems(flatID?: number): Observable<number> {
    return this.fetchEntryCodes(flatID).pipe(
      map((response: any) => {
        return response['hydra:totalItems'];
      })
    );
  }

  /**
   * Beléptető kódok lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A beléptető kódok adatai
   **/
  getEntryCodes(flatID?: number, page?: number): Observable<IEntryCodeModel[]> {
    return this.fetchEntryCodes(flatID, page).pipe(
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }

  /**
   * Összes beléptető kód lekérése
   * @returns Az összes beléptető kód adatai
   */
  getAllEntryCodes(flatID?: number): Observable<IEntryCodeModel[]> {
    // megtudjuk az összes elem számát
    return this.getTotalItems(flatID).pipe(
      switchMap((totalItems) => {
        // megtudjuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        // ha nincs egy elem sem, akkor ennyi volt
        if (pages === 0) {
          // adjunk vissza egy observable-t, ami üres tömböt ad vissza
          return from([[]]);
        }

        // minden oldalra kérünk
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getEntryCodes(flatID, i));
        }

        // a lekéréseket összefűzzük
        return forkJoin(requests).pipe(
          map((results) => {
            let entryCodes = [] as IEntryCodeModel[];
            for (let result of results) {
              entryCodes.push(...result);
            }
            return entryCodes;
          })
        );
      })
    );
  }

  /**
   * Érvényes beléptető kódok lekérése egy lakáshoz
   * @param flatID A lakás azonosítója
   * @returns Az aktív beléptető kódok adatai
   */
  getActiveEntryCodes(flatID: number, userID?: number): Observable<IEntryCodeModel[]> {
    //this.log.debug('Loading active entry codes for flat ' + flatID);
    return this.getAllEntryCodes(flatID).pipe(
      //tap((entryCodes) => { this.log.debug('Loaded all entry codes for flat ' + flatID, entryCodes); }),
      map((entryCodes) => {
        return entryCodes.filter((entryCode) => {
          return isBefore(
            new Date(),
            parse(
              entryCode.valid_to ?? '',
              "yyyy-MM-dd'T'HH:mm:ssxxx",
              new Date()
            )
          ) && entryCode.user?.id === userID;
        });
      })
    );
  }

  /**
   * Beléptető kód lekérése az IRI alapján
   * @param iri A beléptető kód IRI-ja
   * @returns A beléptető kód adatai
   **/
  getEntryCodeByIri(iri: string): Observable<any> {
    return this.http
      .get(this.apiService.getBaseUrl() + iri)
      .pipe(catchError(this.handleError));
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'EntryCodeClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform entry code operation')
    );
  };
}
