import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { ICleaningScheduleModel } from '../../../services/api/cleaning-schedule/cleaning-schedule-model.interface';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { CleaningScheduleClientService } from '../../../services/api/cleaning-schedule/cleaning-schedule-client.service';
import { CleaningTypeClientService } from '../../../services/api/cleaning-type/cleaning-type-client.service';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'cleaning-schedule-note-editor',
  standalone: true,
  imports: [UiSwitchComponent, ReactiveFormsModule, NgSelectModule],
  templateUrl: './cleaning-schedule-note-editor.component.html',
  styleUrl: './cleaning-schedule-note-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningScheduleNoteEditorComponent {
  @Input() cleaningSchedule!: ICleaningScheduleModel;
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  buildingSelectItems: IUiSelectItem[] = [];
  flatSelectItems: IUiSelectItem[] = [];
  typeSelectItems: IUiSelectItem[] = [];

  cleaningScheduleNoteForm: FormGroup = this.fb.group({
    building: [{ value: '', disabled: true }],
    flat: [{ value: '', disabled: true }],
    cleaning_date: [{ value: '', disabled: true }],
    cleaning_done: [''],
    cleaning_note: [''],
    cleaner_note: [''],
    cleaningType: [{ value: '', disabled: true }],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private cleaningScheduleService: CleaningScheduleClientService,
    private typeService: CleaningTypeClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('CleaningScheduleEditor Changed', this.cleaningSchedule);
    this.cleaningScheduleNoteForm.reset();
    this.cleaningScheduleNoteForm.patchValue(this.cleaningSchedule);

    // Épületek, Lakások és takarítási típusok kezelése a dropdownban
    this.cleaningScheduleNoteForm
      .get('building')
      ?.setValue(this.cleaningSchedule.building?.['@id']);
    this.cleaningScheduleNoteForm
      .get('flat')
      ?.setValue(this.cleaningSchedule.flat?.['@id']);
    this.cleaningScheduleNoteForm
      .get('cleaningType')
      ?.setValue(this.cleaningSchedule.cleaningType?.['@id']);
  }

  ngOnInit() {
    // Betöltjük a lakásokat a dropdownhoz
    this.silo.getFlatSelectItems().subscribe({
      next: (flats) => {
        this.flatSelectItems = flats;
        this.log.debug('Flats loaded into select:', this.flatSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading flats into select:', err);
      },
    });

    // Betöltjük az épületeket a dropdownhoz
    this.silo.getBuildingSelectItems().subscribe({
      next: (buildings) => {
        this.buildingSelectItems = buildings;
        this.log.debug(
          'Buildings loaded into select:',
          this.buildingSelectItems
        );
      },
      error: (err) => {
        this.log.error('Error loading buildings into select:', err);
      },
    });

    // Betöltjük a takarítási típusokat a dropdownhoz
    // TODO: SILO-ba szervezni
    this.typeService.getItems().subscribe({
      next: (types) => {
        this.typeSelectItems = types.map((cleaningType) => ({
          val: cleaningType['@id'],
          title: cleaningType.name,
        }));
        this.typeSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('Cleaning types loaded:', this.typeSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading cleaning types:', err);
      },
    });
  }

  saveCleaningScheduleNote() {
    this.log.info('Saving cleaning schedule note');

    if (this.cleaningScheduleNoteForm.valid) {
      const updatedUntypedCleaningSchedule: { [key: string]: any } = {};

      // Csak azokat a mezőket vesszük figyelembe, amiket módosítottak
      Object.keys(this.cleaningScheduleNoteForm.controls).forEach((key) => {
        const control = this.cleaningScheduleNoteForm.get(key);
        if (control?.dirty) {
          updatedUntypedCleaningSchedule[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedCleaningSchedule).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedCleaningSchedule =
        updatedUntypedCleaningSchedule as ICleaningScheduleModel;

      if (
        this.cleaningScheduleNoteForm.get('cleaning_done')?.value === false &&
        this.cleaningScheduleNoteForm.get('cleaning_done')?.dirty
      ) {
        updatedCleaningSchedule.cleaning_done_at = '';
      }
      updatedCleaningSchedule.id = this.cleaningSchedule.id;
      this.cleaningScheduleService
        .updateCleaningSchedule(updatedCleaningSchedule)
        .subscribe({
          next: () => {
            this.toastr.success('Cleaning schedule updated');
            this.log.debug(
              'Cleaning schedule updated:',
              updatedCleaningSchedule
            );
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error saving cleaning schedule');
            this.log.error('Error saving cleaning schedule:', err);
          },
        });
    }
  }
}
