import { Component, Input, SimpleChanges } from '@angular/core';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { CommonModule } from '@angular/common';
import { LucideAngularModule } from 'lucide-angular';
import { ReadingClientService } from '../../services/api/reading/reading-client.service';
import { LogService } from '../../services/utils/log.service';
import { TimeAgoPipe } from '../../services/utils/time-ago.pipe';
import { UiBadgeComponent } from '../atomic-ui-components/badge/ui-badge.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'meters',
  standalone: true,
  imports: [
    CommonModule, 
    LucideAngularModule, 
    RouterLink,
    TimeAgoPipe,
    UiBadgeComponent,
],
  templateUrl: './meters.component.html',
  styleUrl: './meters.component.css',
})
export class MetersComponent {
  @Input() meters: IMeterModel[] = [];

  lastReadings: Map<number, string> = new Map();
  lastReadingTimes: Map<number, string> = new Map();
  alertText: string = '';
  alert: boolean = false;

  constructor(
    private readingClient: ReadingClientService,
    private log: LogService
  ) {}

    ngOnChanges(changes: SimpleChanges): void {
    if (changes['meters']) {
      // Lekérjük a mérők utolsó leolvasásait
      this.lastReadings.clear();
      for (let i = 0; i < this.meters.length; i++) {
        const meter = this.meters[i];
        this.readingClient.getLastReading(meter.id).subscribe({
          next: (reading) => {
            if (reading.reading) {
              this.lastReadings.set(meter.id, reading.reading);
              this.lastReadingTimes.set(meter.id, reading.read_at ?? 'N/A');
            } else {
              this.log.debug('No reading found for meter', meter);
              this.lastReadings.set(meter.id, 'N/A');
              this.lastReadingTimes.set(meter.id, 'N/A');
            }
          },
          error: (err) => {
            console.error('Could not get last reading for meter', meter, err);
          },
        });
      }
    }
  }
}
