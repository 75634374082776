import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { IIssueModel } from './issue-model.interface';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class IssueTransformService {
  constructor(private log: LogService) {}

  transformIssue(issue: any): IIssueModel {
    try {
      if (issue.created_at !== undefined) {
        issue.created_at = format(
          parse(issue.created_at, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('IssueTransformService: created_at ', error);
    }
    try {
      if (issue.updated_at !== undefined) {
        issue.updated_at = format(
          parse(issue.updated_at, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('IssueTransformService: updated_at ', error);
    }
    return issue;
  }

  serializeIssue(issue: IIssueModel): IIssueModel {
    const serializedIssue: any = { ...issue };

    // Dátum mezők átalakítása ISO stringgé
    if (serializedIssue.created_at !== undefined) {
      try {
        serializedIssue.created_at = format(
          parse(serializedIssue.created_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('IssueTransformService: created_at ', error);
      }
    }
    if (serializedIssue.updated_at !== undefined) {
      try {
        serializedIssue.updated_at = format(
          parse(serializedIssue.updated_at, 'yyyy-MM-dd HH:mm:ss', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('IssueTransformService: updated_at ', error);
      }
    }
    // Többi mező stringgé alakítása
    for (const key in serializedIssue) {
      if (
        typeof serializedIssue[key] !== 'string' &&
        serializedIssue[key] !== null &&
        key !== 'delegatedUsers'
      ) {
        serializedIssue[key] = String(serializedIssue[key]);
      }
    }
    return serializedIssue;
  }
}
