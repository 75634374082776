import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { CleaningOverviewTableComponent } from '../../components/tables/cleaning-overview-table/cleaning-overview-table.component';
import { CleaningRequestTableComponent } from '../../components/tables/cleaning-request-table/cleaning-request-table.component';
import { CleaningScheduleTableComponent } from '../../components/tables/cleaning-schedule-table/cleaning-schedule-table.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cleaning',
  standalone: true,
  imports: [
    LayoutMainComponent,
    CleaningOverviewTableComponent,
    CleaningRequestTableComponent,
    CleaningScheduleTableComponent,
    UiButtonComponent,
    RouterLink,
  ],

  templateUrl: './cleaning.component.html',
  styleUrl: './cleaning.component.css'
})
export class CleaningComponent {

}
