import { Component, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UnitClientService } from '../../../services/api/unit/unit-client.service';
import { IUnitModel } from '../../../services/api/unit/unit-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { UnitEditorComponent } from '../../modal-editors/unit-editor/unit-editor.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { UnitTableRowComponent } from './unit-table-row/unit-table-row.component';

@Component({
  selector: 'unit-table',
  standalone: true,
  imports: [
    UiButtonComponent,
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
    UnitTableRowComponent,
    UnitEditorComponent,
  ],
  templateUrl: './unit-table.component.html',
  styleUrl: './unit-table.component.css',
})
export class UnitTableComponent {
  @ViewChild(UnitEditorComponent)
  editor!: UnitEditorComponent;
  selectedUnit!: IUnitModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  units: IUnitModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private unitClientService: UnitClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: IUnitModel) {
    this.selectedUnit = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IUnitModel) {
    this.selectedUnit = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IUnitModel) {
    this.selectedUnit = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.unitClientService.deleteItem(this.selectedUnit.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('Unit deleted');
        this.units = this.units.filter((pr) => pr.id !== this.selectedUnit.id);
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.units.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Flat type deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting unit');
        this.log.error('Error deleting unit', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedUnit = {
      '@id': '',
      id: 0,
      name: '',
    } as IUnitModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.unitClientService.getItems(page).subscribe({
      next: (units) => {
        this.units = units;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);

        setTimeout(() => {
          this.log.info('Units table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching units', err);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.unitClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
