import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertiesTableComponent } from '../../components/tables/properties-table/properties-table.component';
import { RouterLink } from '@angular/router';
import { FlatTableComponent } from '../../components/tables/flat-table/flat-table.component';

@Component({
  selector: 'properties',
  standalone: true,
  imports: [
    LayoutMainComponent,
    PropertiesTableComponent,
    RouterLink,
    FlatTableComponent,
  ],
  templateUrl: './properties.component.html',
  styleUrl: './properties.component.css',
})
export class PropertiesComponent {}
