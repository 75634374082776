import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { ITaxModel } from '../../../../services/api/tax/tax-model.interface';

@Component({
  selector: 'tax-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
  ],
  templateUrl: './tax-table-row.component.html',
  styleUrl: './tax-table-row.component.css'
})
export class TaxTableRowComponent {
  @Input() pitem: ITaxModel = {} as ITaxModel;
  @Output() onDelete = new EventEmitter<ITaxModel>();
  @Output() onEdit = new EventEmitter<ITaxModel>();

  handleEdit(pitem: ITaxModel) {
   this.onEdit.emit(pitem);
  }

  handleDelete(pitem: ITaxModel) {
    this.onDelete.emit(pitem);
  }


}
