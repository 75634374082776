import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { CheckinsOverviewComponent } from '../../components/tables/checkins-overview/checkins-overview.component';
import { CheckoutsOverviewComponent } from '../../components/tables/checkouts-overview/checkouts-overview.component';
import { GuestsOverviewComponent } from '../../components/tables/guests-overview/guests-overview.component';
import { RouterLink } from '@angular/router';
import { UiBadgeComponent } from '../../components/atomic-ui-components/badge/ui-badge.component';

@Component({
  selector: 'booking-overview',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    UiBadgeComponent,
    CheckinsOverviewComponent,
    CheckoutsOverviewComponent,
    GuestsOverviewComponent,
    RouterLink,
],
  templateUrl: './booking-overview.component.html',
  styleUrl: './booking-overview.component.css'
})
export class BookingOverviewComponent {
  checkinCount: number = 0;
  guestsCount: number = 0;
  checkoutsCount: number = 0;

}
