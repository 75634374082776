<tr class="">
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <div class="w-full flex items-center gap-x-3">
      <div class="grow">
        <span class="text-sm font-medium">
          {{ pitem.name }}
        </span>
      </div>
    </div>
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <span class="text-sm">
      {{ pitem.location }}
    </span>
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    @if(pitem.id == 2){
    <ui-badge
      color="red"
      badgeType="solid"
      cornerType="rounded"
      iconSrc="assets/img/alert-triangle.svg"
      iconClass="h-4 invert"
      class="block flex-shrink-0"
      >No active contract!</ui-badge
    >
    } @else {
    <span class="text-sm">
      {{ pitem.tenant }}
    </span>
    }
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    @if(pitem.id === 4){ Has <span class="font-semibold">2</span> open ticket }
    @if(pitem.id === 5){ Contract ends in
    <span class="font-semibold">60</span> days
    <ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 ml-2"
      >and 2 more</ui-badge
    >
    } @if(pitem.id === 7){ Has <span class="font-semibold">3</span> overdue
    bills } @if(pitem.id === 9){ Missed
    <span class="font-semibold">2</span> meter readings
    <ui-badge
      color="yellow"
      badgeType="solid"
      cornerType="rounded"
      iconClass="h-4 invert"
      class="inline-block flex-shrink-0 ml-2"
      >and 2 more</ui-badge
    >
    } @if(pitem.id === 10){ High utility usage suspected } @if(pitem.id !== 4 &&
    pitem.id !== 5 && pitem.id !== 7 && pitem.id !== 9 && pitem.id !==10){
    <span class="text-sm">
      {{ pitem.status }}
    </span>
    }
  </td>

  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    <div class="inline-flex rounded-lg shadow-sm">
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/home.svg"
          alt="home"
          class="inline-block h-5 leading-4"
        />
      </button>
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/user.svg"
          alt="user"
          class="inline-block h-5 leading-4"
        />
      </button>
      <button
        type="button"
        class="py-1 px-2 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/clipboard-edit.svg"
          alt="clipboard"
          class="inline-block h-5 leading-4"
        />
      </button>
    </div>
    <div
      class="hs-dropdown relative inline-flex [--auto-close:inside] [--placement:top-right]"
    >
      <button
        id="hs-pro-dutdm12"
        type="button"
        class="w-7 h-7 inline-flex justify-center items-center gap-x-2 rounded-lg bg-white hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50"
      >
        ...
      </button>

      <div
        class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] [0_10px_40px_10px_rgba(0,0,0,0.2)]"
        aria-labelledby="hs-pro-dutdm12"
      >
        <div class="p-1">
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/home.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />
              Edit property
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/user.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Tenant info
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/clipboard-edit.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Edit billing
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/clipboard-list.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              list of bills
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>

          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/file-x.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Lease termination
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/gauge.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Utility usage
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/folder.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Inventory
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
          <div
            class="flex justify-between items-center py-2 px-3 cursor-pointer rounded-lg hover:bg-gray-100"
          >
            <div
              class="flex flex-1 items-center gap-x-3 cursor-pointer text-sm"
            >
              <img
                src="assets/img/file-clock.svg"
                alt="home"
                class="inline-block h-4 leading-4"
              />

              Previous contracts
            </div>
            <img
              src="assets/img/forward.svg"
              alt="home"
              class="inline-block h-4 leading-4"
            />
          </div>
        </div>
      </div>
    </div>
  </td>
</tr>
