<layout-main id="content" role="main">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm focus:outline-none focus:text-blue-600 leading-5 cursor-default"
      >
        Services
      </span>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Parking
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!--  **** PARKING MAIN **** -->
  <div class="mb-4">
    <!--  TAB *******************************************************************************************************************-->
    <nav
      class="flex space-x-2 mb-4 xs:mx-4 sm:ml-0 overflow-x-auto"
      aria-label="Tabs"
      role="tablist"
    >
      <!-- TAB1 head  -->
      <!-- active class az alapértelmezettnek -->
      <ui-button
        id="tabs-with-underline-item-1"
        data-hs-tab="#tabs-with-underline-1"
        aria-controls="tabs-with-underline-1"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-range-blue.svg"
        iconAlt="Parking overview icon"
        class="active"
        >Parking overview</ui-button
      >
      <!-- TAB1 head  -->
      <!-- TAB2 head  -->
      <ui-button
        id="tabs-with-underline-item-2"
        data-hs-tab="#tabs-with-underline-2"
        aria-controls="tabs-with-underline-2"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/home-blue.svg"
        iconAlt="Parking request tab icon"
        >Parking request</ui-button
      >
    </nav>
    <div class="bg-white border shadow-sm sm:rounded-xl">
      <!--  ******************************************************************************************************************************** -->
      <div>
        <!--  1. tab body ******************************************************************************************************************-->
        <div
          id="tabs-with-underline-1"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-1"
        >
          <parking-overview></parking-overview>
        </div>
        <!--  // 1  -->
        <!--  ** TENANT 2 *************************************************************************************************************************-->
        <div
          id="tabs-with-underline-2"
          class="hidden"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-2"
        >
          <parking-requests-table></parking-requests-table>
        </div>
        <!--  // 2  -->
      </div>
    </div>
  </div>
</layout-main>
