import { IUiSelectItem } from './ui-select-item.interface';

export const countries: IUiSelectItem[] = [
  {
    val: 'AF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/af.png',
      description: 'Afghanistan',
    },
    title: 'Afghanistan',
  },
  {
    val: 'AX',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ax.png',
      description: 'Aland Islands',
    },
    title: 'Aland Islands',
  },
  {
    val: 'AL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/al.png',
      description: 'Albania',
    },
    title: 'Albania',
  },
  {
    val: 'DZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/dz.png',
      description: 'Algeria',
    },
    title: 'Algeria',
  },
  {
    val: 'AS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/as.png',
      description: 'American Samoa',
    },
    title: 'American Samoa',
  },
  {
    val: 'AD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ad.png',
      description: 'Andorra',
    },
    title: 'Andorra',
  },
  {
    val: 'AO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ao.png',
      description: 'Angola',
    },
    title: 'Angola',
  },
  {
    val: 'AI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ai.png',
      description: 'Anguilla',
    },
    title: 'Anguilla',
  },
  {
    val: 'AG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ag.png',
      description: 'Antigua and Barbuda',
    },
    title: 'Antigua and Barbuda',
  },
  {
    val: 'AR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ar.png',
      description: 'Argentina',
    },
    title: 'Argentina',
  },
  {
    val: 'AM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/am.png',
      description: 'Armenia',
    },
    title: 'Armenia',
  },
  {
    val: 'AW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/aw.png',
      description: 'Aruba',
    },
    title: 'Aruba',
  },
  {
    val: 'AU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/au.png',
      description: 'Australia',
    },
    title: 'Australia',
  },
  {
    val: 'AT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/at.png',
      description: 'Austria',
    },
    title: 'Austria',
  },
  {
    val: 'AZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/az.png',
      description: 'Azerbaijan',
    },
    title: 'Azerbaijan',
  },
  {
    val: 'BS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bs.png',
      description: 'Bahamas',
    },
    title: 'Bahamas',
  },
  {
    val: 'BH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bh.png',
      description: 'Bahrain',
    },
    title: 'Bahrain',
  },
  {
    val: 'BD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bd.png',
      description: 'Bangladesh',
    },
    title: 'Bangladesh',
  },
  {
    val: 'BB',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bb.png',
      description: 'Barbados',
    },
    title: 'Barbados',
  },
  {
    val: 'BY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/by.png',
      description: 'Belarus',
    },
    title: 'Belarus',
  },
  {
    val: 'BE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/be.png',
      description: 'Belgium',
    },
    title: 'Belgium',
  },
  {
    val: 'BZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bz.png',
      description: 'Belize',
    },
    title: 'Belize',
  },
  {
    val: 'BJ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bj.png',
      description: 'Benin',
    },
    title: 'Benin',
  },
  {
    val: 'BM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bm.png',
      description: 'Bermuda',
    },
    title: 'Bermuda',
  },
  {
    val: 'BT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bt.png',
      description: 'Bhutan',
    },
    title: 'Bhutan',
  },
  {
    val: 'BO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bo.png',
      description: 'Bolivia (Plurinational State of)',
    },
    title: 'Bolivia (Plurinational State of)',
  },
  {
    val: 'BQ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bq.png',
      description: 'Bonaire, Sint Eustatius and Saba',
    },
    title: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    val: 'BA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ba.png',
      description: 'Bosnia and Herzegovina',
    },
    title: 'Bosnia and Herzegovina',
  },
  {
    val: 'BW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bw.png',
      description: 'Botswana',
    },
    title: 'Botswana',
  },
  {
    val: 'BR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/br.png',
      description: 'Brazil',
    },
    title: 'Brazil',
  },
  {
    val: 'IO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/io.png',
      description: 'British Indian Ocean Territory',
    },
    title: 'British Indian Ocean Territory',
  },
  {
    val: 'BN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bn.png',
      description: 'Brunei Darussalam',
    },
    title: 'Brunei Darussalam',
  },
  {
    val: 'BG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bg.png',
      description: 'Bulgaria',
    },
    title: 'Bulgaria',
  },
  {
    val: 'BF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bf.png',
      description: 'Burkina Faso',
    },
    title: 'Burkina Faso',
  },
  {
    val: 'BI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bi.png',
      description: 'Burundi',
    },
    title: 'Burundi',
  },
  {
    val: 'CV',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cv.png',
      description: 'Cabo Verde',
    },
    title: 'Cabo Verde',
  },
  {
    val: 'KH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kh.png',
      description: 'Cambodia',
    },
    title: 'Cambodia',
  },
  {
    val: 'CM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cm.png',
      description: 'Cameroon',
    },
    title: 'Cameroon',
  },
  {
    val: 'CA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ca.png',
      description: 'Canada',
    },
    title: 'Canada',
  },
  {
    val: 'KY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ky.png',
      description: 'Cayman Islands',
    },
    title: 'Cayman Islands',
  },
  {
    val: 'CF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cf.png',
      description: 'Central African Republic',
    },
    title: 'Central African Republic',
  },
  {
    val: 'TD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/td.png',
      description: 'Chad',
    },
    title: 'Chad',
  },
  {
    val: 'CL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cl.png',
      description: 'Chile',
    },
    title: 'Chile',
  },
  {
    val: 'CN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cn.png',
      description: 'China',
    },
    title: 'China',
  },
  {
    val: 'CX',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cx.png',
      description: 'Christmas Island',
    },
    title: 'Christmas Island',
  },
  {
    val: 'CC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cc.png',
      description: 'Cocos (Keeling) Islands',
    },
    title: 'Cocos (Keeling) Islands',
  },
  {
    val: 'CO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/co.png',
      description: 'Colombia',
    },
    title: 'Colombia',
  },
  {
    val: 'KM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/km.png',
      description: 'Comoros',
    },
    title: 'Comoros',
  },
  {
    val: 'CK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ck.png',
      description: 'Cook Islands',
    },
    title: 'Cook Islands',
  },
  {
    val: 'CR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cr.png',
      description: 'Costa Rica',
    },
    title: 'Costa Rica',
  },
  {
    val: 'HR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/hr.png',
      description: 'Croatia',
    },
    title: 'Croatia',
  },
  {
    val: 'CU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cu.png',
      description: 'Cuba',
    },
    title: 'Cuba',
  },
  {
    val: 'CW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cw.png',
      description: 'Curaçao',
    },
    title: 'Curaçao',
  },
  {
    val: 'CY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cy.png',
      description: 'Cyprus',
    },
    title: 'Cyprus',
  },
  {
    val: 'CZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cz.png',
      description: 'Czech Republic',
    },
    title: 'Czech Republic',
  },
  {
    val: 'CI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ci.png',
      description: 'Côte Ivoire',
    },
    title: 'Côte Ivoire',
  },
  {
    val: 'CD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cd.png',
      description: 'Democratic Republic of the Congo',
    },
    title: 'Democratic Republic of the Congo',
  },
  {
    val: 'DK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/dk.png',
      description: 'Denmark',
    },
    title: 'Denmark',
  },
  {
    val: 'DJ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/dj.png',
      description: 'Djibouti',
    },
    title: 'Djibouti',
  },
  {
    val: 'DM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/dm.png',
      description: 'Dominica',
    },
    title: 'Dominica',
  },
  {
    val: 'DO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/do.png',
      description: 'Dominican Republic',
    },
    title: 'Dominican Republic',
  },
  {
    val: 'EC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ec.png',
      description: 'Ecuador',
    },
    title: 'Ecuador',
  },
  {
    val: 'EG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/eg.png',
      description: 'Egypt',
    },
    title: 'Egypt',
  },
  {
    val: 'SV',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sv.png',
      description: 'El Salvador',
    },
    title: 'El Salvador',
  },
  {
    val: 'GB-ENG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gb.png',
      description: 'England',
    },
    title: 'England',
  },
  {
    val: 'GQ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gq.png',
      description: 'Equatorial Guinea',
    },
    title: 'Equatorial Guinea',
  },
  {
    val: 'ER',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/er.png',
      description: 'Eritrea',
    },
    title: 'Eritrea',
  },
  {
    val: 'EE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ee.png',
      description: 'Estonia',
    },
    title: 'Estonia',
  },
  {
    val: 'ET',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/et.png',
      description: 'Ethiopia',
    },
    title: 'Ethiopia',
  },
  {
    val: 'FK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fk.png',
      description: 'Falkland Islands',
    },
    title: 'Falkland Islands',
  },
  {
    val: 'FO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fo.png',
      description: 'Faroe Islands',
    },
    title: 'Faroe Islands',
  },
  {
    val: 'FM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fm.png',
      description: 'Federated States of Micronesia',
    },
    title: 'Federated States of Micronesia',
  },
  {
    val: 'FJ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fj.png',
      description: 'Fiji',
    },
    title: 'Fiji',
  },
  {
    val: 'FI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fi.png',
      description: 'Finland',
    },
    title: 'Finland',
  },
  {
    val: 'FR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/fr.png',
      description: 'France',
    },
    title: 'France',
  },
  {
    val: 'GF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gf.png',
      description: 'French Guiana',
    },
    title: 'French Guiana',
  },
  {
    val: 'PF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pf.png',
      description: 'French Polynesia',
    },
    title: 'French Polynesia',
  },
  {
    val: 'TF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tf.png',
      description: 'French Southern Territories',
    },
    title: 'French Southern Territories',
  },
  {
    val: 'GA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ga.png',
      description: 'Gabon',
    },
    title: 'Gabon',
  },
  {
    val: 'GM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gm.png',
      description: 'Gambia',
    },
    title: 'Gambia',
  },
  {
    val: 'GE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ge.png',
      description: 'Georgia',
    },
    title: 'Georgia',
  },
  {
    val: 'DE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/de.png',
      description: 'Germany',
    },
    title: 'Germany',
  },
  {
    val: 'GH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gh.png',
      description: 'Ghana',
    },
    title: 'Ghana',
  },
  {
    val: 'GI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gi.png',
      description: 'Gibraltar',
    },
    title: 'Gibraltar',
  },
  {
    val: 'GR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gr.png',
      description: 'Greece',
    },
    title: 'Greece',
  },
  {
    val: 'GL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gl.png',
      description: 'Greenland',
    },
    title: 'Greenland',
  },
  {
    val: 'GD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gd.png',
      description: 'Grenada',
    },
    title: 'Grenada',
  },
  {
    val: 'GP',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gp.png',
      description: 'Guadeloupe',
    },
    title: 'Guadeloupe',
  },
  {
    val: 'GU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gu.png',
      description: 'Guam',
    },
    title: 'Guam',
  },
  {
    val: 'GT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gt.png',
      description: 'Guatemala',
    },
    title: 'Guatemala',
  },
  {
    val: 'GG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gg.png',
      description: 'Guernsey',
    },
    title: 'Guernsey',
  },
  {
    val: 'GN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gn.png',
      description: 'Guinea',
    },
    title: 'Guinea',
  },
  {
    val: 'GW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gw.png',
      description: 'Guinea-Bissau',
    },
    title: 'Guinea-Bissau',
  },
  {
    val: 'GY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gy.png',
      description: 'Guyana',
    },
    title: 'Guyana',
  },
  {
    val: 'HT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ht.png',
      description: 'Haiti',
    },
    title: 'Haiti',
  },
  {
    val: 'VA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/va.png',
      description: 'Holy See',
    },
    title: 'Holy See',
  },
  {
    val: 'HN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/hn.png',
      description: 'Honduras',
    },
    title: 'Honduras',
  },
  {
    val: 'HK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/hk.png',
      description: 'Hong Kong',
    },
    title: 'Hong Kong',
  },
  {
    val: 'HU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/hu.png',
      description: 'Hungary',
    },
    title: 'Hungary',
  },
  {
    val: 'IS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/is.png',
      description: 'Iceland',
    },
    title: 'Iceland',
  },
  {
    val: 'IN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/in.png',
      description: 'India',
    },
    title: 'India',
  },
  {
    val: 'ID',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/id.png',
      description: 'Indonesia',
    },
    title: 'Indonesia',
  },
  {
    val: 'IR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ir.png',
      description: 'Iran (Islamic Republic of)',
    },
    title: 'Iran (Islamic Republic of)',
  },
  {
    val: 'IQ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/iq.png',
      description: 'Iraq',
    },
    title: 'Iraq',
  },
  {
    val: 'IE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ie.png',
      description: 'Ireland',
    },
    title: 'Ireland',
  },
  {
    val: 'IM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/im.png',
      description: 'Isle of Man',
    },
    title: 'Isle of Man',
  },
  {
    val: 'IL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/il.png',
      description: 'Israel',
    },
    title: 'Israel',
  },
  {
    val: 'IT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/it.png',
      description: 'Italy',
    },
    title: 'Italy',
  },
  {
    val: 'JM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/jm.png',
      description: 'Jamaica',
    },
    title: 'Jamaica',
  },
  {
    val: 'JP',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/jp.png',
      description: 'Japan',
    },
    title: 'Japan',
  },
  {
    val: 'JE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/je.png',
      description: 'Jersey',
    },
    title: 'Jersey',
  },
  {
    val: 'JO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/jo.png',
      description: 'Jordan',
    },
    title: 'Jordan',
  },
  {
    val: 'KZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kz.png',
      description: 'Kazakhstan',
    },
    title: 'Kazakhstan',
  },
  {
    val: 'KE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ke.png',
      description: 'Kenya',
    },
    title: 'Kenya',
  },
  {
    val: 'KI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ki.png',
      description: 'Kiribati',
    },
    title: 'Kiribati',
  },
  {
    val: 'KW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kw.png',
      description: 'Kuwait',
    },
    title: 'Kuwait',
  },
  {
    val: 'KG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kg.png',
      description: 'Kyrgyzstan',
    },
    title: 'Kyrgyzstan',
  },
  {
    val: 'LA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/la.png',
      description: 'Laos',
    },
    title: 'Laos',
  },
  {
    val: 'LV',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lv.png',
      description: 'Latvia',
    },
    title: 'Latvia',
  },
  {
    val: 'LB',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lb.png',
      description: 'Lebanon',
    },
    title: 'Lebanon',
  },
  {
    val: 'LS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ls.png',
      description: 'Lesotho',
    },
    title: 'Lesotho',
  },
  {
    val: 'LR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lr.png',
      description: 'Liberia',
    },
    title: 'Liberia',
  },
  {
    val: 'LY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ly.png',
      description: 'Libya',
    },
    title: 'Libya',
  },
  {
    val: 'LI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/li.png',
      description: 'Liechtenstein',
    },
    title: 'Liechtenstein',
  },
  {
    val: 'LT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lt.png',
      description: 'Lithuania',
    },
    title: 'Lithuania',
  },
  {
    val: 'LU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lu.png',
      description: 'Luxembourg',
    },
    title: 'Luxembourg',
  },
  {
    val: 'MO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mo.png',
      description: 'Macau',
    },
    title: 'Macau',
  },
  {
    val: 'MG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mg.png',
      description: 'Madagascar',
    },
    title: 'Madagascar',
  },
  {
    val: 'MW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mw.png',
      description: 'Malawi',
    },
    title: 'Malawi',
  },
  {
    val: 'MY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/my.png',
      description: 'Malaysia',
    },
    title: 'Malaysia',
  },
  {
    val: 'MV',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mv.png',
      description: 'Maldives',
    },
    title: 'Maldives',
  },
  {
    val: 'ML',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ml.png',
      description: 'Mali',
    },
    title: 'Mali',
  },
  {
    val: 'MT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mt.png',
      description: 'Malta',
    },
    title: 'Malta',
  },
  {
    val: 'MH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mh.png',
      description: 'Marshall Islands',
    },
    title: 'Marshall Islands',
  },
  {
    val: 'MQ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mq.png',
      description: 'Martinique',
    },
    title: 'Martinique',
  },
  {
    val: 'MR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mr.png',
      description: 'Mauritania',
    },
    title: 'Mauritania',
  },
  {
    val: 'MU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mu.png',
      description: 'Mauritius',
    },
    title: 'Mauritius',
  },
  {
    val: 'YT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/yt.png',
      description: 'Mayotte',
    },
    title: 'Mayotte',
  },
  {
    val: 'MX',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mx.png',
      description: 'Mexico',
    },
    title: 'Mexico',
  },
  {
    val: 'MD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/md.png',
      description: 'Moldova',
    },
    title: 'Moldova',
  },
  {
    val: 'MC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mc.png',
      description: 'Monaco',
    },
    title: 'Monaco',
  },
  {
    val: 'MN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mn.png',
      description: 'Mongolia',
    },
    title: 'Mongolia',
  },
  {
    val: 'ME',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/me.png',
      description: 'Montenegro',
    },
    title: 'Montenegro',
  },
  {
    val: 'MS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ms.png',
      description: 'Montserrat',
    },
    title: 'Montserrat',
  },
  {
    val: 'MA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ma.png',
      description: 'Morocco',
    },
    title: 'Morocco',
  },
  {
    val: 'MZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mz.png',
      description: 'Mozambique',
    },
    title: 'Mozambique',
  },
  {
    val: 'MM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mm.png',
      description: 'Myanmar',
    },
    title: 'Myanmar',
  },
  {
    val: 'NA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/na.png',
      description: 'Namibia',
    },
    title: 'Namibia',
  },
  {
    val: 'NR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nr.png',
      description: 'Nauru',
    },
    title: 'Nauru',
  },
  {
    val: 'NP',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/np.png',
      description: 'Nepal',
    },
    title: 'Nepal',
  },
  {
    val: 'NL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nl.png',
      description: 'Netherlands',
    },
    title: 'Netherlands',
  },
  {
    val: 'NC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nc.png',
      description: 'New Caledonia',
    },
    title: 'New Caledonia',
  },
  {
    val: 'NZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nz.png',
      description: 'New Zealand',
    },
    title: 'New Zealand',
  },
  {
    val: 'NI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ni.png',
      description: 'Nicaragua',
    },
    title: 'Nicaragua',
  },
  {
    val: 'NE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ne.png',
      description: 'Niger',
    },
    title: 'Niger',
  },
  {
    val: 'NG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ng.png',
      description: 'Nigeria',
    },
    title: 'Nigeria',
  },
  {
    val: 'NU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nu.png',
      description: 'Niue',
    },
    title: 'Niue',
  },
  {
    val: 'NF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/nf.png',
      description: 'Norfolk Island',
    },
    title: 'Norfolk Island',
  },
  {
    val: 'KP',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kp.png',
      description: 'North Korea',
    },
    title: 'North Korea',
  },
  {
    val: 'MK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mk.png',
      description: 'North Macedonia',
    },
    title: 'North Macedonia',
  },
  {
    val: 'GB-NIR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gb.png',
      description: 'Northern Ireland',
    },
    title: 'Northern Ireland',
  },
  {
    val: 'MP',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mp.png',
      description: 'Northern Mariana Islands',
    },
    title: 'Northern Mariana Islands',
  },
  {
    val: 'NO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/no.png',
      description: 'Norway',
    },
    title: 'Norway',
  },
  {
    val: 'OM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/om.png',
      description: 'Oman',
    },
    title: 'Oman',
  },
  {
    val: 'PK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pk.png',
      description: 'Pakistan',
    },
    title: 'Pakistan',
  },
  {
    val: 'PW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pw.png',
      description: 'Palau',
    },
    title: 'Palau',
  },
  {
    val: 'PA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pa.png',
      description: 'Panama',
    },
    title: 'Panama',
  },
  {
    val: 'PG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pg.png',
      description: 'Papua New Guinea',
    },
    title: 'Papua New Guinea',
  },
  {
    val: 'PY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/py.png',
      description: 'Paraguay',
    },
    title: 'Paraguay',
  },
  {
    val: 'PE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pe.png',
      description: 'Peru',
    },
    title: 'Peru',
  },
  {
    val: 'PH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ph.png',
      description: 'Philippines',
    },
    title: 'Philippines',
  },
  {
    val: 'PN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pn.png',
      description: 'Pitcairn',
    },
    title: 'Pitcairn',
  },
  {
    val: 'PL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pl.png',
      description: 'Poland',
    },
    title: 'Poland',
  },
  {
    val: 'PT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pt.png',
      description: 'Portugal',
    },
    title: 'Portugal',
  },
  {
    val: 'PR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pr.png',
      description: 'Puerto Rico',
    },
    title: 'Puerto Rico',
  },
  {
    val: 'QA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/qa.png',
      description: 'Qatar',
    },
    title: 'Qatar',
  },
  {
    val: 'CG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/cg.png',
      description: 'Republic of the Congo',
    },
    title: 'Republic of the Congo',
  },
  {
    val: 'RO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ro.png',
      description: 'Romania',
    },
    title: 'Romania',
  },
  {
    val: 'RU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ru.png',
      description: 'Russia',
    },
    title: 'Russia',
  },
  {
    val: 'RW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/rw.png',
      description: 'Rwanda',
    },
    title: 'Rwanda',
  },
  {
    val: 'RE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/re.png',
      description: 'Réunion',
    },
    title: 'Réunion',
  },
  {
    val: 'BL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/bl.png',
      description: 'Saint Barthélemy',
    },
    title: 'Saint Barthélemy',
  },
  {
    val: 'SH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sh.png',
      description: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    title: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    val: 'KN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kn.png',
      description: 'Saint Kitts and Nevis',
    },
    title: 'Saint Kitts and Nevis',
  },
  {
    val: 'LC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lc.png',
      description: 'Saint Lucia',
    },
    title: 'Saint Lucia',
  },
  {
    val: 'MF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/mf.png',
      description: 'Saint Martin',
    },
    title: 'Saint Martin',
  },
  {
    val: 'PM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/pm.png',
      description: 'Saint Pierre and Miquelon',
    },
    title: 'Saint Pierre and Miquelon',
  },
  {
    val: 'VC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/vc.png',
      description: 'Saint Vincent and the Grenadines',
    },
    title: 'Saint Vincent and the Grenadines',
  },
  {
    val: 'WS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ws.png',
      description: 'Samoa',
    },
    title: 'Samoa',
  },
  {
    val: 'SM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sm.png',
      description: 'San Marino',
    },
    title: 'San Marino',
  },
  {
    val: 'ST',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/st.png',
      description: 'Sao Tome and Principe',
    },
    title: 'Sao Tome and Principe',
  },
  {
    val: 'SA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sa.png',
      description: 'Saudi Arabia',
    },
    title: 'Saudi Arabia',
  },
  {
    val: 'GB-SCT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gb.png',
      description: 'Scotland',
    },
    title: 'Scotland',
  },
  {
    val: 'SN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sn.png',
      description: 'Senegal',
    },
    title: 'Senegal',
  },
  {
    val: 'RS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/rs.png',
      description: 'Serbia',
    },
    title: 'Serbia',
  },
  {
    val: 'SC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sc.png',
      description: 'Seychelles',
    },
    title: 'Seychelles',
  },
  {
    val: 'SL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sl.png',
      description: 'Sierra Leone',
    },
    title: 'Sierra Leone',
  },
  {
    val: 'SG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sg.png',
      description: 'Singapore',
    },
    title: 'Singapore',
  },
  {
    val: 'SX',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sx.png',
      description: 'Sint Maarten',
    },
    title: 'Sint Maarten',
  },
  {
    val: 'SK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sk.png',
      description: 'Slovakia',
    },
    title: 'Slovakia',
  },
  {
    val: 'SI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/si.png',
      description: 'Slovenia',
    },
    title: 'Slovenia',
  },
  {
    val: 'SB',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sb.png',
      description: 'Solomon Islands',
    },
    title: 'Solomon Islands',
  },
  {
    val: 'SO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/so.png',
      description: 'Somalia',
    },
    title: 'Somalia',
  },
  {
    val: 'ZA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/za.png',
      description: 'South Africa',
    },
    title: 'South Africa',
  },
  {
    val: 'GS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gs.png',
      description: 'South Georgia and the South Sandwich Islands',
    },
    title: 'South Georgia and the South Sandwich Islands',
  },
  {
    val: 'KR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/kr.png',
      description: 'South Korea',
    },
    title: 'South Korea',
  },
  {
    val: 'SS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ss.png',
      description: 'South Sudan',
    },
    title: 'South Sudan',
  },
  {
    val: 'ES',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/es.png',
      description: 'Spain',
    },
    title: 'Spain',
  },
  {
    val: 'LK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/lk.png',
      description: 'Sri Lanka',
    },
    title: 'Sri Lanka',
  },
  {
    val: 'PS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ps.png',
      description: 'State of Palestine',
    },
    title: 'State of Palestine',
  },
  {
    val: 'SD',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sd.png',
      description: 'Sudan',
    },
    title: 'Sudan',
  },
  {
    val: 'SR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sr.png',
      description: 'Suriname',
    },
    title: 'Suriname',
  },
  {
    val: 'SJ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sj.png',
      description: 'Svalbard and Jan Mayen',
    },
    title: 'Svalbard and Jan Mayen',
  },
  {
    val: 'SZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sz.png',
      description: 'Swaziland',
    },
    title: 'Swaziland',
  },
  {
    val: 'SE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/se.png',
      description: 'Sweden',
    },
    title: 'Sweden',
  },
  {
    val: 'CH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ch.png',
      description: 'Switzerland',
    },
    title: 'Switzerland',
  },
  {
    val: 'SY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/sy.png',
      description: 'Syrian Arab Republic',
    },
    title: 'Syrian Arab Republic',
  },
  {
    val: 'TW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tw.png',
      description: 'Taiwan',
    },
    title: 'Taiwan',
  },
  {
    val: 'TJ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tj.png',
      description: 'Tajikistan',
    },
    title: 'Tajikistan',
  },
  {
    val: 'TZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tz.png',
      description: 'Tanzania',
    },
    title: 'Tanzania',
  },
  {
    val: 'TH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/th.png',
      description: 'Thailand',
    },
    title: 'Thailand',
  },
  {
    val: 'TL',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tl.png',
      description: 'Timor-Leste',
    },
    title: 'Timor-Leste',
  },
  {
    val: 'TG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tg.png',
      description: 'Togo',
    },
    title: 'Togo',
  },
  {
    val: 'TK',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tk.png',
      description: 'Tokelau',
    },
    title: 'Tokelau',
  },
  {
    val: 'TO',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/to.png',
      description: 'Tonga',
    },
    title: 'Tonga',
  },
  {
    val: 'TT',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tt.png',
      description: 'Trinidad and Tobago',
    },
    title: 'Trinidad and Tobago',
  },
  {
    val: 'TN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tn.png',
      description: 'Tunisia',
    },
    title: 'Tunisia',
  },
  {
    val: 'TR',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tr.png',
      description: 'Turkey',
    },
    title: 'Turkey',
  },
  {
    val: 'TM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tm.png',
      description: 'Turkmenistan',
    },
    title: 'Turkmenistan',
  },
  {
    val: 'TC',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tc.png',
      description: 'Turks and Caicos Islands',
    },
    title: 'Turks and Caicos Islands',
  },
  {
    val: 'TV',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/tv.png',
      description: 'Tuvalu',
    },
    title: 'Tuvalu',
  },
  {
    val: 'UG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ug.png',
      description: 'Uganda',
    },
    title: 'Uganda',
  },
  {
    val: 'UA',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ua.png',
      description: 'Ukraine',
    },
    title: 'Ukraine',
  },
  {
    val: 'AE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ae.png',
      description: 'United Arab Emirates',
    },
    title: 'United Arab Emirates',
  },
  {
    val: 'GB',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gb.png',
      description: 'United Kingdom',
    },
    title: 'United Kingdom',
  },
  {
    val: 'UM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/um.png',
      description: 'United States Minor Outlying Islands',
    },
    title: 'United States Minor Outlying Islands',
  },
  {
    val: 'US',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/us.png',
      description: 'United States of America',
    },
    title: 'United States of America',
  },
  {
    val: 'UY',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/uy.png',
      description: 'Uruguay',
    },
    title: 'Uruguay',
  },
  {
    val: 'UZ',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/uz.png',
      description: 'Uzbekistan',
    },
    title: 'Uzbekistan',
  },
  {
    val: 'VU',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/vu.png',
      description: 'Vanuatu',
    },
    title: 'Vanuatu',
  },
  {
    val: 'VE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ve.png',
      description: 'Venezuela (Bolivarian Republic of)',
    },
    title: 'Venezuela (Bolivarian Republic of)',
  },
  {
    val: 'VN',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/vn.png',
      description: 'Vietnam',
    },
    title: 'Vietnam',
  },
  {
    val: 'VG',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/vg.png',
      description: 'Virgin Islands (British)',
    },
    title: 'Virgin Islands (British)',
  },
  {
    val: 'VI',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/vi.png',
      description: 'Virgin Islands (U.S.)',
    },
    title: 'Virgin Islands (U.S.)',
  },
  {
    val: 'GB-WLS',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/gb.png',
      description: 'Wales',
    },
    title: 'Wales',
  },
  {
    val: 'WF',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/wf.png',
      description: 'Wallis and Futuna',
    },
    title: 'Wallis and Futuna',
  },
  {
    val: 'EH',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/eh.png',
      description: 'Western Sahara',
    },
    title: 'Western Sahara',
  },
  {
    val: 'YE',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/ye.png',
      description: 'Yemen',
    },
    title: 'Yemen',
  },
  {
    val: 'ZM',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/zm.png',
      description: 'Zambia',
    },
    title: 'Zambia',
  },
  {
    val: 'ZW',
    options: {
      icon: 'assets/vendor/svg-country-flags/png100px/zw.png',
      description: 'Zimbabwe',
    },
    title: 'Zimbabwe',
  },
];
