import { Injectable } from '@angular/core';
import { GenericApiClientService } from '../generic-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import { API_ENDPOINTS, ApiService } from '../api.service';
import { IContractModel } from './contract-model.interface';
import { ContractTransformService } from './contract-transform.service';

@Injectable({
  providedIn: 'root',
})
export class ContractClientService extends GenericApiClientService<IContractModel> {
  constructor(http: HttpClient, apiService: ApiService, log: LogService, transformer: ContractTransformService) {
    super(http, apiService, log, transformer);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.contracts);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.contract);
  }
}
