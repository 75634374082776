<div
  [id]="hsId"
  class="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none [--close-when-click-inside:true]"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-md sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="relative max-h-full w-full overflow-hidden flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
    >
      <!-- Close Button -->
      <div class="absolute top-2 end-4 z-10">
        <button
          type="button"
          class="w-8 h-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200"
          [attr.data-hs-overlay]="'#'+hsId"
          (click)="onCancel()"
        >
          <span class="sr-only">Close modal</span>
          <svg
            class="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      </div>
      <!-- End Close Button -->

      <!-- Body -->
      <div class="p-4">
        <h2 class="text-lg font-medium text-gray-800">{{ title }}</h2>
        <p class="my-2 text-gray-500"> {{ message }}</p>

        <!-- Button Group -->
        <div class="mt-4 flex justify-end gap-x-3">
          <ui-button
            btnType="outline"
            [attr.data-hs-overlay]="'#'+hsId"
            (click)="onCancel()"
          >
            Cancel
          </ui-button>
          <ui-button
            [btnType]="type === 'positive' ? 'solid' : 'solid-negative'"
            [attr.data-hs-overlay]="'#'+hsId"
            (click)="onConfirm()"
          >
            {{ confirmButtonText }}
          </ui-button>
        </div>
        <!-- End Button Group -->
      </div>
      <!-- End Body -->
    </div>
  </div>
</div>
