<layout-main>
    <!-- BREADCRUMB -->
  <ol
  class="flex items-center whitespace-nowrap mb-4 leading-5  xs:mt-4 sm:mt-0  xs:mx-4 sm:mx-0"
  aria-label="Breadcrumb"
>
  <li class="inline-flex items-center leading-5">
    <a
      class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 "
      routerLink="/"
      >Home
    </a>
    <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5  cursor-default"
      >/</span
    >
  </li>
  <li class="inline-flex items-center leading-5">
    <a
      class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 "
      [routerLink]="['/bookings']"
    >
      Bookings
    </a>
    <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
      >/</span
    >
  </li>
  <li
    class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
    aria-current="page"
  >
    Booking Info  {{ booking && booking.id ? "#" + booking.id : "" }}
  </li>
</ol>
<!-- BREADCRUMB -->

<h2 class="font-bold text-lg xs:px-4 sm:px-0">Edit Booking</h2>

<!--  **** BOOKING MAIN **** -->
<div class="mb-4">
    <!-- TOP INFO  -->

    <!--  TAB *******************************************************************************************************************-->
    <nav class="flex overflow-x-auto pr-2 mb-4 overflow-x-auto xs:px-4 sm:px-0" aria-label="Tabs" role="tablist">
      <!-- TAB1 head  --</nav>>  -->
      <!-- active class az alapértelmezettnek -->
      <ui-button
        id="tabs-with-underline-item-1"
        data-hs-tab="#tabs-with-underline-1"
        aria-controls="tabs-with-underline-1"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-range-blue.svg"
        iconAlt="Cleaning overview tab icon"
        class="active"
        >Reservation</ui-button>
      <!-- TAB2 head  -->
      <ui-button
        id="tabs-with-underline-item-2"
        data-hs-tab="#tabs-with-underline-2"
        aria-controls="tabs-with-underline-2"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/request-blue.svg"
        iconAlt="Cleaning request tab icon"
        >Contact Info</ui-button>

      <!-- TAB3 head  -->
      <ui-button
        id="tabs-with-underline-item-3"
        data-hs-tab="#tabs-with-underline-3"
        aria-controls="tabs-with-underline-3"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Rooms/Apartments</ui-button>
      <!-- TAB4 head  -->
      <ui-button
        id="tabs-with-underline-item-4"
        data-hs-tab="#tabs-with-underline-4"
        aria-controls="tabs-with-underline-4"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Payment</ui-button>
      <!-- TAB5 head  -->
      <ui-button
        id="tabs-with-underline-item-5"
        data-hs-tab="#tabs-with-underline-5"
        aria-controls="tabs-with-underline-5"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Billing Info</ui-button>
    </nav>
    <div class="bg-white border shadow-sm sm:rounded-xl p-4 sm:w-2/3 md:w-2/4 mx-auto max-w-lg"
    >
      <!--  ******************************************************************************************************************************** -->

      <!--  1. tab body ******************************************************************************************************************-->
      <div
        id="tabs-with-underline-1"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-1">
        <reservation-tab [booking]="booking"></reservation-tab>
      </div>
      <!--  // 1  -->
      <!--   2 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-2"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-2">
      <contact-info-tab [booking]="booking"></contact-info-tab>
      </div>
      <!--  // 2  -->
      <!--   3 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-3"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-3"
      >
      <rooms-apartments-tab [booking]="booking"></rooms-apartments-tab>

      </div>
      <!--  // 3  -->
      <!--   4 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-4"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-4"
      >
      <payment-tab [booking]="booking"></payment-tab>

      </div>
      <!--  // 4  -->
      <!--   5 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-5"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-5"
      >
      <billing-data-tab [booking]="booking"></billing-data-tab>

      </div>
      <!--  // 3  -->
    </div>
  </div>

</layout-main>

