import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { menuItems } from './menuitems';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'nav-sidebar',
  standalone: true,
  imports: [CommonModule, RouterLink, LucideAngularModule],
  templateUrl: './nav-sidebar.component.html',
  styleUrl: './nav-sidebar.component.css',
})
export class NavSidebarComponent {
  // verzió megjelenítése a sidebaron
  version: string = environment.version;

  // a külső fájlból importált menuItems tömböt így érjük el a HTML-ből
  menuItems = menuItems;

  activeMenuItem: number = 1;
  activeSubmenuItem: number = 0;

  constructor(private readonly router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveMenuItemByURL(event.url);
      }
    });
  }

  setActiveMenuItemByURL(url: string): void {
    for (const element of menuItems) {
      if (url === element.link || this.getParentLink(url) === element.link) {
        this.setActiveMenuItem(element.id);
      }
      if (element.subMenuItems !== undefined) {
        for (const subMenuItem of element.subMenuItems) {
          if (
            url === subMenuItem.link ||
            this.getParentLink(url) === subMenuItem.link
          ) {
            this.setActiveMenuItem(element.id, subMenuItem.id);
          }
        }
      }
    }
  }

  setActiveMenuItem(menuItem: number, submenuItem?: number): void {
    this.activeMenuItem = menuItem;

    if (submenuItem !== undefined) {
      this.activeSubmenuItem = submenuItem;
    } else {
      this.activeSubmenuItem = -1;
    }

    // az Angular navigáció és a preline helpre script össze tud akadni, ezért manuálisan kell törölni a backdropot mobil méreten
    // kikeressük a divet, ahol az id=hs-pro-sidebar-backdrop
    const backdrop = document.getElementById('hs-pro-sidebar-backdrop');
    if (backdrop !== null) {
      // ha a backdrop létezik, akkor töröljük a DOM-ból
      backdrop.remove();
      // töröljük a bodyról a style="overflow: hidden;" attribútumot is
      document.body.style.overflow = '';
    }
  }

  // egyszerű segédfüggvény a szülő menü link meghatározására mélyebb navigáció esetén
  private getParentLink(url: string): string {
    if (url.startsWith('/bookings/')) {
      return '/bookings';
    } else if (url.startsWith('/issues/')) {
      return '/issues';
    } else if (
      url.startsWith('/properties/') ||
      url.startsWith('/properties?')
    ) {
      return '/properties';
    } else if (url.startsWith('/meters/')) {
      return '/meters';
    } else {
      return url;
    }
  }
}
