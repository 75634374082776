import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { InvoiceTransformService } from './invoice-transform.service';
import { IInvoiceModel } from './invoice-model.interface';

@Injectable({
  providedIn: 'root',
})
export class InvoiceClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: InvoiceTransformService
  ) {}

  /**
   * A számlák lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A számlák lekért oldala, nyers válaszban
   */
  private fetchInvoices(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.invoices), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a számlák első oldalát, hogy megtudja az összes számla számát
   * @returns Az összes számla számossága
   */
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchInvoices().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Egy számla törlése
   * @param id A törlendő számla azonosítója
   * @returns A törlés eredménye
   */
  deleteInvoice(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.invoice) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy számla létrehozása
   * @param invoice Az új számla adatai
   * @returns Az új számla adatai
   */
  createInvoice(invoice: IInvoiceModel): Observable<IInvoiceModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.invoices),
        this.transformer.serializeInvoice(invoice),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IInvoiceModel)
      );
  }

  /**
   * Egy számla módosítása
   * @param invoice Az módosítandó számla adatai
   * @returns Az módosított számla adatai
   */
  updateInvoice(invoice: IInvoiceModel): Observable<IInvoiceModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.invoice) + invoice.id,
        this.transformer.serializeInvoice(invoice),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IInvoiceModel)
      );
  }

  /**
   * Az összes számla lekérése
   * @returns Az összes számla adatai
   */
  getAllInvoices(): Observable<IInvoiceModel[]> {
    // megtudjuk az összes számla számát
    return this.getTotalItems().pipe(
      switchMap((totalItems) => {
        // kiszámoljuk, hogy hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        // minden oldalra kérünk egy-egy lekérést
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getInvoices(i));
        }

        // a lekéréseket összefűzzük
        return forkJoin(requests).pipe(
          map((results) => {
            let invoices = [] as IInvoiceModel[];
            for (let result of results) {
              invoices.push(...result);
            }
            return invoices;
          })
        );
      })
    );
  }

  /**
   * A számlák lekérése nyers, nem feldolgozott formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A számlák adatai nyers, nem feldolgozott formában
   */
  private getrawInvoices(page?: number): Observable<IInvoiceModel[]> {
    return this.fetchInvoices(page).pipe(
      map((response: any) => {
        const invoices = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
        return invoices;
      })
    );
  }

  /**
   * A számlák lekérése úgy, hogy a dátum/idő információk helyes formátumban legyenek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A számlák lekért oldala
   */
  getInvoices(page?: number): Observable<IInvoiceModel[]> {
    return this.getrawInvoices(page).pipe(
      map((invoices) => {
        return invoices.map((invoice) =>
          this.transformer.transformInvoice(invoice)
        );
      })
    );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'InvoiceClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform invoice operation'));
  };
}
