import { Component, Input} from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import {
  FlatClientService,
  FlatFilterType,
  IFlatTypeFilter
} from '../../../services/api/flat/flat-client.service';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { FlatTableRowComponent } from './flat-table-row/flat-table-row.component';


@Component({
  selector: 'flat-table',
  standalone: true,
  imports: [
    UiButtonComponent,
    ReactiveFormsModule,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
    FlatTableRowComponent,
  ],
  templateUrl: './flat-table.component.html',
  styleUrl: './flat-table.component.css',
})
export class FlatTableComponent {
  //@ViewChild(IssueEditorComponent)
  //editor!: IssueEditorComponent;
  @Input() flatTypeToFilter: IFlatTypeFilter = { type: 'ALL' };
  @Input() title = 'Properties';
  @Input() showCreateButton = true;
  selectedFlat!: IFlatModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  flats: IFlatModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;
  currentPage = 1;

  constructor(
    private flatService: FlatClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handlePagination(page: number) {
    this.currentPage = page;
    this.refresh(this.currentPage);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IFlatModel) {
    this.selectedFlat = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.flatService.deleteFlat(this.selectedFlat).subscribe({
      next: () => {
        // ha sikeres volt a törlés, akkor töröljük a listából is, de nem kérünk le mindent újra
        this.flats = this.flats.filter(
          (item) => item.id !== this.selectedFlat.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.flats.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Flat deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting flat');
        this.log.error('Error deleting flat', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedFlat = {
      id: 0,
      name: '',
      flat_number: '',
      size: '',
      number_of_bedrooms: 0,
      number_of_bathrooms: 0,
      number_of_rooms: 0,
      balcony: false,
      terrace: false,
    } as IFlatModel;
  }

  refresh(page?: number) {
    // lekérjük a hibajegyeket
    this.isLoading = true;
    this.flatService
      //TODO ez most egyetlen típust kér le, a lakásokat
      .getFlats(page)
      .subscribe({
        next: (flats) => {
          this.flats = flats;
          this.isLoading = false;
          setTimeout(() => {
            window.HSStaticMethods.autoInit();
          }, 100);
        },
        error: (err) => {
          this.toastr.error('Error getting flats');
          this.log.error('Error getting flats', err);
          this.isLoading = false;
        },
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.flatService.getTotalItems(FlatFilterType.FLAT).subscribe({
      next: (count) => {
        this.totalItems = count;
        this.isPagerLoading = false;
      },
      error: (err) => {
        this.log.error('Error getting total items', err);
        this.totalItems = 0;
        this.isPagerLoading = false;
      },
    });
  }
}
