import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { FloorClientService } from '../../../../services/api/floor/floor-client.service';
import { IFloorModel } from '../../../../services/api/floor/floor-model.interface';
import { LogService } from '../../../../services/utils/log.service';

@Component({
  selector: 'floor-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
  ],
  templateUrl: './floor-table-row.component.html',
  styleUrl: './floor-table-row.component.css'
})
export class FloorTableRowComponent {
  @Input() pitem: IFloorModel = {} as IFloorModel;
  @Output() onDelete = new EventEmitter<IFloorModel>();
  @Output() onEdit = new EventEmitter<IFloorModel>();
  @Output() onView = new EventEmitter<IFloorModel>();

  constructor(
    private floorService: FloorClientService,
    private log: LogService
  ) {}

  handleEdit(pitem: IFloorModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IFloorModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IFloorModel) {
    this.onDelete.emit(pitem);
  }

}
