<div class="">
  <div class="p-2">
    <form
      id="booking-flat-form"
      [formGroup]="bookingFlatForm"
      (ngSubmit)="saveBookingFlatInfo()"
    >
      <!--  Flat select  -->
      <label class="block mb-2 font-bold" for="booking-rooms-flats-select"
        >Rooms/apartments</label
      >
      <ng-select
        [items]="flatSelectItems"
        class="mb-4"
        labelForId="booking-rooms-flats-select"
        bindLabel="title"
        bindValue="val"
        [multiple]="true"
        placeholder="Select rooms/apartments"
        clearAllText="Clear"
        formControlName="flats"
        [virtualScroll]="true"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm"
        >
          <div>{{ item.title }}</div>
        </ng-template>
      </ng-select>

      <!--  Flat select  -->

      <div class="w-full max-w-lg text-right">
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          form="booking-flat-form"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
