import { Component, ViewChild } from '@angular/core';
import { ParkingRequestsTableRowComponent } from './parking-requests-table-row/parking-requests-table-row.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { ParkingRequestEditorComponent } from '../../modal-editors/parking-request-editor/parking-request-editor.component';
import { IParkingRequestModel } from '../../../services/api/parking-request/parking-request-model.interface';
import { ParkingRequestClientService } from '../../../services/api/parking-request/parking-request-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'parking-requests-table',
  standalone: true,
  templateUrl: './parking-requests-table.component.html',
  styleUrl: './parking-requests-table.component.css',
  imports: [
    ParkingRequestsTableRowComponent,
    UiButtonComponent,
    ParkingRequestEditorComponent,
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
  ],
})
export class ParkingRequestsTableComponent {
  @ViewChild(ParkingRequestEditorComponent)
  editor!: ParkingRequestEditorComponent;
  selectedParkingRequest!: IParkingRequestModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  parkingRequests: IParkingRequestModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private parkingRequestClientService: ParkingRequestClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: IParkingRequestModel) {
    this.selectedParkingRequest = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IParkingRequestModel) {
    this.selectedParkingRequest = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IParkingRequestModel) {
    this.selectedParkingRequest = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.parkingRequestClientService
      .deleteParkingRequest(this.selectedParkingRequest.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.log.info('Parking request deleted');
          this.parkingRequests = this.parkingRequests.filter(
            (pr) => pr.id !== this.selectedParkingRequest.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.parkingRequests.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Parking request deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting parking request');
          this.log.error('Error deleting parking request', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedParkingRequest = {
      '@id': '',
      id: 0,
      license_plate: '',
      license_plate_country_code: '',
      license_plate_not_known: false,
      disabled_parking_place: false,
      start_date: '',
      end_date: '',
      paid: false,
      created_at: '',
      updated_at: '',
      paymentTransactions: [],
    } as IParkingRequestModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.parkingRequestClientService
      .getParkingRequests('', '', page)
      .subscribe({
        next: (parkingRequests) => {
          this.log.debug('Parking requests fetched', parkingRequests);
          this.parkingRequests = parkingRequests;
          setTimeout(() => {
            this.isLoading = false;
          }, 100);

          setTimeout(() => {
            this.log.info('Parking requests table initialized');
            window.HSStaticMethods.autoInit('overlay');
          }, 100);
        },
        error: (err) => {
          this.log.error('Error fetching parking requests', err);
          this.isLoading = false;
        },
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.parkingRequestClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
