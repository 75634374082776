<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm focus:outline-none focus:text-blue-600 leading-5 cursor-default"
      >
        Short-term rental
      </span>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Invoicing
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!--  **** INVOICING MAIN **** -->
  <div class="mb-4">
    <!--  TAB *******************************************************************************************************************-->

    <nav
      class="flex flex-wrap xs:mx-4 sm:ml-0 mb-4"
      aria-label="Tabs"
      role="tablist"
    >
      <!-- TAB1 head  -->
      <!-- active class az alapértelmezettnek -->
      <ui-button
        id="tabs-with-underline-item-1"
        data-hs-tab="#tabs-with-underline-1"
        aria-controls="tabs-with-underline-1"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/list.svg"
        iconAlt="Invoices tab icon"
        class="active"
        >Invoices</ui-button
      >
      <!-- TAB2 head  -->
      <ui-button
        id="tabs-with-underline-item-2"
        data-hs-tab="#tabs-with-underline-2"
        aria-controls="tabs-with-underline-2"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/home-blue.svg"
        iconAlt="Billing adresses tab icon"
        >Billing adresses</ui-button
      >
      <!-- TAB3 head  -->
      <ui-button
        id="tabs-with-underline-item-3"
        data-hs-tab="#tabs-with-underline-3"
        aria-controls="tabs-with-underline-3"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/dollar-sign2-blue.svg"
        iconAlt="Payment transactions tab icon"
        >Payment transactions</ui-button
      >
    </nav>
    <div class="bg-white border shadow-sm sm:rounded-xl p-4">
      <!--  ******************************************************************************************************************************** -->
      <!--  1. tab body ******************************************************************************************************************-->
      <div
        id="tabs-with-underline-1"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-1"
      >
        <invoice-table></invoice-table>
      </div>
      <!--  // 1  -->
      <!--  ** TENANT 2 *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-2"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-2"
      >
        <billing-address-table></billing-address-table>
      </div>
      <!--  // 2  -->

      <!--  BILLING 3  *************************************************************************************************************************-->

      <div
        id="tabs-with-underline-3"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-3"
      >
        <payment-transaction-table></payment-transaction-table>
      </div>
      <!-- // 3 -->
    </div>
  </div>
</layout-main>
