<main id="content" role="main">
  <div class="max-w-[50rem] flex flex-col mx-auto size-full">
    <!-- Header -->
    <header class="px-8 h-[46px] mt-14 text-center mb-14">
      <!-- Logo -->
      <a
        class="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80 w-64"
        routerLink="/"
        aria-label="Preline"
      >
        <img
          src="assets/img/i11-company-logo.svg"
          alt="Smartapart"
          class="w-full h-auto"
        />
      </a>
      <!-- End Logo -->
    </header>
    <!-- End Header -->
    <!-- Error content here -->

    <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
      <h1 class="block text-7xl font-bold text-gray-800 sm:text-9xl">
        {{ errorCode }}
      </h1>
      <p class="mt-3 text-gray-600">Oops, something went wrong.</p>
      <p class="text-gray-600">{{ errorMessage }}.</p>
    </div>
    <ui-button
      class="m-auto mb-10 inline-block text-center"
      btnType="solid"
      buttonSize="lg"
      routerLink="/"
      >
    Go to the Homepage
    </ui-button>
    <!-- End Error content -->
  </div>
</main>
