import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { IParkingRequestModel } from '../../../services/api/parking-request/parking-request-model.interface';
import { ParkingRequestClientService } from '../../../services/api/parking-request/parking-request-client.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { UiSelectComponent } from '../../atomic-ui-components/select/ui-select.component';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { countries } from '../../atomic-ui-components/select/country-data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'parking-request-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiSwitchComponent,
    UiSelectComponent,
    NgSelectModule,
  ],
  templateUrl: './parking-request-editor.component.html',
  styleUrl: './parking-request-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingRequestEditorComponent {
  @Input() parkingRequest!: IParkingRequestModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  countries = countries;

  bookingSelectItems: IUiSelectItem[] = [];
  parkingSPaceSelectItems: IUiSelectItem[] = [];

  parkingRequestForm: FormGroup = this.fb.group({
    license_plate: [''],
    license_plate_country_code: [''],
    license_plate_not_known: [''],
    disabled_parking_place: [''],
    start_date: [''],
    end_date: [''],
    paid: [''],
    booking: [''],
    parkingPlace: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private parkingRequestService: ParkingRequestClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('ParkingRequestEditor Changed', this.parkingRequest);
    this.parkingRequestForm.reset();
    if (this.mode === 'view') {
      this.parkingRequestForm.disable();
    } else {
      this.parkingRequestForm.enable();
    }
    if (this.mode === 'edit') {
      this.parkingRequestForm.patchValue(this.parkingRequest);
      this.parkingRequestForm
        .get('license_plate')
        ?.setValue(
          this.parkingRequest.license_plate
            ? this.parkingRequest.license_plate
            : ''
        );
    }

    // Parkolóhelyek (lakások) és foglalások kezelése a dropdownban
    this.parkingRequestForm
      .get('booking')
      ?.setValue(this.parkingRequest.booking?.['@id']);
    this.parkingRequestForm
      .get('parkingPlace')
      ?.setValue(this.parkingRequest.parkingPlace?.['@id']);
  }

  ngOnInit() {
    // Várunk egy kicsit, hogy a parkoló adatok a chartban esélyt kapjanak betöltődni
    setTimeout(() => {
      // Betöltjük a foglalásokat a dropdownhoz
      this.silo.getBookingSelectItems().subscribe({
        next: (bookings) => {
          this.bookingSelectItems = bookings;
          this.log.debug(
            'Bookings loaded into select:',
            this.bookingSelectItems
          );
        },
        error: (err) => {
          this.log.error('Error loading bookings into select:', err);
        },
      });

      // Betöltjük a parkolókat a dropdownhoz
      this.silo.getParkingSPaceSelectItems().subscribe({
        next: (parkingSpaces) => {
          this.parkingSPaceSelectItems = parkingSpaces;
          this.log.debug('Flats loaded into select:', this.parkingSPaceSelectItems);
        },
        error: (err) => {
          this.log.error('Error loading flats into select:', err);
        },
      });
    }, 1000);
  }

  saveParkingRequest() {
    this.log.info('Saving Parking request');
    if (this.parkingRequestForm.valid && this.mode !== 'view') {
      const updatedUntypedParkingRequest: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.parkingRequestForm.controls).forEach((key) => {
        const control = this.parkingRequestForm.get(key);
        if (control?.dirty) {
          updatedUntypedParkingRequest[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedParkingRequest).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedParkingRequest =
        updatedUntypedParkingRequest as IParkingRequestModel;
      this.log.debug('Saving Parking request:', updatedParkingRequest);

      if (this.mode === 'edit') {
        updatedParkingRequest.id = this.parkingRequest.id;
        this.parkingRequestService
          .updateParkingRequest(updatedParkingRequest)
          .subscribe({
            next: (data) => {
              this.toastr.success('Parking request updated');
              this.log.debug('Parking request updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Parking request');
              this.log.error('Error updating Parking request:', err);
            },
          });
      } else {
        this.parkingRequestService
          .createParkingRequest(updatedParkingRequest)
          .subscribe({
            next: (data) => {
              this.toastr.success('Parking request created');
              this.log.debug('Parking request created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Parking request');
              this.log.error('Error creating Parking request:', err);
            },
          });
      }
    }
  }
}
