import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, throwError, Observable, map } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  ApiService,
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
} from '../api.service';
import { IBookingStatusModel } from './booking-status-model.interface';

@Injectable({
  providedIn: 'root',
})
export class BookingStatusClientService {
  // egy minimális "cache", hogy ne kelljen mindig lekérni az összes foglalási státuszt
  private bookingStatuses: IBookingStatusModel[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Foglalási státuszok lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A foglalási státuszok lekért oldala, nyers válaszban
   */
  private fetchBookingStatuses(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.bookingStatuses), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Foglalási státuszok lekérése, gyorsítótárazása
   * @returns A foglalási státuszok listája
   */
  getBookingStatuses(): Observable<IBookingStatusModel[]> {
    if (this.bookingStatuses.length > 0) {
      return new Observable<IBookingStatusModel[]>((observer) => {
        observer.next(this.bookingStatuses);
        observer.complete();
      });
    }
    return this.fetchBookingStatuses().pipe(
      map((response: any) => {
        this.bookingStatuses = response['hydra:member'];
        return this.bookingStatuses;
      })
    );
  }

  /**
   * Foglalási státusz lekérése azonosító alapján
   * @param id A foglalási státusz azonosítója
   * @returns A foglalási státusz adatai
   */
  getBookingStatus(id: number): Observable<IBookingStatusModel> {
    // ha nincs cache, akkor lekérjük az összes foglalási státuszt
    if (this.bookingStatuses.length === 0) {
      return this.getBookingStatuses().pipe(
        map((bookingStatuses) => {
          const bookingStatus = bookingStatuses.find(
            (bookingStatus) => bookingStatus.id === id
          );
          if (bookingStatus) {
            return bookingStatus;
          }
          throw new Error('BookingStatus not found');
        }),
        catchError(this.handleError)
      );
    } else {
      // ha van cache, akkor ott keresünk
      const bookingStatus = this.bookingStatuses.find(
        (bookingStatus) => bookingStatus.id === id
      );
      if (bookingStatus) {
        return new Observable<IBookingStatusModel>((observer) => {
          observer.next(bookingStatus);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és nézzük frissen
        this.invalidateCache();
        return this.getBookingStatus(id);
      }
    }
  }

  /**
   * Foglalási státusz lekérése IRI alapján
   * @param iri A foglalási státusz IRI-ja
   * @returns A foglalási státusz adatai
   */
  getBookingStatusByIri(iri: string): Observable<IBookingStatusModel> {
    // ha nincs cache, akkor lekérjük az összes foglalási státuszt
    if (this.bookingStatuses.length === 0) {
      return this.http
        .get(this.apiService.getBaseUrl() + iri)
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError))
        .pipe(
          map((response: any) => {
            if (response) {
              return response;
            }
            throw new Error('BookingStatus not found');
          }),
          catchError(this.handleError)
        );
    } else {
      // ha van cache, akkor ott keresünk
      const bookingStatus = this.bookingStatuses.find(
        (bookingStatus) => bookingStatus['@id'] === iri
      );
      if (bookingStatus) {
        return new Observable<IBookingStatusModel>((observer) => {
          observer.next(bookingStatus);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és nézzük frissen
        this.invalidateCache();
        return this.getBookingStatusByIri(iri);
      }
    }
  }

  /**
   * Érvényteleníti a cache-t
   */
  invalidateCache() {
    this.bookingStatuses = [];
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'BookingStatusClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform bookingStatus operation')
    );
  };
}
