import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { DatePipe } from '@angular/common';
import { LogService } from '../../../../services/utils/log.service';
import { ReadingClientService } from '../../../../services/api/reading/reading-client.service';
import { IReadingModel } from '../../../../services/api/reading/reading-model.interface';

@Component({
  selector: 'meter-reading-table-row',
  standalone: true,
  imports: [UiSwitchComponent, UiBadgeComponent, UiButtonComponent, DatePipe],
  templateUrl: './meter-reading-table-row.component.html',
  styleUrl: './meter-reading-table-row.component.css',
})
export class MeterReadingTableRowComponent {
  @Input() pitem: IReadingModel = {} as IReadingModel;
  @Output() onDelete = new EventEmitter<IReadingModel>();
  @Output() onEdit = new EventEmitter<IReadingModel>();

  constructor(
    private readingService: ReadingClientService,
    private log: LogService
  ) {}

  handleEdit(pitem: IReadingModel) {
    this.onEdit.emit(pitem);
  }

  handleDelete(pitem: IReadingModel) {
    this.onDelete.emit(pitem);
  }
}
