import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { RouterLink } from '@angular/router';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { UiBadgeComponent } from '../../atomic-ui-components/badge/ui-badge.component';
import { FlatFormatterPipe } from '../../../services/utils/flat-formatter.pipe';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'booking-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    RouterLink,
    UiBadgeComponent,
    FlatFormatterPipe, 
    UiButtonComponent,
  ],
  templateUrl: './booking-table-row.component.html',
  styleUrl: './booking-table-row.component.css',
})
export class BookingTableRowComponent {
  @Input() pitem: IBookingModel = {} as IBookingModel;
  @Output() onDelete = new EventEmitter<IBookingModel>();

  handleDelete(pitem: IBookingModel) {
    this.onDelete.emit(pitem);
  }
}
