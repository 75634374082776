import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { IIssueModel } from '../../services/api/issue/issue-model.interface';
import { IIssueNoteModel } from '../../services/api/issue-note/issue-note-model.interface';
import { LogService } from '../../services/utils/log.service';
import { UiBadgeComponent } from '../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IssueNoteClientService } from '../../services/api/issue-note/issue-note-client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'issue-notes',
  standalone: true,
  imports: [
    LayoutMainComponent,
    DatePipe,
    UiBadgeComponent,
    CommonModule,
    UiButtonComponent,
    UiButtonComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './issue-notes.component.html',
  styleUrl: './issue-notes.component.css',
})
export class IssueNotesComponent implements OnChanges {
  @Input() issue!: IIssueModel;
  @Input() mode: 'view' | 'edit' = 'view';
  issueNotes: IIssueNoteModel[] = [];
  lastDay: string | undefined = '';
  thisDay: string | undefined = 'abc';

  commentForm: FormGroup = this.fb.group({
    note: ['', Validators.required],
  });

  constructor(
    private issueNoteService: IssueNoteClientService,
    private log: LogService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnChanges(): void {
    this.log.debug('IssueNotesComponent changed', this.issue);
    this.issueNotes = this.issue.issueNotes ?? [];
  }

  sendNote() {
    this.log.debug('sendNote');
    if (this.commentForm.valid && this.issue) {
      this.issueNoteService
        .createIssueNote({
          note: this.commentForm.get('note')?.value,
          issue: this.issue['@id'],
        } as IIssueNoteModel)
        .subscribe({
          next: (note) => {
            this.log.debug('Note sent', note);
            this.toastr.success('Note sent');
            this.issueNotes.push(note);
            this.commentForm.reset();
          },
          error: (err) => {
            this.log.error('Error sending note', err);
            this.toastr.error('Error sending note');
          },
        });
    }
  }

  isNewDay(Day?: string) {
    let show: boolean = false;
    this.thisDay = Day?.slice(0, 10);

    if (this.thisDay != this.lastDay) {
      this.lastDay = this.thisDay;
      show = true;
    } else {
      show = false;
    }
    return show;
  }

  setThisDay(newDay: Date) {
    //this.Day
  }
}
