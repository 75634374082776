import { Component, EventEmitter, Output } from '@angular/core';
import { LogService } from '../../../services/utils/log.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { GuestsOverviewRowComponent } from './guests-overview-row/guests-overview-row.component';
import { IGuestOverviewItem } from './guest-overview-item.interface';
import {
  BookingClientService,
  IBookingQueryParams,
} from '../../../services/api/booking/booking-client.service';
import { EntryCodeClientService } from '../../../services/api/entry-code/entry-code-client.service';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { format, isWithinInterval, parseISO } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';

@Component({
  selector: 'guests-overview',
  standalone: true,
  imports: [
    UiButtonComponent,
    GuestsOverviewRowComponent,
    TableEmptyStateComponent,
    UiTablePagerComponent,
  ],
  templateUrl: './guests-overview.component.html',
  styleUrl: './guests-overview.component.css',
})
export class GuestsOverviewComponent {
  @Output() guestsCount = new EventEmitter<number>();
  guests: IGuestOverviewItem[] = [];
  isLoading = true;
  totalItems = 0;
  isPagerLoading = false;

  constructor(
    private bookingService: BookingClientService,
    private entryCodeService: EntryCodeClientService,
    private log: LogService,
    private formatter: ModelFormatterService,
    private toastr: ToastrService,
    private hardCodedConfig: HardCodedConfigService
  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.log.info('Loading guests');

    // A mai napot vesszük alapul, és azokat a foglalásokat kérjük le, amiknek a start_date-je kisebb, az end_date-je pedig nagyobb vagy egyenlő
    // tehát a mai napon kezdők nincsenek benne
    // TODO visszakérdezni, hogy a mai napon kezdők is benne legyenek-e
    const today = new Date();
    const queryParams = {
      startDate: { strictly_before: format(today, 'yyyy-MM-dd') },
      endDate: { after: format(today, 'yyyy-MM-dd') },
    } as IBookingQueryParams;

    this.bookingService.getAllBookings(queryParams).subscribe({
      next: (data) => {
        this.guests = data
          // Csak az aktív foglalásokat tartjuk meg
          .filter((booking) => {
            const startDate = parseISO(booking.start_date ?? '1970-01-01');
            const endDate = parseISO(booking.end_date ?? '9999-12-31');
            return (
              isWithinInterval(today, { start: startDate, end: endDate }) &&
              booking.bookingStatus?.id === this.hardCodedConfig.FINALIZED_BOOKING_STATUS_ID
            );
          })
          // Nem kell az egész booking, átalakítjuk a megjelenítéshez szükséges formátumra
          .map((booking: IBookingModel) => {
            return {
              id: booking.id,
              name: booking.name ?? '',
              email: booking.email_address ?? '',
              phone: booking.phone_number ?? '',
              flats: booking.flats?.map((flat) => {
                return {
                  id: flat.id,
                  building: flat.building?.name ? flat.building.name : '',
                  room: this.formatter.getFormattedFlat(flat, false),
                };
              }),
              isLoadingCodes: true,
              entryCodes: [],
              startDate: booking.start_date ?? '',
              endDate: booking.end_date ?? '',
              bookingSource: booking.bookingSource?.name ?? '',
              bookingUser: booking.user,
            } as IGuestOverviewItem;
          })
          .sort((a, b) => {
            // ellenőrzések. Ha nincs flats tömb, vagy 0 hosszú, akkor a másik jön előrébb. Ha mindkettőnél ez van, akkor meg 0-t ad vissza.
            if (!a.flats || a.flats.length === 0) {
              if (!b.flats || b.flats.length === 0) return 0;
              return -1;
            }
            if (!b.flats || b.flats.length === 0) return 1;
            return (a.flats[0]?.building || '').localeCompare(
              b.flats[0]?.building || ''
            );
          });
        this.totalItems = this.guests.length;
        this.guestsCount.emit(this.totalItems);
        this.isLoading = false;
        this.log.debug('Loaded guests', this.guests);
        for (const guest of this.guests) {
          if (guest.flats && guest.flats.length > 0) {
            for (const flat of guest.flats) {
              this.entryCodeService
                .getActiveEntryCodes(flat.id, guest.bookingUser?.id)
                .subscribe({
                  next: (entryCodes) => {
                    guest.entryCodes.push(
                      ...entryCodes.map((entryCode) => entryCode.entry_code)
                    );
                    guest.isLoadingCodes = false;
                  },
                  error: (error) => {
                    this.log.error('Error loading entry codes', error);
                  },
                });
            }
          } else {
            guest.isLoadingCodes = false;
          }
        }
      },
      error: (error) => {
        this.log.error('Error loading guests', error);
        this.isLoading = false;
      },
    });
  }

  handlePagination(page: number) {
    this.refresh(page);
  }


  sendEmail(id: number) {
    this.log.debug('Send entry code email for booking: ', id);
    this.bookingService.sendEntryCodeEmail(id).subscribe({
      next: (data) => {
        this.log.info('Email trigger result: ' + data.message);
        this.toastr.info('Email trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error sending email for booking: ', id, error);
        this.toastr.error('Error sending email for booking', error);
      },
    });
  }

  sendSms(id: number) {
    this.log.debug('Send entry code sms for booking: ', id);
    this.bookingService.sendEntryCodeSms(id).subscribe({
      next: (data) => {
        this.log.info('Sms trigger result: ' + data.message);
        this.toastr.info('Sms trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error sending sms for booking: ', id, error);
        this.toastr.error('Error sending sms for booking', error);
      },
    });
  }

  generateInvoice(id: number) {
    this.log.debug('Generate invoice for booking: ', id);
    this.bookingService.generateInvoice(id).subscribe({
      next: (data) => {
        this.log.info('Invoice trigger result: ' + data.message);
        this.toastr.info('Invoice trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error generating invoice for booking: ', id, error);
        this.toastr.error('Error generating invoice for booking', error);
      },
    });
  }
}
