import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, throwError, Observable, map } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  ApiService,
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
} from '../api.service';
import { ICurrencyModel } from './currency-model.interface';

@Injectable({
  providedIn: 'root',
})
export class CurrencyClientService {
  // egy minimális "cache", hogy ne kelljen mindig lekérni az összes valutát
  private currencies: ICurrencyModel[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Valuták lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A valuták lekért oldala, nyers válaszban
   */
  private fetchCurrencies(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.currencies), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Valuták lekérése, gyorsítótárazása
   * @returns A valuták listája
   */
  getCurrencies(): Observable<ICurrencyModel[]> {
    return this.fetchCurrencies().pipe(
      map((response: any) => {
        this.currencies = response['hydra:member'];
        return this.currencies;
      })
    );
  }

  /**
   * Valuta lekérése azonosító alapján
   * @param id A valuta azonosítója
   * @returns A valuta adatai
   */
  getCurrency(id: number): Observable<ICurrencyModel> {
    // ha nincs cache, akkor lekérjük az összes valutát
    if (this.currencies.length === 0) {
      return this.getCurrencies().pipe(
        map((currencies) => {
          const currency = currencies.find((currency) => currency.id === id);
          if (currency) {
            return currency;
          }
          throw new Error('Currency not found');
        }),
        catchError(this.handleError)
      );
    } else {
      // ha van cache, akkor ott keresünk
      const currency = this.currencies.find((currency) => currency.id === id);
      if (currency) {
        return new Observable<ICurrencyModel>((observer) => {
          observer.next(currency);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és nézzük frissen
        this.invalidateCache();
        return this.getCurrency(id);
      }
    }
  }

  /**
   * Valuta lekérése IRI alapján
   * @param iri A valuta IRI-ja
   * @returns A valuta adatai
   */
  getCurrencyByIri(iri: string): Observable<ICurrencyModel> {
    // ha nincs cache, akkor lekérjük az összes valutát
    if (this.currencies.length === 0) {
      return this.http
        .get(this.apiService.getBaseUrl() + iri)
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError))
        .pipe(
          map((response: any) => {
            if (response) {
              return response;
            }
            throw new Error('Currency not found');
          }),
          catchError(this.handleError)
        );
    } else {
      // ha van cache, akkor ott keresünk
      const currency = this.currencies.find(
        (currency) => currency['@id'] === iri
      );
      if (currency) {
        return new Observable<ICurrencyModel>((observer) => {
          observer.next(currency);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és nézzük frissen
        this.invalidateCache();
        return this.getCurrencyByIri(iri);
      }
    }
  }

  /**
   * Érvényteleníti a cache-t
   */
  invalidateCache() {
    this.currencies = [];
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'CurrencyClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform currency operation'));
  };
}
