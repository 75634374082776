import { Component, EventEmitter, Output } from '@angular/core';
import { LogService } from '../../../services/utils/log.service';
import { CheckinsOverviewRowComponent } from './checkins-overview-row/checkins-overview-row.component';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import {
  BookingClientService,
  IBookingQueryParams,
} from '../../../services/api/booking/booking-client.service';
import { ICheckinOverviewItem } from './checkin-overview-item.interface';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import { EntryCodeClientService } from '../../../services/api/entry-code/entry-code-client.service';
import { ToastrService } from 'ngx-toastr';
import { format, isToday, parseISO } from 'date-fns';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';

@Component({
  selector: 'checkins-overview',
  standalone: true,
  imports: [
    CheckinsOverviewRowComponent, 
    TableEmptyStateComponent,
    UiTablePagerComponent,
  ],
  templateUrl: './checkins-overview.component.html',
  styleUrl: './checkins-overview.component.css',
})
export class CheckinsOverviewComponent {
  @Output() checkinsCount = new EventEmitter<number>();
  checkins: ICheckinOverviewItem[] = [];
  isLoading = true;
  totalItems = 0;
  isPagerLoading = false;

  constructor(
    private bookingService: BookingClientService,
    private entryCodeService: EntryCodeClientService,
    private log: LogService,
    private formatter: ModelFormatterService,
    private toastr: ToastrService,
    private hardCodedConfig: HardCodedConfigService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.log.info('Loading checkins');
    // Azokat a foglalásokat kérjük le, amiknek a start_date-je ma van
    const today = new Date();
    const queryParams = {
      startDate: format(today, 'yyyy-MM-dd'),
    } as IBookingQueryParams;

    this.bookingService.getAllBookings(queryParams).subscribe({
      next: (data) => {
        this.log.debug('Loaded bookings starting today', data);
        this.checkins = data
          // Nem kellenek csak a mai, elfogadott foglalások
          .filter(
            (booking) =>
              booking.bookingStatus?.id ===
                this.hardCodedConfig.FINALIZED_BOOKING_STATUS_ID &&
              isToday(parseISO(booking.start_date ?? '1970-01-01'))
          )
          // Nem kell az egész booking, átalakítjuk a megjelenítéshez szükséges formátumra
          .map((booking: IBookingModel) => {
            return {
              id: booking.id,
              name: booking.name ?? '',
              email: booking.email_address ?? '',
              phone: booking.phone_number ?? '',
              flats: booking.flats?.map((flat) => {
                return {
                  id: flat.id,
                  building: flat.building?.name ? flat.building.name : '',
                  room: this.formatter.getFormattedFlat(flat, false),
                };
              }),
              isLoadingCodes: true,
              entryCodes: [],
              startDate: booking.start_date ?? '',
              endDate: booking.end_date ?? '',
              bookingSource: booking.bookingSource?.name ?? '',
              bookingUser: booking.user,
            } as ICheckinOverviewItem;
          })
          .sort((a, b) => {
            // ellenőrzések. Ha nincs flats tömb, vagy 0 hosszú, akkor a másik jön előrébb. Ha mindkettőnél ez van, akkor meg 0-t ad vissza.
            if (!a.flats || a.flats.length === 0) {
              if (!b.flats || b.flats.length === 0) return 0;
              return -1;
            }
            if (!b.flats || b.flats.length === 0) return 1;
            return (a.flats[0]?.building || '').localeCompare(
              b.flats[0]?.building || ''
            );
          });
        this.totalItems = this.checkins.length;
        this.checkinsCount.emit(this.totalItems);
        this.isLoading = false;
        this.log.debug('Loaded checkins', this.checkins);
        for (const checkin of this.checkins) {
          if (checkin.flats && checkin.flats.length > 0) {
            for (const flat of checkin.flats) {
              this.entryCodeService
                .getActiveEntryCodes(flat.id, checkin.bookingUser?.id)
                .subscribe({
                  next: (entryCodes) => {
                    checkin.entryCodes.push(
                      ...entryCodes.map((entryCode) => entryCode.entry_code)
                    );
                    checkin.isLoadingCodes = false;
                  },
                  error: (error) => {
                    this.log.error('Error loading entry codes', error);
                  },
                });
            }
          } else {
            checkin.isLoadingCodes = false;
          }
        }
      },
      error: (error) => {
        this.log.error('Error loading checkins', error);
        this.isLoading = false;
      },
    });
  }

  sendEmail(id: number) {
    this.log.debug('Send entry code email for booking: ', id);
    this.bookingService.sendEntryCodeEmail(id).subscribe({
      next: (data) => {
        this.log.info('Email trigger result: ' + data.message);
        this.toastr.info('Email trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error sending email for booking: ', id, error);
        this.toastr.error('Error sending email for booking', error);
      },
    });
  }

  sendSms(id: number) {
    this.log.debug('Send entry code sms for booking: ', id);
    this.bookingService.sendEntryCodeSms(id).subscribe({
      next: (data) => {
        this.log.info('Sms trigger result: ' + data.message);
        this.toastr.info('Sms trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error sending sms for booking: ', id, error);
        this.toastr.error('Error sending sms for booking', error);
      },
    });
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  generateInvoice(id: number) {
    this.log.debug('Generate invoice for booking: ', id);
    this.bookingService.generateInvoice(id).subscribe({
      next: (data) => {
        this.log.info('Invoice trigger result: ' + data.message);
        this.toastr.info('Invoice trigger result', data.message);
      },
      error: (error) => {
        this.log.error('Error generating invoice for booking: ', id, error);
        this.toastr.error('Error generating invoice for booking', error);
      },
    });
  }
}
