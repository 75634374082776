import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { IBillingAddressModel } from '../../../services/api/billing-address/billing-address-model.interface';
import { BillingAddressClientService } from '../../../services/api/billing-address/billing-address-client.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { countries } from '../../atomic-ui-components/select/country-data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'billing-address-editor',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
  templateUrl: './billing-address-editor.component.html',
  styleUrl: './billing-address-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingAddressEditorComponent implements OnChanges {
  @Input() billingAddress!: IBillingAddressModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  countries = countries;

  billingAddressForm: FormGroup = this.fb.group({
    name: [''],
    company_name: [''],
    tax_number: [''],
    bank_account_number: [''],
    country: [''],
    town: [''],
    street: [''],
    po_code: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private billingAddressService: BillingAddressClientService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges() {
    this.billingAddressForm.reset();

    if (this.mode === 'view') {
      this.billingAddressForm.disable();
    } else {
      this.billingAddressForm.enable();
    }
    if (this.mode === 'edit') {
      this.billingAddressForm.patchValue(this.billingAddress);
    }
  }

  saveBillingAddress() {
    if (this.billingAddressForm.valid && this.mode !== 'view') {
      const updatedUntypedBillingAddress: { [key: string]: any } = {};

      // csak a módosított mezőket küldjük
      Object.keys(this.billingAddressForm.controls).forEach((key) => {
        const control = this.billingAddressForm.get(key);
        if (control?.dirty) {
          updatedUntypedBillingAddress[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedBillingAddress).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      const updatedBillingAddress =
        updatedUntypedBillingAddress as IBillingAddressModel;
      this.log.info('Saving billing address');

      if (this.mode === 'edit') {
        updatedBillingAddress.id = this.billingAddress.id;
        this.billingAddressService
          .updateBillingAddress(updatedBillingAddress)
          .subscribe({
            next: (data) => {
              this.log.debug('Billing address updated: ', data);
              this.toastr.success('Billing address updated');
              this.onSave.emit();
            },
            error: (err) => {
              this.log.error('Error updating billing address:', err);
              this.toastr.error('Error updating billing address');
            },
          });
      } else {
        this.billingAddressService
          .createBillingAddress(updatedBillingAddress)
          .subscribe({
            next: (data) => {
              this.log.debug('Billing address created: ', data);
              this.toastr.success('Billing address created');
              this.onCreate.emit();
            },
            error: (err) => {
              this.log.error('Error creating billing address:', err);
              this.toastr.error('Error creating billing address');
            },
          });
      }
    }
  }
}
