import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { IContractModel } from './contract-model.interface';
import { II11Transformer } from '../generic-api-client.service';

@Injectable({
  providedIn: 'root',
})
export class ContractTransformService
  implements II11Transformer<IContractModel>
{
  constructor(private log: LogService) {}

  transformItem(contract: IContractModel): IContractModel {
    try {
      if (contract.contract_date !== undefined) {
        contract.contract_date = format(
          parse(contract.contract_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ContractTransformService: contract_date ', error);
    }
    try {
      if (contract.tenancy_starts_at !== undefined) {
        contract.tenancy_starts_at = format(
          parse(
            contract.tenancy_starts_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ContractTransformService: tenancy_starts_at ', error);
    }
    try {
      if (contract.tenancy_ends_at !== undefined) {
        contract.tenancy_ends_at = format(
          parse(
            contract.tenancy_ends_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ContractTransformService: tenancy_ends_at ', error);
    }
    // TODO: the rest of the fields
    return contract;
  }

  serializeItem(contract: IContractModel): IContractModel {
    const serializedContract: any = { ...contract };

    // Dátum mezők átalakítása ISO stringgé
    if (serializedContract.contract_date !== undefined) {
      try {
        serializedContract.contract_date = format(
          parse(serializedContract.contract_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedContract.contractDate = serializedContract.contract_date;
      } catch (error) {
        this.log.error('ContractTransformService: contract_date ', error);
      }
    }
    if (serializedContract.tenancy_starts_at !== undefined) {
      try {
        serializedContract.tenancy_starts_at = format(
          parse(serializedContract.tenancy_starts_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedContract.tenancyStartsAt =
          serializedContract.tenancy_starts_at;
      } catch (error) {
        this.log.error('ContractTransformService: tenancy_starts_at ', error);
      }
    }
    if (serializedContract.tenancy_ends_at !== undefined && serializedContract.tenancy_ends_at !== null) {
      try {
        serializedContract.tenancy_ends_at = format(
          parse(serializedContract.tenancy_ends_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
        serializedContract.tenancyEndsAt = serializedContract.tenancy_ends_at;
      } catch (error) {
        this.log.error('ContractTransformService: tenancy_ends_at ', error);
      }
    }
    if (serializedContract.contract_number) {
      serializedContract.contractNumber = serializedContract.contract_number;
    }
    if ('open_ended' in serializedContract) {
      serializedContract.openEnded = serializedContract.open_ended;
      if (serializedContract.openEnded) {
        serializedContract.tenancyEndsAt = null;
      }
    }

    // Többi mező stringgé alakítása
    if (serializedContract.tenants === undefined || serializedContract.tenants === null || serializedContract.tenants === "") {
      serializedContract.tenants = [];
    }

    return serializedContract;
  }
}
