<layout-main id="content" role="main">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm focus:outline-none focus:text-blue-600 leading-5 cursor-default"
      >
        Infrastructure
      </span>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm focus:outline-none hover:text-blue-600 focus:text-blue-600 leading-5"
        routerLink="/meters"
      >
        Meters
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Meter Detail
    </li>
  </ol>
  <!-- BREADCRUMB -->
  <div id="hs-single-area-chart"></div>

  <!--  **** METERS DETAIL MAIN **** -->
  <div class="mb-4">
    <div class="bg-white border shadow-sm sm:rounded-xl">
      <div class="m-4">
        <h2 class="font-bold text-lg">
          <!-- {{ title }} -->
          Meter #{{ meter?.id }}
        </h2>
      </div>

      <div class="h-80 m-4 bg-gray-100">CHART</div>

      <div class="m-4">
        <meter-reading-table [meter]="meter"></meter-reading-table>
      </div>
    </div>
  </div>
</layout-main>
