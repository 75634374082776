<tr>
  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-800">
    {{ pitem.name }}
  </td>
  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-800">
    {{ pitem.company_name }}
  </td>
  <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-800">
    {{ pitem.tax_number }}
  </td>
  <td class="px-4 py-4 whitespace-nowrap text-end text-sm font-medium">
    <div class="inline-flex rounded-lg shadow-sm">
      <button
        type="button"
        (click)="handleEdit(pitem)"
        data-hs-overlay="#hs-tenant-editor-modal"
        class="py-1 px-2 flex shrink-0 items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/arrow-down-tray.svg"
          alt="Download"
          class="inline-block h-4 stroke-gray-500 leading-4"
        />
        <span class="shrink-0 text-xs"> Edit </span>
      </button>
      @if(removeOrDelete === 'remove'){
      <button
        type="button"
        (click)="handleRemove(pitem)"
        data-hs-overlay="#hs-remove-tenant-confirmation-modal"
        class="py-1 px-2 flex shrink-0 items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/trash-2.svg"
          alt="Download"
          class="inline-block h-4 stroke-gray-500 leading-4"
        />
        <span class="shrink-0 text-xs"> Remove from contract </span>
      </button>
      }@else{
      <button
        type="button"
        (click)="handleDelete(pitem)"
        data-hs-overlay="#hs-delete-tenant-confirmation-modal"
        class="py-1 px-2 flex shrink-0 items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
      >
        <img
          src="assets/img/trash-2.svg"
          alt="Download"
          class="inline-block h-4 stroke-gray-500 leading-4"
        />
        <span class="shrink-0 text-xs"> Delete </span>
      </button>
      }
    </div>
  </td>
</tr>
