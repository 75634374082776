import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { IFlatModel } from './flat-model.interface';
import { IFlatTypeModel } from '../flat-type/flat-type-model.interface';
import { FlatTransformService } from './flat-transform.service';

export enum FlatFilterType {
  FLAT,
  PARKING_SPACE,
  BOTH,
}

//TODO cserélni az enum használatot az alábbira:
/**
 * Az ingatlanok (lakások, parkolók stb.) lekérdezésének szűrője
 * @param type Az ingatlan típusának szűrője
 * Megjegyzés: Az 'ALL' érték azt jelenti, hogy minden ingatlan lekér
 * Jelenlegi szerver értékek type.id-re:
 * 1: 'Apartman'
 * 2: 'Parkoló'
 */
export interface IFlatTypeFilter {
  type: IFlatTypeModel | 'ALL';
}

@Injectable({
  providedIn: 'root',
})
export class FlatClientService {
  private totalFlatItems!: number;
  private totalParkingSpaceItems!: number;
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: FlatTransformService
  ) {}

  /**
   * A lakások lekérése a szerverről nyers formában
   * @param filter A lekérdezés szűrője, alapértelmezetten mind a lakásokat mind parkolókat lekéri
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A lakások lekért oldala, nyers válaszban
   */
  private fetchFlats(
    filter: FlatFilterType = FlatFilterType.BOTH,
    page?: number
  ) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();

    // alapból minden jöjjön
    let url = this.apiService.getUrlFor(API_ENDPOINTS.flats);

    //TODO kivezetni a hardkódolt flatType-okat
    if (filter == FlatFilterType.PARKING_SPACE) {
      url = this.apiService.getUrlFor(API_ENDPOINTS.flatTypes) + '/2/flats';
    } else if (filter == FlatFilterType.FLAT) {
      url = this.apiService.getUrlFor(API_ENDPOINTS.flatTypes) + '/1/flats';
    }

    return this.http
      .get(url, {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a lakások első oldalát, hogy megtudja az összes lakás számát
   * @param filter A lekérdezés szűrője, alapértelmezetten mind a lakásokat mind parkolókat lekéri
   * @returns Az összes lakás számossága
   **/
  getTotalItems(filter: FlatFilterType): Observable<number> {
    switch (filter) {
      case FlatFilterType.PARKING_SPACE:
        if (this.totalParkingSpaceItems) {
          return new Observable((observer) => {
            observer.next(this.totalParkingSpaceItems);
            observer.complete();
          });
        } else {
          return this.fetchFlats(filter).pipe(
            map((response: any) => {
              this.totalParkingSpaceItems = response['hydra:totalItems'];
              return this.totalParkingSpaceItems;
            })
          );
        }
      // break;
      case FlatFilterType.FLAT:
        if (this.totalFlatItems) {
          return new Observable((observer) => {
            observer.next(this.totalFlatItems);
            observer.complete();
          });
        } else {
          return this.fetchFlats(filter).pipe(
            map((response: any) => {
              this.totalFlatItems = response['hydra:totalItems'];
              return this.totalFlatItems;
            })
          );
        }
      // break;
      default:
        if (this.totalItems) {
          return new Observable((observer) => {
            observer.next(this.totalItems);
            observer.complete();
          });
        } else {
          return this.fetchFlats().pipe(
            map((response: any) => {
              this.totalItems = response['hydra:totalItems'];
              return this.totalItems;
            })
          );
        }
      // break;
    }
  }

  /**
   * Lakások lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A lakások adatai
   **/
  getFlats(page?: number): Observable<IFlatModel[]> {
    return this.fetchFlats(FlatFilterType.FLAT, page).pipe(
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }

  /**
   * Parkolók lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A parkolók adatai
   **/
  getParkingSpaces(page?: number): Observable<IFlatModel[]> {
    return this.fetchFlats(FlatFilterType.PARKING_SPACE, page).pipe(
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }

  /**
   * Összes lakás lekérése
   * @returns Az összes lakás adatai
   */
  getAllFlats(): Observable<IFlatModel[]> {
    // megtudjuk az összes elem számát
    return this.getTotalItems(FlatFilterType.FLAT).pipe(
      switchMap((totalItems) => {
        // megtudjuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        // minden oldalra kérünk
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getFlats(i));
        }

        // a lekéréseket összefűzzük
        return forkJoin(requests).pipe(
          map((results) => {
            let flats = [] as IFlatModel[];
            for (let result of results) {
              flats.push(...result);
            }
            return flats;
          })
        );
      })
    );
  }

  /**
   * Összes parkoló lekérése
   * @returns Az összes parkoló adatai
   */
  getAllParkingSpaces(): Observable<IFlatModel[]> {
    // megtudjuk az összes elem számát
    return this.getTotalItems(FlatFilterType.PARKING_SPACE).pipe(
      switchMap((totalItems) => {
        // megtudjuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        // minden oldalra kérünk
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getParkingSpaces(i));
        }

        // a lekéréseket összefűzzük
        return forkJoin(requests).pipe(
          map((results) => {
            let flats = [] as IFlatModel[];
            for (let result of results) {
              flats.push(...result);
            }
            return flats;
          })
        );
      })
    );
  }

  /**
   * Lakás lekérése az ID alapján
   * @param id A lakás ID-ja
   * @returns A lakás adatai
   */
  getFlat(id: number): Observable<IFlatModel> {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.flat) + id)
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((flat: any) => flat as IFlatModel)
      );
  }

  /**
   * Lakás törlése
   * @param id A törlendő lakás ID-ja
   * @returns A törlés eredménye
   */
  deleteFlat(flat: IFlatModel): Observable<any> {
    if (flat.meters?.length ?? 0 > 0) {
      // akkor előbb törölni kell a métereket róla
      const flatToUpdate = {
        '@id': flat['@id'],
        id: flat.id,
        meters: [],
      };
      return this.updateFlat(flatToUpdate).pipe(
        switchMap((updatedFlat) => {
          // ha sikeres volt a mérők törlése, akkor a flat törlésre is mehetünk
          this.log.debug('Meters removed from flat, before deleting flat itself', updatedFlat);
          return this.http
            .delete(this.apiService.getUrlFor(API_ENDPOINTS.flat) + flat.id)
            .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
        }),
        catchError((err) => {
          this.log.error('Error deleting flat', err);
          return throwError(() => new Error('Failed to delete meters from flat'));
        })
      );
    } else {
      return this.http
        .delete(this.apiService.getUrlFor(API_ENDPOINTS.flat) + flat.id)
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
    }
  }

  /**
   * Lakás lekérése az IRI alapján
   * @param iri A lakás IRI-ja
   * @returns A lakás adatai
   **/
  getFlatByIri(iri: string): Observable<any> {
    return this.http
      .get(this.apiService.getBaseUrl() + iri)
      .pipe(catchError(this.handleError));
  }

  /**
   * Lakás létrehozása
   * @param flat A mentendő lakás adatai
   * @returns A mentett lakás adatai
   */
  createFlat(flat: IFlatModel): Observable<IFlatModel> {
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.flats),
        this.transformer.serializeItem(flat)
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((flat: any) => flat as IFlatModel)
      );
  }

  /**
   * Lakás módosítása
   * @param flat A módosítandó lakás adatai
   * @returns A módosított lakás adatai
   */
  updateFlat(flat: IFlatModel): Observable<IFlatModel> {
    return this.http
      .put(
        this.apiService.getUrlFor(API_ENDPOINTS.flat) + flat.id,
        this.transformer.serializeItem(flat)
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((flat: any) => flat as IFlatModel)
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'FlatClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform flat operation'));
  };
}
