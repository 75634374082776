import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { IBillingModel } from './billing-model.interface';

@Injectable({
  providedIn: 'root',
})
export class BillingTransformService {
  constructor(private log: LogService) {}

  serializeBilling(billing: IBillingModel): any {
    const serializedBilling: any = { ...billing };

    // Dátum mezők átalakítása ISO stringgé
    try {
      if (serializedBilling.first_billing_date !== undefined) {
        serializedBilling.firstBillingDate = format(
          parse(serializedBilling.first_billing_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('BillingTransformService: first_billing_date ', error);
    }
    
    return serializedBilling;
  }
}
