import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { UserClientService } from '../../../services/api/user/user-client.service';
import { IUserModel } from '../../../services/api/user/user-model.interface';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'users-creator',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
  templateUrl: './users-creator.component.html',
  styleUrl: './users-creator.component.css',
})
export class UsersCreatorComponent {
  @Input() user!: IUserModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  userSelectItems = [] as IUiSelectItem[];

  userForm: FormGroup = this.fb.group({
    email: [''],
    name: [''],
    szamla_agent_api_key: [''],
    last_login: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private userService: UserClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('UserEditor Changed', this.user);
    this.userForm.reset();
    if (this.mode === 'view') {
      this.userForm.disable();
    } else {
      this.userForm.enable();
    }
    if (this.mode === 'edit') {
      this.userForm.patchValue(this.user);
    }
  }

  ngOnInit() {
    // Várunk egy kicsit, hogy a userek esélyt kapjanak betöltődni
    setTimeout(() => {
      // Betöltjük a parkolókat a dropdownhoz
      this.silo.getParkingSPaceSelectItems().subscribe({
        next: (user) => {
          this.userSelectItems = user;
          this.log.debug('Users loaded into select:', this.userSelectItems);
        },
        error: (err) => {
          this.log.error('Error loading users into select:', err);
        },
      });
    }, 1000);
  }

  saveUser() {
    this.log.info('Saving User');
    if (this.userForm.valid && this.mode != 'view') {
      const updatedUntypedUser: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.userForm.controls).forEach((key) => {
        const control = this.userForm.get(key);
        if (control?.dirty) {
          updatedUntypedUser[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedUser).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedUser = updatedUntypedUser as IUserModel;
      this.log.debug('Saving User request', updatedUser);

      if (this.mode === 'edit') {
        updatedUser.id = this.user.id;
        this.userService.updateUser(updatedUser).subscribe({
          next: (data) => {
            this.toastr.success('User updated');
            this.log.debug('User updated:', data);
            this.onSave.emit();
          },
          error: (err) => {
            this.toastr.error('Error updating User');
            this.log.error('Error updating User:', err);
          },
        });
      } else {
        this.userService.createUser(updatedUser).subscribe({
          next: (data) => {
            this.toastr.success('User created');
            this.log.debug('User created:', data);
            this.onCreate.emit();
          },
        });
      }
    }
  }
}
