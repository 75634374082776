import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITenantModel } from '../../../services/api/tenant/tenant-model.interface';

@Component({
  selector: 'tenant-table-row',
  standalone: true,
  imports: [],
  templateUrl: './tenant-table-row.component.html',
  styleUrl: './tenant-table-row.component.css',
})
export class TenantTableRowComponent {
  @Input() pitem: ITenantModel = {} as ITenantModel;
  @Input() removeOrDelete: 'delete' | 'remove' = 'delete';
  @Output() onDelete = new EventEmitter<ITenantModel>();
  @Output() onRemove = new EventEmitter<ITenantModel>();
  @Output() onEdit = new EventEmitter<ITenantModel>();

  handleEdit(pitem: ITenantModel) {
    this.onEdit.emit(pitem);
  }

  handleDelete(pitem: ITenantModel) {
    this.onDelete.emit(pitem);
  }

  handleRemove(pitem: ITenantModel) {
    this.onRemove.emit(pitem);
  }
}
