import { Component, ViewChild } from '@angular/core';
import { InvoiceTableRowComponent } from './invoice-table-row/invoice-table-row.component';
import { InvoiceEditorComponent } from '../../modal-editors/invoice-editor/invoice-editor.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { InvoiceClientService } from '../../../services/api/invoice/invoice-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { IInvoiceModel } from '../../../services/api/invoice/invoice-model.interface';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';


@Component({
  selector: 'invoice-table',
  standalone: true,
  imports: [
    InvoiceTableRowComponent,
    InvoiceEditorComponent,
    UiButtonComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './invoice-table.component.html',
  styleUrl: './invoice-table.component.css',
})
export class InvoiceTableComponent {
  @ViewChild(InvoiceEditorComponent)
  editor!: InvoiceEditorComponent;
  selectedInvoice!: IInvoiceModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  invoices = [] as IInvoiceModel[];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private invoiceService: InvoiceClientService,
    private log: LogService,
    private toastr: ToastrService,
  ) {}

  handleEdit(pitem: IInvoiceModel) {
    this.selectedInvoice = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IInvoiceModel) {
    this.selectedInvoice = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IInvoiceModel) {
    this.selectedInvoice = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.invoiceService.deleteInvoice(this.selectedInvoice.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.invoices = this.invoices.filter(
          (item) => item.id !== this.selectedInvoice.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.invoices.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Invoice deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting invoice');
        this.log.error('Error deleting invoice', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedInvoice = {
      '@id': '',
      id: 0,
      filename: '',
      issued: false,
    } as IInvoiceModel;
  }

  refresh(page?: number) {
    // lekérjük a számlákat
    this.isLoading = true;
    this.invoiceService.getInvoices(page).subscribe((data) => {
      this.invoices = data;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
      }, 100);
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.invoiceService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
