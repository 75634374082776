<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm focus:outline-none focus:text-blue-600 leading-5 cursor-default"
      >
        Short-term rental
      </span>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Checkins
    </li>
  </ol>
  <!-- BREADCRUMB -->
  <!--  **** CHECKINS MAIN **** -->
  <div class="mb-4">
    <div class="bg-white border shadow-sm sm:rounded-xl p-4">
      <div class="flex justify-between mb-4">
        <div>
          <h2 class="font-bold text-lg">Checkins</h2>
          <div>Short-term rental checkins</div>
        </div>
        <!-- Upload Button -->
        <div class="flex items-center gap-x-2">
          <ui-button
            size="xsmall"
            iconSrc="assets/img/arrow-up-tray.svg"
            iconClass="invert  saturate-0  brightness-0 contrast-125"
            data-hs-overlay="#hs-checkin-editor-modal"
            (click)="handleCreate()"
          >
            Add Checkin
          </ui-button>
        </div>
      </div>
      <!-- Filter Group -->
      <div class="flex justify-end mb-4 hidden">
        <!-- Search Input -->

        <div class="relative">
          <div
            class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5"
          >
            <img
              src="assets/img/search.svg"
              alt="search"
              class="flex-shrink-0 w-4 h-4 text-gray-500"
            />
          </div>
          <input
            type="text"
            class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Search checkin"
          />
        </div>
        <!-- End Search Input -->
        <!-- Filter Dropdown -->
        <div
          class="hs-dropdown relative inline-flex [--auto-close:inside] ml-4"
        >
          <!-- Filter Button -->

          <button
            id="hs-pro-dptfd"
            type="button"
            class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50"
          >
            <img
              src="assets/img/adjustments-horizontal.svg"
              alt="Filter"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Filter
            <span
              class="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full"
            >
              5
            </span>
          </button>
          -->
          <!-- End Filter Button -->

          <!-- Dropdown -->
          <div
            class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
            aria-labelledby="hs-pro-dptfd"
          >
            <div class="p-1">
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/user-square.svg"
                  alt="Name"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Name
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/envelope.svg"
                  alt="Email addresses"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Email addresses
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/description.svg"
                  alt="Description"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Description
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/building-office.svg"
                  alt="Company"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Company
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/user-ID.svg"
                  alt="User ID"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                User ID
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/phone.svg"
                  alt="Phone numbers"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Phone numbers
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/map-pin.svg"
                  alt="Location"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Location
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/signed-up.svg"
                  alt="Signed up as"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Signed up as
              </a>
            </div>
          </div>
          <!-- End Dropdown -->
        </div>
        <!-- End Filter Dropdown -->
      </div>
      <div class="overflow-x-auto">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200 mb-2">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Booking
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Name
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    E-mail Address
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Country Code
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    City
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown## relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs text-left justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Newsletter Signup
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown relative w-52 ml-auto">
                  <div
                    class="text-gray-500 uppercase text-xs text-right w-full font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Actions
                  </div>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div
                class="w-full flex justify-center content-center mt-16 min-h-28"
              >
                <img
                  src="assets/img/loading.gif"
                  alt="loading"
                  class="h-8 w-auto"
                />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>

          } @else { @for (item of checkins; track item.id){
          <checkin-table-row
            (onEdit)="handleEdit($event)"
            (onView)="handleView($event)"
            (onDelete)="prepareDelete($event)"
            [pitem]="item"
            class="divide-y divide-gray-200"
          >
          </checkin-table-row>
          } @empty { 
            <tr>
              <td class="text-center" colspan="100">
                <!--  EMPTY STATE  -->
                <table-empty-state
                  noDataTitle="There are no checkins"
                ></table-empty-state>
                <!--  END EMPTY STATE  -->
              </td>
            </tr>  
          
          } }
        </table>
      </div>
      <table-pager
        [totalItems]="totalItems"
        [isLoading]="isPagerLoading"
        (onPageChange)="handlePagination($event)"
      ></table-pager>
    </div>
  </div>
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- Checkin Editor Modal -->
  <checkin-editor
    [checkin]="selectedCheckin"
    [mode]="editorMode"
    (onSave)="refresh()"
    (onCreate)="refresh()"
  ></checkin-editor>
  <!-- End Checkin Editor Modal -->
  <!-- Delete File Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-delete-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete checkin {{
      selectedCheckin.id
    }}? This action cannot be undone."
    confirmButtonText="Yes, Delete"
    (confirmed)="performDelete()"
  ></confirmation-dialog>
  <!-- End Delete File Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
</layout-main>
