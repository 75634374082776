import { Component, Input, OnChanges } from '@angular/core';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { LogService } from '../../../services/utils/log.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { countries } from '../../atomic-ui-components/select/country-data';
import { ToastrService } from 'ngx-toastr';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';

@Component({
  selector: 'contact-info-tab',
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule],
  templateUrl: './contact-info-tab.component.html',
  styleUrl: './contact-info-tab.component.css',
})
export class ContactInfoTabComponent implements OnChanges {
  @Input() booking?: IBookingModel;

  countries = countries;
  bookingGenderSelectItems = [] as IUiSelectItem[];

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private bookingService: BookingClientService,
    private toastr: ToastrService,
    private silo: SelectItemLoaderService,
  ) {
    this.bookingGenderSelectItems = this.silo.getGenderSelectItems();
  }

  bookingContactInfoForm = this.fb.group({
    name: [''],
    email_address: [''],
    gender: [''],
    phone_number: [''],
    nationality: [''],
  });

  ngOnChanges(): void {
    this.log.debug('Booking changed', this.booking);
    if (this.booking) {
      this.bookingContactInfoForm.reset();
      this.bookingContactInfoForm.patchValue({
        name: this.booking.name,
        email_address: this.booking.email_address,
        gender: this.booking.gender?.['@id'],
        phone_number: this.booking.phone_number,
        nationality: this.booking.nationality,
      });
    }
  }

  saveBookingContactInfo() {
    this.log.info('Saving booking contact info');
    if (this.bookingContactInfoForm.valid && this.booking) {
      const updatedUntypedBooking: { [key: string]: any } = {};

      // Csak a módosított mezőket küldjük el
      Object.keys(this.bookingContactInfoForm.controls).forEach((key) => {
        const control = this.bookingContactInfoForm.get(key);
        if (control?.dirty) {
          updatedUntypedBooking[key] = control.value;
        }
      });

      if (Object.keys(updatedUntypedBooking).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      const updatedBooking = updatedUntypedBooking as IBookingModel;
      this.log.debug('Saving Booking:', updatedBooking);
      updatedBooking.id = this.booking?.id ?? 0;

      this.bookingService.updateBooking(updatedBooking).subscribe({
        next: (data) => {
          this.toastr.success('Booking updated');
          this.log.debug('Booking updated:', data);
        },
        error: (error) => {
          this.toastr.error('Error while updating booking');
          this.log.error('Error while updating booking:', error);
        },
      });
    }
  }
}
