import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { ReservationTabComponent } from '../../components/booking-tabs/reservation-tab/reservation-tab.component';
import { ContactInfoTabComponent } from '../../components/booking-tabs/contact-info-tab/contact-info-tab.component';
import { RoomsApartmentsComponent } from '../../components/booking-tabs/rooms-apartments-tab/rooms-apartments-tab.component';
import { PaymentTabComponent } from '../../components/booking-tabs/payment-tab/payment-tab.component';
import { BillingDataTabComponent } from '../../components/booking-tabs/billing-data-tab/billing-data-tab.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IBookingModel } from '../../services/api/booking/booking-model.interface';
import { BookingClientService } from '../../services/api/booking/booking-client.service';
import { LogService } from '../../services/utils/log.service';

@Component({
  selector: 'booking-edit',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    ReservationTabComponent,
    ContactInfoTabComponent,
    RoomsApartmentsComponent,
    PaymentTabComponent,
    BillingDataTabComponent,
    RouterLink,
  ],
  templateUrl: './booking-edit.component.html',
  styleUrl: './booking-edit.component.css',
})
export class BookingEditComponent {
  booking!: IBookingModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookingService: BookingClientService,
    private log: LogService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.bookingService.getBooking(params['id']).subscribe({
        next: (booking) => {
          this.booking = booking;
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
      });
    }
}
