import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { IUiSelectItem } from '../atomic-ui-components/select/ui-select-item.interface';
import { LogService } from '../../services/utils/log.service';
import { SelectItemLoaderService } from '../../services/utils/select-item-loader.service';
import { IBillingModel } from '../../services/api/billing/billing-model.interface';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';
import { parseISO, format } from 'date-fns';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BillingClientService } from '../../services/api/billing/billing-client.service';
import { ToastrService } from 'ngx-toastr';
import { BillingItemComponent } from '../billing-item/billing-item.component';
import { LucideAngularModule } from 'lucide-angular';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { IBillingItemModel } from '../../services/api/billing-item/billing-item-model.interface';
import { BillingItemClientService } from '../../services/api/billing-item/billing-item-client.service';

@Component({
  selector: 'billing',
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    UiButtonComponent,
    ReactiveFormsModule,
    BillingItemComponent,
    LucideAngularModule,
  ],
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
})
export class BillingComponent implements OnInit, OnChanges {
  // ha nincs billing akkor létre kell hozni
  @Input() billing?: IBillingModel;
  // billingItemek kelleni fog
  @Input() availableMeters?: IMeterModel[] = [];
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCreate: EventEmitter<IBillingModel> =
    new EventEmitter<IBillingModel>();
  @Output() onCreationCanceled: EventEmitter<void> = new EventEmitter<void>();
  scheduleTypeSelectItems: IUiSelectItem[] = [];
  currencySelectItems: IUiSelectItem[] = [];
  selectedScheduleType: string = '';
  isBillingItemCreatorVisible = false;

  billingForm: FormGroup = this.fb.group({
    first_billing_date: [''],
    billingSchedule: [''],
    currency: [''],
  });

  constructor(
    private log: LogService,
    private silo: SelectItemLoaderService,
    private fb: FormBuilder,
    private billingService: BillingClientService,
    private billingItemService: BillingItemClientService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    // Betöltjük a pénznemeket
    this.silo.getCurrencySelectItems().subscribe({
      next: (items) => {
        this.currencySelectItems = items;
        // ha nincs billing, akkor alapértelmezett érték kell
        if (!this.billing && this.currencySelectItems.length > 0) {
          this.billingForm.controls['currency'].setValue(
            this.currencySelectItems[0].val
          );
        }
      },
      error: (err) => {
        this.log.error('Could not load currencies ', err);
      },
    });

    // Betöltjük a számlázási időszakokat
    this.silo.getBillingScheduleSelectItems().subscribe({
      next: (items) => {
        this.scheduleTypeSelectItems = items;
        // ha nincs billing, akkor alapértelmezett érték kell
        if (!this.billing && this.scheduleTypeSelectItems.length > 0) {
          this.billingForm.controls['billingSchedule'].setValue(
            this.scheduleTypeSelectItems[0].val
          );
        }
      },
      error: (err) => {
        this.log.error('Could not load Billing schedule types ', err);
      },
    });

    // ha nincs billing, akkor alapértelmezett érték kell a dátumra is
    if (!this.billing) {
      this.billingForm.controls['first_billing_date'].setValue(
        format(new Date(), 'yyyy-MM-dd')
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['billing']) {
      this.refreshFormwithBilling();
    }
  }

  refreshFormwithBilling(): void {
    if (this.billing) {
      this.log.debug('Refreshing form with billing:', this.billing);
      this.billingForm.reset();
      if (this.billing?.first_billing_date) {
        this.billing.first_billing_date = format(
          parseISO(this.billing?.first_billing_date),
          'yyyy-MM-dd'
        );
      }
      this.billingForm.controls['first_billing_date'].setValue(
        this.billing?.first_billing_date
      );
      this.billingForm.controls['billingSchedule'].setValue(
        this.billing?.billingSchedule?.['@id']
      );
      this.billingForm.controls['currency'].setValue(
        this.billing?.currency?.['@id']
      );
      this.billingForm.markAsPristine();
    }
  }

  saveBilling() {
    this.log.debug('Saving billing');
    // ha van billing, akkor azt kell frissíteni
    if (this.billingForm.valid && this.billing) {
      this.log.debug('Updating billing:', this.billing);
      const updatedUntypedBilling: { [key: string]: any } = {};
      // csak azokat a mezőket küldjük el, amik változtak
      Object.keys(this.billingForm.controls).forEach((key) => {
        const control = this.billingForm.get(key);
        if (control?.dirty) {
          updatedUntypedBilling[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedBilling).length === 0) {
        // ha nincs mentenivaló
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedBilling = updatedUntypedBilling as IBillingModel;
      updatedBilling.id = this.billing.id;
      this.billingService.updateItem(updatedBilling).subscribe({
        next: (reply: IBillingModel) => {
          this.toastr.success('Billing updated');
          this.log.debug('Billing updated:', updatedBilling);
          // megmondjuk a formnak, hogy akkor tiszta lap van, pristine lehet újra
          this.billing = reply;
          this.refreshFormwithBilling();
        },
        error: (err) => {
          this.toastr.error('Error saving billing');
          this.log.error('Error saving billing:', err);
        },
      });
    } else if (this.billingForm.valid) {
      // ha nincs billing, akkor létre kell hozni
      this.log.debug('Creating new billing');
      const untypedBilling: { [key: string]: any } = {};

      Object.keys(this.billingForm.controls).forEach((key) => {
        const control = this.billingForm.get(key);
        // nem nézünk dirty-t mert alapértékek is vannak, lehet, hogy nem változtatták meg
        if (control) {
          untypedBilling[key] = control.value;
        }
      });
      this.onCreate.emit(untypedBilling as IBillingModel);
    } else {
      this.log.error(
        'Error saving billing. Form is either invalid, or there is no billing to save'
      );
    }
  }

  prepareBillingItemCreation() {
    this.log.debug('Preparing to create billing item');
    this.isBillingItemCreatorVisible = true;
  }

  cancelBillingItemCreation() {
    this.log.debug('Cancelling billing item creation');
    this.isBillingItemCreatorVisible = false;
  }

  handleBillingItemCreated(billingItem: IBillingItemModel) {
    this.log.debug('Billing item created:', billingItem);
    if (billingItem) {
      this.billing?.billingItems?.push(billingItem);
    }
    this.isBillingItemCreatorVisible = false;
  }

  deleteBillingItem(billingItemId: number) {
    this.log.debug('Deleting billing item:', billingItemId);
    if (this.billing) {
      this.billingItemService.deleteItem(billingItemId).subscribe({
        next: () => {
          if(this.billing) {
          this.billing.billingItems = this.billing?.billingItems?.filter(
            (item) => item.id !== billingItemId
          );
          this.toastr.success('Billing item deleted');
        }else{
          // TODO: handle this case with refresh
          this.toastr.error('Billing item deleted, but could not refresh');
        }
        },
        error: (err) => {
          this.toastr.error('Error deleting billing item');
          this.log.error('Error deleting billing item:', err);
        },
      });
    }
  }

  deleteBilling() {
    this.onDelete.emit(this.billing?.id);
  }

  cancelCreation() {
    this.onCreationCanceled.emit();
  }

  cancelEdit() {
    this.refreshFormwithBilling();
  }
}
