import { Component, ViewChild } from '@angular/core';
import { FlatTypeTableRowComponent } from './flat-type-table-row/flat-type-table-row.component';
import { FlatTypeEditorComponent } from '../../modal-editors/flat-type-editor/flat-type-editor.component';
import { IFlatTypeModel } from '../../../services/api/flat-type/flat-type-model.interface';
import { FlatTypeClientService } from '../../../services/api/flat-type/flat-type-client.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'flat-type-table',
  standalone: true,
  imports: [
    FlatTypeTableRowComponent,
    FlatTypeEditorComponent,
    UiButtonComponent,
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './flat-type-table.component.html',
  styleUrl: './flat-type-table.component.css'
})
export class FlatTypeTableComponent {
  @ViewChild(FlatTypeEditorComponent)
  editor!: FlatTypeEditorComponent;
  selectedFlatType!: IFlatTypeModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  flatTypes: IFlatTypeModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private flatTypeClientService: FlatTypeClientService,
    private log: LogService,
    private toastr: ToastrService,

  ) { }

  handleEdit(pitem: IFlatTypeModel) {
    this.selectedFlatType = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IFlatTypeModel) {
    this.selectedFlatType = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IFlatTypeModel) {
    this.selectedFlatType = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.flatTypeClientService
      .deleteItem(this.selectedFlatType.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.log.info('Flat type deleted');
          this.flatTypes = this.flatTypes.filter(
            (pr) => pr.id !== this.selectedFlatType.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.flatTypes.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Flat type deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting flat type');
          this.log.error('Error deleting flat type', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedFlatType = {
      '@id': '',
      id: 0,
      name: '',
    } as IFlatTypeModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.flatTypeClientService
      .getItems(page)
      .subscribe({
        next: (flatTypes) => {
          this.log.debug('Flat Type requests fetched', flatTypes);
          this.flatTypes = flatTypes;
          setTimeout(() => {
            this.isLoading = false;
          }, 100);

          setTimeout(() => {
            this.log.info('Flat type table initialized');
            window.HSStaticMethods.autoInit('overlay');
          }, 100);
        },
        error: (err) => {
          this.log.error('Error fetching flat type', err);
          this.isLoading = false;
        },
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.flatTypeClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }


}
