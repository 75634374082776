<div id="hs-booking-creation-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none">
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">Add New Booking</h3>
        <button type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-booking-creation-modal">
          <span class="sr-only">Close</span>
          <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round">
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>

      <div class="p-4 overflow-y-auto">
        <form id="booking-creation-form" [formGroup]="bookingForm" (ngSubmit)="createBooking()">
          <!--  form select   -->
          <label for="booking-reservation-status" class="sm:mt-2.5 inline-block text-sm text-gray-500">Booking
            Status*</label>
          <div class="relative">
            <!--<ui-select
            selectId="booking-reservation-status"
            [selectItems]="bookingStatusSelectItems"
            class="py-3 mb-4 block w-full max-w-1/2"
            [ngClass]="
            isValidInput('bookingStatus')
              ? 'border-gray-200 mb-4'
              : 'border-red-500 mb-1'
          "             
            formControlName="bookingStatus">
               </ui-select>-->
            <ng-select class="w-full max-w-lg inline-block mb-4" 
              labelForId="booking-reservation-status"
              [items]="bookingStatusSelectItems" 
              bindLabel="title" 
              bindValue="val" 
              [clearable]="false"
              formControlName="bookingStatus" 
              placeholder="Select booking status">
            </ng-select>
            @if(!isValidInput('bookingStatus')){
            <div class="absolute top-3 end-5 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Required
            </p>
            }
          </div>

          <!-- form-select -->

          <!--  form date  -->
          <div class="sm:flex gap-x-4">
            <div class="basis-1/2">
              <label for="booking-reservation-start_date"
                class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500">Start Date*</label>
              <div class="relative">
                <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full max-w-1/2" [ngClass]="
                isValidInput('start_date')
                  ? 'border-gray-200 mb-4'
                  : 'border-red-500 mb-1'
              " type="date" placeholder="" name="booking-reservation-start_date" formControlName="start_date" />
                @if(!isValidInput('start_date')){
                <div class="absolute top-3 end-5 pointer-events-none pe-3">
                  <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" x2="12" y1="8" y2="12"></line>
                    <line x1="12" x2="12.01" y1="16" y2="16"></line>
                  </svg>
                </div>
                <p class="text-sm text-red-600 mt-0 mb-1">
                  Required
                </p>
                }
              </div>
            </div>
            <div class="basis-1/2">
              <label for="booking-reservation-end_date" class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500">End
                Date*</label>
              <div class="relative">
                <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full max-w-1/2" [ngClass]="
                isValidInput('start_date')
                  ? 'border-gray-200 mb-4'
                  : 'border-red-500 mb-1'
              " type="date" placeholder="" name="booking-reservation-end_date" formControlName="end_date" />
                @if(!isValidInput('end_date')){
                <div class="absolute top-3 end-6 pointer-events-none pe-3">
                  <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" x2="12" y1="8" y2="12"></line>
                    <line x1="12" x2="12.01" y1="16" y2="16"></line>
                  </svg>
                </div>
                <p class="text-sm text-red-600 mt-0 mb-1">
                  Required
                </p>
                }
              </div>
            </div>
          </div>
          <!--  // form date  -->

          <!--  form number  -->
          <label for="booking-reservation-adult_occupants"
            class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500">Adult Occupants*</label>
          <div class="relative">
            <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full max-w-1/2" [ngClass]="
            isValidInput('adult_occupants')
              ? 'border-gray-200 mb-4'
              : 'border-red-500 mb-1'
          " type="number" placeholder="" name="booking-reservation-adult_occupants"
              formControlName="adult_occupants" />
            @if(!isValidInput('adult_occupants')){
            <div class="absolute top-3 end-5 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Minimum 1
            </p>
            }
          </div>

          <!--  // form number -->

          <!--  form number  -->
          <label for="booking-reservation-child_occupants"
            class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500">Child Occupants*</label>
          <div class="relative">
            <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full max-w-1/2" [ngClass]="
            isValidInput('child_occupants')
              ? 'border-gray-200 mb-4'
              : 'border-red-500 mb-1'
          " type="number" placeholder="" name="booking-reservation-child_occupants"
              formControlName="child_occupants" />
            @if(!isValidInput('child_occupants')){
            <div class="absolute top-3 end-5 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Minimum 0
            </p>
            }
          </div>
          <!--  // form number -->

          <!--  form number  -->
          <label for="booking-reservation-number_of_rooms"
            class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500">Number of rooms*</label>
          <div class="relative">
            <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full max-w-1/2" [ngClass]="
            isValidInput('number_of_rooms')
              ? 'border-gray-200 mb-4'
              : 'border-red-500 mb-1'
          " type="number" placeholder="" name="booking-reservation-number_of_rooms"
              formControlName="number_of_rooms" />
            @if(!isValidInput('number_of_rooms')){
            <div class="absolute top-3 end-5 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Minimum 1
            </p>
            }
          </div>
          <!--  // form number  -->

          <!--  Name  -->
          <label class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500"
            for="booking-contact-booking_name">Name*</label>
          <div class="relative">
            <input class="mb-1 border border-gray-200 p-2 rounded-lg w-full" [ngClass]="
            isValidInput('name')
              ? 'border-gray-200 mb-4'
              : 'border-red-500 mb-1'
          " type="text" placeholder="Like John Doe" name="booking-contact-booking_name"
              id="booking-contact-booking_name" formControlName="name" />
            @if(!isValidInput('name')){
            <div class="absolute top-3 end-0 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Required
            </p>
            }
          </div>


          <!--  End Name  -->

          <!--  Email  -->
          <label class="sm:mt-2.5 mb-1 inline-block text-sm text-gray-500" for="booking-contact-email_address">Email
            Address*</label>
          <div class="relative">
            <input class=" border border-gray-200 p-2 rounded-lg w-full " [ngClass]="
                isValidInput('email_address')
                  ? 'border-gray-200 mb-4'
                  : 'border-red-500 mb-1'
              " type="text" placeholder="Like john@doe.com" name="email_address" id="booking-contact-email_address"
              formControlName="email_address" />
            @if(!isValidInput('email_address')){
            <div class="absolute top-3 end-0 pointer-events-none pe-3">
              <svg class="flex-shrink-0 size-4 text-red-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" x2="12" y1="8" y2="12"></line>
                <line x1="12" x2="12.01" y1="16" y2="16"></line>
              </svg>
            </div>
            <p class="text-sm text-red-600 mt-0 mb-1">
              Please enter a valid email address.
            </p>
            }
          </div>
          <!--  End Email  -->
        </form>
      </div>

      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-booking-creation-modal">
          Close
        </button>

        <button type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="bookingForm.invalid" data-hs-overlay="#hs-booking-creation-modal" form="booking-creation-form">
          Create Booking
        </button>
      </div>
    </div>
  </div>
</div>