import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service'; 
import { FlatTypeClientService } from '../../../services/api/flat-type/flat-type-client.service'; 
import { IFlatTypeModel } from '../../../services/api/flat-type/flat-type-model.interface';
import { ToastrService } from 'ngx-toastr';
 

@Component({
  selector: 'flat-type-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './flat-type-editor.component.html',
  styleUrl: './flat-type-editor.component.css'
})
export class FlatTypeEditorComponent {
  @Input() flatType!: IFlatTypeModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  flatTypeForm: FormGroup = this.fb.group({
    name: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private flatTypeService: FlatTypeClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('FlatTypeEditor Changed', this.flatType);
    this.flatTypeForm.reset();
    if (this.mode === 'view') {
      this.flatTypeForm.disable();
    } else {
      this.flatTypeForm.enable();
    }
    if (this.mode === 'edit') {
      this.flatTypeForm.patchValue(this.flatType);
    }
   }

  saveFlatType() {
    this.log.info('Saving Flat Type');
    if (this.flatTypeForm.valid && this.mode !== 'view') {
      const updatedUntypedFlatType: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.flatTypeForm.controls).forEach((key) => {
        const control = this.flatTypeForm.get(key);
        if (control?.dirty) {
          updatedUntypedFlatType[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedFlatType).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedFlatType =
        updatedUntypedFlatType as IFlatTypeModel;
      this.log.debug('Flat Type request:', updatedFlatType);

      if (this.mode === 'edit') {
        updatedFlatType.id = this.flatType.id;
        this.flatTypeService
          .updateItem(updatedFlatType)
          .subscribe({
            next: (data) => {
              this.toastr.success('Flat Type updated');
              this.log.debug('Flat Type updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Flat Type');
              this.log.error('Error updating Flat Type:', err);
            },
          });
      } else {
        this.flatTypeService
          .createItem(updatedFlatType)
          .subscribe({
            next: (data) => {
              this.toastr.success('Flat Type created');
              this.log.debug('Flat Type created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Flat Type ');
              this.log.error('Error creating Flat Type:', err);
            },
          });
      }
    }
  }

}
