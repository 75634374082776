import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertyAndContractBoxComponent } from '../../components/property-and-contract-box/property-and-contract-box.component';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { LogService } from '../../services/utils/log.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FlatClientService } from '../../services/api/flat/flat-client.service';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IIssueFilterType } from '../../services/api/issue/issue-client.service';
import { IssuesTableComponent } from '../../components/tables/issues-table/issues-table.component';

@Component({
  selector: 'property-issues',
  standalone: true,
  templateUrl: './property-issues.component.html',
  styleUrl: './property-issues.component.css',
  imports: [
    LayoutMainComponent,
    PropertyAndContractBoxComponent,
    TableEmptyStateComponent,
    RouterLink,
    UiButtonComponent,
    CommonModule,
    IssuesTableComponent,
  ],
})
export class PropertyIssuesComponent {
  contract?: IContractModel;
  flat!: IFlatModel;
  propertyTitle = '';
  isLoading = true;
  isLoadingContract = true;
  issuesTableFilter: IIssueFilterType = {};

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private flatService: FlatClientService,
    private formatter: ModelFormatterService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    //kiszedjük a lakás id-jét a paraméterek közül
    this.route.params.subscribe((params) => {
      let id = params['id'];
      //lekérjük a lakást
      this.flatService.getFlat(id).subscribe({
        next: (flat) => {
          this.flat = flat;
          this.propertyTitle = this.formatter.getFormattedFlat(
            this.flat,
            false
          );
          // TODO: itt kellene a szerződés is, mint feltétel
          this.issuesTableFilter = {
            flatId: this.flat.id,
          };
          this.log.debug('Flat loaded', flat);
          this.isLoading = false;
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }

  handleContractChange(contractItem: string, keepCategory: boolean = false) {
    this.log.debug('Contract ID received', contractItem);
  }
}
