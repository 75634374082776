import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LogService } from '../../services/utils/log.service';

@Component({
  selector: 'login',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  loginCredentials = {
    email: '',
    password: '',
  };
  isLoginSuccessful = false;
  errorMessage = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private log: LogService
  ) {}

  onSubmit() {
    //kiszedjük a ?returnUrl paraméter értékét
    //kezelni a paramétereket rendesen
    const returnUrl = this.router.parseUrl(this.router.url).queryParams['returnUrl'];
    this.authService
      .loginUser(this.loginCredentials.email, this.loginCredentials.password)
      .subscribe({
        next: (data) => {
          this.isLoginSuccessful = true;
          setTimeout(() => {
            this.router.navigate(returnUrl ? [returnUrl] : ['/']).catch((error) => {
              // Kezeljük a 'Cannot match any routes' errorokat
              this.router.navigate(['/']);
            });
          }, 300);
        },
        error: (err) => {
          this.errorMessage = 'Error during login';
          this.log.error('error during login:');
          if (err.error?.message) {
            this.log.error(err);
            this.errorMessage = err.error.message;
          }
        },
      });
  }
}
