<tr>
  <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-gray-800">
    @if(item.name){
    {{ item.name }}
    }@else {
    <span class="text-gray-500 font-normal italic">Unnamed file</span>
    }
  </td>
  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
    {{ item.updated_at | date : "yyyy-MM-dd HH:mm" }}
  </td>
  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
    <input
      type="checkbox"
      [disabled]="true"
      [checked]="!item.private"
      class="relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200"
    />
    <label for="hs-basic-usage" class="sr-only">switch</label>
  </td>
  <td class="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
    <div class="inline-flex rounded-lg shadow-sm">
      <button
        type="button"
        class="py-2 px-3 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
        (click)="handleDownload()"
      >
        <img
          src="assets/img/arrow-down-tray.svg"
          alt="Download"
          class="inline-block h-4 stroke-gray-500 leading-4"
        />
        Download
      </button>
      <button
        type="button"
        class="py-2 px-3 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-semibold focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
        (click)="handleDelete()"
        data-hs-overlay="#hs-delete-inventory-item-confirmation-modal"
      >
        <img
          src="assets/img/trash-2.svg"
          alt="Download"
          class="inline-block h-4 stroke-gray-500 leading-4"
        />
        Delete
      </button>
    </div>
  </td>
</tr>
