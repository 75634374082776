<select
  [id]="selectId"
  (change)="changed($event)"
  class="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
  [disabled]="disabled"
>
  <option value="">Choose</option>
  @for (item of selectItems; track item.val) {
  <option [value]="item.val" [selected]="selectedOptionValue === item.val">
    {{ item.title }}
  </option>
  }
</select>
