import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { ICleaningRequestModel } from './cleaning-request-model.interface';

@Injectable({
  providedIn: 'root',
})
export class CleaningRequestTransformService {
  constructor(private log: LogService) {}

  transformCleaningRequest(cleaningRequest: ICleaningRequestModel): ICleaningRequestModel {

    try {
      if (cleaningRequest.start_date !== undefined) {
        cleaningRequest.start_date = format(
          parse(
            cleaningRequest.start_date,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: startDate ', error);
    }
    try {
      if (cleaningRequest.created_at !== undefined) {
        cleaningRequest.created_at = format(
          parse(
            cleaningRequest.created_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: createdAt ', error);
    }
    try {
      if (cleaningRequest.updated_at !== undefined) {
        cleaningRequest.updated_at = format(
          parse(
            cleaningRequest.updated_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: updatedAt ', error);
    }
    return cleaningRequest;
  }

  serializeCleaningRequest(cleaningRequest: ICleaningRequestModel): any {
    const serializedCleaningRequest = { ...cleaningRequest };

    // Dátum mezők átalakítása ISO stringgé
    try {
      if (serializedCleaningRequest.start_date !== undefined) {
        serializedCleaningRequest.start_date = format(
          parse(serializedCleaningRequest.start_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: startDate ', error);
    }
    try {
      if (serializedCleaningRequest.created_at !== undefined) {
        serializedCleaningRequest.created_at = format(
          parse(serializedCleaningRequest.created_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: createdAt ', error);
    }
    try {
      if (serializedCleaningRequest.updated_at !== undefined) {
        serializedCleaningRequest.updated_at = format(
          parse(serializedCleaningRequest.updated_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('CleaningRequestTransformService: updatedAt ', error);
    }
    return serializedCleaningRequest;
  }
}
