import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { countries } from '../../atomic-ui-components/select/country-data';
import { ToastrService } from 'ngx-toastr';
import { ITenantModel } from '../../../services/api/tenant/tenant-model.interface';
import { TenantClientService } from '../../../services/api/tenant/tenant-client.service';

@Component({
  selector: 'tenant-editor',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
  templateUrl: './tenant-editor.component.html',
  styleUrl: './tenant-editor.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantEditorComponent implements OnChanges {
  @Input() tenant!: ITenantModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  countries = countries;

  tenantForm: FormGroup = this.fb.group({
    name: [''],
    company_name: [''],
    tax_number: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private tenantService: TenantClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.tenantForm.reset();

    if (this.mode === 'view') {
      this.tenantForm.disable();
    } else {
      this.tenantForm.enable();
    }
    if (this.mode === 'edit') {
      this.tenantForm.patchValue(this.tenant);
    }
  }

  saveTenant() {
    if (this.tenantForm.valid && this.mode !== 'view') {
      const updatedUntypedBillingAddress: { [key: string]: any } = {};

      // csak a módosított mezőket küldjük
      Object.keys(this.tenantForm.controls).forEach((key) => {
        const control = this.tenantForm.get(key);
        if (control?.dirty) {
          updatedUntypedBillingAddress[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedBillingAddress).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      const updatedBillingAddress =
        updatedUntypedBillingAddress as ITenantModel;
      this.log.info('Saving tenant');

      if (this.mode === 'edit') {
        updatedBillingAddress.id = this.tenant.id;
        this.tenantService.updateItem(updatedBillingAddress).subscribe({
          next: (data) => {
            this.log.debug('Tenant updated: ', data);
            this.toastr.success('Tenant updated');
            this.onSave.emit();
          },
          error: (err) => {
            this.log.error('Error updating tenant:', err);
            this.toastr.error('Error updating tenant');
          },
        });
      } else {
        this.tenantService.createItem(updatedBillingAddress).subscribe({
          next: (data) => {
            this.log.debug('Tenant created: ', data);
            this.toastr.success('Tenant created');
            this.onCreate.emit();
          },
          error: (err) => {
            this.log.error('Error creating tenant:', err);
            this.toastr.error('Error creating tenant');
          },
        });
      }
    }
  }
}
