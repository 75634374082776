import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { ParkingOverviewComponent } from '../../components/parking-overview/parking-overview.component';
import { ParkingRequestsTableComponent } from '../../components/tables/parking-requests-table/parking-requests-table.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'parking',
  standalone: true,
  imports: [
    LayoutMainComponent,
    ParkingOverviewComponent,
    ParkingRequestsTableComponent,
    UiButtonComponent,
    RouterLink,
    ],
  templateUrl: './parking.component.html',
  styleUrl: './parking.component.css'
})
export class ParkingComponent {

}
