import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { BookingStatusClientService } from '../../../services/api/booking-status/booking-status-client.service';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';


@Component({
  selector: 'booking-creator',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NgSelectModule,],
  templateUrl: './booking-creator.component.html',
  styleUrl: './booking-creator.component.css',
})
export class BookingCreatorComponent {
  @Output() onCreate = new EventEmitter<number>();

  bookingStatusSelectItems: IUiSelectItem[] = [];

  bookingForm: FormGroup = this.fb.group({
    bookingStatus: ['', Validators.required],
    start_date: ['', Validators.required],
    end_date: ['', Validators.required],
    adult_occupants: ['', [Validators.required, Validators.min(1)]],
    child_occupants: ['', [Validators.required, Validators.min(0)]],
    number_of_rooms: ['', [Validators.required, Validators.min(1)]],
    name: ['', Validators.required],
    email_address: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private bookingStatusService: BookingStatusClientService,
    private bookingService: BookingClientService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.bookingStatusService.getBookingStatuses().subscribe({
      next: (bookingStatuses) => {
        this.bookingStatusSelectItems = bookingStatuses.map((status) => ({
          val: status['@id'],
          title: status.name,
        }));
        this.log.debug(
          'Booking statuses loaded',
          this.bookingStatusSelectItems
        );
      },
      error: (error) => {
        this.log.error('Error while fetching booking statuses', error);
      },
    });
  }

  createBooking() {
    if (this.bookingForm.valid) {
      const booking: IBookingModel = this.bookingForm.value;

      this.log.debug('Creating booking', booking);
      this.bookingService.createBooking(booking).subscribe({
        next: (data) => {
          this.log.debug('Booking created', data);
          this.toastr.success('Booking created');
          this.onCreate.emit(data.id);
        },
        error: (error) => {
          this.log.error('Error while creating booking', error);
          this.toastr.error('Error while creating booking');
        },
      });
    }
  }

  /**
   * Ellenőrzi, hogy a mező érvényes-e
   * @param fieldName mező neve (formControlName)
   * @returns true, ha érvényes, egyébként false
   */
  isValidInput(fieldName: string) {
    return (
      this.bookingForm.controls[fieldName].valid ||
      !(this.bookingForm.controls[fieldName].dirty &&
        this.bookingForm.controls[fieldName].touched)
    );
  }
}
