import { Component, Input } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe, SlicePipe } from '@angular/common';
import { IDepartureModel } from '../departure-model.interface';

@Component({
  selector: 'departures-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiButtonComponent,
    UiBadgeComponent,
    SlicePipe,
    DatePipe,
  ],
  templateUrl: './departures-row.component.html',
  styleUrl: './departures-row.component.css',
})
export class DeparturesRowComponent {
  @Input() pitem: IDepartureModel = {} as IDepartureModel;
}
