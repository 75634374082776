import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { IUiSelectItem } from '../atomic-ui-components/select/ui-select-item.interface';
import { LogService } from '../../services/utils/log.service';
import { SelectItemLoaderService } from '../../services/utils/select-item-loader.service';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IBillingItemModel } from '../../services/api/billing-item/billing-item-model.interface';
import { LucideAngularModule } from 'lucide-angular';
import { UiBadgeComponent } from '../atomic-ui-components/badge/ui-badge.component';
import { CurrencyValueCorrectorPipe } from '../../services/utils/currency-value-corrector.pipe';
import { HardCodedConfigService } from '../../services/utils/hard-coded-config.service';
import { IMeterModel } from '../../services/api/meter/meter-model.interface';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { ToastrService } from 'ngx-toastr';
import { BillingItemClientService } from '../../services/api/billing-item/billing-item-client.service';
import { IBillingModel } from '../../services/api/billing/billing-model.interface';

@Component({
  selector: 'billing-item',
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    UiButtonComponent,
    ReactiveFormsModule,
    LucideAngularModule,
    UiBadgeComponent,
    CurrencyValueCorrectorPipe,
  ],
  templateUrl: './billing-item.component.html',
  styleUrls: ['./billing-item.component.css'],
})
export class BillingItemComponent implements OnInit, OnChanges {
  @Input() billingItem?: IBillingItemModel;
  @Input() billing?: IBillingModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'view';
  @Input() isLast: boolean = false;
  @Input() availableMeters?: IMeterModel[] = [];
  @Output() onDelete: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCreationCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCreate: EventEmitter<IBillingItemModel> =
    new EventEmitter<IBillingItemModel>();
  billingItemTypeSelectItems: IUiSelectItem[] = [];
  unitSelectItems: IUiSelectItem[] = [];
  taxSelectItems: IUiSelectItem[] = [];
  meterSelectItems: IUiSelectItem[] = [];
  selectedItemType: string = '';
  fixedItemType: string = '';

  billingItemForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    tax: [''],
    unit: [''],
    unit_price: ['', Validators.required],
    billing: [''],
    meter: [''],
    billingItemType: [''],
  });

  constructor(
    private log: LogService,
    private silo: SelectItemLoaderService,
    private hardCodedConfig: HardCodedConfigService,
    private formatter: ModelFormatterService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private billingItemService: BillingItemClientService
  ) {
    this.fixedItemType = this.hardCodedConfig.FIXED_PRICE_TYPE_FOR_BILLING_ITEM;
    this.selectedItemType = this.fixedItemType;
  }

  ngOnInit() {
    // Betöltjük a dropdownok értékkészletét
    this.silo.getBillingItemTypeSelectItems().subscribe({
      next: (items) => {
        this.billingItemTypeSelectItems = items;
        // ha nincs billingItem, akkor alapértelmezett érték kell
        if (!this.billingItem && this.billingItemTypeSelectItems.length > 0) {
          this.billingItemForm.controls['billingItemType'].setValue(
            this.billingItemTypeSelectItems[0].val
          );
          this.selectedItemType = this.billingItemTypeSelectItems[0].val;
        }
      },
      error: (err) => {
        this.log.error('Could not load Billing item types ', err);
      },
    });

    this.silo.getUnitSelectItems().subscribe({
      next: (items) => {
        this.unitSelectItems = items;
        //ha nincs billingItem, akkor alapértelmezett érték kell
        if (!this.billingItem && this.unitSelectItems.length > 0) {
          this.billingItemForm.controls['unit'].setValue(
            this.unitSelectItems[0].val
          );
        }
      },
      error: (err) => {
        this.log.error('Could not load units ', err);
      },
    });

    this.silo.getTaxSelectItems().subscribe({
      next: (items) => {
        this.taxSelectItems = items;
        if (!this.billingItem && this.taxSelectItems.length > 0) {
          this.billingItemForm.controls['tax'].setValue([]);
        }
      },
      error: (err) => {
        this.log.error('Could not load taxes ', err);
      },
    });
    // feliratkozunk a billingItemType változásaira
    this.billingItemForm.controls['billingItemType'].valueChanges.subscribe(
      (value) => {
        this.selectedItemType = value;
      }
    );
    // feliratkozunk a meter változásaira
    this.billingItemForm.controls['meter'].valueChanges.subscribe((value) => {
      this.log.debug('Meter changed:', value);
      this.adjustUnitForMeter(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['billingItem']) {
      this.refreshFormwithBillingItem();
    }
    if (changes['availableMeters']) {
      this.prepareMeterSelectItems();
      this.billingItemForm.controls['meter'].setValue([]);
    }
  }

  private prepareMeterSelectItems() {
    if (this.availableMeters) {
      this.meterSelectItems = this.availableMeters?.map((meter) => {
        return {
          val: meter['@id'],
          title: this.formatter.getFormattedMeter(meter),
        } as IUiSelectItem;
      });
    } else {
      this.meterSelectItems = [];
    }
  }

  /**
   * Ha a meter változik, akkor az alapján beállítjuk a unitot
   * Persze felülírhatja a felhasználó, de alapértelmezetten a meterhez tartozó unitot állítjuk be
   * @param meterId
   */
  private adjustUnitForMeter(meterId: string) {
    const selectedMeter = this.availableMeters?.find(
      (meter) => meter['@id'] === meterId
    );
    if (selectedMeter) {
      this.billingItemForm.controls['unit'].setValue(
        selectedMeter.meterType?.unit?.['@id']
      );
    }
  }

  refreshFormwithBillingItem(): void {
    if (this.billingItem) {
      //this.log.debug('Refreshing form with billingItem:', this.billingItem);
      this.billingItemForm.reset();

      this.billingItemForm.controls['billingItemType'].setValue(
        this.billingItem?.billingItemType?.['@id']
      );
      this.billingItemForm.controls['name'].setValue(this.billingItem?.name);
      this.billingItemForm.controls['tax'].setValue(
        this.billingItem?.tax?.['@id']
      );
      this.billingItemForm.controls['unit_price'].setValue(
        this.billingItem?.unit_price
      );
      this.billingItemForm.controls['unit'].setValue(
        this.billingItem?.unit?.['@id']
      );
      this.billingItemForm.controls['meter'].setValue(
        this.billingItem?.meter?.['@id']
      );
      this.billingItemForm.markAsPristine();
    }
  }

  saveBillingItem() {
    this.log.debug('Saving billing');
    // Update kezelése
    if (
      this.billingItemForm.valid &&
      (this.mode === 'edit' || this.mode === 'create')
    ) {
      const updatedUntypedBillingItem: { [key: string]: any } = {};

      Object.keys(this.billingItemForm.controls).forEach((key) => {
        const control = this.billingItemForm.get(key);
        if (
          this.selectedItemType === this.fixedItemType &&
          (key === 'unit' || key === 'meter')
        ) {
          // ha fix árú, akkor nem kell a unit és meter, véletlenül sem vesszük őket a kalapba
          return;
        }
        if (control?.dirty || this.mode === 'create') {
          // editnél csak azokat a mezőket küldjük el, amik változtak, create-nél mindet
          updatedUntypedBillingItem[key] = control?.value;
          if (key === 'unit_price') {
            // a unit_price-t stringként kell elküldeni
            // TODO: formatter-rel megoldani a clientService-ben
            updatedUntypedBillingItem[key] =
              updatedUntypedBillingItem[key] + '';
          }
        }
      });
      if (Object.keys(updatedUntypedBillingItem).length === 0) {
        // ha nincs mentenivaló
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      // ha update-elünk, akkor kelleni fog az id-ja
      if (this.billingItem?.id) {
        updatedUntypedBillingItem['id'] = this.billingItem.id;
      } else if (this.mode === 'create') {
        // ha create, akkor nincs saját id, de a billing-hez tartoznia kell
        updatedUntypedBillingItem['billing'] = this.billing?.['@id'];
      }

      const updatedBillingItem = updatedUntypedBillingItem as IBillingItemModel;
      if (this.mode === 'edit') {
        this.billingItemService.updateItem(updatedBillingItem).subscribe({
          next: (reply: IBillingItemModel) => {
            this.toastr.success('Billing item updated');
            this.log.debug('Billing item updated:', updatedBillingItem);
            // megmondjuk a formnak, hogy akkor tiszta lap van, pristine lehet újra
            this.billingItem = reply;
            this.mode = 'view';
            this.refreshFormwithBillingItem();
          },
          error: (err) => {
            this.toastr.error('Error saving billing item');
            this.log.error('Error saving billing item:', err);
          },
        });
      } else {
        this.log.debug('Creating billingItem:', updatedBillingItem);
        this.billingItemService.createItem(updatedBillingItem).subscribe({
          next: (reply: IBillingItemModel) => {
            this.toastr.success('Billing item created');
            this.log.debug('Billing item created:', reply);
            this.billingItem = reply;
            this.mode = 'view';
            this.onCreate.emit(reply);
          },
          error: (err) => {
            this.toastr.error('Error creating billing item');
            this.log.error('Error creating billing item:', err);
          },
        });
      }
    } else {
      this.log.error(
        'Error saving billingItem. Form is either invalid, or there is no billingItem to save'
      );
    }
  }

  editBillingItem() {
    this.log.debug('Editing billing item');
    this.mode = 'edit';
    this.refreshFormwithBillingItem();
  }

  deleteBillingItem() {
    this.log.debug('Deleting billing item');
    this.onDelete.emit(this.billingItem?.id);
  }

  cancelCreation() {
    this.log.debug('Creation canceled');
    this.onCreationCanceled.emit();
  }

  cancelEdit() {
    this.log.debug('Edit canceled');
    this.mode = 'view';
    this.refreshFormwithBillingItem();
  }
}
