<tr>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 "
  >
    {{ pitem.name }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 "
  >
    {{ pitem.company_name }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 "
  >
    {{ pitem.tax_number }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 "
  >
    {{ pitem.country }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 "
  >
    {{ pitem.town }}
  </td>

  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium">
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
      btnType="inline"
      (click)="handleView(pitem)"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-billing-address-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
    <ui-button
      btnType="inline"
      (click)="handleEdit(pitem)"
      
      iconSrc="assets/img/pencil.svg"
      data-hs-overlay="#hs-billing-address-editor-modal"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>
    <ui-button
    btnType="inline"
    (click)="handleDelete(pitem)"
    inlinePos="right"
    
    iconSrc="assets/img/trash-2.svg"
    data-hs-overlay="#hs-delete-billing-address-confirmation-modal"
    > <span class="shrink-0 text-xs"> Delete </span>
  </ui-button>    
    </div>
  </td>
</tr>
