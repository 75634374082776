<!-- File Upload Modal -->
<!-- https://preline.co/plugins/html/overlay.html -->
<div
  id="hs-doc-file-upload-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto [--close-when-click-inside:true] pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
    >
      <!-- Media Card -->
      <div
        class="flex flex-col bg-white border border-stone-200 overflow-hidden rounded-xl shadow-sm"
      >
        <!-- Header -->
        <div
          class="py-3 px-5 flex justify-between items-center gap-x-5 border-b border-stone-200"
        >
          <h2 class="inline-block font-semibold text-stone-800">
            {{ cardTitle }}
          </h2>
          <button
            type="button"
            class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none"
            data-hs-overlay="#hs-doc-file-upload-modal"
          >
            <span class="sr-only">Close</span>
            <svg
              class="flex-shrink-0 size-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </div>
        <!-- End Header -->

        <!-- Body -->
        <div class="p-5 space-y-4">
          @if(isLoading){
            <div class="p-12 h-56 flex flex-col gap-4 items-center justify-center">
              <div
              class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
              >
              <span class="sr-only">Loading...</span>
            </div>
            <p class="block">{{ selectedFile?.name }}</p>
          </div>
          }@else{

          <!-- Drag 'n Drop -->
          <div class="space-y-2">
            <div
              class="p-12 h-56 flex justify-center bg-white border border-dashed border-stone-300 rounded-xl"
            >
              <div class="text-center">
                <svg
                  class="w-16 text-stone-400 mx-auto"
                  width="70"
                  height="46"
                  viewBox="0 0 70 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="2"
                    class="fill-white stroke-stone-400"
                  />
                  <path
                    d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="2"
                    class="fill-white stroke-stone-400"
                  />
                  <rect
                    x="17.0656"
                    y="1.62305"
                    width="35.8689"
                    height="42.7541"
                    rx="5"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="2"
                    class="fill-white stroke-stone-400"
                  />
                  <path
                    d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z"
                    stroke="currentColor"
                    stroke-width="2"
                    class="stroke-stone-400"
                  />
                  <circle
                    cx="39.5902"
                    cy="14.9672"
                    r="4.16393"
                    stroke="currentColor"
                    stroke-width="2"
                    class="stroke-stone-400"
                  />
                </svg>

                <div
                  class="mt-4 flex flex-wrap justify-center text-sm leading-6 text-stone-600"
                >
                  <span class="pe-1 font-medium text-stone-800">
                    Drop your files here or
                  </span>
                  <label
                    for="hs-pro-epdupb"
                    class="relative cursor-pointer bg-white font-semibold text-green-600 hover:text-green-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2"
                  >
                    <span>browse</span>
                    <input
                      id="hs-pro-epdupb"
                      type="file"
                      class="sr-only"
                      name="hs-pro-deuuf"
                      accept=".docx, .pdf, .jpg, .png"
                      (change)="onFileSelected($event)"
                      multiple="false"
                    />
                  </label>
                </div>

                <p class="mt-1 text-xs text-stone-400">DOCX, PDF, JPG, PNG. Maximum {{ maxfileSize }} MB.</p>
              </div>
            </div>
          </div>
          <!-- End Drag 'n Drop -->
          }
        </div>
        <!-- End Body -->
      </div>
      <!-- End Media Card -->
    </div>
  </div>
</div>
<!-- End File Upload Modal -->
