<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/properties"
      >
        Properties
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      @if(flat) {
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        [routerLink]="'/properties/' + flat.id"
      >
        {{ propertyTitle }}
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >} @else {
      <span class="flex items-center text-sm leading-5">Properties</span>
      }
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Issues
    </li>
  </ol>
  <!-- BREADCRUMB -->
  @if(isLoading){
  <div
    class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center"
  >
    <div
      class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-sm text-gray-500 mt-4">Loading property details...</p>
  </div>
  } @else{
  <!-- TOP INFO  -->
  <property-and-contract-box
    [flat]="flat"
    (contractChanged)="handleContractChange($event)"
  ></property-and-contract-box>
  <!-- TOP INFO  -->

  <!-- Issues Table -->
  @if(flat){
  <div class="my-4">
    <issues-table
      [issueFilter]="issuesTableFilter"
      [showCreateButton]="true"
      title="Issues for this property"
    ></issues-table>
  </div>
  }
  <!-- End Issues Table -->

  }
</layout-main>
