<tr>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <b>{{ pitem.name }}</b>
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @for (flat of pitem.flats; track flat.id) {
    <div class="mb-1">
      <ui-badge
        [color]="
          flat.building === 'Lehel'
            ? 'blue'
            : flat.building === 'Kunigunda'
            ? 'yellow'
            : 'black'
        "
        badgeType="soft"
        cornerType="rounded"
        class="w-24 inline-block"
        >{{ flat.building }}</ui-badge
      >
      <span class="inline-block ml-2">{{ flat.room }}</span>
    </div>
    }
  </td>

  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.startDate | date : "yyyy-MM-dd" }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.endDate | date : "yyyy-MM-dd" }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.checkoutDate | date : "yyyy-MM-dd HH:mm" }}
  </td>
  <td
    class="px-4 py-3 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    @if(pitem.isCheckedOut) {
    <ui-button
      iconPos="left"
      iconClass="invert"
      btnType="outline"
      size="xsmall"
      (click)="handleCancelCheckout()"
      >Cancel checkout</ui-button
    >} @else {
    <ui-button
      iconSrc="assets/img/door-open.svg"
      iconPos="left"
      iconClass="invert"
      btnType="solid-negative"
      size="xsmall"
      (click)="handleManualCheckout()"
      >Manual Checkout</ui-button
    >
    }
  </td>
</tr>
