import { Component, Input, EventEmitter, Output } from '@angular/core';
import { LogService } from '../../../../services/utils/log.service';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { IIssueModel } from '../../../../services/api/issue/issue-model.interface';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe, SlicePipe } from '@angular/common';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'issues-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    DatePipe,
    SlicePipe,
    UiButtonComponent,
    RouterLink,
  ],
  templateUrl: './issues-table-row.component.html',
  styleUrl: './issues-table-row.component.css'
})
export class IssuesTableRowComponent {
  @Input() pitem: IIssueModel = {} as IIssueModel;
  @Output() onDelete = new EventEmitter<IIssueModel>();

  constructor(private log: LogService) {}

  handleDelete(pitem: IIssueModel) {
    this.onDelete.emit(pitem);
  }

}
