<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm align-middle "
  >@if(pitem.issuerUser?.name){
    <span class="block text-blue-600 font-bold">{{ pitem.issuerUser?.name}}</span>
    <span class="block text-blue-600 font-normal">{{pitem.issuerUser?.email}}</span>
  }@else {
    &ndash;
  }
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    {{ pitem.created_at | date : "yyyy-MM-dd" }} <br />
    {{ pitem.created_at | date : "HH:mm" }}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    @if (pitem.flat?.flat_number){
    {{ pitem.flat?.flat_number ? pitem.flat?.flat_number : ""
    }}{{ " " + pitem.flat?.name }}
  }@else {
    &ndash;
  }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-center align-middle"
  >@if(pitem.issueStatus?.name){
    <ui-badge
      [color]="pitem.issueStatus?.id == 1 ? 'orange' : 'green'"
      [badgeType]="pitem.issueStatus?.id == 1 ? 'soft' : 'solid'"
    >
      {{ pitem.issueStatus?.name }}
    </ui-badge>
  }@else {
    &ndash;
  }
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    <div class="hs-tooltip inline-block">
      <span class="hs-tooltip-toggle"
        >{{ pitem.note | slice : 0 : 20 }}@if (pitem.note && pitem.note.length >
        20){ ... }</span
      >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm"
        role="tooltip"
      >
        {{ pitem.note }}
      </span>
    </div>
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle text-right">
    {{ pitem.issueNotes?.length }}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    {{ pitem.updated_at | date : "yyyy-MM-dd" }} <br />
    {{ pitem.updated_at | date : "HH:mm" }}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle text-right">
    @for(user of pitem.delegatedUsers; track user.id){
    <span class="block text-blue-600 font-bold">{{ user.name }}</span>
    }
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle w-72"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
      btnType="inline"
      [routerLink]="['/issues', pitem.id]"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
    <ui-button
      btnType="inline"
      [routerLink]="['/issues', pitem.id, 'edit']"
      iconSrc="assets/img/pencil.svg"
      > <span class="shrink-0 text-xs"> Edit </span>
    </ui-button>
    <ui-button
    btnType="inline"
    (click)="handleDelete(pitem)"
    inlinePos="right"

    iconSrc="assets/img/trash-2.svg"
    data-hs-overlay="#hs-delete-issue-confirmation-modal"
    > <span class="shrink-0 text-xs"> Delete </span>
  </ui-button>

    </div>
  </td>
</tr>
