import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'correctCurrencyFormat',
  standalone: true
})
export class CurrencyValueCorrectorPipe implements PipeTransform {

  transform(value: string | null): string {
    // Cseréljük a vesszőket szóközökre és a pontokar vesszőkre
    if (!value) {
      return '';
    }
    return value.replace(/,/g, ' ').replace(/\./g, ',');
  }

}
