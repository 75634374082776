import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const routeurl: string = state.url;
    return this.isUserLoggedIn(routeurl);
  }

  isUserLoggedIn(routeurl: string) {
    if (!this.authService.isUserLoggedIn()) {
      // navigate to login page
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: routeurl },
      });
    }
    return true;
  }
}
