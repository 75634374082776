<tr>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm font-bold text-gray-800 align-middle"
  >
    {{ pitem.id }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.name }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
        btnType="inline"
        (click)="handleEdit(pitem)"
        iconSrc="assets/img/pencil.svg"
        data-hs-overlay="#hs-unit-editor-modal"
        inlinePos="left"
      >
        <span class="shrink-0 text-xs"> Edit </span>
      </ui-button>

      <ui-button
        btnType="inline"
        (click)="handleDelete(pitem)"
        inlinePos="right"
        iconSrc="assets/img/trash-2.svg"
        data-hs-overlay="#hs-delete-unit-confirmation-modal"
      >
        <span class="shrink-0 text-xs"> Delete </span>
      </ui-button>
    </div>
  </td>
</tr>
