import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserModel } from '../../../../services/api/user/user-model.interface';
import { UserClientService } from '../../../../services/api/user/user-client.service';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { LogService } from '../../../../services/utils/log.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'users-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
    DatePipe
  ],
  templateUrl: './users-table-row.component.html',
  styleUrl: './users-table-row.component.css'
})
export class UsersTableRowComponent {
  @Input() pitem: IUserModel = {} as IUserModel;
  @Output() onDelete = new EventEmitter<IUserModel>();
  @Output() onEdit = new EventEmitter<IUserModel>();
  @Output() onView = new EventEmitter<IUserModel>();


  constructor(
    private userService: UserClientService,
    private log: LogService,
  ){}

  handleEdit(pitem: IUserModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IUserModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IUserModel) {
    this.onDelete.emit(pitem);
  }
}
