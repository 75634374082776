<div
  class="grid gap-x-8 gap-y-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 justify-items-stretch"
>
  @for (meter of meters; track meter.id) {
  <!-- CARD -->
  <div
    class="flex flex-col bg-white border shadow-sm rounded-xl overflow-hidden relative h-64 hover:shadow-lg hover:cursor-pointer active:shadow-sm"
    routerLink="/meters/{{ meter.id }}"
  >
    <div
      class="h-2/3 absolute -left-16 top-0 -right-16 px-22 flex align-middle rounded-b-full z-10 border-b"
      [ngClass]="{
        'bg-blue-50 border-blue-100': meter.meterType?.id === 3,
        'bg-orange-50 border-orange-100': meter.meterType?.id === 4,
        'bg-yellow-50 border-yellow-100':
          meter.meterType?.id === 1 || meter.meterType?.id === 2,
        'bg-red-50 border-red-100': meter.meterType?.id === 6,
        'bg-cyan-50 border-cyan-100': meter.meterType?.id === 5
      }"
    >
      <div
        class="absolute left-16 top-2 text-xs pl-4 right-1/2 leading-4 text-slate-500"
      >
        <span class="font-semibold mr-1">#{{ meter.id }}</span>
        <span class="">{{ meter.meterType?.name }}</span>
      </div>
      <div
        class="absolute top-2 text-xs right-16 text-right left-1/2 pr-4 leading-4"
      >
        <span class="">{{ lastReadingTimes.get(meter.id) | timeAgo }}</span>
      </div>
      <lucide-angular
        [name]="
          meter.meterType?.id === 3
            ? 'droplet'
            : meter.meterType?.id === 4
            ? 'droplet'
            : meter.meterType?.id === 1 || meter.meterType?.id === 2
            ? 'plug'
            : meter.meterType?.id === 6
            ? 'thermometer-sun'
            : 'thermometer-snowflake'
        "
        class="block left-1/2 top-6 -ml-14 absolute w-28 h-28"
        [ngClass]="{
          'text-blue-200': meter.meterType?.id === 3,
          'text-orange-200': meter.meterType?.id === 4,
          'text-yellow-300':
            meter.meterType?.id === 1 || meter.meterType?.id === 2,
          'text-red-200': meter.meterType?.id === 6,
          'text-cyan-200': meter.meterType?.id === 5
        }"
        [size]="24"
      ></lucide-angular>
      <div
        class="absolute left-16 right-16 top-24 flex justify-center opacity-75"
      >
        @if(meter.id == 17) {
        <ui-badge
          iconSrc="assets/img/alert-triangle.svg"
          iconClass="h-4"
          color="orange"
          badgeType="solid"
          ><p class="text-black">above average</p></ui-badge
        >
        }
      </div>
    </div>

    <div class="text-center absolute left-2 right-2 bottom-8 h-30">
      <span class="text-2xl font-bold font-mono tracking-wider">{{
        lastReadings.get(meter.id)
      }}</span>
      <span class="text-xl font-bold tracking-wider">{{
        lastReadings.get(meter.id) !== "N/A" ? meter.meterType?.unit?.name : ""
      }}</span>
    </div>
  </div>
  <!-- END CARD -->
  }
  <div
    class="group flex flex-col border-4 shadow-sm rounded-xl overflow-hidden relative h-64 
    border-dashed bg-opacity-0 border-opacity-30 border-indigo-100 bg-indigo-50 hover:border-indigo-200 hover:shadow-lg hover:cursor-pointer active:shadow-sm active:bg-opacity-60"
    data-hs-overlay="#hs-property-meters-editor-modal"
    >
    <div
      class="absolute left-0 right-0 top-5 text-9xl text-center text-indigo-200 group-hover:text-indigo-400"
    >
      <lucide-angular
        name="router"
        class="inline-block w-28 h-28"
        [size]="24"
      ></lucide-angular>
    </div>
    <div
      class="text-xl absolute bottom-8 text-center left-1 right-1 text-gray-400 group-hover:text-gray-600"
    >
      Manage Meters
    </div>
  </div>
</div>
