import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { UiSwitchComponent } from "../../../atomic-ui-components/switch/ui-switch.component";
import { IBillingAddressModel } from '../../../../services/api/billing-address/billing-address-model.interface';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';


@Component({
    selector: 'billing-address-table-row',
    standalone: true,
    templateUrl: './billing-address-table-row.component.html',
    styleUrl: './billing-address-table-row.component.css',
    imports: [
      UiSwitchComponent,
      UiButtonComponent,
    ]
})
export class BillingAddressTableRowComponent {
  @Input() pitem: IBillingAddressModel = {} as IBillingAddressModel;
  @Output() onDelete = new EventEmitter<IBillingAddressModel>();
  @Output() onEdit = new EventEmitter<IBillingAddressModel>();
  @Output() onView = new EventEmitter<IBillingAddressModel>();

  handleEdit(pitem: IBillingAddressModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IBillingAddressModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IBillingAddressModel) {
    this.onDelete.emit(pitem);
  }

}
