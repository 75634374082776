<layout-main>
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm focus:outline-none focus:text-blue-600 leading-5 cursor-default"
      >
        Short-term rental
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        [routerLink]="['/bookings']"
      >
        Bookings
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Booking Detail {{ booking && booking.id ? "#" + booking.id : "" }}
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <h2 class="font-bold text-lg xs:px-4 sm:px-0">Booking detail</h2>

  <!--  **** BOOKING MAIN **** -->
  <div class="mb-4">
    <!-- TOP INFO  -->

    <!--  TAB *******************************************************************************************************************-->
    <nav
      class="flex flex-wrap pr-2 mb-4 overflow-x-auto xs:px-4 sm:px-0"
      aria-label="Tabs"
      role="tablist"
    >
      <!-- TAB1 head  --</nav>> -->
      <!-- active class az alapértelmezettnek -->
      <ui-button
        id="tabs-with-underline-item-1"
        data-hs-tab="#tabs-with-underline-1"
        aria-controls="tabs-with-underline-1"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-range-blue.svg"
        iconAlt="Cleaning overview tab icon"
        class="active"
        >Reservation</ui-button
      >
      <!-- TAB2 head  -->
      <ui-button
        id="tabs-with-underline-item-2"
        data-hs-tab="#tabs-with-underline-2"
        aria-controls="tabs-with-underline-2"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/request-blue.svg"
        iconAlt="Cleaning request tab icon"
        >Contact Info</ui-button
      >

      <!-- TAB3 head  -->
      <ui-button
        id="tabs-with-underline-item-3"
        data-hs-tab="#tabs-with-underline-3"
        aria-controls="tabs-with-underline-3"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Rooms/Apartments</ui-button
      >
      <!-- TAB4 head  -->
      <ui-button
        id="tabs-with-underline-item-4"
        data-hs-tab="#tabs-with-underline-4"
        aria-controls="tabs-with-underline-4"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Payment</ui-button
      >
      <!-- TAB5 head  -->
      <ui-button
        id="tabs-with-underline-item-5"
        data-hs-tab="#tabs-with-underline-5"
        aria-controls="tabs-with-underline-5"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/calendar-check-blue.svg"
        iconAlt="Cleaning schedule tab icon"
        >Billing Info</ui-button
      >
    </nav>
    <div
      class="bg-white border shadow-sm sm:rounded-xl p-4 sm:w-2/3 md:w-2/4 mx-auto max-w-lg"
    >
      <!--  ******************************************************************************************************************************** -->

      <!--  1. tab body ******************************************************************************************************************-->
      <div
        id="tabs-with-underline-1"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-1"
      >
        <div class="">
          <div class="p-2">
            <!--  form select   -->
            <label for="booking-reservation-status" class="block font-bold"
              >Booking Status</label
            >
            <div class="mb-4 text-sm mt-2">
              @if(booking.bookingStatus){
              <ui-badge
                [color]="
                  booking.bookingStatus.id == 1
                    ? 'black'
                    : booking.bookingStatus.id == 2
                    ? 'yellow'
                    : booking.bookingStatus.id == 3
                    ? 'teal'
                    : booking.bookingStatus.id == 4
                    ? 'blue'
                    : booking.bookingStatus.id == 5
                    ? 'green'
                    : 'red'
                "
                [badgeType]="booking.bookingStatus.id == 6 ? 'solid' : 'soft'"
                >{{ booking.bookingStatus.name }}</ui-badge
              >
              } @else { &ndash; }
            </div>

            <!--  form date  -->
            <div class="sm:flex gap-x-4 max-w-lg mb-4">
              <div class="basis-1/2">
                <label
                  for="booking-reservation-start_date"
                  class="block mb-2 font-bold"
                  >Start Date</label
                >
                <span class="mb-4 text-sm p-2 pl-0">
                  @if(booking.start_date){
                  {{ booking.start_date }}
                  } @else { &ndash; }
                </span>
              </div>
              <div class="basis-1/2">
                <label
                  for="booking-reservation-end_date"
                  class="block mb-2 font-bold"
                  >End Date</label
                >
                <span class="text-sm p-2 pl-0">
                  @if(booking.end_date){
                  {{ booking.end_date }}
                  } @else { &ndash; }
                </span>
              </div>
            </div>
            <!--  // form date  -->

            <!--  form number  -->
            <label
              for="booking-reservation-adult_occupants"
              class="block mb-2 font-bold"
              >Adult Occupants</label
            >
            <div class="mb-4 text-sm">
              @if(booking.adult_occupants){
              {{ booking.adult_occupants }}
              } @else { &ndash; }
            </div>
            <!--  // form number -->

            <!--  form number  -->
            <label
              for="booking-reservation-child_occupants"
              class="block mb-2 font-bold"
              >Child Occupants</label
            >
            <div class="mb-4 text-sm">
              @if(booking.child_occupants){
              {{ booking.child_occupants }}
              } @else { &ndash; }
            </div>
            <!--  // form number -->

            <!--  form number  -->
            <label
              for="booking-reservation-number_of_rooms"
              class="block mb-2 font-bold"
              >Number of rooms</label
            >
            <div class="mb-4 text-sm">
              @if(booking.number_of_rooms){
              {{ booking.number_of_rooms }}
              } @else { &ndash; }
            </div>
            <!--  // form number  -->

            <!--  form text  -->
            <label for="booking-reservation-code" class="block mb-2 font-bold"
              >Reservation Code</label
            >
            <div class="mb-4 text-sm">
              @if(booking.reservation_code){
              {{ booking.reservation_code }}
              } @else { &ndash; }
            </div>
            <!--  // form text  -->

            <!--  form text  -->
            <label
              for="booking-reservation-foreign_reservation_code"
              class="block mb-2 font-bold"
              >Foreign Reservation Code</label
            >
            <div class="mb-4 text-sm">
              @if(booking.foreign_reservation_code){
              {{ booking.foreign_reservation_code }}
              } @else { &ndash; }
            </div>
            <!--  // form text  -->
          </div>
        </div>
      </div>
      <!--  // 1  -->
      <!--   2 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-2"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-2"
      >
        <div class="">
          <div class="p-2">
            <!--  Name  -->
            <label
              class="block mb-2 font-bold"
              for="booking-contact-booking_name"
              >Name</label
            >
            <div class="mb-4 text-sm">
              @if(booking.name){
              {{ booking.name }}
              } @else { &ndash; }
            </div>
            <!--  End Name  -->

            <!--  Email  -->
            <label
              class="block mb-2 font-bold"
              for="booking-contact-email_address"
              >Email Address</label
            >
            <div class="mb-4 text-sm">
              @if(booking.email_address){
              {{ booking.email_address }}
              } @else { &ndash; }
            </div>
            <!--  End Email  -->

            <!--  Gender  -->
            <label
              class="block mb-2 font-bold"
              for="booking-contact-gender-select"
              >Sex</label
            >
            <div class="mb-4 text-sm">
              @if(booking.gender){
              {{ booking.gender.name }}
              } @else { &ndash; }
            </div>
            <!--  End Gender  -->

            <!--  Phone  -->
            <label
              class="block mb-2 font-bold"
              for="booking-contact-phone_number"
              >Phone Number</label
            >
            <div class="mb-4 text-sm">
              @if(booking.phone_number){
              {{ booking.phone_number }}
              } @else { &ndash; }
            </div>
            <!--  End Phone  -->

            <!--  Country  -->
            <label class="block mb-2 font-bold" for="booking-contact-country"
              >Nationality</label
            >
            <div class="mb-4 text-sm">
              @if(booking.nationality){
              {{ booking.nationality }}
              } @else { &ndash; }
            </div>
          </div>
        </div>
      </div>
      <!--  // 2  -->
      <!--   3 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-3"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-3"
      >
        <div class="">
          <div class="p-2">
            <!--  Flat  -->
            <label class="block mb-2 font-bold" for="booking-rooms-flats-select"
              >Rooms/apartments</label
            >
            <div class="mb-4 text-sm">
              @if(booking.flats){
              <ul>
                @for(flat of booking.flats; track flat.id){
                <li class="mb-2">
                  @if(flat.building){
                  <ui-badge
                    [color]="
                      flat.building.name === 'Lehel'
                        ? 'blue'
                        : flat.building.name === 'Kunigunda'
                        ? 'yellow'
                        : 'black'
                    "
                    badgeType="soft"
                    cornerType="rounded"
                    class="w-24 inline-block"
                    >{{ flat.building.name }}</ui-badge
                  >}
                  <span class="inline-block">{{ flat | flatFormat }}</span>
                </li>
                } @empty { &ndash; }
              </ul>
              } @else { &ndash; }
            </div>
            <!--  End Flat   -->
          </div>
        </div>
      </div>
      <!--  // 3  -->
      <!--   4 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-4"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-4"
      >
        <div class="">
          <div class="p-2">
            <!--  form switch  -->
            <div class="mb-4">
              <label
                for="booking-payment-currency-type-select"
                class="block mb-2 font-bold"
                >Paid</label
              >
              <div class="mb-4 text-sm">
                @if(booking.paid){ Yes } @else { No }
              </div>
            </div>
            <!--  // form switch -->

            <!--  form select  -->
            <div class="mb-4">
              <label
                class="block text-sm mb-2 font-bold"
                for="booking-payment-currency-type-select"
                >Currency</label
              >
              <div class="mb-4 text-sm">
                @if(booking.currency){
                {{ booking.currency.code }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form select  -->

            <!--  form number  -->
            <div class="mb-4">
              <label
                for="booking-payment-booking-price"
                class="block mb-2 font-bold"
                >Booking Price</label
              >
              <div class="mb-4 text-sm">
                @if(booking.booking_price){
                {{ booking.booking_price | number : "1.2-2"
                          | correctCurrencyFormat }} {{ booking.currency?.symbol }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form number  -->

            <!--  form number  -->
            <div class="mb-4">
              <label
                for="booking-payment-booking-price-default-currency"
                class="block mb-2 font-bold"
                >Booking Price in Default Currency</label
              >
              <div class="mb-4 text-sm">
                @if(booking.booking_price_in_default_currency){
                {{ booking.booking_price_in_default_currency + "HUF" }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form number  -->

            <!--  form number  -->
            <div class="mb-4">
              <label
                for="booking-payment-booking-price-exchange-rate"
                class="block mb-2 font-bold"
                >Booking Price Exchange Rate</label
              >
              <div class="mb-4 text-sm">
                @if(booking.booking_price_exchange_rate){
                {{ booking.booking_price_exchange_rate }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form number  -->
          </div>
        </div>
      </div>
      <!--  // 4  -->
      <!--   5 TAB BODY *************************************************************************************************************************-->
      <div
        id="tabs-with-underline-5"
        class="hidden"
        role="tabpanel"
        aria-labelledby="tabs-with-underline-item-5"
      >
        <div class="">
          <div class="p-2">
            <!--  form text  -->
            <div class="mb-4">
              <label for="booking-billing-name" class="block mb-2 font-bold"
                >Name</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_name){
                {{ booking.billing_name }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->

            <!--  form select  -->
            <label for="booking-billing-country" class="block mb-2 font-bold"
              >Country</label
            >
            <div class="mb-4 text-sm">
              @if(booking.billing_country){
              {{ booking.billing_country }}
              } @else { &ndash; }
            </div>
            <!--  // form select  -->

            <!--  form text  -->
            <div class="mb-4">
              <label for="booking-billing-po-code" class="block mb-2 font-bold"
                >Zip Code</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_pocode){
                {{ booking.billing_pocode }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->

            <!--  form text  -->
            <div class="mb-4">
              <label for="booking-billing-county" class="block mb-2 font-bold"
                >County</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_county){
                {{ booking.billing_county }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->

            <!--  form text  -->
            <div class="mb-4">
              <label for="booking-billing-city" class="block mb-2 font-bold"
                >City</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_city){
                {{ booking.billing_city }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->

            <!--  form text  -->
            <div class="mb-4">
              <label
                for="booking-billing-address-line1"
                class="block mb-2 font-bold"
                >Billing Address Line 1</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_address_line1){
                {{ booking.billing_address_line1 }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->

            <!--  form text  -->
            <div class="mb-4">
              <label
                for="booking-billing-address-line2"
                class="block mb-2 font-bold"
                >Billing Address Line 2</label
              >
              <div class="mb-4 text-sm">
                @if(booking.billing_address_line2){
                {{ booking.billing_address_line2 }}
                } @else { &ndash; }
              </div>
            </div>
            <!--  // form text  -->
          </div>
        </div>
      </div>
      <!--  // 3  -->
    </div>
  </div>
</layout-main>
