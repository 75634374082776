import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { IPaymentTransactionModel } from './payment-transaction-model.interface';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class PaymentTransactionTransformService {
  constructor(private log: LogService) {}

  transformPaymentTransaction(
    paymentTransaction: any
  ): IPaymentTransactionModel {
    try {
      if (paymentTransaction.created_at !== undefined) {
        paymentTransaction.created_at = format(
          parse(
            paymentTransaction.created_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('PaymentTransactionTransformService: createdAt ', error);
    }
    try {
      if (paymentTransaction.updated_at !== undefined) {
        paymentTransaction.updated_at = format(
          parse(
            paymentTransaction.updated_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('PaymentTransactionTransformService: updatedAt ', error);
    }
    return paymentTransaction;
  }

  serializePaymentTransaction(
    paymentTransaction: IPaymentTransactionModel
  ): any {
    const serializedPaymentTransaction: any = { ...paymentTransaction };

    // Dátum mezők átalakítása ISO formátumra
    if (paymentTransaction.created_at !== undefined) {
      try {
        serializedPaymentTransaction.created_at = format(
          parse(
            paymentTransaction.created_at,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('PaymentTransactionTransformService: createdAt ', error);
      }
    }
    if (paymentTransaction.updated_at !== undefined) {
      try {
        serializedPaymentTransaction.updated_at = format(
          parse(
            paymentTransaction.updated_at,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      } catch (error) {
        this.log.error('PaymentTransactionTransformService: updatedAt ', error);
      }
    }

    // Többi mező stringgé alakítása
    serializedPaymentTransaction.price = String(serializedPaymentTransaction.price);
    
    return serializedPaymentTransaction;
  }
}
