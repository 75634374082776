<!-- TABLE WRAPPER ************************************************************************** -->

<h2 class="ms-1 mb-4 inline-block font-semibold">Properties</h2>

@if(isLoading){
<div class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center">
  <div
    class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
    role="status" aria-label="loading">
    <span class="sr-only">Loading...</span>
  </div>
  <p class="text-sm text-gray-500 mt-4">Loading properties...</p>
</div>
} @else {
<!-- Filter Group -->
<div class="grid md:grid-cols-2 gap-y-2 md:gap-y-0 md:gap-x-5 hidden">
  <div>
    <!-- Search Input -->
    <div class="relative">
      <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
        <img src="assets/img/search.svg" alt="search" class="flex-shrink-0 w-4 h-4 text-gray-500" />
      </div>
      <input type="text"
        class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
        placeholder="Search property" />
    </div>
    <!-- End Search Input -->
  </div>
  <!-- End Col -->

  <div class="flex justify-end items-center gap-x-2">
    <!-- Filter Dropdown -->
    <div class="hs-dropdown relative inline-flex [--auto-close:true]">
      <!-- Filter Button -->
      <button id="hs-pro-dptied" type="button"
        class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50">
        <img src="assets/img/arrows-up-down.svg" alt="Import / Export" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
        Import / Export
        <img src="assets/img/down.svg" alt="" class="flex-shrink-0 w-3.5 h-3.5" />
      </button>
      <!-- End Filter Button -->

      <!-- Dropdown -->
      <div
        class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
        aria-labelledby="hs-pro-dptied">
        <div class="p-1">
          <button type="button"
            class="w-full flex gap-x-3 py-1.5 px-2 rounded-lg text-[13px] hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:b g-gray-100"
            data-hs-overlay="#hs-pro-dicm">
            <img src="assets/img/arrow-down-tray.svg" alt="Import contacts" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
            Import contacts
          </button>
          <button type="button"
            class="w-full flex gap-x-3 py-1.5 px-2 rounded-lg text-[13px] hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            data-hs-overlay="#hs-pro-decm">
            <img src="assets/img/arrow-up-tray.svg" alt="Export contacts" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

            Export contacts
          </button>
        </div>
      </div>
      <!-- End Dropdown -->
    </div>
    <!-- End Filter Dropdown -->

    <!-- Filter Dropdown -->
    <div class="hs-dropdown relative inline-flex [--auto-close:inside]">
      <!-- Filter Button -->
      <button id="hs-pro-dptfd" type="button"
        class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50">
        <img src="assets/img/adjustments-horizontal.svg" alt="Filter" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

        Filter
        <span class="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full">
          5
        </span>
      </button>
      <!-- End Filter Button -->

      <!-- Dropdown -->
      <div
        class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
        aria-labelledby="hs-pro-dptfd">
        <div class="p-1">
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/user-square.svg" alt="Name" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

            Name
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/envelope.svg" alt="Email addresses" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

            Email addresses
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/description.svg" alt="Description" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
            Description
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/building-office.svg" alt="Company" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
            Company
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/user-ID.svg" alt="User ID" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
            User ID
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/phone.svg" alt="Phone numbers" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

            Phone numbers
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/map-pin.svg" alt="Location" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />

            Location
          </a>
          <a class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
            href="#">
            <img src="assets/img/signed-up.svg" alt="Signed up as" class="flex-shrink-0 mt-0.5 w-3.5 h-3.5" />
            Signed up as
          </a>
        </div>
      </div>
      <!-- End Dropdown -->
    </div>
    <!-- End Filter Dropdown -->
  </div>
  <!-- End Col -->
</div>
<!-- End Filter Group -->
<div>
  <!-- Tab Content -->
  <div id="hs-pro-tabs-dut-all" role="tabpanel" aria-labelledby="hs-pro-tabs-dut-item-all">
    <!-- Table Section -->
    <div
      class="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300">
      <div class="min-w-full inline-block align-middle">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
                  <button id="hs-pro-dutnms" type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
                    Name
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
                  <button id="hs-pro-dutlctn" type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
                    Location
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
                  <button id="hs-pro-duttenant" type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
                    Tenant
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
                  <button id="hs-pro-dutstatus" type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
                    Status Information
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>
              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown relative inline-flex w-full cursor-pointer">
                  <button id="hs-pro-dutactions" type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100">
                    Actions
                  </button>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div class="w-full flex justify-center content-center mt-16 min-h-28">
                <img src="assets/img/loading.gif" alt="loading" class="h-8 w-auto" />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>
          } @else {
          @for (item of propertiesRows; track item.id) {
          <properties-table-row [pitem]="item" class="divide-y divide-gray-200">
          </properties-table-row>
          } @empty {
          <tr>
            <td class="text-center" colspan="100">
              <!--  EMPTY STATE  -->
              <table-empty-state noDataTitle="There are no properties"></table-empty-state>
              <!--  END EMPTY STATE  -->
            </td>
          </tr>
          }
        }
        </table>
      </div>
    </div>
    <!-- Footer -->
    <div class="mt-4">
      <table-pager [totalItems]="totalItems" [isLoading]="isPagerLoading"
        (onPageChange)="handlePagination($event)"></table-pager>
    </div>

    <!-- End Footer -->
  </div>
</div>
}