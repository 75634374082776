<layout-main>
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5  xs:mt-4 sm:mt-0  xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/issues"
      >
        Issues
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5 cursor-default"
      aria-current="page"
    >
      Issue {{ issue.id ? "#" + issue.id : "" }}
    </li>
  </ol>
  <!-- END BREADCRUMB -->
  <issue-view-edit [issue]="issue" [mode]="mode"></issue-view-edit>
  <issue-notes [issue]="issue" [mode]="mode"></issue-notes>
</layout-main>
