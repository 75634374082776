import { Component } from '@angular/core';
import { UiButtonComponent } from "../../components/atomic-ui-components/button/ui-button.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'error',
  standalone: true,
  imports: [UiButtonComponent, RouterLink],
  templateUrl: './error.component.html',
  styleUrl: './error.component.css'
})
export class ErrorComponent {
  errorCode: number = 404;
  errorMessage: string = "Sorry, we couldn't find your page..";
}
