import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service'; 
import { IUnitModel } from '../../../services/api/unit/unit-model.interface';
import { ToastrService } from 'ngx-toastr';
import { UnitClientService } from '../../../services/api/unit/unit-client.service';
 

@Component({
  selector: 'unit-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './unit-editor.component.html',
  styleUrl: './unit-editor.component.css'
})
export class UnitEditorComponent {
  @Input() unit!: IUnitModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  unitForm: FormGroup = this.fb.group({
    name: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private unitService: UnitClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('UnitEditor Changed', this.unit);
    this.unitForm.reset();
    if (this.mode === 'view') {
      this.unitForm.disable();
    } else {
      this.unitForm.enable();
    }
    if (this.mode === 'edit') {
      this.unitForm.patchValue(this.unit);
    }
   }

  saveUnit() {
    this.log.info('Saving Unit');
    if (this.unitForm.valid && this.mode !== 'view') {
      const updatedUntypedUnit: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.unitForm.controls).forEach((key) => {
        const control = this.unitForm.get(key);
        if (control?.dirty) {
          updatedUntypedUnit[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedUnit).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedUnit =
        updatedUntypedUnit as IUnitModel;
      this.log.debug('Unit request:', updatedUnit);

      if (this.mode === 'edit') {
        updatedUnit.id = this.unit.id;
        this.unitService
          .updateItem(updatedUnit)
          .subscribe({
            next: (data) => {
              this.toastr.success('Unit updated');
              this.log.debug('Unit updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Unit');
              this.log.error('Error updating Unit:', err);
            },
          });
      } else {
        this.unitService
          .createItem(updatedUnit)
          .subscribe({
            next: (data) => {
              this.toastr.success('Unit created');
              this.log.debug('Unit created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Unit ');
              this.log.error('Error creating Unit:', err);
            },
          });
      }
    }
  }

}
