import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule , FormGroup} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { FloorClientService } from '../../../services/api/floor/floor-client.service';
import { IFloorModel } from '../../../services/api/floor/floor-model.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'floor-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule, 
  ],
  templateUrl: './floor-editor.component.html',
  styleUrl: './floor-editor.component.css'
})
export class FloorEditorComponent {
  @Input() floor!: IFloorModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  floorForm: FormGroup = this.fb.group({
    name: [''],
    level: [''],
  });


  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private floorService: FloorClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('FloorEditor Changed', this.floor);
    this.floorForm.reset();
    if (this.mode === 'view') {
      this.floorForm.disable();
    } else {
      this.floorForm.enable();
    }
    if (this.mode === 'edit') {
      this.floorForm.patchValue(this.floor);
    }
   }

   saveFloor() {
    this.log.info('Saving Floor');
    if (this.floor) {
      const updatedUntypedFloor: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.floorForm.controls).forEach((key) => {
        const control = this.floorForm.get(key);
        if (control?.dirty) {
          updatedUntypedFloor[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedFloor).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedFloor =
        updatedUntypedFloor as IFloorModel;
      this.log.debug('Floor request:', updatedFloor);

      if (this.mode === 'edit') {
        updatedFloor.id = this.floor.id;
        this.floorService
          .updateItem(updatedFloor)
          .subscribe({
            next: (data) => {
              this.toastr.success('Floor updated');
              this.log.debug('Floor updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Floor');
              this.log.error('Error updating Floor:', err);
            },
          });
      } else {
        this.floorService
          .createItem(updatedFloor)
          .subscribe({
            next: (data) => {
              this.toastr.success('Floor created');
              this.log.debug('Floor created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Floor ');
              this.log.error('Error creating Floor:', err);
            },
          });
      }
    }
  }

}
