import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LogService } from '../../../../services/utils/log.service';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { ICleaningRequestModel } from '../../../../services/api/cleaning-request/cleaning-request-model.interface';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { FlatFormatterPipe } from '../../../../services/utils/flat-formatter.pipe';

@Component({
  selector: 'cleaning-request-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
    FlatFormatterPipe,
  ],
  templateUrl: './cleaning-request-table-row.component.html',
  styleUrl: './cleaning-request-table-row.component.css',
})
export class CleaningRequestTableRowComponent {
  @Input() pitem: ICleaningRequestModel = {} as ICleaningRequestModel;
  @Output() onDelete = new EventEmitter<ICleaningRequestModel>();
  @Output() onEdit = new EventEmitter<ICleaningRequestModel>();
  @Output() onView = new EventEmitter<ICleaningRequestModel>();

  displayedBuilding: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pitem && this.pitem.flat) {
      this.displayedBuilding = this.pitem.flat.building?.name || '';
    } else {
      this.displayedBuilding = '';
    }
  }
  handleEdit(pitem: ICleaningRequestModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: ICleaningRequestModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: ICleaningRequestModel) {
    this.onDelete.emit(pitem);
  }
}
