import { Component } from '@angular/core';
import { LogService } from '../../../services/utils/log.service';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { BookingClientService, IBookingQueryParams } from '../../../services/api/booking/booking-client.service';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { ModelFormatterService } from '../../../services/utils/model-formatter.service';
import { DeparturesRowComponent } from './departures-row/departures-row.component';
import { IDepartureModel } from './departure-model.interface';
import { format, isToday, parseISO } from 'date-fns';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { HardCodedConfigService } from '../../../services/utils/hard-coded-config.service';


@Component({
  selector: 'departures',
  standalone: true,
  imports: [
    DeparturesRowComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './departures.component.html',
  styleUrl: './departures.component.css',
})
export class DeparturesComponent {
  departures = [] as IDepartureModel[];
  bookings = [] as IBookingModel[];
  isPreparing = true;

  constructor(
    private log: LogService,
    private bookingService: BookingClientService,
    private formatter: ModelFormatterService,
    private hardCodedConfig: HardCodedConfigService,
  ) {}

  ngOnInit() {
    this.isPreparing = true;

    // Azokat a foglalásokat kérjük le, amiknek az end_date-je ma van
    const today = new Date();
    const queryParams = {
      endDate: format(today, 'yyyy-MM-dd'),
    } as IBookingQueryParams;

    this.bookingService.getAllBookings(queryParams).subscribe({
      next: (bookings) => {
        this.log.debug('Bookings loaded', bookings);
        this.bookings = bookings;
        setTimeout(() => {
          this.prepareDepartures();
        }, 100);
      },
    });
  }

  prepareDepartures() {
    this.log.info('Preparing departure overview');
    const departureList = [];
    for (const element of this.bookings.filter(
      (booking) =>
        isToday(parseISO(booking.end_date ?? '9999-12-31')) &&
        booking.bookingStatus?.id === this.hardCodedConfig.FINALIZED_BOOKING_STATUS_ID
    )) {
      const flats: IFlatModel[] = element.flats ?? [];
      const listItem: IDepartureModel = {
        id: element.id,
        flats: flats.map((flat) => {
          return {
            id: flat.id,
            building: flat.building?.name ?? '',
            room: this.formatter.getFormattedFlat(flat, false),
          };
        }),
        startDate: element.start_date ?? '',
        endDate: element.end_date ?? '',
        checkOutDate: element.checkout_time ?? '',
      };
      departureList.push(listItem);
    }
    this.departures = departureList.sort((a, b) =>
      a.flats[0]?.building?.localeCompare(b.flats[0]?.building)
    );
    this.isPreparing = false;
  }
}
