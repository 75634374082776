import { Component, ViewChild } from '@angular/core';
import { IPaymentTransactionModel } from '../../../services/api/payment-transaction/payment-transaction-model.interface';
import { PaymentTransactionTableRowComponent } from './payment-transaction-table-row/payment-transaction-table-row.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { PaymentTransactionEditorComponent } from '../../modal-editors/payment-transaction-editor/payment-transaction-editor.component';
import { PaymentTransactionClientService } from '../../../services/api/payment-transaction/payment-transaction-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { ICleaningRequestModel } from '../../../services/api/cleaning-request/cleaning-request-model.interface';
import { ICurrencyModel } from '../../../services/api/currency/currency-model.interface';
import { IInvoiceModel } from '../../../services/api/invoice/invoice-model.interface';
import { IParkingRequestModel } from '../../../services/api/parking-request/parking-request-model.interface';
import { IPaymentProviderModel } from '../../../services/api/payment-provider/payment-provider-model.interface';
import { IUserModel } from '../../../services/api/user/user-model.interface';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';


@Component({
  selector: 'payment-transaction-table',
  standalone: true,
  imports: [
    PaymentTransactionTableRowComponent,
    UiButtonComponent,
    UiTablePagerComponent,
    PaymentTransactionEditorComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './payment-transaction-table.component.html',
  styleUrl: './payment-transaction-table.component.css',
})
export class PaymentTransactionTableComponent {
  @ViewChild(PaymentTransactionEditorComponent)
  editor!: PaymentTransactionEditorComponent;
  selectedPaymentTransaction!: IPaymentTransactionModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  paymentTransactions = [] as IPaymentTransactionModel[];
  isLoading = false;
  isPagerLoading = false;
  totalItems = 0;

  constructor(
    private paymentTransactionService: PaymentTransactionClientService,
    private log: LogService,
    private toastr: ToastrService,
  ) {}

  handleEdit(pitem: IPaymentTransactionModel) {
    this.selectedPaymentTransaction = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IPaymentTransactionModel) {
    this.selectedPaymentTransaction = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IPaymentTransactionModel) {
    this.selectedPaymentTransaction = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.paymentTransactionService
      .deletePaymentTransaction(this.selectedPaymentTransaction.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.paymentTransactions = this.paymentTransactions.filter(
            (item) => item.id !== this.selectedPaymentTransaction.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.paymentTransactions.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.log.info('Payment transaction deleted');
          this.toastr.success('Payment transaction deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting payment transaction');
          this.log.error('Error deleting payment transaction', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedPaymentTransaction = {
      id: 0,
      price: '',
      payer_name: '',
      payer_email: '',
      paid: false,
      payment_status: '',
      response_string: '',
      response_code: '',
      transaction_code: '',
      created_at: '',
      updated_at: '',
      currency: {} as ICurrencyModel,
      paymentProvider: {} as IPaymentProviderModel,
      payee_user: {} as IUserModel,
      payer_user: {} as IUserModel,
      invoice: {} as IInvoiceModel,
      booking: {} as IBookingModel,
      cleaningRequest: [] as ICleaningRequestModel[],
      parkingRequest: [] as IParkingRequestModel[],
    } as IPaymentTransactionModel;
  }

  refresh(page?: number) {
    // Lekérjük a fizetési tranzakciókat
    this.isLoading = true;
    this.paymentTransactionService.getPaymentTransactions(page).subscribe({
      next: (data) => {
        this.log.debug('Payment transactions fetched', data);
        this.paymentTransactions = data;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      },
      error: (err) => {
        // TODO error alert
        this.log.error('Error fetching payment transactions', err);
      },
    });
    // Lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.paymentTransactionService.getTotalItems().subscribe({
      next: (count) => {
        this.totalItems = count;
        this.isPagerLoading = false;
      },
      error: (err) => {
        // TODO error alert
        this.log.error('Error fetching total items', err);
      },
    });
  }
}
