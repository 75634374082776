import { Component, Input, ViewChild } from '@angular/core';
import { IssuesTableRowComponent } from './issues-table-row/issues-table-row.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { IssueEditorComponent } from '../../modal-editors/issue-creator/issue-creator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { IIssueModel } from '../../../services/api/issue/issue-model.interface';
import { IIssueNoteModel } from '../../../services/api/issue-note/issue-note-model.interface';
import {
  IssueClientService,
  IIssueFilterType,
} from '../../../services/api/issue/issue-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'issues-table',
  standalone: true,
  imports: [
    IssuesTableRowComponent,
    UiButtonComponent,
    IssueEditorComponent,
    ReactiveFormsModule,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './issues-table.component.html',
  styleUrl: './issues-table.component.css',
})
export class IssuesTableComponent {
  @ViewChild(IssueEditorComponent)
  editor!: IssueEditorComponent;
  @Input() issueFilter: IIssueFilterType = { };
  @Input() flatIdToFilter?: number ;
  @Input() title = 'Issues';
  @Input() showCreateButton = true;
  selectedIssue!: IIssueModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  issues: IIssueModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;
  currentPage = 1;

  constructor(
    private issuesService: IssueClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handlePagination(page: number) {
    this.currentPage = page;
    this.refresh(this.currentPage);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IIssueModel) {
    this.selectedIssue = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.issuesService.deleteIssue(this.selectedIssue.id).subscribe({
      next: () => {
        // ha sikeres volt a törlés, akkor töröljük a listából is, de nem kérünk le mindent újra
        this.issues = this.issues.filter(
          (item) => item.id !== this.selectedIssue.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.issues.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Issue deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting issue');
        this.log.error('Error deleting issue', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedIssue = {
      id: 0,
      note: '',
      created_at: '',
      updated_at: '',
      issueNotes: [] as IIssueNoteModel[],
    } as IIssueModel;
  }

  refresh(page?: number) {
    // lekérjük a hibajegyeket
    this.isLoading = true;
    this.issuesService
      .getIssues(this.issueFilter, page)
      .subscribe((issues) => {
        this.issues = issues;
        this.isLoading = false;
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.issuesService
      .getTotalItems(this.issueFilter)
      .subscribe((count) => {
        this.totalItems = count;
        this.isPagerLoading = false;
      });
  }
}
