import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { IMeterModel } from '../../../../services/api/meter/meter-model.interface';
import { DatePipe } from '@angular/common';
import { FlatFormatterPipe } from '../../../../services/utils/flat-formatter.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'meter-table-row',
  standalone: true,
  imports: [
    UiBadgeComponent,
    UiButtonComponent,
    DatePipe,
    FlatFormatterPipe,
  ],
  templateUrl: './meter-table-row.component.html',
  styleUrl: './meter-table-row.component.css',
})
export class MeterTableRowComponent {
  @Input() pitem: IMeterModel = {} as IMeterModel;
  @Input() lastReadingValue: string = '';
  @Input() lastReadingTime: string = '';
  @Output() onDelete = new EventEmitter<IMeterModel>();
  @Output() onEdit = new EventEmitter<IMeterModel>();

  displayedBuilding: string = '';

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pitem && this.pitem.flat) {
      this.displayedBuilding = this.pitem.flat.building?.name || '';
    } else {
      this.displayedBuilding = '';
    }
  }

  handleView() {
    this.router.navigate(['/meters', this.pitem.id]);
  }

  handleEdit(pitem: IMeterModel) {
    this.onEdit.emit(pitem);
  }

  handleDelete(pitem: IMeterModel) {
    this.onDelete.emit(pitem);
  }
}
