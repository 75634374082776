import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';
import { LogService } from '../../services/utils/log.service';

@Component({
  selector: 'nav-header',
  standalone: true,
  imports: [UiButtonComponent],
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.css',
})
export class NavHeaderComponent {
  userName: string = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private log: LogService
  ) {
    this.userName = this.authService.getUserName();
  }

  performLogout() {
    this.log.info('Logging out...');
    this.authService.logoutUser();
    this.router.navigate(['/login']);
  }
}
