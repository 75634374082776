<tr>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800">
    @for (flat of pitem.flats; track flat.id) {
    <div class="mb-1">
      <ui-badge
        [color]="
          flat.building === 'Lehel'
            ? 'blue'
            : flat.building === 'Kunigunda'
            ? 'yellow'
            : 'black'
        "
        badgeType="soft"
        cornerType="rounded"
        class="w-24 inline-block"
        >{{ flat.building }}</ui-badge
      >
      <span class="inline-block ml-2">{{ flat.room }}</span>
    </div>
    }
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 text-left align-middle"
  >
    {{ pitem.startDate | date : "yyyy-MM-dd" }}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    {{ pitem.endDate | date : "yyyy-MM-dd" }}
  </td>
  <td class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle">
    @if(pitem.checkOutDate){
    <div class="mb-2 leading-4">
      {{ pitem.checkOutDate | date : "yyyy-MM-dd, HH:mm" }}
    </div>
    } @else {
    <div class="mb-2 leading-4 text-gray-400">not yet</div>
    }
  </td>
</tr>
