import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { IMeterModel } from '../../../services/api/meter/meter-model.interface';
import { MeterClientService } from '../../../services/api/meter/meter-client.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'meter-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './meter-editor.component.html',
  styleUrl: './meter-editor.component.css'
})
export class MeterEditorComponent {
  @Input()  meter!: IMeterModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();  

  meterForm: FormGroup = this.fb.group({
    value: [''],
    timestamp: [''],
    sampling_type:  [''],
    photo: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private meterClientService: MeterClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('Meter Editor Changed', this.meter);
    this.meterForm.reset();
    if (this.mode === 'view') {
      this.meterForm.disable();
    } else {
      this.meterForm.enable();
    }
    if (this.mode === 'edit') {
      this.meterForm.patchValue(this.meter);
    }
   }

   saveMeter() {
    this.log.info('Saving Meter');
    if (this.meterForm.valid && this.mode !== 'view') {
      const updatedUntypedMeter: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.meterForm.controls).forEach((key) => {
        const control = this.meterForm.get(key);
        if (control?.dirty) {
          updatedUntypedMeter[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedMeter).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedMeter =
        updatedUntypedMeter as IMeterModel;
      this.log.debug('Meter request:', updatedMeter);

      if (this.mode === 'edit') {
        updatedMeter.id = this.meter.id;
        this.meterClientService
          .updateItem(updatedMeter)
          .subscribe({
            next: (data) => {
              this.toastr.success('Meter updated');
              this.log.debug('Meter updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Meter');
              this.log.error('Error updating Meter:', err);
            },
          });
      } else {
        this.meterClientService
          .createItem(updatedMeter)
          .subscribe({
            next: (data) => {
              this.toastr.success('Meter created');
              this.log.debug('Meter created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Meter ');
              this.log.error('Error creating Meter:', err);
            },
          });
      }
    }
  }

}
