<div class="flex justify-between m-4">
  <div class="mb-4">
    <h2 class="font-bold text-lg">Cleaning overview</h2>
    <p>A quick glance about the cleaning services</p>
  </div>
</div>
<!-- Filter Group -->
<div class="overflow-x-auto sm:mx-4 sm:mb-4">
  <!-- Table -->
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr class="border-t border-gray-200 divide-x divide-gray-200">
        <th scope="col" class="px-4 py-2 leading-4 text-center">
          <!-- Sort Dropdown -->
          <div
            class="hs-dropdown## relative inline-flex w-full cursor-pointer text-left"
          >
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Flat
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Priority
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-40 cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Type
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Cleanable
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Done
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Note for Cleaning Staff
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2 leading-4">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown## relative inline-flex w-full cursor-pointer">
            <button
              id="hs-pro-dutnms"
              type="button"
              class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Note for Management
              <img
                src="assets/img/up-down.svg"
                alt="Name"
                class="flex-shrink-0 w-3.5 h-3.5"
              />
            </button>

            <!-- Dropdown -->
            <div
              class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
              aria-labelledby="hs-pro-dutnms"
            >
              <div class="p-1">
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-up.svg"
                    alt="Sort ascending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />
                  Sort ascending
                </button>
                <button
                  type="button"
                  class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                >
                  <img
                    src="assets/img/arrow-down.svg"
                    alt="Sort descending"
                    class="flex-shrink-0 w-3.5 h-3.5"
                  />

                  Sort descending
                </button>
              </div>
            </div>
            <!-- End Dropdown -->
          </div>
          <!-- End Sort Dropdown -->
        </th>
        <th scope="col" class="px-4 py-2">
          <!-- Sort Dropdown -->
          <div class="hs-dropdown relative inline-flex w-48 cursor-pointer">
            <button
              id="hs-pro-dutactions"
              type="button"
              class="text-gray-500 uppercase text-xs w-full justify-end flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
            >
              Actions
            </button>
          </div>
          <!-- End Sort Dropdown -->
        </th>
      </tr>
    </thead>
    @if(isLoading){
    <tr>
      <td colspan="100">
        <div class="w-full flex justify-center content-center mt-16 min-h-28">
          <img src="assets/img/loading.gif" alt="loading" class="h-8 w-auto" />
          <div class="ml-4">Loading...</div>
        </div>
      </td>
    </tr>

    } @else{ @for (item of cleaningOverview; track item.id) {
    <cleaning-overview-table-row
      [pitem]="item"
      class="divide-y divide-gray-200"
      (onEdit)="handleEdit($event)"
      (onDone)="handleMarkDone($event)"
    >
    </cleaning-overview-table-row>
    } @empty {
    <tr>
      <td class="text-center" colspan="100">
        <!--  EMPTY STATE  -->
        <table-empty-state
          noDataTitle="No Cleaning Schedules For The Day"
        ></table-empty-state>
        <!--  END EMPTY STATE  -->
      </td>
    </tr>

    } }
  </table>
</div>

<!--<table-pager
[totalItems]="totalItems"
[isLoading]="isPagerLoading"
(onPageChange)="handlePagination($event)"
></table-pager>-->

<!-- ========== SECONDARY CONTENT ========== -->
<!-- Cleaning Schedule Editor Modal -->
<cleaning-schedule-note-editor
  [cleaningSchedule]="selectedCleaningSchedule"
  (onSave)="refresh()"
></cleaning-schedule-note-editor>
<!-- End Cleaning Schedule Editor Modal -->
<!-- ========== END SECONDARY CONTENT ========== -->
