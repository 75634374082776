import { Component, ViewChild } from '@angular/core';
import { FloorTableRowComponent } from './floor-table-row/floor-table-row.component';
import { FloorEditorComponent } from '../../modal-editors/floor-editor/floor-editor.component';
import { IFloorModel } from '../../../services/api/floor/floor-model.interface';
import { FloorClientService } from '../../../services/api/floor/floor-client.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'floor-table',
  standalone: true,
  imports: [
    FloorTableRowComponent,
    FloorEditorComponent,
    UiButtonComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './floor-table.component.html',
  styleUrl: './floor-table.component.css'
})
export class FloorTableComponent {
  @ViewChild(FloorEditorComponent)
  editor!: FloorEditorComponent;
  selectedFloor!: IFloorModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  floor: IFloorModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private floorClientService: FloorClientService,
    private log: LogService,
    private toastr: ToastrService,
  ) { }

  handleEdit(pitem: IFloorModel) {
    this.selectedFloor = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IFloorModel) {
    this.selectedFloor = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IFloorModel) {
    this.selectedFloor = pitem;
  }

   // tényleges törlés
   performDelete() {
    this.floorClientService
      .deleteItem(this.selectedFloor.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.log.info('Floor deleted');
          this.floor = this.floor.filter(
            (pr) => pr.id !== this.selectedFloor.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.floor.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Floor deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting floor');
          this.log.error('Error deleting floor', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedFloor = {
      '@id': '',
      id: 0,
      name: '',
      level: 0,
    } as IFloorModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.floorClientService
      .getItems(page)
      .subscribe({
        next: (floor) => {
          this.log.debug('Floor requests fetched', floor);
          this.floor = floor;
          setTimeout(() => {
            this.isLoading = false;
          }, 100);

          setTimeout(() => {
            this.log.info('Floor table initialized');
            window.HSStaticMethods.autoInit('overlay');
          }, 100);
        },
        error: (err) => {
          this.log.error('Error fetching floor', err);
          this.isLoading = false;
        },
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.floorClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
