import { Pipe, PipeTransform } from '@angular/core';
import { IBillingAddressModel } from '../api/billing-address/billing-address-model.interface';

@Pipe({
  name: 'billingAddress',
  standalone: true
})
export class BillingAddressPipe implements PipeTransform {

  transform(value: IBillingAddressModel | null): string {
    let result = '';
    if (!value) {
      return result;
    }
    if (value.po_code) {
      result += value.po_code + ' ';
    }
    if (value.country) {
      result += value.country + ', ';
    }
    if (value.town) {
      result += value.town + ' ';
    }
    if (value.street) {
      result += value.street + ' ';
    }
    return result;
  }

}
