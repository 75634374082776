import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format } from 'date-fns';
import { ICleaningOverviewModel } from '../../../components/tables/cleaning-overview-table/cleaning-overview-model.interface';
import { BookingClientService } from '../../api/booking/booking-client.service';
import { IBookingModel } from '../../api/booking/booking-model.interface';
import {
  CleaningScheduleClientService,
  ICleaningScheduleQueryParams,
} from '../../api/cleaning-schedule/cleaning-schedule-client.service';
import { ICleaningScheduleModel } from '../../api/cleaning-schedule/cleaning-schedule-model.interface';
import { ModelFormatterService } from '../../utils/model-formatter.service';
import { IFlatModel } from '../../api/flat/flat-model.interface';
import { BehaviorSubject } from 'rxjs';

export interface ICleaningStats {
  cleaningsTodo: number;
  cleaningsDone: number;
}

@Injectable({
  providedIn: 'root',
})
export class CleaningStatsService {
  private cleaningOverviewItems = [] as ICleaningOverviewModel[];
  private cleaningOverviewSubject: BehaviorSubject<ICleaningOverviewModel[]> =
    new BehaviorSubject(this.cleaningOverviewItems);

  private cleaningStats = {} as ICleaningStats;
  private cleaningStatsSubject: BehaviorSubject<ICleaningStats> =
    new BehaviorSubject(this.cleaningStats);

  private schedules = [] as ICleaningScheduleModel[];
  private bookings = [] as IBookingModel[];
  private isLoadingSchedules = false;
  private isLoadingBookings = false;
  private isPreparing = false;

  constructor(
    private log: LogService,
    private scheduleService: CleaningScheduleClientService,
    private bookingService: BookingClientService,
    private formatter: ModelFormatterService
  ) {
    this.refresh();
  }

  /**
   * A lehető legtakarékosabb módon frissíti a takarítási beosztásokat és az ezzel kapcsolatos statisztikai adatokat.
   */
  private refresh() {
    // Ha már fut a frissítés, akkor ne indítsuk el újra, a végén úgyis lesz egy-egy next az observable-ökre
    if (this.isLoadingBookings || this.isLoadingSchedules || this.isPreparing) {
      return;
    }

    this.isLoadingBookings = true;
    this.isLoadingSchedules = true;
    this.isPreparing = false;

    // A mai napot vesszük alapul, és azokat a takarítási beosztásokat kérjük le, amiknek a cleaning_date-je ma van
    const today = new Date();
    const queryParams = {
      cleaning_date: format(today, 'yyyy-MM-dd'),
    } as ICleaningScheduleQueryParams;

    this.scheduleService.getAllCleaningSchedules(queryParams).subscribe({
      next: (schedules) => {
        this.schedules = schedules;
        this.isLoadingSchedules = false;
        this.prepareCleaningOverviews();
      },
      error: (err) => {
        this.log.error(
          'Error loading cleaning schedules for CleaningStatsService',
          err
        );
      },
    });
    this.bookingService.getRelevantBookings().subscribe({
      next: (bookings) => {
        this.bookings = bookings;
        this.isLoadingBookings = false;
        this.prepareCleaningOverviews();
      },
      error: (err) => {
        this.log.error('Error loading bookings for CleaningStatsService', err);
      },
    });
  }

  /**
   * Megmondja, hogy éppen frissítjük-e az adatokat. Observable-re való feliratkozásokban kell ezt használni.
   * @returns true, ha valamelyik adatot éppen frissítjük, false egyébként
   */
  isLoading(): boolean {
    return (
      this.isLoadingBookings || this.isLoadingSchedules || this.isPreparing
    );
  }

  /**
   * Visszaadja a takarítási beosztásokat tartalmazó tömböt Observable-be csomagolva.
   * @returns BehaviorSubject<ICleaningOverviewModel[]> - az observable, amire fel lehet iratkozni a takarítási beosztások lekérdezéséhez
   */
  getCleaningOverview(): BehaviorSubject<ICleaningOverviewModel[]> {
    if (this.cleaningOverviewItems.length === 0) {
      this.refresh();
    }
    return this.cleaningOverviewSubject;
  }

  /**
   * Visszaadja a takarítási statisztikákat tartalmazó objektumot Observable-be csomagolva.
   * @returns BehaviorSubject<ICleaningStats> - az observable, amiben takarítási statisztikák vannak összes/done számokkal
   */
  getCleaningStats(): BehaviorSubject<ICleaningStats> {
    if (this.cleaningStats.cleaningsDone === undefined) {
      this.refresh();
    }
    return this.cleaningStatsSubject;
  }

  /**
   * Visszaadja a megadott id-jű takarítási beosztást, mivel a service-t használók, csak OverView Ietemeket kapnak alapból.
   * @param id - a keresett takarítási beosztás id-je
   * @returns ICleaningScheduleModel | undefined - a keresett takarítási beosztás, ha létezik, egyébként undefined
   */
  getScheduleById(id: number): ICleaningScheduleModel | undefined {
    return this.schedules.find((schedule) => schedule.id === id);
  }

  /**
   * A takarítási beosztások alapján előkészíti a takarítási beosztásokat tartalmazó tömböt.
   * Szintén odafigyelve arra, hogy ne induljon el többször egyszerre.
   */
  private prepareCleaningOverviews() {
    if (
      !this.isLoadingBookings &&
      !this.isLoadingSchedules &&
      !this.isPreparing
    ) {
      this.isPreparing = true;
      this.log.debug('Preparing cleaning overviews');
      const cleaningOverviewList = this.schedules
        // a takarítási beosztásokat alakítjuk át a megjelenítéshez szükséges formátumra
        .map((element) => {
          const flat: IFlatModel = element.flat ?? ({} as IFlatModel);
          return {
            id: element.id,
            building: element.building?.name
              ? element.building.name
              : flat?.building?.name ?? 'N/A',
            flat: this.formatter.getFormattedFlat(flat, false),
            checkin: element.cleaning_date + '',
            type: element.cleaningType?.name + '',
            typeColor: this.getTypeColor(element.cleaningType?.id ?? 0),
            cleanable: this.getCleanable(flat, element.cleaning_date),
            done:
              element.cleaning_done_at === undefined
                ? ''
                : element.cleaning_done_at + '',
            cleaningNote:
              element.cleaning_note === undefined
                ? ''
                : element.cleaning_note + '',
            cleanerNote:
              element.cleaner_note === undefined
                ? ''
                : element.cleaner_note + '',
            priority: this.getPriority(element),
          };
        })
        // Rendezés először a prioritás alapján, azon belül a building, majd a flat alapján
        .sort((a, b) => {
          return (
            a.priority - b.priority ||
            a.building.localeCompare(b.building) ||
            a.flat.localeCompare(b.flat)
          );
        });
      this.cleaningOverviewItems = cleaningOverviewList;
      this.isPreparing = false;
      this.log.info('CleaningStatsService: cleaning overviews prepared');
      // Értesítjük az observable-re feliratkozottakat, hogy frissült a takarítási beosztások tömbje
      this.cleaningOverviewSubject.next(this.cleaningOverviewItems);
      this.calculateCleaningStats();
    }
  }

  /**
   * Kiszámolja a takarítási statisztikákat, és értesíti az observable-re feliratkozottakat.
   */
  private calculateCleaningStats() {
    // összes
    const cleaningsTodo = this.cleaningOverviewItems.length;
    // kész
    const cleaningsDone = this.cleaningOverviewItems.filter(
      (overview: ICleaningOverviewModel) => overview.done !== ''
    ).length;
    this.cleaningStats = {
      cleaningsTodo: cleaningsTodo,
      cleaningsDone: cleaningsDone,
    };
    this.cleaningStatsSubject.next(this.cleaningStats);
  }

  /**
   * Visszaadja a takarítási típusnak megfelelő színkódot.
   * 1 - gray, 2 - teal, 3 - blue, 4 - yellow, 5 - orange, minden más - red
   * @param cleaningTypeId - a takarítási típus id-je
   * @returns a színkód
   */
  private getTypeColor(
    cleaningTypeId: number
  ):
    | 'black'
    | 'gray'
    | 'teal'
    | 'blue'
    | 'red'
    | 'yellow'
    | 'green'
    | 'orange'
    | 'white' {
    switch (cleaningTypeId) {
      case 1:
        return 'gray';
      case 2:
        return 'teal';
      case 3:
        return 'blue';
      case 4:
        return 'yellow';
      case 5:
        return 'orange';
      default:
        return 'red';
    }
  }

  /**
   * Visszaadja a takarítási beosztás prioritását.
   * @param schedule - a takarítási beosztás
   * @returns a prioritás
   */
  private getPriority(schedule: ICleaningScheduleModel): number {
    // TODO: be van drótozva a fontosság, mert a backenden nincs ilyen logika
    if (schedule.cleaningType?.id === 5 || schedule.cleaningType?.id === 1) {
      return 0;
    } else {
      return 1;
    }
  }

  /**
   * Megmondja, hogy a megadott dátumon takarítani kell-e a megadott lakásban.
   * @param flatToClean - a takarítandó lakás
   * @param cleaningDate - a takarítás dátuma
   * @returns true, ha takarítani kell, false egyébként
   */
  private getCleanable(
    flatToClean: IFlatModel,
    cleaningDate?: string
  ): boolean {
    const reducedBookingList = this.bookings.filter((booking) => {
      return (
        booking.flats &&
        booking.flats?.some((flat: IFlatModel) => flat.id === flatToClean.id) &&
        booking.end_date === cleaningDate &&
        booking.checked_out
      );
    });
    return reducedBookingList.length > 0;
  }
}
