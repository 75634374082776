<div class="">
  <div class="p-2">
    <form
      id="booking-contact-form"
      [formGroup]="bookingContactInfoForm"
      (ngSubmit)="saveBookingContactInfo()"
    >
      <!--  Name  -->
      <label class="block mb-2 font-bold" for="booking-contact-booking_name"
        >Name</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
        type="text"
        placeholder="Like John Doe"
        name="booking-contact-booking_name"
        id="booking-contact-booking_name"
        [value]="booking?.name"
        formControlName="name"
      />
      <!--  End Name  -->

      <!--  Email  -->
      <label class="block mb-2 font-bold" for="booking-contact-email_address"
        >Email Address</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
        type="text"
        placeholder="Like john@doe.com"
        name="email_address"
        id="booking-contact-email_address"
        [value]="booking?.email_address"
        formControlName="email_address"
      />
      <!--  End Email  -->

      <!--  Gender  -->
      <label class="block mb-2 font-bold" for="booking-contact-gender-select"
        >Sex</label
      >
      <ng-select
        class="w-full max-w-lg inline-block mb-4"
        selectId="booking-contact-gender-select"
        [items]="bookingGenderSelectItems"
        bindLabel="title"
        bindValue="val"
        formControlName="gender"
      />
      <!--  End Gender  -->

      <!--  Phone  -->
      <label class="block mb-2 font-bold" for="booking-contact-phone_number"
        >Phone Number</label
      >
      <input
        class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
        type="text"
        placeholder="In +36 1 234 5678 format"
        name="booking-contact-phone_number"
        id="booking-contact-phone_number"
        [value]="booking?.phone_number ?? ''"
        formControlName="phone_number"
      />
      <!--  End Phone  -->

      <!--  Country  -->
      <label class="block mb-2 font-bold" for="booking-contact-country"
        >Nationality</label
      >
      <ng-select
        class="w-full max-w-lg inline-block mb-4"
        [items]="countries"
        bindLabel="title"
        bindValue="val"
        formControlName="nationality"
        [virtualScroll]="true"
        placeholder="Select Nationality"
      >
        <ng-template ng-label-tmp let-item="item">
          <div>
            <div class="flex items-center">
              <div class="me-2">
                <img
                  class="inline-block size-4 rounded-full"
                  [src]="item.options?.icon"
                  [alt]="item.options?.description"
                />
              </div>
              <div class="text-gray-800">
                {{ item.title }}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div>
            <div class="flex items-center">
              <div class="me-2">
                <img
                  class="inline-block size-4 rounded-full"
                  [src]="item.options?.icon"
                  [alt]="item.options?.description"
                />
              </div>
              <div class="text-gray-800">
                {{ item.title }}
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <!--  End Country  -->

      <div class="w-full max-w-lg text-right">
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          form="booking-contact-form"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
