import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { IssueStatusTableComponent } from '../../components/tables/issue-status-table/issue-status-table.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'issue-status',
  standalone: true,
  imports: [
    LayoutMainComponent,
    IssueStatusTableComponent,
    RouterLink,
  ],
  templateUrl: './issue-status.component.html',
  styleUrl: './issue-status.component.css'
})
export class IssueStatusComponent {

}
