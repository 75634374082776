import { Injectable } from '@angular/core';
import { IBookingModel } from '../api/booking/booking-model.interface';
import { IFlatModel } from '../api/flat/flat-model.interface';
import { IUserModel } from '../api/user/user-model.interface';
import { LogService } from './log.service';
import { IInvoiceModel } from '../api/invoice/invoice-model.interface';
import { IContractModel } from '../api/contract/contract-model.interface';
import { format, parseISO } from 'date-fns';
import { ITenantModel } from '../api/tenant/tenant-model.interface';
import { ITaxModel } from '../api/tax/tax-model.interface';
import { IMeterModel } from '../api/meter/meter-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ModelFormatterService {
  constructor(private log: LogService) {}

  /**
   * A felhasználóhoz tartozó dropdown title-t formázó függvény
   * @param user a felhasználó
   * @returns a formázott string
   */
  getFormattedUser(user: IUserModel): string {
    let result = '';
    if (user.name) {
      result += user.name;
    }
    if (user.name && user.email) {
      result += ' ';
    }
    if (user.email) {
      result += '(' + user.email + ')';
    }
    if (!result) {
      result = 'UserID: ' + user.id + ' - No name or email provided.';
    }
    return result;
  }

  /**
   * A lakáshoz tartozó dropdown title-t formázó függvény
   * @param flat a lakás
   * @returns a formázott string
   */
  getFormattedFlat(
    flat: IFlatModel | undefined,
    withBuilding: boolean = true,
    withFloor: boolean = false
  ): string {
    if (!flat?.id) {
      return '';
    }
    let result = '';
    if (flat.building && withBuilding) {
      result += '[' + flat.building.name + ']';
    }
    if (flat.floor?.name && withFloor) {
      result += ' ' + flat.floor.name + ' -';
    }
    if (flat.flat_number || flat.name) {
      result += ' ';
    }
    if (flat.flat_number) {
      result += flat.flat_number;
    }
    if (flat.flat_number && flat.name) {
      result += ' ';
    }
    if (flat.name) {
      result += flat.name;
    }
    if (!result) {
      result = 'FlatID: ' + flat.id + ' - No flat info provided.';
    }
    return result;
  }

  /**
   * A lakáshoz tartozó dropdown title-t formázó függvény
   * @param parkingSpace a parkolóhely
   * @returns a formázott string
   */
  getFormattedParkingSpace(
    parkingSpace?: IFlatModel,
    withBuilding: boolean = true
  ): string {
    if (!parkingSpace) {
      return '';
    }
    let result = '#' + parkingSpace.id + ' - ';
    if (parkingSpace.building && withBuilding) {
      result += '[' + parkingSpace.building.name?.at(0) + ']';
    }
    if (parkingSpace.name) {
      result += ' ' + parkingSpace.name;
    }
    if (!result) {
      result = 'FlatID: ' + parkingSpace.id + ' - No flat info provided.';
    }
    return result;
  }

  /**
   * A foglaláshoz tartozó dropdown title-t formázó függvény
   * @param booking a foglalás
   * @returns a formázott string
   */
  getFormattedBooking(booking: IBookingModel): string {
    let result = '';
    if (
      booking.flats &&
      booking.flats.length > 0 &&
      booking.flats[0].building
    ) {
      result += '[' + booking.flats[0].building.name + '] ';
    } else {
      result += '[Unknown Building] ';
    }
    if (booking.name) {
      result += booking.name;
    }
    if (booking.name && (booking.start_date || booking.end_date)) {
      result += ' ';
    }
    if (booking.start_date || booking.end_date) {
      result += '(' + booking.start_date + ' - ' + booking.end_date + ')';
    }
    if (!result) {
      result = 'BookingID: ' + booking.id + ' - No booking info provided.';
    }
    return result;
  }

  /**
   * A számlához tartozó dropdown title-t formázó függvény
   * @param invoice a számla
   * @returns a formázott string
   */
  getFormattedInvoice(invoice: IInvoiceModel): string {
    let result = '';
    if (invoice.invoice_number) {
      result += invoice.invoice_number;
    } else {
      result += 'InvoiceID: ' + invoice.id;
    }
    return result;
  }

  /**
   * A szerződéshez tartozó dropdown title-t formázó függvény
   * @param contract a szerződés
   * @returns a formázott string
   */
  getFormattedContract(contract: IContractModel): string {
    //TODO: értelmesebb formázás
    let result = '';
    if (contract.contract_number) {
      result += contract.contract_number;
      if (contract.tenancy_starts_at) {
        result +=
          ' (' +
          format(parseISO(contract.tenancy_starts_at), 'yyyy-MM-dd') +
          ' - ' +
          (contract.tenancy_ends_at
            ? format(parseISO(contract.tenancy_ends_at), 'yyyy-MM-dd')
            : '...') +
          ')';
      }
    } else {
      result += 'ContractID: ' + contract.id;
    }
    return result;
  }

  /**
   * A tenanthez tartozó dropdown title-t formázó függvény
   * @param tenant A tenant
   * @param withCompanyName Ha igaz, akkor a cégnév is megjelenik
   * @returns a formázott string
   */
  getFormattedTenant(
    tenant: ITenantModel,
    withCompanyName: boolean = false
  ): string {
    let result = '';
    if (tenant.name) {
      result += tenant.name;
    }
    if (tenant.name && tenant.companyName && withCompanyName) {
      result += ' at ' + tenant.companyName;
    }
    if (!result) {
      result = 'TenantID: ' + tenant.id + ' - No name rovided.';
    }
    return result;
  }

  /**
   * A taxhoz tartozó dropdown title-t formázó függvény
   * @param tax A formázandó adónem
   * @returns a formázott string
   */
  getFormattedTax(tax: ITaxModel): string {
    let result = '';
    if (tax.name) {
      result += tax.name;
    }
    if (tax.name && tax.percentage) {
      result += ' (' + tax.percentage + '%)';
    }
    if (!result) {
      result = 'TaxID: ' + tax.id + '';
    }
    return result;
  }

  /**
   * A metert formázó függvény
   * @param meter A formázandó mérőóra
   * @param withFlat (Alapértelmezetten false) Ha igaz, akkor a lakás is megjelenik, elég részletesen. Dropdownhoz kihagyandó ez a paraméter.
   * @returns a formázott string
   */
  getFormattedMeter(meter: IMeterModel, withFlat = false): string {
    let result = '';
    if (withFlat) {
      result += this.getFormattedFlat(meter.flat);
    }
    if (meter.meterType?.name) {
      result += meter.meterType.name;
    }
    if (meter.meterType?.name && meter.id) {
      result += ' #' + meter.id;
    }
    if (!result) {
      result = 'MeterID: ' + meter.id + ' - No name or serial number provided.';
    }
    return result;
  }
}
