import { Component } from '@angular/core';
import { RouterOutlet, Router, Event, NavigationEnd } from '@angular/router';
// nem kell modulozni, mert Angular 17-től standalone komponensekkel is lehet dolgozni

// Preline igényli:
// Újrainicializáljuk a komponenseket minden alkalommal, amikor az oldal frissül az alkalmazásban
import { IStaticMethods } from 'preline/preline';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  standalone: true, // standalone komponens, így nem kell modulba tenni
  imports: [RouterOutlet], //itt kell jelezni, hogy milyen standalone komponenseket használunk
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  constructor(private router: Router) {}

  ngOnInit() {
    // Itt használjuk fel a preline-hoz tartozó autoInit metódust,
    // amely az alkalmazásban minden alkalommal újrainicializálja a komponenseket
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 1000);
      }
    });
  }
  // Böngésző ablakon megjelenő cím
  title = 'i11 Admin';
}
