import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UsersTableComponent } from '../../components/tables/users-table/users-table.component';

@Component({
  selector: 'users',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UsersTableComponent,

  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent {

}
