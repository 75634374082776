import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { IssueNotesComponent } from '../../components/issue-notes/issue-notes.component';
import { IssueViewEditComponent } from '../../components/issue-view-edit/issue-view-edit.component';
import { LogService } from '../../services/utils/log.service';
import { IIssueModel } from '../../services/api/issue/issue-model.interface';
import { IUiSelectItem } from '../../components/atomic-ui-components/select/ui-select-item.interface';
import { IssueClientService } from '../../services/api/issue/issue-client.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'issue-detail',
  standalone: true,
  imports: [
    IssueDetailComponent,
    LayoutMainComponent,
    IssueNotesComponent,
    IssueViewEditComponent,
    RouterLink,
  ],
  templateUrl: './issue-detail.component.html',
  styleUrl: './issue-detail.component.css',
})
export class IssueDetailComponent {
  issue: IIssueModel = {} as IIssueModel;
  userSelectItems = [] as IUiSelectItem[];
  flatSelectItems: IUiSelectItem[] = [];
  statusSelectItems: IUiSelectItem[] = [];
  mode: 'view' | 'edit' = 'view';

  constructor(
    private log: LogService,
    private issueService: IssueClientService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    // Betöltjük az issue-t az URL-ből nyert id alapján
    this.route.params.subscribe((params) => {
      this.issueService.getIssue(params['id']).subscribe({
        next: (issue) => {
          this.issue = issue;
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
      // megnézzük van-e edit paraméter, ha igen, akkor edit módban jelenítjük meg
      if (this.route.snapshot.url.join('/') === 'issues/' + params['id'] + '/edit') {
        this.mode = 'edit';
      }
    });
  }
}
