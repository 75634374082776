import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'confirmation-dialog',
  standalone: true,
  imports: [UiButtonComponent],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  // Ez alapján színezzük a 'proceed' gombot pozitív (pl. zöld, vagy brand kék) vagy negatív (pl. piros) színűre
  @Input() type: 'positive' | 'negative' = 'positive';
  @Input() hsId: string = 'hs-confirmation-dialog';
  @Input() title: string = 'Confirmation';
  @Input() message: string = 'Are you sure you want to proceed?';
  @Input() confirmButtonText: string = 'Yes, proceed';
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  // A confirmation-dialog egyéb tulajdonságai és metódusai

  onConfirm(): void {
    this.confirmed.emit();
  }

  onCancel(): void {
    this.canceled.emit();
  }
}
