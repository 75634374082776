import { Component, Renderer2, ElementRef } from '@angular/core';
import { UiButtonComponent } from '../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'billing-items',
  standalone: true,
  imports: [UiButtonComponent],
  templateUrl: './billing-items.component.html',
  styleUrl: './billing-items.component.css'
})
export class BillingItemsComponent {
  formCounter: number = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private elementRef: ElementRef) {
  }


  addCodeMeters(event: Event) {
    let elementId: string = (event.target as Element).id;
    let sourcedata = this.el.nativeElement.querySelector('#' + elementId).getAttribute('data-source');
    let sourceHtml = this.el.nativeElement.querySelector(sourcedata).innerHTML;
    let container = this.el.nativeElement.querySelector('#hs-container');

    sourceHtml = sourceHtml.replaceAll('###', this.formCounter);
    sourceHtml = sourceHtml.replaceAll('@@@', '');
    container.innerHTML = container.innerHTML + sourceHtml;

    let element = this.elementRef.nativeElement.querySelector('.xButton');

    let button = document.querySelector('.xButton') as HTMLInputElement | null;

    document.querySelectorAll('.xButton').forEach(item => {
      item.addEventListener('click', event => {
        let rowNeumber = item.getAttribute('data-rowcounter');
        let rowItem = document.querySelector('.form-row-'+ rowNeumber);
        rowItem?.remove();
      })
    })


    // element.addEventListener('click', this.deleteRow());
    /* element.addEventListener('click', (thisHTML: HTMLElement, event: MouseEvent) => {

    }); */

    this.formCounter++;

  }

  deleteRow() {
    alert('dd');
    // let elementId: string = (event.target as Element).id;

  }
}
