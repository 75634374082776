<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5"
      >
        Long-term leasing
      </span>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Tenants
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!--  **** TENANTS MAIN **** -->
  <div class="mb-4">
    <div class="bg-white border shadow-sm rounded-xl p-4">
      <div class="flex justify-between mb-4">
        <div>
          <h2 class="font-bold text-lg">Tenants</h2>
          <div>Long-term leasing tenants</div>
        </div>
        <!-- Upload Button -->
        <div class="flex items-center gap-x-2">
          <ui-button
            data-hs-overlay="#hs-tenant-editor-modal"
            (click)="handleCreate()"
            size="xsmall"
            iconSrc="assets/img/arrow-up-tray.svg"
            iconClass="invert  saturate-0  brightness-0 contrast-125"
            >New Tenant</ui-button
          >
        </div>
      </div>

      <div class="overflow-x-auto">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Name
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Company Name
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Tax Number
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 text-right">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown relative w-48 ml-auto">
                  <div
                    class="text-gray-500 uppercase text-xs text-right items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Actions
                  </div>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div
                class="w-full flex justify-center content-center mt-16 min-h-28"
              >
                <img
                  src="assets/img/loading.gif"
                  alt="loading"
                  class="h-8 w-auto"
                />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>
          } @else { @for (item of tenants; track item.id) {
          <tenant-table-row
            (onDelete)="prepareDelete($event)"
            (onEdit)="handleEdit($event)"
            [pitem]="item"
            class="divide-y divide-gray-200"
          >
          </tenant-table-row>
          } @empty {

          <tr>
            <td class="text-center" colspan="100">
              <!--  EMPTY STATE  -->

              <table-empty-state
                noDataTitle="There are no tenants"
              ></table-empty-state>
              <!--  END EMPTY STATE  -->
            </td>
          </tr>

          } }
        </table>
      </div>
      <!-- Footer -->
      <div class="mt-4">
        <table-pager
          [totalItems]="totalItems"
          [isLoading]="isPagerLoading"
          (onPageChange)="handlePagination($event)"
        ></table-pager>
      </div>
      <!-- End Footer -->
    </div>
  </div>
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- Tenant Editor Modal -->
  <tenant-editor
    [tenant]="selectedTenant"
    [mode]="editorMode"
    (onSave)="refresh()"
    (onCreate)="refresh()"
  ></tenant-editor>
  <!-- End Tenant Editor Modal -->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-delete-tenant-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete Tenant {{
      selectedTenant.name
    }}? This action cannot be undone."
    confirmButtonText="Yes, Delete"
    (confirmed)="performDelete()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
</layout-main>
