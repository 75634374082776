import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UiButtonComponent } from "../../components/atomic-ui-components/button/ui-button.component";
import { LayoutMainComponent } from "../../components/layout-main/layout-main.component";
import { ArrivalsComponent } from "../../components/tables/arrivals/arrivals.component";
import { DeparturesComponent } from "../../components/tables/departures/departures.component";


@Component({
  selector: 'arrivals-departures',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    ArrivalsComponent,
    DeparturesComponent,
    RouterLink,
  ],
  templateUrl: './arrivals-departures.component.html',
  styleUrl: './arrivals-departures.component.css',
})
export class ArrivalsDeparturesComponent {}
