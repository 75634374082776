import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { FlatClientService } from '../../../../services/api/flat/flat-client.service';
import { IFlatTypeModel } from '../../../../services/api/flat-type/flat-type-model.interface';
import { LogService } from '../../../../services/utils/log.service';


@Component({
  selector: 'flat-type-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    UiButtonComponent,
  ],
  templateUrl: './flat-type-table-row.component.html',
  styleUrl: './flat-type-table-row.component.css'
})
export class FlatTypeTableRowComponent {
  @Input() pitem: IFlatTypeModel = {} as IFlatTypeModel;
  @Output() onDelete = new EventEmitter<IFlatTypeModel>();
  @Output() onEdit = new EventEmitter<IFlatTypeModel>();
  @Output() onView = new EventEmitter<IFlatTypeModel>();

  constructor(
    private flatTypeService: FlatClientService,
    private log: LogService
  ) {}

  handleEdit(pitem: IFlatTypeModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IFlatTypeModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IFlatTypeModel) {
    this.onDelete.emit(pitem);
  }

}
