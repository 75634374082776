import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UnitTableComponent } from '../../components/tables/unit-table/unit-table.component';

@Component({
  selector: 'unit',
  standalone: true,
  imports: [LayoutMainComponent, UnitTableComponent],
  templateUrl: './unit.component.html',
  styleUrl: './unit.component.css',
})
export class UnitComponent {}
