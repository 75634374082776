import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe } from '@angular/common';
import { ICheckinOverviewItem } from '../checkin-overview-item.interface';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'checkins-overview-row',
  standalone: true,
  imports: [RouterLink, UiBadgeComponent, UiButtonComponent, DatePipe],
  templateUrl: './checkins-overview-row.component.html',
  styleUrl: './checkins-overview-row.component.css',
})
export class CheckinsOverviewRowComponent {
  @Input() pitem: ICheckinOverviewItem = {} as ICheckinOverviewItem;
  @Output() onSendEmail = new EventEmitter<number>();
  @Output() onSendSms = new EventEmitter<number>();
  @Output() onGenerateInvoice = new EventEmitter<number>();

  constructor(private router: Router) {}

  //elnavigál a foglaláshoz bookings/id
  navigateToBooking(id: number) {
    this.router.navigate(['/bookings', id]);
  }

  sendEmail(id: number) {
    this.onSendEmail.emit(id);
  }

  sendSms(id: number) {
    this.onSendSms.emit(id);
  }

  generateInvoice(id: number) {
    this.onGenerateInvoice.emit(id);
  }


}
