import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { BookingTableRowComponent } from '../../components/tables/booking-table-row/booking-table-row.component';
import { IBookingModel } from '../../services/api/booking/booking-model.interface';
import { BookingClientService, IBookingQueryParams } from '../../services/api/booking/booking-client.service';
import { LogService } from '../../services/utils/log.service';
import { UiTablePagerComponent } from '../../components/atomic-ui-components/table-pager/table-pager.component';
import { BookingCreatorComponent } from '../../components/modal-editors/booking-creator/booking-creator.component';
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { Router, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { IApiQueryOrdering } from '../../services/api/api.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'booking',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    BookingTableRowComponent,
    UiTablePagerComponent,
    BookingCreatorComponent,
    ConfirmationDialogComponent,
    RouterLink,
    TableEmptyStateComponent,
    CommonModule,
  ],
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.css',
})
export class BookingComponent {
  bookings: IBookingModel[] = [];
  selectedBooking!: IBookingModel;
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;
  currentPage = 1;
  ordering: IApiQueryOrdering = {
    field: 'end_date',
    direction: 'desc',
  };
  queryParams: IBookingQueryParams = {
    order: this.ordering,
  }

  constructor(
    private bookingService: BookingClientService,
    private log: LogService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  handlePagination(page: number) {
    this.currentPage = page;
    this.refresh();
  }

  ngOnInit() {
    this.refresh();
  }

  prepareDelete(booking: IBookingModel) {
    this.selectedBooking = booking;
  }

  performDelete() {
    this.bookingService.deleteBooking(this.selectedBooking.id).subscribe({
      next: () => {
        this.toastr.success('Booking deleted');
        this.refresh();
      },
      error: (err) => {
        this.toastr.error('Error deleting booking');
        this.log.error(err);
      },
    });
  }

  handleCreate(bookingId: number) {
    // navigáljunk a szerkesztőre /bookings/:id
    this.router.navigate(['/bookings', bookingId]);
  }

  refresh() {
    this.isLoading = true;
    this.bookingService
      .getBookings(this.queryParams, this.currentPage)
      .subscribe((data) => {
        this.bookings = data;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.bookingService.getTotalItems(this.queryParams).subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }

  reorder(field: string, direction: 'asc' | 'desc') {
    this.log.debug('Reordering by', field, direction);
    this.ordering.field = field;
    this.ordering.direction = direction;
    this.queryParams.order = this.ordering;
    this.refresh();
  }
}
