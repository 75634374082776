<div
  id="hs-cleaning-request-editor-modal"
  class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
>
  <div
    class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center"
  >
    <div
      class="w-full max-h-full overflow-hidden flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto"
    >
      <div class="flex justify-between items-center py-3 px-4 border-b">
        <h3 class="font-bold text-gray-800">
          {{
            mode === "create"
              ? "Add New Cleaning Request"
              : mode === "edit"
              ? "Edit Cleaning Request"
              : "View Cleaning Request"
          }}
        </h3>
        <button
          type="button"
          class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-cleaning-request-editor-modal"
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>
        </button>
      </div>
      <div class="overflow-y-auto p-4">
        <div class="space-y-4">
          <form
            id="cleaning-request-form"
            [formGroup]="cleaningRequestForm"
            (ngSubmit)="saveCleaningRequest()"
          >
            <div class="py-2 sm:py-4 sm:px-3">
              <div class="space-y-5">
                <!-- Grid StartDate-->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningRequestStartDate"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Start Date
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningRequest.start_date }}
                    </p>
                    } @else {
                    <input
                      id="cleaningRequestStartDate"
                      type="date"
                      class="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      placeholder=""
                      [value]="cleaningRequest.start_date"
                      formControlName="start_date"
                    />
                    }
                  </div>
                </div>
                <!-- End Grid -->
                <!-- Flat Grid-->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningRequestFlat"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Flat
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{
                        cleaningRequest.flat?.flat_number
                          ? cleaningRequest.flat?.flat_number
                          : ""
                      }}{{ " " + cleaningRequest.flat?.name }}
                    </p>
                    } @else {
                 <!--   <ui-select
                      selectId="cleaningRequestFlat"
                      [selectItems]="flatSelectItems"
                      [selectedOptionValue]="cleaningRequest.flat?.['@id']"
                      formControlName="flat"
                    ></ui-select>  -->
                    <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    selectId="cleaningRequestFlat"
                    [items]="flatSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="flat"
                  />                     
                    }
                  </div>
                </div>
                <!-- End Flat Grid -->

                <!-- Cleaning Package Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningRequestPackage"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Cleaning Package
                    </label>
                  </div>
                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      {{ cleaningRequest.cleaningPackage?.name }}
                    </p>
                    } @else {
                <!--    <ui-select
                      selectId="cleaningRequestPackage"
                      [selectItems]="packageSelectItems"
                      [selectedOptionValue]="cleaningRequest.cleaningPackage?.['@id']"
                      formControlName="cleaningPackage"
                    ></ui-select>  -->
                    <ng-select
                    class="w-full max-w-lg inline-block mb-4"
                    selectId="cleaningRequestPackage"
                    [items]="packageSelectItems"
                    bindLabel="title"
                    bindValue="val"
                    formControlName="cleaningPackage"
                  />                           
                    
                    }
                  </div>
                </div>
                <!-- End Cleaning Package Grid -->

                <!-- Paid Grid -->
                <div
                  class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5"
                >
                  <div class="sm:col-span-4">
                    <label
                      for="cleaningRequestPaid"
                      class="sm:mt-2.5 inline-block text-sm text-gray-500"
                    >
                      Paid
                    </label>
                  </div>
                  <!-- End Col -->

                  <div class="sm:col-span-8">
                    @if (mode === 'view') {
                    <p class="py-2 px-3 block w-full font-semibold">
                      <ui-switch
                      switchId="cleaningRequestPaid"
                      [disabled]=true
                      [checked]="!!cleaningRequest.paid"
                      formControlName="paid"
                    ></ui-switch>
                    </p>
                    } @else {
                    <ui-switch
                      switchId="cleaningRequestPaid"
                      [checked]="!!cleaningRequest.paid"
                      formControlName="paid"
                    ></ui-switch>

                    }
                  </div>
                  <!-- End Col -->
                </div>
                <!-- Paid Grid -->
              </div>
            </div>
          </form>
        </div>
        <!-- End Card -->
      </div>
      <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-overlay="#hs-cleaning-request-editor-modal"
        >
          Close
        </button>
        @if (mode != 'view') {
        <button
          type="submit"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-button text-brand-button-text hover:bg-brand-button-active disabled:opacity-50 disabled:pointer-events-none"
          [disabled]="cleaningRequestForm.invalid"
          form="cleaning-request-form"
          data-hs-overlay="#hs-cleaning-request-editor-modal"
        >
          {{ mode === "create" ? "Create" : "Save" }}
        </button>
        }
      </div>
    </div>
  </div>
</div>
