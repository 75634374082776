import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { FlatTypeTableComponent } from '../../components/tables/flat-type-table/flat-type-table.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'flat-type',
  standalone: true,
  imports: [LayoutMainComponent, FlatTypeTableComponent, RouterLink],
  templateUrl: './flat-type.component.html',
  styleUrl: './flat-type.component.css',
})
export class FlatTypeComponent {}
