import { Component, ViewChild } from '@angular/core';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { LogService } from '../../../services/utils/log.service';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';

import { IMeterModel } from '../../../services/api/meter/meter-model.interface';
import { MeterClientService } from '../../../services/api/meter/meter-client.service';
import { MeterEditorComponent } from '../../modal-editors/meter-editor/meter-editor.component';
import { ToastrService } from 'ngx-toastr';
import { MeterTableRowComponent } from './meter-table-row/meter-table-row.component';
import { ReadingClientService } from '../../../services/api/reading/reading-client.service';

@Component({
  selector: 'meter-table',
  standalone: true,
  imports: [
    UiButtonComponent,
    ReactiveFormsModule,
    UiTablePagerComponent,
    TableEmptyStateComponent,
    ConfirmationDialogComponent,
    MeterTableRowComponent,
    MeterEditorComponent,
  ],
  templateUrl: './meter-table.component.html',
  styleUrl: './meter-table.component.css',
})
export class MeterTableComponent {
  @ViewChild(MeterEditorComponent)
  editor!: MeterEditorComponent;
  selectedMeter!: IMeterModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  meters: IMeterModel[] = [];
  lastReadings: Map<number, string> = new Map();
  lastReadingTimes: Map<number, string> = new Map();
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private log: LogService,
    private meterClientService: MeterClientService,
    private readingClient: ReadingClientService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: IMeterModel) {
    this.selectedMeter = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IMeterModel) {
    this.selectedMeter = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IMeterModel) {
    this.selectedMeter = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.meterClientService.deleteItem(this.selectedMeter.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('Meter deleted');
        this.meters = this.meters.filter((pr) => pr.id !== this.selectedMeter.id);
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.meters.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Meter deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting Meter');
        this.log.error('Error deleting Meter', err);
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedMeter = {
      '@id': '',
      id: 0,
      value: 0,
      timestamp: '',
      sampling_type: '',
      photo: '',
    } as IMeterModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.meterClientService.getItems(page).subscribe({
      next: (meter) => {
        this.log.debug('Meters fetched', meter);
        this.meters = meter;
        this.getLastReadings();
        setTimeout(() => {
          this.isLoading = false;
        }, 100);

        setTimeout(() => {
          this.log.info('Meter table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching meters', err);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.meterClientService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }

  getLastReadings() {
    // Lekérjük a mérők utolsó leolvasásait
    this.lastReadings.clear();
    for (let i = 0; i < this.meters.length; i++) {
      const meter = this.meters[i];
      this.readingClient.getLastReading(meter.id).subscribe({
        next: (reading) => {
          if (reading.reading) {
            this.lastReadings.set(meter.id, reading.reading);
            this.lastReadingTimes.set(meter.id, reading.read_at ?? 'N/A');
          } else {
            this.log.debug('No reading found for meter', meter);
            this.lastReadings.set(meter.id, 'N/A');
            this.lastReadingTimes.set(meter.id, 'N/A');
          }
        },
        error: (err) => {
          console.error('Could not get last reading for meter', meter, err);
        },
      });
    }
  }
}
