<layout-main id="content" role="main" class="text-gray-800 ">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5  xs:mt-4 sm:mt-0  xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 "
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible cursor-default text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm  cursor-default focus:outline-none focus:text-blue-600  leading-5 font-semibold"

      >
        Issues
    </span>
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <issues-table></issues-table>
</layout-main>
