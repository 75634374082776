import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe } from '@angular/common';
import { ICheckoutOverviewItem } from '../checkout-overview-item.interface';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';

@Component({
  selector: 'checkouts-overview-row',
  standalone: true,
  imports: [RouterLink, UiBadgeComponent, DatePipe, UiButtonComponent],
  templateUrl: './checkouts-overview-row.component.html',
  styleUrl: './checkouts-overview-row.component.css',
})
export class CheckoutsOverviewRowComponent {
  @Input() pitem: ICheckoutOverviewItem = {} as ICheckoutOverviewItem;
  @Output() manualCheckout: EventEmitter<number> = new EventEmitter<number>();
  @Output() cancelCheckout: EventEmitter<number> = new EventEmitter<number>();

  handleManualCheckout() {
    this.manualCheckout.emit(this.pitem.id);
  }

  handleCancelCheckout() {
    this.cancelCheckout.emit(this.pitem.id);
  }
}
