import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'table-pager',
  standalone: true,
  imports: [],
  templateUrl: './table-pager.component.html',
  styleUrl: './table-pager.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTablePagerComponent {
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 30;
  @Input() isLoading: boolean = false;
  @Output() onPageChange = new EventEmitter<number>();
  currentPage: number = 1;
  maxPage: number = 1;

  private countMaxPage() {
    this.maxPage = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  ngOnInit() {
    this.countMaxPage();
  }

  ngOnChanges() {
    this.countMaxPage();
  }

  handlePageNext() {
    if (this.currentPage < this.maxPage) {
      this.currentPage += 1;
      this.onPageChange.emit(this.currentPage);
    }
  }
  handlePagePrev() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.onPageChange.emit(this.currentPage);
    }
  }
  handlePageStart() {
    if (this.currentPage > 1) {
      this.currentPage = 1;
      this.onPageChange.emit(this.currentPage);
    }
  }
  handlePageEnd() {
    if (this.currentPage < this.maxPage) {
      this.currentPage = this.maxPage;
      this.onPageChange.emit(this.currentPage);
    }
  }  
}
