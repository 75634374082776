import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import {
  API_CALL_RETRY_COUNT,
  API_ENDPOINTS,
  ApiService,
} from '../api.service';
import {
  retry,
  catchError,
  Observable,
  map,
  throwError,
  forkJoin,
  switchMap,
} from 'rxjs';
import { IInventoryItemModel } from './inventory-item-model.interface';

@Injectable({
  providedIn: 'root',
})
export class InventoryItemClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Inventory elemek lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A inventory elemek lekért oldala, nyers válaszban
   */
  private fetchInventoryItems(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.inventoryItems), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a inventory elemek első oldalát, hogy megtudja az összes inventory elem számát
   * @returns Az összes inventory elem számossága
   **/
  getTotalItems(): Observable<number> {
    if (!this.totalItems) {
      return this.fetchInventoryItems().pipe(
        map((response: any) => {
          this.totalItems = response['hydra:totalItems'];
          return this.totalItems;
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Az összes inventory elem lekérése
   * @returns Az összes inventory elem adatai
   */
  getAllInventoryItems(): Observable<IInventoryItemModel[]> {
    // megtudjuk hány inventoryItem van összesen
    return this.getTotalItems().pipe(
      switchMap((totalItems) => {
        // kiszámoljuk hány oldal van
        let pages = Math.ceil(totalItems / 30);
        let requests = [];
        // minden oldalra kérünk egy-egy lekérést
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getInventoryItems(i));
        }

        // visszaadjuk a inventory elemekat
        return forkJoin(requests).pipe(
          map((results) => {
            let inventoryItems: IInventoryItemModel[] = [];
            for (let result of results) {
              inventoryItems.push(...result);
            }
            return inventoryItems;
          })
        );
      })
    );
  }

  /**
   * Inventory elemek lekérése
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A inventory elemek listája
   */
  getInventoryItems(page?: number): Observable<IInventoryItemModel[]> {
    return this.fetchInventoryItems(page).pipe(
      map((response: any) => {
        return response['hydra:member'];
      })
    );
  }

  /**
   * Inventory elem lekérése azonosító alapján
   * @param id A inventory elem azonosítója
   * @returns A inventory elem adatai
   */
  getInventoryItem(id: number): Observable<IInventoryItemModel> {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.inventoryItem) + id)
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: any) => {
          return response['hydra:member'];
        })
      );
  }

  /**
   * Inventory elem lekérése IRI alapján
   * @param iri A inventory elem IRI-ja
   * @returns A inventory elem adatai
   */
  getInventoryItemByIri(iri: string): Observable<IInventoryItemModel> {
    return this.http.get(this.apiService.getBaseUrl() + iri).pipe(
      retry(API_CALL_RETRY_COUNT),
      catchError(this.handleError),
      map((response: any) => {
        return response as IInventoryItemModel;
      })
    );
  }

  /**
   * Egy inventory elem törlése
   * @param id A törlendő inventory elem azonosítója
   * @returns A törlés eredménye
   */
  deleteInventoryItem(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.inventoryItem) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy inventory elem létrehozása
   * @param inventoryItem Az új inventory elem adatai
   * @returns Az új inventory elem adatai
   */
  createInventoryItem(
    inventoryItem: IInventoryItemModel
  ): Observable<IInventoryItemModel> {
    const headers = { 'Content-Type': 'application/ld+json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.inventoryItems),
        inventoryItem,
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IInventoryItemModel)
      );
  }

  /**
   * Feltölti a fájlt az inventory elemhez. Multipart upload a "uploadable_file" mezőbe
   * @param inventoryItemId Az inventory elem azonosítója
   * @param file A feltöltendő fájl
   * @returns A feltöltés eredménye, IInventoryItemModel, amiben megtalálható a file neve
   */
  uploadInventoryItemFile(
    inventoryItemId: number,
    file: File
  ): Observable<IInventoryItemModel> {
    const formData = new FormData();
    formData.append('uploadable_file', file);
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.inventoryItem) +
          inventoryItemId +
          '/upload',
        formData
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IInventoryItemModel)
      );
  }

  /**
   * Egy inventory elem módosítása
   * @param inventoryItem Az módosítandó inventory elem adatai
   * @returns Az módosított inventory elem adatai
   */
  updateInventoryItem(
    inventoryItem: IInventoryItemModel
  ): Observable<IInventoryItemModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.inventoryItem) +
          inventoryItem.id,
        inventoryItem,
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as IInventoryItemModel)
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError(error: HttpErrorResponse) {
    this.log.error(
      'InventoryItemClientService error',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform inventoryItem operation')
    );
  }
}
