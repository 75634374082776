import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { IBookingModel } from './booking-model.interface';
import { format, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class BookingTransformService {
  constructor(private log: LogService) {}

  transformBooking(booking: any): IBookingModel {
    try {
      if (booking.start_date !== undefined) {
        booking.start_date = format(
          parse(booking.start_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: start_date ', error);
    }
    try {
      if (booking.end_date !== undefined) {
        booking.end_date = format(
          parse(booking.end_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: end_date ', error);
    }
    try {
      if (booking.created_at !== undefined) {
        booking.created_at = format(
          parse(booking.created_at, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: created_at ', error);
    }
    try {
      if (booking.updated_at !== undefined) {
        booking.updated_at = format(
          parse(booking.updated_at, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: updated_at ', error);
    }
    try {
      if (booking.checkin_time !== undefined) {
        booking.checkin_time = format(
          parse(booking.checkin_time, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: checkin_time ', error);
    }
    try {
      if (booking.checkout_time !== undefined) {
        booking.checkout_time = format(
          parse(booking.checkout_time, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd HH:mm:ss'
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: checkout_time ', error);
    }
    return booking;
  }

  serializeBooking(booking: IBookingModel): any {
    const serializedBooking: any = { ...booking };

    // Dátum mezők átalakítása ISO stringgé
    try {
      if (serializedBooking.start_date !== undefined) {
        serializedBooking.start_date = format(
          parse(serializedBooking.start_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: start_date ', error);
    }
    try {
      if (serializedBooking.end_date !== undefined) {
        serializedBooking.end_date = format(
          parse(serializedBooking.end_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: end_date ', error);
    }
    try {
      if (serializedBooking.checkin_time !== undefined) {
        serializedBooking.checkin_time = format(
          parse(
            serializedBooking.checkin_time,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          ),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('BookingTransformService: checkin_time ', error);
    }
    try {
      if (serializedBooking.checkout_time !== undefined) {
        if (serializedBooking.checkout_time == '') {
          serializedBooking.checkout_time = null;
        } else {
          serializedBooking.checkout_time = format(
            parse(
              serializedBooking.checkout_time,
              'yyyy-MM-dd HH:mm:ss',
              new Date()
            ),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          );
        }
      }
    } catch (error) {
      this.log.error('BookingTransformService: checkout_time ', error);
    }
    try {
      if (serializedBooking.checkoutTime !== undefined) {
        if (serializedBooking.checkoutTime == '') {
          serializedBooking.checkoutTime = null;
        } else {
          serializedBooking.checkoutTime = format(
            parse(
              serializedBooking.checkoutTime,
              'yyyy-MM-dd HH:mm:ss',
              new Date()
            ),
            "yyyy-MM-dd'T'HH:mm:ssxxx"
          );
        }
      }
    } catch (error) {
      this.log.error('BookingTransformService: checkoutTime ', error);
    }

    // Többi mező stringgé alakítása
    for (const key in serializedBooking) {
      if (
        typeof serializedBooking[key] !== 'boolean' &&
        key !== 'flats' &&
        key !== 'adult_occupants' &&
        key !== 'child_occupants' &&
        key !== 'number_of_rooms' &&
        serializedBooking[key] !== null
      ) {
        serializedBooking[key] = String(serializedBooking[key]);
      }
    }
    return serializedBooking;
  }
}
