import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { IIssueStatusModel } from '../../../services/api/issue-status/issue-status-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { IssueStatusClientService } from '../../../services/api/issue-status/issue-status-client.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'issue-status-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './issue-status-editor.component.html',
  styleUrl: './issue-status-editor.component.css'
})
export class IssueStatusEditorComponent {
  @Input() issueStatus!: IIssueStatusModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  issueStatusForm: FormGroup = this.fb.group({
    name: [''],
    description: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private issueStatusService: IssueStatusClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('issueStatusEditor Changed', this.issueStatus);
    this.issueStatusForm.reset();
    if (this.mode === 'view') {
      this.issueStatusForm.disable();
    } else {
      this.issueStatusForm.enable();
    }
    if (this.mode === 'edit') {
      this.issueStatusForm.patchValue(this.issueStatus);
    }
   }

   saveIssueStatus() {
    this.log.info('Saving Issue Status');
    if (this.issueStatusForm.valid && this.mode !== 'view') {
      const updatedUntypedIssueStatus: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.issueStatusForm.controls).forEach((key) => {
        const control = this.issueStatusForm.get(key);
        if (control?.dirty) {
          updatedUntypedIssueStatus[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedIssueStatus).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedIssueStatus =
        updatedUntypedIssueStatus as IIssueStatusModel;
      this.log.debug('Issue Status request:', updatedIssueStatus);

      if (this.mode === 'edit') {
        updatedIssueStatus.id = this.issueStatus.id;
        this.issueStatusService
          .updateItem(updatedIssueStatus)
          .subscribe({
            next: (data) => {
              this.toastr.success('Issue Status updated');
              this.log.debug('Issue Status updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Issue Status');
              this.log.error('Error updating Issue Status:', err);
            },
          });
      } else {
        this.issueStatusService
          .createItem(updatedIssueStatus)
          .subscribe({
            next: (data) => {
              this.toastr.success('Issue Status created');
              this.log.debug('Issue Status created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Issue Status ');
              this.log.error('Error creating Issue Status :', err);
            },
          });
      }
    }
  }

}
