import { Component, ViewChild } from '@angular/core';
import { CleaningOverviewTableRowComponent } from '../cleaning-overview-table/cleaning-overview-table-row/cleaning-overview-table-row.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ICleaningTypeModel } from '../../../services/api/cleaning-type/cleaning-type-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { CleaningTypeEditorComponent } from '../../modal-editors/cleaning-type-editor/cleaning-type-editor.component';
import { CleaningTypeTableRowComponent } from './cleaning-type-table-row/cleaning-type-table-row.component';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { CleaningTypeClientService } from '../../../services/api/cleaning-type/cleaning-type-client.service';

@Component({
  selector: 'cleaning-type-table',
  standalone: true,
  imports: [
    CleaningOverviewTableRowComponent,
    CleaningTypeEditorComponent,
    CleaningTypeTableRowComponent,
    UiButtonComponent,
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './cleaning-type-table.component.html',
  styleUrl: './cleaning-type-table.component.css',
})
export class CleaningTypeTableComponent {
  @ViewChild(CleaningTypeEditorComponent)
  editor!: CleaningTypeEditorComponent;
  selectedCleaningType!: ICleaningTypeModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  cleaningTypes: ICleaningTypeModel[] = [];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private cleaningTypeClientService: CleaningTypeClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: ICleaningTypeModel) {
    this.selectedCleaningType = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: ICleaningTypeModel) {
    this.selectedCleaningType = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ICleaningTypeModel) {
    this.selectedCleaningType = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.cleaningTypeClientService
      .deleteItem(this.selectedCleaningType.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.log.info('Cleaning type deleted');
          this.cleaningTypes = this.cleaningTypes.filter(
            (pr) => pr.id !== this.selectedCleaningType.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.cleaningTypes.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Cleaning type deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting cleaning type');
          this.log.error('Error deleting cleaning type', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedCleaningType = {
      '@id': '',
      id: 0,
      name: '',
      description: '',
    } as ICleaningTypeModel;
  }

  refresh(page?: number) {
    this.isLoading = true;
    this.cleaningTypeClientService.getItems(page).subscribe({
      next: (cleaningTypes: ICleaningTypeModel[]) => {
        this.log.debug('CleaningType requests fetched', cleaningTypes);
        this.cleaningTypes = cleaningTypes;
        this.isLoading = false;

        setTimeout(() => {
          this.log.info('CleaningType table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching cleaning type', err);
        this.isLoading = false;
      },
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.cleaningTypeClientService
      .getTotalItems()
      .subscribe((count) => {
        this.totalItems = count;
        this.isPagerLoading = false;
      });
  }
}
