import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { BillingItemsComponent } from '../../components/billing-items/billing-items.component';
import { PropertyAndContractBoxComponent } from '../../components/property-and-contract-box/property-and-contract-box.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { LogService } from '../../services/utils/log.service';
import { FlatClientService } from '../../services/api/flat/flat-client.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { ContractClientService } from '../../services/api/contract/contract-client.service';
import { TenantTableRowComponent } from '../../components/tables/tenant-table-row/tenant-table-row.component';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { BillingComponent } from '../../components/billing/billing.component';
import { TenantEditorComponent } from '../../components/modal-editors/tenant-editor/tenant-editor.component';
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { ITenantModel } from '../../services/api/tenant/tenant-model.interface';
import { ToastrService } from 'ngx-toastr';
import { IBillingModel } from '../../services/api/billing/billing-model.interface';
import { BillingClientService } from '../../services/api/billing/billing-client.service';
import { CommonModule } from '@angular/common';
import { MetersComponent } from '../../components/meters/meters.component';
import { ContractEditorComponent } from '../../components/modal-editors/contract-editor/contract-editor.component';
import { IssueClientService } from '../../services/api/issue/issue-client.service';
import { PropertyMetersEditorComponent } from '../../components/modal-editors/property-meters-editor/property-meters-editor.component';

@Component({
  selector: 'app-property-detail',
  standalone: true,
  templateUrl: './property-detail.component.html',
  styleUrl: './property-detail.component.css',
  imports: [
    BillingComponent,
    BillingItemsComponent,
    CommonModule,
    ConfirmationDialogComponent,
    ContractEditorComponent,
    LayoutMainComponent,
    MetersComponent,
    PropertyAndContractBoxComponent,
    PropertyMetersEditorComponent,
    RouterLink,
    TableEmptyStateComponent,
    TenantEditorComponent,
    TenantTableRowComponent,
    UiButtonComponent,
  ],
})
export class PropertyDetailComponent {
  /** Elérhető tabok
   * 1: Property info
   * 2: Tenants
   * 3: Billing
   * 4: Meters
   */
  selectedTabId: number = 1;
  flat!: IFlatModel;
  contract?: IContractModel;
  propertyTitle = '';
  isLoading = true;
  selectedTenant!: ITenantModel;
  selectedBillingId: number = 0;
  isBillingCreatorVisible = false;
  contractEditorMode: 'create' | 'edit' = 'create';
  issuesCount = 0;

  constructor(
    private log: LogService,
    private flatService: FlatClientService,
    private route: ActivatedRoute,
    private router: Router,
    private formatter: ModelFormatterService,
    private contractService: ContractClientService,
    private billingService: BillingClientService,
    private issueService: IssueClientService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  handleContractChange(contractItem: string) {
    this.log.debug('Contract ID received', contractItem);
    if (contractItem && this.flat.contracts) {
      this.contractService.getItemByIri(contractItem).subscribe({
        next: (contract) => {
          this.contract = contract;
          this.log.debug('Contract loaded', contract);
        },
        error: (err) => {
          this.log.error('Could not load contract', err);
        },
      });
    }
  }

  prepareTenantRemoval(pitem: ITenantModel) {
    this.log.debug('Preparing to delete tenant', pitem);
    this.selectedTenant = pitem;
  }

  performTenantRemoval() {
    // csinálunk egy tenant azonosító tömböt, amiben nincs benne a kiválasztott tenant
    const reducedTenants = this.contract?.tenants
      ?.filter((t) => t.id !== this.selectedTenant.id)
      .map((t) => t['@id']);
    // frissítjük a szerződést
    this.contractService
      .updateItem({
        id: this.contract?.id,
        tenants: reducedTenants,
      } as IContractModel)
      .subscribe({
        next: (data) => {
          this.log.debug('Tenant removed from contract', data);
          this.toastr.success('Tenant removed from contract');
          this.refresh();
        },
        error: (err) => {
          this.log.error('Error removing tenant from contract', err);
          this.toastr.error('Error removing tenant from contract');
        },
      });
  }

  handleTabChange(tabId: number) {
    this.selectedTabId = tabId;
  }

  handleTenantEdit(pitem: ITenantModel) {
    this.selectedTenant = pitem;
  }

  prepareBillingRemoval(billingId: number) {
    this.selectedBillingId = billingId;
  }

  performBillingRemoval() {
    this.billingService.deleteItem(this.selectedBillingId).subscribe({
      next: (reply) => {
        this.log.debug('Billing deleted', reply);
        this.toastr.success('Billing deleted');
        this.refresh();
      },
      error: (err) => {
        this.log.error('Error deleting billing', err);
        this.toastr.error('Error deleting billing');
      },
    });
  }

  prepareBillingCreation() {
    this.log.debug('Preparing to create billing');
    this.isBillingCreatorVisible = true;
  }

  cancelBillingCreation() {
    this.log.debug('Cancelling billing creation');
    this.isBillingCreatorVisible = false;
  }

  handleBillingCreation(billing: any) {
    billing.contract = this.contract?.['@id'];
    this.billingService.createItem(billing).subscribe({
      next: (reply: IBillingModel) => {
        this.toastr.success('Billing created');
        this.log.debug('Billing created:', billing);
        this.isBillingCreatorVisible = false;
        this.refresh();
      },
      error: (err) => {
        this.toastr.error('Error creating billing');
        this.log.error('Error creating billing:', err);
      },
    });
  }

  handleContractEdit() {
    this.contractEditorMode = 'edit';
  }

  handleContractCreation() {
    this.contractEditorMode = 'create';
  }

  refresh() {
    const tabToRemember = this.selectedTabId;
    this.selectedTenant = {
      id: 0,
      name: '',
      company_name: '',
      tax_number: '',
    } as ITenantModel;
    this.isLoading = true;
    //kiszedjük a lakás id-jét a paraméterek közül
    this.route.params.subscribe((params) => {
      let id = params['id'];
      //lekérjük a lakást
      this.flatService.getFlat(id).subscribe({
        next: (flat) => {
          this.flat = flat;
          this.propertyTitle = this.formatter.getFormattedFlat(
            this.flat,
            false
          );
          // tudjuk meg az issuek számát
          // TODO ez majd a nyitottakat számolja csak
          this.issueService.getTotalItems({ flatId: this.flat.id }).subscribe({
            next: (count) => {
              this.issuesCount = count;
            },
            error: (err) => {
              this.log.error('Error loading issues count', err);
            },
          });
          this.isLoading = false;
          this.log.debug('Flat loaded', flat);
          setTimeout(() => {
            window.HSStaticMethods.autoInit();
            this.selectedTabId = tabToRemember;
          }, 100);
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }
}
