import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

/**
 * Log service a különböző log szintek kezelésére.
 * Alapja gyakorlatilag: https://www.codemag.com/Article/1711021/Logging-in-Angular-Applications
 */

@Injectable({
  providedIn: 'root',
})
export class LogService {
  level: LogLevel = LogLevel.All;

  logLevelCSS = [
    'color:grey;', // All
    'color:brown;', // Debug
    'color:blue;', // Info
    'color:yellow; background-color: black;', // Warn
    'color:red;', // Error
    'color:black; background-color: red;', // Fatal
    'color:white;', // Off
  ];

  constructor() {
    this.level = environment.logLevel;
  }

  /**
   * Kiírja a naplóbejegyzést a konzolra a megadott szinten.
   * @param msg - A naplóbejegyzés üzenete.
   * @param level - A naplóbejegyzés szintje.
   * @param params - A console.log() további paraméterei.
   */
  private writeToLog(msg: string, level: LogLevel, ...params: any[]) {
    if (level >= this.level) {
      // Ha az üzenet objektum, akkor group-oljuk
      if (params[0].length > 0) {
        console.groupCollapsed(
          '%c' + LogLevel[level].charAt(0) + ': ' + msg,
          this.logLevelCSS[level]
        );
        params[0].forEach((param: any) => {
          if (param != undefined) {
            console.dir(param);
          }
        });
        console.groupEnd();
      } else {
        console.log(
          '%c' + LogLevel[level].charAt(0) + ': ' + msg + ' %c',
          this.logLevelCSS[level],
          params
        );
      }
    }
  }

  /**
   * Kiírja a hibakeresési szintű naplóbejegyzést a konzolra.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  /**
   * Kiírja az infó szintű naplóbejegyzést a konzolra.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  /**
   * Kiírja a figyelmeztetés szintű naplóbejegyzést a konzolra.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  /**
   * Kiírja a hiba szintű naplóbejegyzést a konzolra.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  /**
   * Kiírja a végzetes hiba szintű naplóbejegyzést a konzolra.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }
  /**
   * Kiírja a naplóbejegyzést a konzolra. Ez a legalacsonyabb szintű naplózás.
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.log() további paraméterei.
   */
  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  /**
   * Console.trace() wrapper
   * @param msg - A naplóbejegyzés üzenete.
   * @param optionalParams - A console.trace() további paraméterei.
   */
  trace(msg?: string, ...optionalParams: any[]) {
    console.trace(msg, optionalParams);
  }
}
