<tr>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.created_at | date : "yyyy-MM-dd" }} <br />
    {{ pitem.created_at | date : "HH:mm" }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.paymentProvider?.name }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-blue-600 align-middle font-bold cursor-pointer"
  >
    {{ pitem.payee_user?.name
    }}<span class="block text-blue-600 font-normal">{{
      pitem.payee_user?.email
    }}</span>
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-blue-600 align-middle font-bold cursor-pointer"
  >
    {{ pitem.payer_user?.name
    }}<span class="block text-blue-600 font-normal">{{
      pitem.payer_user?.email
    }}</span>
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 text-right whitespace-nowrap font-mono font-bold text-sm text-gray-800 align-middle"
  >
    {{ pitem.price | number : "1.2-2" | correctCurrencyFormat }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.currency?.code }}
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 text-left whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.payer_name }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    {{ pitem.payer_email }}
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-sm text-gray-800 align-middle"
  >
    <ui-switch
      [checked]="!!pitem.paid"
      size="x-small"
      [disabled]="true"
    ></ui-switch>
  </td>
  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-xs text-gray-600 font-mono align-middle"
  >
    <span class="inline-block bg-gray-100 rounded p-1">{{
      pitem.transaction_code
    }}</span>
  </td>

  <td
    class="px-4 py-3 ps-4 leading-4 whitespace-nowrap text-end text-sm font-medium align-middle"
  >
    <div class="inline-flex rounded-lg shadow-sm">
      <ui-button
      btnType="inline"
      (click)="handleView(pitem)"
      inlinePos="left"
      iconSrc="assets/img/eye.svg"
      data-hs-overlay="#hs-payment-transaction-editor-modal"
      > <span class="shrink-0 text-xs"> View </span>
    </ui-button>
    <ui-button
    btnType="inline"
    (click)="handleEdit(pitem)"

    iconSrc="assets/img/pencil.svg"
    data-hs-overlay="#hs-payment-transaction-editor-modal"
    > <span class="shrink-0 text-xs"> Edit </span>
  </ui-button>
  <ui-button
  btnType="inline"
  (click)="handleDelete(pitem)"
  inlinePos="right"

  iconSrc="assets/img/trash-2.svg"
  data-hs-overlay="#hs-delete-payment-transaction-confirmation-modal"
  > <span class="shrink-0 text-xs"> Delete </span>
</ui-button>

    </div>
  </td>
</tr>
