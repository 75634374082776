<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5"
      >
        Short-term rental
      </span>
      <span
        class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5 cursor-default"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm cursor-default font-semibold truncate leading-5"
      aria-current="page"
    >
      Booking
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!--  **** BOOKING MAIN **** -->
  <div class="mb-4">
    <div class="bg-white border shadow-sm sm:rounded-xl p-4">
      <div class="flex justify-between mb-4">
        <div>
          <h2 class="font-bold text-lg">Booking</h2>
          <div>Short-term rental bookings</div>
        </div>
        <!-- Upload Button -->
        <div class="flex items-center gap-x-2">
          <ui-button
            size="xsmall"
            iconSrc="assets/img/plus.svg"
            iconClass="invert  saturate-0  brightness-0 contrast-125"
            data-hs-overlay="#hs-booking-creation-modal"
            >Add Booking</ui-button
          >
        </div>
      </div>
      <!-- Filter Group -->
      <div class="flex justify-end mb-4 hidden">
        <!-- Search Input -->

        <div class="relative">
          <div
            class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5"
          >
            <img
              src="assets/img/search.svg"
              alt="search"
              class="flex-shrink-0 w-4 h-4 text-gray-500"
            />
          </div>
          <input
            type="text"
            class="py-[7px] px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
            placeholder="Search booking"
          />
        </div>
        <!-- End Search Input -->
        <!-- Filter Dropdown -->
        <div
          class="hs-dropdown relative inline-flex [--auto-close:inside] ml-4"
        >
          <!-- Filter Button -->
          <button
            id="hs-pro-dptfd"
            type="button"
            class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-gray-200 bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50"
          >
            <img
              src="assets/img/adjustments-horizontal.svg"
              alt="Filter"
              class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
            />

            Filter
            <span
              class="font-medium text-[10px] py-0.5 px-[5px] bg-gray-800 text-white leading-3 rounded-full"
            >
              5
            </span>
          </button>
          <!-- End Filter Button -->

          <!-- Dropdown -->
          <div
            class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-44 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
            aria-labelledby="hs-pro-dptfd"
          >
            <div class="p-1">
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/user-square.svg"
                  alt="Name"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Name
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/envelope.svg"
                  alt="Email addresses"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Email addresses
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/description.svg"
                  alt="Description"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Description
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/building-office.svg"
                  alt="Company"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Company
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/user-ID.svg"
                  alt="User ID"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                User ID
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/phone.svg"
                  alt="Phone numbers"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Phone numbers
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/map-pin.svg"
                  alt="Location"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />

                Location
              </a>
              <a
                class="w-full flex gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                href="#"
              >
                <img
                  src="assets/img/signed-up.svg"
                  alt="Signed up as"
                  class="flex-shrink-0 mt-0.5 w-3.5 h-3.5"
                />
                Signed up as
              </a>
            </div>
          </div>
          <!-- End Dropdown -->
        </div>
        <!-- End Filter Dropdown -->
      </div>
      <div class="overflow-x-auto pb-4">
        <!-- Table -->
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr class="border-t border-gray-200 divide-x divide-gray-200">
              <th
                scope="col"
                class="px-4 py-2 leading-4 text-xs text-gray-500 uppercase"
              >
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer leading-4"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between text-left w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Booking status
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Checked In
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4 sort">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 focus:outline-none focus:bg-gray-100"
                    [ngClass]="
                      ordering.field === 'start_date'
                        ? 'font-bold'
                        : 'font-normal'
                    "
                  >
                    Start Date
                    <img
                      [src]="
                        ordering.field === 'start_date'
                          ? ordering.direction === 'asc'
                            ? 'assets/img/up.svg'
                            : 'assets/img/down.svg'
                          : 'assets/img/up-down.svg'
                      "
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        (click)="reorder('start_date', 'asc')"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        (click)="reorder('start_date', 'desc')"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4 sort">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 focus:outline-none focus:bg-gray-100"
                    [ngClass]="
                      ordering.field === 'end_date'
                        ? 'font-bold'
                        : 'font-normal'
                    "
                  >
                    End Date
                    <img
                      [src]="
                        ordering.field === 'end_date'
                          ? ordering.direction === 'asc'
                            ? 'assets/img/up.svg'
                            : 'assets/img/down.svg'
                          : 'assets/img/up-down.svg'
                      "
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        (click)="reorder('end_date', 'asc')"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        (click)="reorder('end_date', 'desc')"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown leading-4 relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase leading-4 text-xs text-right justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Adult Occupants
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs w-full justify-between flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Foreign Reservation Code
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs w-full justify-between flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Name
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs justify-between text-right w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Rooms / Apartments
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase text-xs w-full flex justify-between items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Paid
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-2 leading-4">
                <!-- Sort Dropdown -->
                <div
                  class="hs-dropdown relative inline-flex w-full cursor-pointer"
                >
                  <button
                    id="hs-pro-dutnms"
                    type="button"
                    class="text-gray-500 uppercase justify-between text-xs w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    User
                    <img
                      src="assets/img/up-down.svg"
                      alt="Name"
                      class="flex-shrink-0 w-3.5 h-3.5"
                    />
                  </button>

                  <!-- Dropdown -->
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                    aria-labelledby="hs-pro-dutnms"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-up.svg"
                          alt="Sort ascending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />
                        Sort ascending
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                      >
                        <img
                          src="assets/img/arrow-down.svg"
                          alt="Sort descending"
                          class="flex-shrink-0 w-3.5 h-3.5"
                        />

                        Sort descending
                      </button>
                    </div>
                  </div>
                  <!-- End Dropdown -->
                </div>
                <!-- End Sort Dropdown -->
              </th>

              <th scope="col" class="px-4 py-1">
                <!-- Sort Dropdown -->
                <div class="hs-dropdown relative inline-flex w-56">
                  <div
                    class="text-gray-500 uppercase text-xs justify-end w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                  >
                    Actions
                  </div>
                </div>
                <!-- End Sort Dropdown -->
              </th>
            </tr>
          </thead>
          @if(isLoading){
          <tr>
            <td colspan="100">
              <div
                class="w-full flex justify-center content-center mt-16 min-h-28"
              >
                <img
                  src="assets/img/loading.gif"
                  alt="loading"
                  class="h-8 w-auto"
                />
                <div class="ml-4">Loading...</div>
              </div>
            </td>
          </tr>
          } @else { @for (item of bookings; track item.id) {
          <booking-table-row
            [pitem]="item"
            class="divide-y divide-gray-200"
            (onDelete)="prepareDelete($event)"
          >
          </booking-table-row>
          } @empty {
          <tr>
            <td class="text-center" colspan="100">
              <!--  EMPTY STATE  -->
              <table-empty-state
                noDataTitle="There are no bookings"
              ></table-empty-state>
              <!--  END EMPTY STATE  -->
            </td>
          </tr>
          } }
        </table>
      </div>

      <table-pager
        [totalItems]="totalItems"
        [isLoading]="isPagerLoading"
        (onPageChange)="handlePagination($event)"
      ></table-pager>

    </div>
  </div>
</layout-main>
<!-- ========== SECONDARY CONTENT ========== -->
<!--  Booking creation modal  -->
<booking-creator (onCreate)="handleCreate($event)"></booking-creator>
<!--  End Booking creation modal  -->
<!-- Delete File Modal -->
<confirmation-dialog
  type="negative"
  hsId="hs-delete-confirmation-modal"
  title="Delete confirmation"
  message="Are you sure you want to delete booking? This action cannot be undone."
  confirmButtonText="Yes, Delete"
  (confirmed)="performDelete()"
></confirmation-dialog>
<!-- End Delete File Modal -->
<!-- ========== END SECONDARY CONTENT ========== -->
