import { Component, ViewChild } from '@angular/core';
import { CleaningOverviewTableRowComponent } from './cleaning-overview-table-row/cleaning-overview-table-row.component';
import { ICleaningOverviewModel } from './cleaning-overview-model.interface';
import { CleaningScheduleClientService } from '../../../services/api/cleaning-schedule/cleaning-schedule-client.service';
import { LogService } from '../../../services/utils/log.service';
import { ICleaningScheduleModel } from '../../../services/api/cleaning-schedule/cleaning-schedule-model.interface';
import { CleaningScheduleNoteEditorComponent } from '../../modal-editors/cleaning-schedule-note-editor/cleaning-schedule-note-editor.component';
import { format } from 'date-fns';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';
import { CleaningStatsService } from '../../../services/overview-helpers/cleaning/cleaning-stats.service';


@Component({
  selector: 'cleaning-overview-table',
  standalone: true,
  imports: [
    CleaningOverviewTableRowComponent,
    CleaningScheduleNoteEditorComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './cleaning-overview-table.component.html',
  styleUrl: './cleaning-overview-table.component.css',
})
export class CleaningOverviewTableComponent {
  @ViewChild(CleaningScheduleNoteEditorComponent)
  editor!: CleaningScheduleNoteEditorComponent;
  selectedCleaningSchedule!: ICleaningScheduleModel;
  cleaningOverview = [] as ICleaningOverviewModel[];
  isLoading = true;

  constructor(
    private log: LogService,
    private scheduleService: CleaningScheduleClientService,
    private cleaningStatsService: CleaningStatsService
  ) {}

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  refresh() {
    this.isLoading = true;
    this.cleaningStatsService.getCleaningOverview().subscribe({
      next: (overview) => {
        if (!this.cleaningStatsService.isLoading()) {
          this.log.info('Cleaning overview loaded', overview);
          this.cleaningOverview = overview;
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.log.error(
          'Error loading cleaning overview from CleaningStatService',
          err
        );
        this.isLoading = false;
      },
    });
  }

  private reset() {
    this.selectedCleaningSchedule = {
      id: 0,
      cleaning_date: '',
      cleaning_done: false,
      cleaning_done_at: '',
      cleaning_note: '',
      cleaner_note: '',
      created_at: '',
      updated_at: '',
    } as ICleaningScheduleModel;
  }

  handleEdit(item: ICleaningOverviewModel) {
    const schedule = this.cleaningStatsService.getScheduleById(item.id);
    if (schedule) {
      this.selectedCleaningSchedule = schedule;
    }
  }

  handleMarkDone(item: ICleaningOverviewModel) {
    const schedule = {
      id: item.id,
      cleaning_done: true,
      cleaning_done_at: format(new Date(), 'yyyy-MM-dd HH:mm'),
    } as ICleaningScheduleModel;
    this.scheduleService.updateCleaningSchedule(schedule).subscribe({
      next: (response) => {
        this.log.info('Cleaning schedule updated', response);
        this.refresh();
      },
      error: (err) => {
        this.log.error('Error updating cleaning schedule', err);
      },
    });
  }
}
