<!-- ========== MAIN CONTENT ========== -->
<main class="min-h-full bg-white ">
  <!-- Content -->
  <div class="grow px-5">
    <div
      class="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5"
    >
      <!-- Title -->
      <div>
        <h1
          class="text-lg sm:text-2xl font-semibold text-gray-800 "
        >
          I11 Admin Panel
        </h1>
      </div>
      <!-- End Title -->

      <form name="loginForm" (ngSubmit)="onSubmit()">
        <div class="space-y-5">
          <div>
            <label
              for="loginform-email"
              class="block mb-2 text-sm font-medium text-gray-800 "
            >
              Email
            </label>

            <input
              type="email"
              id="loginform-email"
              class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm text-gray-800 placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600 border-gray-400 "
              placeholder="you@email.com"
              name="email"
              [(ngModel)]="loginCredentials.email"
              required
            />
          </div>

          <div>
            <div class="flex justify-between items-center mb-2">
              <label
                for="loginform-password"
                class="block text-sm font-medium text-gray-800 "
              >
                Password
              </label>
            </div>

            <div class="relative">
              <input
                id="loginform-password"
                type="password"
                class="py-2 px-3 block w-full border border-gray-200 rounded-lg text-sm text-gray-800 placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600 border-gray-400 "
                placeholder="********"
                name="password"
                [(ngModel)]="loginCredentials.password"
                required
              />
              <button
                type="button"
                data-hs-toggle-password='{
                    "target": "#loginform-password"
                  }'
                class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 "
              >
              <img src="assets/img/eye-slash.svg" alt="" class="flex-shrink-0 w-4 h-4" >
              </button>
            </div>
          </div>

          <button
            type="submit"
            class="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none "
          >
            Sign in
          </button>
        </div>
      </form>
      <div
      [ngClass]="{'hidden': isLoginSuccessful || !errorMessage}"
        class="bg-red-50 border border-red-200 text-sm text-red-800 rounded-lg p-4 "
        role="alert"
      >
        <div class="flex">
          <div class="flex-shrink-0">
            <img src="assets/img/alert-triangle.svg" alt="Sikertelen belépés" class="flex-shrink-0 size-5 mt-0.5" >
          </div>
          <div class="ms-3">
            <h3 class="text-sm font-semibold">
              Sikertelen belépés
            </h3>
            <div class="mt-2 text-sm text-red-700 ">
              <ul class="list-disc space-y-1 ps-3">
                <li>
                  {{ errorMessage }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Content -->
</main>
<!-- ========== END MAIN CONTENT ========== -->
