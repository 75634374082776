import { Pipe, PipeTransform } from '@angular/core';
import { ModelFormatterService } from './model-formatter.service';
import { IFlatModel } from '../api/flat/flat-model.interface';

@Pipe({
  name: 'flatFormat',
  standalone: true
})
export class FlatFormatterPipe implements PipeTransform {

  constructor(private formatter: ModelFormatterService) {}

  transform(value: IFlatModel | null | undefined): string {
    if (!value) {
      return '';
    }
    return this.formatter.getFormattedFlat(value, false);
  }

}
