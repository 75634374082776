<layout-main id="content" role="main" class="text-gray-800 th-property-detail">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/properties"
      >
        Properties
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      @if(propertyTitle){
      {{ propertyTitle }}
      } @else{ Property details }
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!-- Property detail component -->
  @if(isLoading){
  <div
    class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center"
  >
    <div
      class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-sm text-gray-500 mt-4">Loading property details...</p>
  </div>
  } @else{
  <div class="mb-4">
    <!-- TOP INFO  -->
    <property-and-contract-box
      [flat]="flat"
      (contractChanged)="handleContractChange($event)"
      (contractEditInitiated)="handleContractEdit()"
      (contractCreateInitiated)="handleContractCreation()"
    ></property-and-contract-box>
    <!-- TOP INFO  -->
    @if(contract){
    <!--  TAB *******************************************************************************************************************-->

    <nav class="flex flex-wrap space-x-2 mb-4 px-2" aria-label="Tabs">
      <!-- TAB1 head  -->
      <ui-button
        id="tabs-with-underline-item-1"
        data-hs-tab="#tabs-with-underline-1"
        aria-controls="tabs-with-underline-1"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/building-blue.svg"
        iconAlt="Property info"
        class="!ml-0 mr-2"
        (click)="handleTabChange(1)"
        [ngClass]="{ active: selectedTabId === 1 }"
        >Property info</ui-button
      >

      <!-- TAB2 head  -->
      <ui-button
        id="tabs-with-underline-item-2"
        data-hs-tab="#tabs-with-underline-2"
        aria-controls="tabs-with-underline-2"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/user-blue.svg"
        iconAlt="Tenant"
        class="!ml-0 mr-2"
        (click)="handleTabChange(2)"
        [ngClass]="{ active: selectedTabId === 2 }"
        >Tenants and Contract</ui-button
      >

      <!-- TAB3 head  -->
      <ui-button
        id="tabs-with-underline-item-3"
        data-hs-tab="#tabs-with-underline-3"
        aria-controls="tabs-with-underline-3"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/Icon-blue.svg"
        iconAlt="Billing"
        class="!ml-0 mr-2"
        (click)="handleTabChange(3)"
        [ngClass]="{ active: selectedTabId === 3 }"
        >Billing</ui-button
      >

      <!-- TAB4 head  -->
      <ui-button
        id="tabs-with-underline-item-4"
        data-hs-tab="#tabs-with-underline-4"
        aria-controls="tabs-with-underline-4"
        role="tab"
        btnType="tab"
        iconSrc="assets/img/gauge-blue.svg"
        iconAlt="Meters"
        class="!ml-0 mr-2"
        (click)="handleTabChange(4)"
        [ngClass]="{ active: selectedTabId === 4 }"
        >Meters</ui-button
      >
    </nav>

    <div
      [ngClass]="{
        'bg-white border shadow-sm rounded-xl p-4': selectedTabId !== 3
      }"
    >
      <!--  ******************************************************************************************************************************** -->
      <div>
        <!--  1. tab body ******************************************************************************************************************-->
        <div
          id="tabs-with-underline-1"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-1"
          [ngClass]="{ hidden: selectedTabId !== 1 }"
        >
          <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
            <div
              class="border-r border-gray-300 border-solid p-r-2"
              style="border-right-width: 1px; padding-right: 8px"
            >
              <h3 class="mb-4 font-semibold">About</h3>
              <div class="mb-4">
                <img
                  src="assets/img/smartapart.svg"
                  alt="smartapart"
                  class="h-6 inline-block"
                />
                SmartApart Group
              </div>
              <div class="mb-4">
                <img
                  src="assets/img/poi.svg"
                  alt="smartapart"
                  class="h-6 inline-block"
                />
                Hungary
              </div>
              <hr class="mb-4" />
              <a [routerLink]="'/properties/' + flat.id + '/issues'">
                <div class="flex border rounded-xl p-2 items-center mb-4">
                  <div class="p-1 border rounded-xl grow-0">
                    <img
                      src="assets/img/wrench.svg"
                      alt="smartapart"
                      class="h-6 inline-block"
                    />
                  </div>
                  <span class="grow mx-2"
                    >Issues for this property @if(issuesCount>0){
                    <span class="text-red-500">({{ issuesCount }})</span>
                    }
                  </span>
                  <span class="grow-0"
                    ><img
                      src="assets/img/forward.svg"
                      alt="smartapart"
                      class="h-6 inline-block"
                  /></span>
                </div>
              </a>
              <a
                [routerLink]="'/properties/' + flat.id + '/document-inventory'"
              >
                <div
                  class="flex border rounded-xl p-2 items-center mb-4 cursor-pointer"
                >
                  <div class="p-1 border rounded-xl grow-0">
                    <img
                      src="assets/img/folder-blue.svg"
                      alt="smartapart"
                      class="h-6 inline-block"
                    />
                  </div>
                  <span class="grow mx-2">Document inventory</span>
                  <span class="grow-0"
                    ><img
                      src="assets/img/forward.svg"
                      alt="smartapart"
                      class="h-6 inline-block"
                  /></span>
                </div>
              </a>
              <div class="flex border rounded-xl p-2 items-center mb-4">
                <div class="p-1 border rounded-xl grow-0">
                  <img
                    src="assets/img/clipboard-list-blue.svg"
                    alt="smartapart"
                    class="h-6 inline-block"
                  />
                </div>
                <span class="grow mx-2">Issued bills</span>
                <span class="grow-0"
                  ><img
                    src="assets/img/forward.svg"
                    alt="smartapart"
                    class="h-6 inline-block"
                /></span>
              </div>
              <div class="flex border rounded-xl p-2 items-center mb-4">
                <div class="p-1 border rounded-xl grow-0">
                  <img
                    src="assets/img/x-red.svg"
                    alt="smartapart"
                    class="h-6 inline-block"
                  />
                </div>
                <span class="grow mx-2 text-red-500">Terminate lease</span>
                <span class="grow-0"
                  ><img
                    src="assets/img/forward.svg"
                    alt="smartapart"
                    class="h-6 inline-block"
                /></span>
              </div>
            </div>
            <div class="xl:col-span-2">
              <div class="bg-white border shadow-sm rounded-xl p-4 mb-4">
                <div class="sm:grid sm:grid-cols-3 gap-4">
                  <div class="col-span-2">
                    <h2 class="font-semibold mb-6 text-lg">Basic info</h2>
                  </div>
                  <div class="text-right w-full" style="text-align: right">
                    <ui-button
                      iconClass="h-6 invert  saturate-0  brightness-0 contrast-125"
                      size="small"
                      iconSrc="assets/img/save.svg"
                      >Save Changes</ui-button
                    >
                  </div>
                </div>
                <div>
                  <label class="block mb-2" for="propertynumber"
                    >Property number</label
                  >
                  <input
                    class="mb-4 border border-gray-200 p-2 rounded-lg"
                    placeholder="Like 4.3 or #65"
                    name="propertynumber"
                    [value]="flat.flat_number"
                  />
                  <label class="block mb-2" for="propertyname"
                    >Property name</label
                  >
                  <input
                    class="mb-4 border border-gray-200 p-2 rounded-lg"
                    placeholder="Something practical, like “SmartApart II/4. - ECO"
                    name="propertyname"
                    [value]="flat.name"
                  />
                  <label class="block mb-2" for="floorsize"
                    >Floor area size</label
                  >
                  <div class="flex mb-2 rounded-lg">
                    <input
                      class="grow-0 leading-6 p-2 rounded-lg rounded-tl-lg rounded-bl-lg border-gray-200"
                      placeholder="For example: 64.3"
                      name="floorsize"
                      [value]="flat.size"
                    />
                    <span class="p-2 grow-0 leading-6"
                      >m<sup class="leading-4">2</sup></span
                    >
                  </div>
                </div>
              </div>

              <div class="bg-white border shadow-sm rounded-xl p-4 mb-4">
                <div class="sm:grid sm:grid-cols-3 gap-4">
                  <div class="col-span-2">
                    <h2 class="block font-bold text-lg mb-2">Location</h2>
                    <label class="block mb-2">Country</label>

                    <select
                      class="py-3 px-4 mb-4 pe-9 block w-full bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                    >
                      <option selected>Select country</option>
                      <option value="Hungary" selected>Hungary</option>
                      <option value="Germany">Germany</option>
                    </select>
                    <label class="block mb-2">Street</label>
                    <input
                      class="mb-4 border border-gray-200 p-2 rounded-lg"
                      placeholder="eg.: 221 Baker Str."
                      value="Lehel u. 58."
                      name="street"
                    />
                    <label class="block mb-2">Street line 2</label>
                    <input
                      class="mb-4 border border-gray-200 p-2 rounded-lg"
                      placeholder="(optional)"
                      name="street2"
                    />
                    <div class="flex justify-between mb-2 gap-8 columns-2">
                      <div class="item-1">
                        <label class="block mb-2">City</label>
                        <input
                          class="w-full border border-gray-200 p-2 rounded-lg"
                          placeholder="(optional)"
                          name="City"
                          value="Budapest"
                        />
                      </div>
                      <div class="item-2">
                        <label class="block mb-2">Zip/Postal code</label>
                        <input
                          class="w-full border border-gray-200 p-2 rounded-lg"
                          placeholder="Like 21134"
                          name="city"
                          valu="1135"
                        />
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  <div class="text-right w-full" style="text-align: right">
                    <ui-button
                      iconClass="h-6 invert  saturate-0  brightness-0 contrast-125"
                      size="small"
                      iconSrc="assets/img/save.svg"
                      >Save Changes</ui-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  // 1  -->
        <!--  ** TENANT 2 *************************************************************************************************************************-->
        <div
          id="tabs-with-underline-2"
          [ngClass]="{ hidden: selectedTabId !== 2 }"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-2"
        >
          <div class="flex justify-between mb-6">
            <div>
              <h2 class="font-semibold text-lg">Tenants</h2>
              <div>Your tenants for this property.</div>
            </div>
            <!-- Upload Button -->
            <div class="flex items-center gap-x-2">
              <ui-button
                data-hs-overlay="#hs-contract-editor-modal"
                (click)="handleContractCreation()"
                size="xsmall"
                iconSrc="assets/img/add.svg"
                iconClass="invert saturate-0 brightness-0 contrast-125"
                >New Contract</ui-button
              >
              <ui-button
                data-hs-overlay="#hs-contract-editor-modal"
                (click)="handleContractEdit()"
                size="xsmall"
                iconSrc="assets/img/pencil.svg"
                iconClass="invert saturate-0 brightness-0 contrast-125"
                >Edit Contract</ui-button
              >
            </div>
          </div>
          <!-- Table -->
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr class="border-t border-gray-200 divide-x divide-gray-200">
                <th scope="col" class="px-4 py-2">
                  <!-- Sort Dropdown -->
                  <div
                    class="hs-dropdown relative inline-flex w-full cursor-pointer"
                  >
                    <button
                      id="hs-pro-dutnms"
                      type="button"
                      class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                    >
                      Name
                      <img
                        src="assets/img/up-down.svg"
                        alt="Name"
                        class="flex-shrink-0 w-3.5 h-3.5"
                      />
                    </button>

                    <!-- Dropdown -->
                    <div
                      class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                      aria-labelledby="hs-pro-dutnms"
                    >
                      <div class="p-1">
                        <button
                          type="button"
                          class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-up.svg"
                            alt="Sort ascending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />
                          Sort ascending
                        </button>
                        <button
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-down.svg"
                            alt="Sort descending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />

                          Sort descending
                        </button>
                      </div>
                    </div>
                    <!-- End Dropdown -->
                  </div>
                  <!-- End Sort Dropdown -->
                </th>

                <th scope="col" class="px-4 py-2">
                  <!-- Sort Dropdown -->
                  <div
                    class="hs-dropdown relative inline-flex w-full cursor-pointer"
                  >
                    <button
                      id="hs-pro-dutnms"
                      type="button"
                      class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                    >
                      Company Name
                      <img
                        src="assets/img/up-down.svg"
                        alt="Name"
                        class="flex-shrink-0 w-3.5 h-3.5"
                      />
                    </button>

                    <!-- Dropdown -->
                    <div
                      class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                      aria-labelledby="hs-pro-dutnms"
                    >
                      <div class="p-1">
                        <button
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-up.svg"
                            alt="Sort ascending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />
                          Sort ascending
                        </button>
                        <button
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-down.svg"
                            alt="Sort descending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />

                          Sort descending
                        </button>
                      </div>
                    </div>
                    <!-- End Dropdown -->
                  </div>
                  <!-- End Sort Dropdown -->
                </th>

                <th scope="col" class="px-4 py-2">
                  <!-- Sort Dropdown -->
                  <div
                    class="hs-dropdown relative inline-flex w-full cursor-pointer"
                  >
                    <button
                      id="hs-pro-dutnms"
                      type="button"
                      class="text-gray-500 uppercase text-xs justify-between w-full flex items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                    >
                      Tax Number
                      <img
                        src="assets/img/up-down.svg"
                        alt="Name"
                        class="flex-shrink-0 w-3.5 h-3.5"
                      />
                    </button>

                    <!-- Dropdown -->
                    <div
                      class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)]"
                      aria-labelledby="hs-pro-dutnms"
                    >
                      <div class="p-1">
                        <button
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-up.svg"
                            alt="Sort ascending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />
                          Sort ascending
                        </button>
                        <button
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] font-normal hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100"
                        >
                          <img
                            src="assets/img/arrow-down.svg"
                            alt="Sort descending"
                            class="flex-shrink-0 w-3.5 h-3.5"
                          />

                          Sort descending
                        </button>
                      </div>
                    </div>
                    <!-- End Dropdown -->
                  </div>
                  <!-- End Sort Dropdown -->
                </th>

                <th scope="col" class="px-4 py-2 text-right">
                  <!-- Sort Dropdown -->
                  <div class="hs-dropdown relative w-48 ml-auto">
                    <div
                      class="text-gray-500 uppercase text-xs text-right items-center gap-x-1 font-normal focus:outline-none focus:bg-gray-100"
                    >
                      Actions
                    </div>
                  </div>
                  <!-- End Sort Dropdown -->
                </th>
              </tr>
            </thead>
            @if(isLoading) {
            <tr>
              <td colspan="100">
                <div
                  class="w-full flex justify-center content-center mt-16 min-h-28"
                >
                  <img
                    src="assets/img/loading.gif"
                    alt="loading"
                    class="h-8 w-auto"
                  />
                  <div class="ml-4">Loading...</div>
                </div>
              </td>
            </tr>
            } @else { @for (item of contract.tenants; track item.id) {
            <tenant-table-row
              [pitem]="item"
              class="divide-y divide-gray-200"
              removeOrDelete="remove"
              (onRemove)="prepareTenantRemoval($event)"
              (onEdit)="handleTenantEdit($event)"
            >
            </tenant-table-row>
            } @empty {

            <tr>
              <td class="text-center" colspan="100">
                <!--  EMPTY STATE  -->

                <table-empty-state
                  noDataTitle="There are no tenants"
                ></table-empty-state>
                <!--  END EMPTY STATE  -->
              </td>
            </tr>

            } }
          </table>
        </div>
        <!--  // 2  -->

        <!--  BILLING 3  *************************************************************************************************************************-->

        <div
          [ngClass]="{ hidden: selectedTabId !== 3 }"
          id="tabs-with-underline-3"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-3"
        >
          <div
            class="md:col-span-5 xl:col-span-10 bg-white border shadow-sm rounded-xl p-4 mb-4"
          >
            <!-- Due dates header -->
            <div class="flex justify-between">
              <div class="">
                <h2 class="font-semibold mb-6 text-lg">Due dates</h2>
              </div>
              <div class="text-right" style="text-align: right">
                <ui-button
                  iconSrc="assets/img/save.svg"
                  iconClass="invert  saturate-0  brightness-0 contrast-125"
                  size="xsmall"
                >
                  Save Changes</ui-button
                >
              </div>
            </div>
            <!-- End Due dates header -->
            <!-- Due dates content -->
            <div>
              <!-- Input -->
              <div class="sm:flex mb-2 items-center">
                <label class="block basis-1/2 xl:basis-1/3 mr-4 shrink-0"
                  >Days to Pay After Invoice Issuance</label
                >

                <div class="basis-1/2 xl:basis-1/4">
                  <div class="relative">
                    <input
                      id="hs-pro-epdvtp "
                      type="number"
                      class="py-2 ps-3 block border w-20 border-stone-200 rounded-lg text-sm placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600"
                      placeholder="eg.: 14"
                      value="14"
                    />
                  </div>
                </div>
              </div>
              <!-- End Input -->
              <!-- Input -->
              <div class="sm:flex mb-2 items-center">
                <label class="block basis-1/2 xl:basis-1/3 mr-4 shrink-0"
                  >Late Payment Interest Rate</label
                >
                <div class="flex items-center xl:basis-1/4">
                  <div class="relative">
                    <input
                      id="hs-pro-epdvtp"
                      type="number"
                      step="0.1"
                      placeholder="eg.: 0.5"
                      class="py-2 ps-3 block w-20 border border-stone-200 rounded-lg text-sm placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600"
                      value="0.5"
                    />
                  </div>
                  <span class="grow-0 mb-2 p-2">% per day</span>
                </div>
              </div>
              <!-- End Input -->
              <!-- Input -->
              <div class="sm:flex mb-2 items-center">
                <label class="block basis-1/2 xl:basis-1/3 mr-4 shrink-0"
                  >Late Payment Interest Rate Cap</label
                >
                <div class="flex items-center xl:basis-1/4">
                  <div>
                    <div class="relative w-full">
                      <input
                        id="hs-pro-epdvtp"
                        placeholder="eg.: 2.0"
                        type="number"
                        step="0.1"
                        class="py-2 ps-3 block w-20 border border-stone-200 rounded-lg text-sm placeholder:text-stone-500 focus:z-10 focus:border-green-600 focus:ring-green-600"
                        value="2.0"
                      />
                    </div>
                  </div>
                  <span class="grow-0 p-2">% of original Bill</span>
                </div>
              </div>
              <!-- End Input -->
            </div>
            <!-- End Due dates content -->
          </div>
          @if(contract.billings?.length){
          <div class="bg-white border shadow-sm rounded-xl">
            <!--  Billings Component  -->
            @for(item of contract.billings; track item.id){
            <billing
              [availableMeters]="flat.meters"
              [billing]="item"
              (onDelete)="prepareBillingRemoval($event)"
              (onItemAddedd)="refresh()"
              class=""
            ></billing>
            }
          </div>
          } @if(!isBillingCreatorVisible && !contract.billings?.length){
          <!-- Empty State -->
          <div class="bg-white border shadow-sm rounded-xl">
            <table-empty-state
              noDataTitle="There are no billings"
              noDataDescription="You can set up billing by clicking the button below."
              ctaText="Add new billing"
              (ctaClick)="prepareBillingCreation()"
            ></table-empty-state>
            <!-- End Empty State -->
          </div>
          }@else { @if(!isBillingCreatorVisible){
          <!-- Add Billing Button -->
          <div class="text-center my-8">
            <ui-button size="xsmall" (click)="isBillingCreatorVisible = true"
              >Add new billing</ui-button
            >
          </div>
          <!-- End Add Billing Button -->
          } @else {
          <!-- Billing Creator -->
          <div class="bg-white border shadow-sm rounded-xl my-4">
            <billing
              (onCreate)="handleBillingCreation($event)"
              (onCreationCanceled)="cancelBillingCreation()"
            ></billing>
          </div>
          <!-- End Billing Creator -->
          } }
        </div>
        <!-- // 3 -->
        <!-- 4 -->
        <!--  ********************************************************************************************************************************-->

        <div
          id="tabs-with-underline-4"
          [ngClass]="{ hidden: selectedTabId !== 4 }"
          role="tabpanel"
          aria-labelledby="tabs-with-underline-item-3"
        >
          <meters [meters]="flat.meters ?? []"></meters>
        </div>
      </div>
      <!--  ******************************************************************************************************************************** -->
    </div>
    }
  </div>
  }
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- Property Meter Editor Modal -->
  @if(flat){
  <property-meters-editor
    [flat]="flat"
    [selectedMeters]="flat.meters ?? []"
    (onSave)="refresh()"
  ></property-meters-editor>
  }
  <!-- Contract Editor Modal -->
  <contract-editor
    [property]="flat"
    [contract]="contract"
    [mode]="contractEditorMode"
    (onSave)="refresh()"
    (onCreate)="refresh()"
  ></contract-editor>
  <!-- End Contract Editor Modal -->
  <!-- Tenant Editor Modal -->
  <tenant-editor
    [tenant]="selectedTenant"
    mode="edit"
    (onSave)="refresh()"
  ></tenant-editor>
  <!-- End Tenant Editor Modal -->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-remove-tenant-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to remove Tenant {{
      selectedTenant.name
    }} from contract?"
    confirmButtonText="Remove"
    (confirmed)="performTenantRemoval()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-remove-billing-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete Billing #{{
      selectedBillingId
    }} from contract?"
    confirmButtonText="Remove"
    (confirmed)="performBillingRemoval()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
</layout-main>
