import { Component } from '@angular/core';
import { NavSidebarComponent } from '../nav-sidebar/nav-sidebar.component';
import { NavHeaderComponent } from '../nav-header/nav-header.component';

@Component({
  selector: 'layout-main',
  standalone: true,
  //itt jelezzük, hogy milyen standalone komponenseket használunk. HTML-ben enélkül nem ismeri meg őket.
  imports: [NavHeaderComponent, NavSidebarComponent], 
  templateUrl: './layout-main.component.html',
  styleUrl: './layout-main.component.css'
})
export class LayoutMainComponent {

}
