import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ReactiveFormsModule,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { IIssueModel } from '../../../services/api/issue/issue-model.interface';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { IssueStatusClientService } from '../../../services/api/issue-status/issue-status-client.service';
import { IssueClientService } from '../../../services/api/issue/issue-client.service';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'issue-creator',
  standalone: true,
  imports: [ReactiveFormsModule, NgSelectModule],
  templateUrl: './issue-creator.component.html',
  styleUrl: './issue-creator.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueEditorComponent {
  @Input() issue!: IIssueModel;
  @Output() onCreate = new EventEmitter<void>();

  userSelectItems = [] as IUiSelectItem[];
  flatSelectItems: IUiSelectItem[] = [];
  statusSelectItems: IUiSelectItem[] = [];

  issueForm: FormGroup = this.fb.group({
    issuerUser: [''],
    flat: [''],
    issueStatus: ['', Validators.required],
    note: [''],
    issueNotes: [''],
    issueUrgency: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private statusService: IssueStatusClientService,
    private issueService: IssueClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('IssueEditor Changed', this.issue);
    this.issueForm.reset();
    this.issueForm.patchValue(this.issue);

    // user és lakás kezelése a dropdownban
    // alapértelmezetten a belépett user a reporter
    if (this.silo.getSelectItemForCurrentUser().val) {
      this.issueForm
        .get('issuerUser')
        ?.setValue(this.silo.getSelectItemForCurrentUser().val);
    }
    this.issueForm.get('flat')?.setValue(this.issue.flat?.['@id']);
    this.issueForm
      .get('issueStatus')
      ?.setValue(this.issue.issueStatus?.['@id']);
    this.issueForm
      .get('issueUrgency')
      ?.setValue(this.issue.issueUrgency?.['@id']);
  }

  ngOnInit() {
    // Betöltjük a lakásokat a dropdownhoz
    this.silo.getFlatSelectItems().subscribe({
      next: (flats) => {
        this.flatSelectItems = flats;
        this.log.debug('Flats loaded into select:', this.flatSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading flats into select:', err);
      },
    });

    // Betöltjük a felhasználókat a dropdownhoz
    this.silo.getUserSelectItems().subscribe({
      next: (users) => {
        this.userSelectItems = users;
        this.log.debug('Users loaded into select:', this.userSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading users into select:', err);
      },
    });

    //Lekérjük az összes státuszt
    // TODO: SILO-ba szervezni
    this.statusService.getItems().subscribe({
      next: (statuses) => {
        this.statusSelectItems = statuses.map((status) => ({
          val: status['@id'],
          title: status.name,
        }));
        this.statusSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('Issue statuses loaded:', this.statusSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading issue statuses:', err);
      },
    });
  }

  createIssue() {
    this.log.info('Saving issue');

    const updatedUnTypedIssue: { [key: string]: any } = {};

    // Csak a módosított mezőket küldjük
    Object.keys(this.issueForm.controls).forEach((key) => {
      const control = this.issueForm.get(key);
      // az issueruser-t mindig küldjük, de nem lesz dirty ha a currentUser-t hagytuk benne
      if (control?.dirty || key === 'issuerUser') {
        updatedUnTypedIssue[key] = control?.value;
      }
    });
    if (Object.keys(updatedUnTypedIssue).length === 0) {
      // Nincs módosított adat, nem kell menteni
      this.toastr.info('No changes to save');
      this.log.info('No changes to save');
      return;
    }

    const updatedIssue = updatedUnTypedIssue as IIssueModel;

    this.issueService.createIssue(updatedIssue).subscribe({
      next: (data) => {
        this.toastr.success('Issue created');
        this.log.debug('Issue created:', data);
        this.onCreate.emit();
      },
      error: (err) => {
        this.toastr.error('Error while creating issue');
        this.log.error('Error creating issue:', err);
      },
    });
  }
}
