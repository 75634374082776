import { Component, Input, OnChanges } from '@angular/core';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { BookingStatusClientService } from '../../../services/api/booking-status/booking-status-client.service';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { LogService } from '../../../services/utils/log.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'reservation-tab',
  standalone: true,
  imports: [NgSelectModule, UiButtonComponent, ReactiveFormsModule],
  templateUrl: './reservation-tab.component.html',
  styleUrl: './reservation-tab.component.css',
})
export class ReservationTabComponent implements OnChanges {
  @Input() booking?: IBookingModel = {} as IBookingModel;

  bookingStatusSelectItems: IUiSelectItem[] = [];

  constructor(
    private fb: FormBuilder,
    private bookingStatusClient: BookingStatusClientService,
    private log: LogService,
    private bookingService: BookingClientService,
    private toastr: ToastrService
  ) {}

  bookingReservationForm: FormGroup = this.fb.group({
    bookingStatus: [''],
    start_date: [''],
    end_date: [''],
    adult_occupants: [''],
    child_occupants: [''],
    number_of_rooms: [''],
    foreign_reservation_code: [''],
  });

  ngOnChanges() {
    this.log.debug('Booking changed', this.booking);
    if (this.booking) {
      this.bookingReservationForm.reset();
      this.bookingReservationForm.patchValue({
        bookingStatus: this.booking.bookingStatus?.['@id'],
        start_date: this.booking.start_date,
        end_date: this.booking.end_date,
        adult_occupants: this.booking.adult_occupants,
        child_occupants: this.booking.child_occupants,
        number_of_rooms: this.booking.number_of_rooms,
        foreign_reservation_code: this.booking.foreign_reservation_code,
      });
    }
  }

  ngOnInit() {
    this.bookingStatusClient.getBookingStatuses().subscribe({
      next: (bookingStatuses) => {
        this.bookingStatusSelectItems = bookingStatuses.map((status) => ({
          val: status['@id'],
          title: status.name,
        }));
      },
      error: (error) => {
        this.log.error('Error while fetching booking statuses', error);
      },
    });
  }

  saveBookingReservationInfo() {
    this.log.info('Saving Booking Reservation Info');
    if (this.bookingReservationForm.valid && this.booking) {
      const updatedUntypedBooking: { [key: string]: any } = {};

      // Csak a megváltozott mezőket küldjük el
      Object.keys(this.bookingReservationForm.controls).forEach((key) => {
        const control = this.bookingReservationForm.get(key);
        if (control?.dirty) {
          // TODO: ezt egyelőre API limitáció miatt külön kell kezelni
          if (key === 'foreign_reservation_code') {
            updatedUntypedBooking['foreignReservationCode'] = control.value;
          } else {
            updatedUntypedBooking[key] = control.value;
          }
        }
      });

      if (Object.keys(updatedUntypedBooking).length === 0) {
        // Nincs változás, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }
      const updatedBooking = updatedUntypedBooking as IBookingModel;
      this.log.debug('Saving Booking:', updatedBooking);
      updatedBooking.id = this.booking?.id ?? 0;

      this.bookingService.updateBooking(updatedBooking).subscribe({
        next: (data) => {
          this.toastr.success('Booking updated');
          this.log.debug('Booking updated: ', data);
        },
        error: (err) => {
          this.toastr.error('Error while updating booking');
          this.log.error('Error updating booking:', err);
        },
      });
    }
  }
}
