import { Injectable } from '@angular/core';
import { LogService } from '../../utils/log.service';
import { format, parse } from 'date-fns';
import { IParkingRequestModel } from './parking-request-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ParkingRequestTransformService {
  constructor(private log: LogService) {}

  transformParkingRequest(parkingRequest: IParkingRequestModel): IParkingRequestModel {
    try {
      if (parkingRequest.start_date !== undefined) {
        parkingRequest.start_date = format(
          parse(
            parkingRequest.start_date,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: startDate ', error);
    }
    try {
      if (parkingRequest.end_date !== undefined) {
        parkingRequest.end_date = format(
          parse(parkingRequest.end_date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date()),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: endDate ', error);
    }
    try {
      if (parkingRequest.created_at !== undefined) {
        parkingRequest.created_at = format(
          parse(
            parkingRequest.created_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: createdAt ', error);
    }
    try {
      if (parkingRequest.updated_at !== undefined) {
        parkingRequest.updated_at = format(
          parse(
            parkingRequest.updated_at,
            "yyyy-MM-dd'T'HH:mm:ssxxx",
            new Date()
          ),
          'yyyy-MM-dd'
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: updatedAt ', error);
    }
    return parkingRequest;
  }

  serializeParkingRequest(parkingRequest: IParkingRequestModel): any {
    const serializedParkingRequest = { ...parkingRequest };
    // Dátum mezők átalakítása ISO stringgé
    try {
      if (serializedParkingRequest.start_date !== undefined) {
        serializedParkingRequest.start_date = format(
          parse(serializedParkingRequest.start_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: startDate ', error);
    }
    try {
      if (serializedParkingRequest.end_date !== undefined) {
        serializedParkingRequest.end_date = format(
          parse(serializedParkingRequest.end_date, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: endDate ', error);
    }
    try {
      if (serializedParkingRequest.created_at !== undefined) {
        serializedParkingRequest.created_at = format(
          parse(serializedParkingRequest.created_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: createdAt ', error);
    }
    try {
      if (serializedParkingRequest.updated_at !== undefined) {
        serializedParkingRequest.updated_at = format(
          parse(serializedParkingRequest.updated_at, 'yyyy-MM-dd', new Date()),
          "yyyy-MM-dd'T'HH:mm:ssxxx"
        );
      }
    } catch (error) {
      this.log.error('ParkingRequestTransformService: updatedAt ', error);
    }
    return serializedParkingRequest;
  }
}
