import { Component, Input } from '@angular/core';
import { PropertyRowModel } from './properties-table-row-interface';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';

@Component({
  selector: 'properties-table-row',
  standalone: true,
  imports: [
    UiBadgeComponent
  ],
  templateUrl: './properties-table-row.component.html',
  styleUrl: './properties-table-row.component.css',
})
export class PropertiesTableRowComponent {
  @Input() pitem = {} as PropertyRowModel;
}
