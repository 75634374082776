import { Component, ViewChild } from '@angular/core';
import { BillingAddressTableRowComponent } from './billing-address-table-row/billing-address-table-row.component';
import { BillingAddressEditorComponent } from '../../modal-editors/billing-address-editor/billing-address-editor.component';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { BillingAddressClientService } from '../../../services/api/billing-address/billing-address-client.service';
import { IBillingAddressModel } from '../../../services/api/billing-address/billing-address-model.interface';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../../../services/utils/log.service';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'billing-address-table',
  standalone: true,
  templateUrl: './billing-address-table.component.html',
  styleUrl: './billing-address-table.component.css',
  imports: [
    BillingAddressTableRowComponent,
    BillingAddressEditorComponent,
    UiButtonComponent,
    UiTablePagerComponent,
    ConfirmationDialogComponent,
    TableEmptyStateComponent,
  ],
})
export class BillingAddressTableComponent {
  @ViewChild(BillingAddressEditorComponent)
  editor!: BillingAddressEditorComponent;
  selectedBillingAddress!: IBillingAddressModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  addresses = [] as IBillingAddressModel[];
  isLoading = true;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
    private billingAddressService: BillingAddressClientService,
    private log: LogService,
    private toastr: ToastrService,
  ) {}

  handleEdit(pitem: IBillingAddressModel) {
    this.selectedBillingAddress = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handleView(pitem: IBillingAddressModel) {
    this.selectedBillingAddress = pitem;
    this.editorMode = 'view';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: IBillingAddressModel) {
    this.selectedBillingAddress = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.billingAddressService
      .deleteBillingAddress(this.selectedBillingAddress.id)
      .subscribe({
        next: () => {
          // ha sikerült, elhisszük, de nem kérünk le mindent újra
          this.addresses = this.addresses.filter(
            (item) => item.id !== this.selectedBillingAddress.id
          );
          if (this.totalItems > 0) {
            this.totalItems--;
          }
          if (this.addresses.length === 0 && this.totalItems > 0) {
            this.refresh();
          }
          this.toastr.success('Billing address deleted');
        },
        error: (err) => {
          this.toastr.error('Error deleting billing address');
          this.log.error('Error deleting billing address', err);
        },
      });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedBillingAddress = {
      id: 0,
      name: '',
      company_name: '',
      tax_number: '',
      country: '',
      po_code: '',
      town: '',
      street: '',
      bank_account_number: '',
    };
  }

  refresh(page?: number) {
    // lekérjük a címeket
    this.isLoading = true;
    this.billingAddressService
      .getBillingAddresses(page)
      .subscribe((addresses) => {
        this.addresses = addresses;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);
      });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.billingAddressService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
