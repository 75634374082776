import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { PropertyRowModel } from '../../../components/tables/properties-table/properties-table-row/properties-table-row-interface';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
} from '../api.service';
import { LogService } from '../../utils/log.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyClientService {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Lekéri és feldolgozza az összes ingatlant
   * @returns Az összes ingatlan adata
   */
  getAllProperties(): Observable<PropertyRowModel[]> {
    return this.fetchAllProperties().pipe(
      map((data) => this.transformPropertiesResponse(data))
    );
  }

  /**
   * Lekéri az összes ingatlan adatait a szerverről
   * @returns
   */
  private fetchAllProperties() {
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.properties))
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri egy adott ingatlan adatait
   * @param id Az ingatlan azonosítója
   * @returns
   */
  getPropertyById(id: number) {
    return this.http
      .get<{
        id: number;
        name: string;
        location: string;
        tenant: string;
        status: string;
        url: string;
      }>(this.apiService.getUrlFor(API_ENDPOINTS.properties))
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  // TODO: implement
  /*
  The API returns in this format:
  {
	"total": 75,
	"limit": 100,
	"current_page": 1,
	"last_page": 1,
	"properties": [
		{
			"id": 1,
			"name": "ECO",
			"address": "1234 Budapest, Teszt Elek utca 123.",
			"tenant": "Gipsz Jakab",
			"new_issue": 0,
			"unpaid_invoice": 0,
			"missing_reading": 0,
			"expiring_contract": 0,
			"outlier_consumption": false
		},
		{
			"id": 2,
			"name": "ECO",
			"address": "1234 Budapest, Teszt Elek utca 123.",
			"tenant": "Gipsz Jakab",
			"new_issue": 0,
			"unpaid_invoice": 0,
			"missing_reading": 0,
			"expiring_contract": 0,
			"outlier_consumption": false
		}
  ]}

  this method transforms the response to the format that the properties-table-row component expects
  status is a concatenation of the new_issue, unpaid_invoice, missing_reading, expiring_contract fields

  */
  private transformPropertiesResponse(response: any): PropertyRowModel[] {
    return response.properties.map((property: any) => {
      return {
        id: property.id,
        name: property.name,
        location: property.address,
        tenant: property.tenant,
        status: this.calculateStatus(property),
        url: '',
      } as PropertyRowModel;
    });
  }
  /**
   * Calculates the status of a property based on the new_issue, unpaid_invoice, missing_reading, expiring_contract fields
   * Like these: 'Has  3 overdue bills', 'Has 1 open ticket', '', 'Has 1 open ticket, 3 overdue bills' etc
   * @param property
   */
  private calculateStatus(property: any) {
    let status = '';
    if (property.new_issue > 0) {
      status += `Has ${property.new_issue} open ticket`;
      if (property.new_issue > 1) {
        status += 's';
      }
    }
    if (property.unpaid_invoice > 0) {
      if (status) {
        status += ', ';
      }
      status += `Has ${property.unpaid_invoice} overdue bill`;
      if (property.unpaid_invoice > 1) {
        status += 's';
      }
    }
    if (property.missing_reading > 0) {
      if (status) {
        status += ', ';
      }
      status += `Has ${property.missing_reading} missing reading`;
      if (property.missing_reading > 1) {
        status += 's';
      }
    }
    if (property.expiring_contract > 0) {
      if (status) {
        status += ', ';
      }
      status += `Has ${property.expiring_contract} expiring contract`;
      if (property.expiring_contract > 1) {
        status += 's';
      }
    }
    return status;
  }

  private handleError(error: HttpErrorResponse) {
    this.log.error(
      'PropertyClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to get properties'));
  }
}
