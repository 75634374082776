import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { IBookingModel } from '../../services/api/booking/booking-model.interface';
import { BookingClientService } from '../../services/api/booking/booking-client.service';
import { LogService } from '../../services/utils/log.service';
import { FlatFormatterPipe } from '../../services/utils/flat-formatter.pipe';
import { UiBadgeComponent } from '../../components/atomic-ui-components/badge/ui-badge.component';
import { CurrencyValueCorrectorPipe } from '../../services/utils/currency-value-corrector.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'booking-detail',
  standalone: true,
  imports: [
    RouterLink,
    LayoutMainComponent,
    UiButtonComponent,
    RouterLink,
    FlatFormatterPipe,
    UiBadgeComponent,
    CurrencyValueCorrectorPipe,
    CommonModule,
  ],
  templateUrl: './booking-detail.component.html',
  styleUrl: './booking-detail.component.css',
})
export class BookingDetailComponent {
  booking!: IBookingModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookingService: BookingClientService,
    private log: LogService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.bookingService.getBooking(params['id']).subscribe({
        next: (booking) => {
          this.booking = booking;
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }
}
