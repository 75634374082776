import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { IssueStatusClientService } from '../../../../services/api/issue-status/issue-status-client.service';
import { IIssueStatusModel } from '../../../../services/api/issue-status/issue-status-model.interface';
import { LogService } from '../../../../services/utils/log.service';

@Component({
  selector: 'issue-status-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent, 
    UiBadgeComponent,
    UiButtonComponent,
  ],
  templateUrl: './issue-status-table-row.component.html',
  styleUrl: './issue-status-table-row.component.css'
})
export class IssueStatusTableRowComponent {
  @Input() pitem: IIssueStatusModel = {} as IIssueStatusModel;
  @Output() onDelete = new EventEmitter<IIssueStatusModel>();
  @Output() onEdit = new EventEmitter<IIssueStatusModel>();
  @Output() onView = new EventEmitter<IIssueStatusModel>();

  constructor(
    private issueStatusService: IssueStatusClientService,
    private log: LogService
  ) {}

  handleEdit(pitem: IIssueStatusModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IIssueStatusModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IIssueStatusModel) {
    this.onDelete.emit(pitem);
  }

}
