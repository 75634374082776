import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, throwError, Observable, map } from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  ApiService,
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
} from '../api.service';
import { IPaymentProviderModel } from './payment-provider-model.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderClientService {
  // egy minimális "cache", hogy ne kelljen mindig lekérni az összes fizetési szolgáltatót
  private paymentProviders: IPaymentProviderModel[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService
  ) {}

  /**
   * Fizetési szolgáltatók lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A fizetési szolgáltatók lekért oldala, nyers válaszban
   */
  private fetchPaymentProviders(page?: number) {
    const params = page
      ? new HttpParams().set('page', page.toString())
      : new HttpParams();
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.paymentProviders), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Fizetési szolgáltatók lekérése, gyorsítótárazása
   * @returns A fizetési szolgáltatók listája
   */
  getPaymentProviders(): Observable<IPaymentProviderModel[]> {
    return this.fetchPaymentProviders().pipe(
      map((response: any) => {
        this.paymentProviders = response['hydra:member'];
        return this.paymentProviders;
      })
    );
  }

  /**
   * Fizetési szolgáltató lekérése azonosító alapján
   * @param id A fizetési szolgáltató azonosítója
   * @returns A fizetési szolgáltató adatai
   */
  getPaymentProvider(id: number): Observable<IPaymentProviderModel> {
    // ha nincs cache-ben, akkor lekérjük
    if (this.paymentProviders.length === 0) {
      return this.getPaymentProviders().pipe(
        map((providers) => {
          const paymentProvider = providers.find(
            (provider) => provider.id === id
          );
          if (paymentProvider) {
            return paymentProvider;
          } else {
            throw new Error('Payment provider not found');
          }
        }),
        catchError(this.handleError)
      );
    } else {
      // ha van cache, akkor onnan vesszük
      const paymentProvider = this.paymentProviders.find(
        (provider) => provider.id === id
      );
      if (paymentProvider) {
        return new Observable<IPaymentProviderModel>((observer) => {
          observer.next(paymentProvider);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és újra lekérjük
        this.invalidateCache();
        return this.getPaymentProvider(id);
      }
    }
  }

  /**
   * Fizetési szolgáltató lekérése IRI alapján
   * @param iri A fizetési szolgáltató IRI-ja
   * @returns A fizetési szolgáltató adatai
   */
  getPaymentProviderByIri(iri: string): Observable<IPaymentProviderModel> {
    // ha nincs cache-ben, akkor lekérjük
    if (this.paymentProviders.length === 0) {
      return this.http
        .get(this.apiService.getBaseUrl() + iri)
        .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError))
        .pipe(
          map((response: any) => {
            if (response) {
              return response;
            }
            throw new Error('Payment provider not found');
          }),
          catchError(this.handleError)
        );
    } else {
      // ha van cache, akkor onnan vesszük
      const paymentProvider = this.paymentProviders.find(
        (provider) => provider['@id'] === iri
      );
      if (paymentProvider) {
        return new Observable<IPaymentProviderModel>((observer) => {
          observer.next(paymentProvider);
          observer.complete();
        });
      } else {
        // ha nincs cache-ben, akkor invalidáljuk a cache-t és újra lekérjük
        this.invalidateCache();
        return this.getPaymentProviderByIri(iri);
      }
    }
  }

  /**
   * Érvényteleníti a gyorsítótárat
   */
  invalidateCache() {
    this.paymentProviders = [];
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'PaymentProviderClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform payment provider operation')
    );
  };
}
