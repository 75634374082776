<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span
        class="flex-shrink-0 mx-3 overflow-visible cursor-default text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5"
      >
        Properties
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5 font-semibold"
      >
        Add new Property
      </span>
    </li>
  </ol>
  <!-- BREADCRUMB -->
  <!-- Stepper -->
  <div data-hs-stepper="" id="stepper">
    <!-- Stepper Nav -->
    <div class="max-w-4xl mx-auto">
      <ul class="relative flex flex-row justify-center gap-x-2">
        <li
          class="flex items-center gap-x-2 shrink basis-0 flex-auto group"
          data-hs-stepper-nav-item='{
      "index": 1
    }'
        >
          <span
            class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle"
          >
            <span
              class="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600"
            >
              <span
                class="hs-stepper-success:hidden hs-stepper-completed:hidden"
                >1</span
              >
              <svg
                class="hidden shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span class="ms-2 text-sm font-medium text-gray-800">
              Basic info
            </span>
          </span>
          <div
            class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600"
          ></div>
        </li>

        <li
          class="flex items-center gap-x-2 shrink basis-0 flex-auto group"
          data-hs-stepper-nav-item='{
      "index": 2
    }'
        >
          <span
            class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle"
          >
            <span
              class="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600"
            >
              <span
                class="hs-stepper-success:hidden hs-stepper-completed:hidden"
                >2</span
              >
              <svg
                class="hidden shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span class="ms-2 text-sm font-medium text-gray-800">
              Contract
            </span>
          </span>
          <div
            class="w-full h-px flex-1 bg-gray-200 group-last:hidden hs-stepper-success:bg-blue-600 hs-stepper-completed:bg-teal-600"
          ></div>
        </li>

        <li
          class="flex items-center gap-x-2 shrink basis-0"
          data-hs-stepper-nav-item='{
        "index": 3
      }'
        >
          <span
            class="min-w-7 min-h-7 group inline-flex items-center text-xs align-middle"
          >
            <span
              class="size-7 flex justify-center items-center shrink-0 bg-gray-100 font-medium text-gray-800 rounded-full group-focus:bg-gray-200 hs-stepper-active:bg-blue-600 hs-stepper-active:text-white hs-stepper-success:bg-blue-600 hs-stepper-success:text-white hs-stepper-completed:bg-teal-500 hs-stepper-completed:group-focus:bg-teal-600"
            >
              <span
                class="hs-stepper-success:hidden hs-stepper-completed:hidden"
                >3</span
              >
              <svg
                class="hidden shrink-0 size-3 hs-stepper-success:block"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span class="ms-2 text-sm font-medium text-gray-800"> Meters </span>
          </span>
        </li>
        <!-- End Item -->
      </ul>
    </div>
    <!-- End Stepper Nav -->

    <!-- Stepper Content -->
    <div
      class="bg-white border shadow-sm sm:rounded-xl p-4 mt-6 sm:w-2/3 md:w-2/4 mx-auto max-w-lg"
    >
      <!-- First Content -->
      <div
        data-hs-stepper-content-item='{
      "index": 1
    }'
      >
        <div class="p-4">
          <h3 class="text-gray-500 font-bold mb-10 uppercase text-center">
            Basic Info
          </h3>
          <div class="p-2">
            <form
              id="property-form"
              [formGroup]="propertyForm"
              (ngSubmit)="saveProperty()"
            >
              <!-- Building -->
              <label class="block mb-2 font-bold" for="building"
                >Building</label
              >
              <ng-select
                class="mb-4"
                [items]="buildingSelectItems"
                bindLabel="title"
                bindValue="val"
                [multiple]="false"
                loadingText="Loading..."
                placeholder="Select Building"
                formControlName="building"
              ></ng-select>
              <!-- End Building -->

              <!-- Floor -->
              <!--                 <label class="block mb-2 font-bold" for="floor">Floor</label>
                <ng-select
                  class="mb-4"
                  [items]="floorSelectItems"
                  bindLabel="title"
                  bindValue="val"
                  [multiple]="false"
                  loadingText="Loading..."
                  placeholder="Select Floor"
                  formControlName="floor"
                ></ng-select> -->
              <!-- End Floor -->

              <!-- Property Type -->
              <!--                 <label class="block mb-2 font-bold" for="flatType"
                  >Property Type</label
                >
                <ng-select
                  class="mb-4"
                  [items]="flatTypeSelectItems"
                  bindLabel="title"
                  bindValue="val"
                  [multiple]="false"
                  loadingText="Loading..."
                  placeholder="Select Type"
                  formControlName="flatType"
                ></ng-select> -->
              <!-- End Property Type -->

              <!-- Number -->
              <label class="block mb-2 font-bold" for="flat_number"
                >Property number</label
              >
              <input
                class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="text"
                placeholder="Like 4.3 or #65"
                formControlName="flat_number"
              />

              <!--  Name  -->
              <label class="block mb-2 font-bold" for="name"
                >Property Name</label
              >
              <input
                class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="text"
                placeholder="Something practical, like “ECO” or “Modern”"
                formControlName="name"
              />
              <!--  End Name  -->

              <!--  Floor area size  -->
              <div class="block mb-2">
                <label class="font-bold" for="size">Floor area size</label>
                <span class="text-sm pl-2"
                  >( m<sup class="leading-4">2</sup> )</span
                >
              </div>
              <input
                class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="number"
                min="0"
                placeholder="For example, 64.3"
                formControlName="size"
              />
              <!-- End Floor area size  -->
            </form>
          </div>
        </div>
      </div>
      <!-- End First Contnet -->

      <!-- Second Content -->
      <div
        data-hs-stepper-content-item='{
      "index": 2
    }'
        style="display: none"
      >
        <div class="p-4">
          <h3 class="text-gray-500 font-bold mb-10 uppercase text-center">
            Contract
          </h3>
          <div class="p-2">
            <form
              id="contract-form"
              [formGroup]="contractForm"
              (ngSubmit)="saveContract()"
            >
              <!-- Contract Number -->
              <label class="block mb-2 font-bold" for="contract_number"
                >Contract Number</label
              >
              <input
                class="mb-4 text-sm border border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="text"
                placeholder="Unique contract number. Like CNTRCT-K01"
                formControlName="contract_number"
              />
              <!-- End Contract Number -->

              <!-- Tenants -->
              <label class="block mb-2 font-bold" for="floor">Tenants</label>
              <ng-select
                class="mb-4"
                [items]="tenantSelectItems"
                bindLabel="title"
                bindValue="val"
                [multiple]="true"
                loadingText="Loading..."
                placeholder="Select Tenants"
                formControlName="tenants"
                [virtualScroll]="true"
              ></ng-select>
              <!-- End Tenants -->

              <!-- Contract Date -->
              <label class="block mb-2 font-bold" for="contract_date"
                >Contract Date</label
              >
              <input
                class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="date"
                formControlName="contract_date"
              />
              <!-- End Contract Date -->

              <!-- Tenancy Start Date -->
              <label class="block mb-2 font-bold" for="tenancy_starts_at"
                >Tenancy Start Date</label
              >
              <input
                class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="date"
                formControlName="tenancy_starts_at"
              />
              <!-- End Tenancy Start Date -->

              <!--  Open Ended  -->
              <div class="flex gap-4 mb-2 items-center">
                <label class="block font-bold" for="name">Open Ended</label>
                <ui-switch
                  formControlName="open_ended"
                  size="small"
                ></ui-switch>
              </div>
              <!--  End Open Ended  -->

              <!-- Tenancy End Date -->
              <label
                class="block mb-2 mt-4 font-bold"
                for="tenancy_ends_at"
                [ngClass]="{ 'text-gray-400': isOpenEnded }"
                >Tenancy End Date</label
              >
              <input
                class="mb-4 border text-sm border-gray-200 p-2 rounded-lg w-full max-w-lg"
                type="date"
                [ngClass]="{ 'text-gray-400': isOpenEnded }"
                formControlName="tenancy_ends_at"
              />
              <!-- End Tenancy End Date -->
            </form>
          </div>
        </div>
      </div>
      <!-- End Second Content -->

      <!-- Third Content -->
      <div
        data-hs-stepper-content-item='{
      "index": 3
    }'
        style="display: none"
      >
        <div class="p-4">
          <h3 class="text-gray-500 font-bold mb-10 uppercase text-center">
            Meters
          </h3>
        </div>
        <!-- Type Filter Grid -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-4">
            <label
              for="parkingRequestBooking"
              class="sm:mt-2.5 inline-block text-sm text-gray-500"
            >
              1. Filter by Type
            </label>
          </div>
          <div class="sm:col-span-8">
            <ng-select
              id="meterType"
              class="w-full max-w-lg inline-block mb-4"
              [items]="meterTypeSelectItems"
              bindLabel="title"
              [(ngModel)]="selectedMeterType"
              [multiple]="false"
              loadingText="Loading..."
              placeholder="Choose a Meter Type"
              (change)="filterMetersByType()"
            ></ng-select>
          </div>
        </div>
        <!-- End Type Filter Grid -->
        <!-- Meter Selection Grid -->
        <div class="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div class="sm:col-span-4">
            <label
              for="parkingRequestBooking"
              class="sm:mt-2.5 inline-block text-sm text-gray-500"
            >
              2. Select Meters
            </label>
          </div>
          <div class="sm:col-span-8">
            <ng-select
              id="meters"
              class="w-full max-w-lg inline-block mb-4"
              [items]="meterSelectItems"
              bindLabel="title"
              [(ngModel)]="selectedMeters"
              [multiple]="true"
              [virtualScroll]="true"
              loadingText="Loading..."
              placeholder="Start typing to search"
              [searchFn]="customSearchForSelect"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label">{{
                  item.meterType.name + " #" + item.id
                }}</span>
                <span
                  class="ng-value-icon right"
                  (click)="clear(item)"
                  aria-hidden="true"
                  >×</span
                >
              </ng-template>
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <span [ngOptionHighlight]="search">{{
                  item.meterType.name + " #" + item.id
                }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <!-- End Meter Selection Grid -->
        <!--          <ui-button
          class="mt-4 flex justify-center items-center w-full"
          size="small"
          btnType="soft"
          [disabled]="!selectedMeters.length"
          (click)="addMeters()"
          >Add Meters</ui-button> -->
        <!-- Meter Cards -->
        <div
          class="grid mt-4 mb-8 gap-x-4 gap-y-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-items-stretch"
        >
          <!-- CARD -->
          <div
            *ngFor="let meter of selectedMeters"
            class="flex flex-col justify-between items-center border shadow-sm rounded-xl overflow-hidden h-32 relative"
            [ngClass]="{
              'bg-blue-50 border-blue-100': meter.meterType?.id === 3,
              'bg-orange-50 border-orange-100': meter.meterType?.id === 4,
              'bg-yellow-50 border-yellow-100':
                meter.meterType?.id === 1 || meter.meterType?.id === 2,
              'bg-red-50 border-red-100': meter.meterType?.id === 6,
              'bg-cyan-50 border-cyan-100': meter.meterType?.id === 5
            }"
          >

              <div
                class="text-xs mt-2 pl-3 text-slate-500"
              >
                <span class="font-semibold mr-1">#{{ meter.id }}</span>
                <span class="">{{ meter.meterType?.name }}</span>
              </div>

              <lucide-angular
                [name]="
                  meter.meterType?.id === 3
                    ? 'droplet'
                    : meter.meterType?.id === 4
                    ? 'droplet'
                    : meter.meterType?.id === 1 || meter.meterType?.id === 2
                    ? 'plug'
                    : meter.meterType?.id === 6
                    ? 'thermometer-sun'
                    : 'thermometer-snowflake'
                "
                class="block mb-4 w-14 h-14"
                [ngClass]="{
                  'text-blue-200': meter.meterType?.id === 3,
                  'text-orange-200': meter.meterType?.id === 4,
                  'text-yellow-300':
                    meter.meterType?.id === 1 || meter.meterType?.id === 2,
                  'text-red-200': meter.meterType?.id === 6,
                  'text-cyan-200': meter.meterType?.id === 5
                }"
                [size]="12"
              ></lucide-angular>
          </div>
          <!-- END CARD -->
        </div>
      </div>
      <!-- End Third Content -->

      <!-- Final Content -->
      <div
        data-hs-stepper-content-item='{
      "isFinal": true
    }'
        style="display: none"
      >
        <div
          class="p-4 h-48 bg-gray-50 flex justify-center items-center border border-dashed border-gray-200 rounded-xl"
        >
          <h3 class="text-gray-500">Final content</h3>
        </div>
      </div>
      <!-- End Final Content -->

      <!-- Button Group -->
      <div class="mt-5 flex justify-between items-center gap-x-2">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-0 disabled:pointer-events-none"
          data-hs-stepper-back-btn=""
        >
          <svg
            class="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m15 18-6-6 6-6"></path>
          </svg>
          Back
        </button>
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-stepper-next-btn=""
        >
          Next
          <svg
            class="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </button>
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-stepper-finish-btn=""
          style="display: none"
        >
          Finish
        </button>
        <button
          type="reset"
          class="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
          data-hs-stepper-reset-btn=""
          style="display: none"
        >
          Reset
        </button>
      </div>
      <!-- End Button Group -->
    </div>
    <!-- End Stepper Content -->
  </div>
  <!-- End Stepper -->
</layout-main>
