import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { PropertiesTableComponent } from '../../components/tables/properties-table/properties-table.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'overview',
  standalone: true,
  imports: [LayoutMainComponent, PropertiesTableComponent, RouterLink],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.css',
})
export class OverviewComponent {}
