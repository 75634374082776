import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { FloorTableComponent } from '../../components/tables/floor-table/floor-table.component';

@Component({
  selector: 'floor',
  standalone: true,
  imports: [
    LayoutMainComponent,
    FloorTableComponent
],
  templateUrl: './floor.component.html',
  styleUrl: './floor.component.css'
})
export class FloorComponent {

}
