import { Component, Input } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe, SlicePipe } from '@angular/common';
import { IArrivalModel } from '../arrival-model.interface';

@Component({
  selector: 'arrivals-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiButtonComponent,
    UiBadgeComponent,
    SlicePipe,
    DatePipe,
  ],
  templateUrl: './arrivals-row.component.html',
  styleUrl: './arrivals-row.component.css',
})
export class ArrivalsRowComponent {
  @Input() pitem: IArrivalModel = {} as IArrivalModel;
}
