<div class="bg-white border shadow-sm rounded-xl p-6 mb-4">
  <div class="text-2xl mt-1 mb-2 text-center font-semibold">
    {{ propertyTitle }}
  </div>
  <div class="text-xs mt-0 mb-6 text-center font-semibold">
    {{ propertyAddress }}
  </div>

  <div class="text-center">
    @if(contractSelectItems.length > 0){
    <span class="text-lg font-bold mr-2">Selected contract</span>
    <ng-select
      class="w-80 m-auto text-left"
      [items]="contractSelectItems"
      bindLabel="title"
      [multiple]="false"
      loadingText="Loading..."
      placeholder="Select contract"
      [(ngModel)]="selectedContract"
      (change)="onContractChange($event)"
    ></ng-select>
    }@else {
    <!-- Empty State -->
    <div class="flex justify-center items-center mt-12 mb-12 text-gray-500">
      <i-lucide
        name="scroll-text"
        class="w-12 h-12"
        [strokeWidth]="1"
      ></i-lucide>
      <p class="text-sm">No contracts for this property.</p>
    </div>
    <!-- Add Contract -->
    <div class="text-center mb-10">
      <ui-button
      class="inline-block"
        (click)="initiateContractCreation()"
        data-hs-overlay="#hs-contract-editor-modal"
        >Set up a new contract</ui-button
      >
    </div>
    <!-- End Add Contract -->
    <!-- End Empty State -->
    }
  </div>
</div>
@if(isExpired){
<div
  class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded-lg relative text-center mb-4"
  role="alert"
>
  <span class="block sm:inline"
    >You are looking at a former contract that's expired by now</span
  >
</div>
}
