import { Component, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  NgApexchartsModule,
  ChartComponent,
  ApexTooltip,
  ApexPlotOptions,
  ApexXAxis,
  ApexStroke,
} from 'ng-apexcharts';
import { IParkingRequestModel } from '../../services/api/parking-request/parking-request-model.interface';
import { LogService } from '../../services/utils/log.service';
import { ParkingRequestClientService } from '../../services/api/parking-request/parking-request-client.service';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import {
  subWeeks,
  addWeeks,
  format,
  addDays,
  isWithinInterval,
} from 'date-fns';
import { CommonModule } from '@angular/common';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  colors: any;
  grid: any;
  fill: any;
  labels: any;
  tooltip: ApexTooltip;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

export type ChartData = {
  x: string;
  y: number[];
  fillColor: string;
};

export type OccupancyData = {
  date: string;
  morning: number;
  afternoon: number;
};

@Component({
  selector: 'parking-overview',
  standalone: true,
  imports: [NgApexchartsModule, CommonModule],
  templateUrl: './parking-overview.component.html',
  styleUrl: './parking-overview.component.css',
})
export class ParkingOverviewComponent {
  parkingRequests: IParkingRequestModel[] = [];
  chartData = [] as ChartData[];
  weekBefore: string = '';
  weekAfter: string = '';

  lehelOccupancyData: OccupancyData[] = [];
  kunigundaOccupancyData: OccupancyData[] = [];
  lehelMax = 14;
  kunigundaMax = 4;

  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  constructor(
    private parkingRequestService: ParkingRequestClientService,
    private formatter: ModelFormatterService,
    private log: LogService
  ) {
    this.chartOptions = {
      series: [
        {
          data: this.chartData,
        },
      ],
      chart: {
        height: 500,
        type: 'rangeBar',
        //width: 500,
        background: '#FFF',
      },
      stroke: {
        width: 1,
        colors: ['#B6B8BC'],
      },
      dataLabels: {
        enabled: true,
        formatter: this.getDataLabels.bind(this),
        style: {
          colors: ['#000000'],
        },
      },
      title: {
        text: ' ',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',
        },
      },
      xaxis: {
        type: 'datetime',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      tooltip: {
        enabled: true,
        shared: false,
        followCursor: true,
        custom: this.tooltipFormatter.bind(this),
        hideEmptySeries: true,
        fillSeriesColor: false,
        theme: 'light',
        style: {
          fontSize: '12px',
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        marker: {
          show: true,
        },
        items: {
          display: 'flex',
        },
        fixed: {
          enabled: false,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
        },
      },
    };
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh(): void {
    const today = new Date();
    this.weekBefore = format(subWeeks(today, 2), 'yyyy-MM-dd');
    this.weekAfter = format(addWeeks(today, 2), 'yyyy-MM-dd');
    this.parkingRequestService
      .getAllParkingRequests(this.weekBefore, this.weekAfter)
      .subscribe({
        next: (requests: IParkingRequestModel[]) => {
          this.log.info('Parkingrequests loaded');
          this.parkingRequests = requests;
          this.chartData = this.parkingRequests
            .sort((a, b) => {
              // ABC sorrendbe rendezzük a lakásokat
              if (
                this.formatter.getFormattedParkingSpace(a.parkingPlace) <
                this.formatter.getFormattedParkingSpace(b.parkingPlace)
              ) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((request) => {
              return {
                x: this.formatter.getFormattedParkingSpace(
                  request.parkingPlace
                ),
                y: [
                  request.start_date
                    ? new Date(request.start_date).getTime()
                    : 0,
                  request.end_date ? new Date(request.end_date).getTime() : 0,
                ],
                fillColor: request.paid ? '#89e4a9' : '#DAE5FF',
              };
            });
          this.log.debug('Chart data:', this.chartData);
          this.chartOptions.series = [
            {
              data: this.chartData,
            },
          ];
          this.prepareOccupancyData();
        },
      });
  }

  /**
   * Elkészíti a parkolóhelyek foglaltságát mutató adatokat
   * Be van drótozva, hogy a Lehel és Kunigunda parkolóhelyeket ismerje
   */
  private prepareOccupancyData(): void {
    // A start_date és end_date között végig iterálunk minden napon
    const startDate = new Date(this.weekBefore);
    const endDate = new Date(this.weekAfter);
    let currentDate = startDate;

    while (currentDate <= endDate) {
      let morningOccupiedLehel = 0;
      let afternoonOccupiedLehel = 0;
      let morningOccupiedKunigunda = 0;
      let afternoonOccupiedKunigunda = 0;
      const currentDateAsString = format(currentDate, 'yyyy-MM-dd');

      for (const request of this.parkingRequests) {
        // Ha a mai nap benne van a foglalás időintervallumában
        if (
          isWithinInterval(currentDate, {
            start: request.start_date ?? '1970-01-01',
            end: request.end_date ?? '9999-12-31',
          })
        ) {
          // Ha a mai nap egybeesik a foglalás kezdetével, akkor délutánra beszámoljuk
          if (currentDateAsString === request.start_date) {
            if (request.parkingPlace?.building?.id === 1) {
              afternoonOccupiedLehel++;
            } else if (request.parkingPlace?.building?.id === 2) {
              afternoonOccupiedKunigunda++;
            }
          }
          // Ha a mai nap egybeesik a foglalás végével, akkor reggelre beszámoljuk
          if (currentDateAsString === request.end_date) {
            if (request.parkingPlace?.building?.id === 1) {
              morningOccupiedLehel++;
            } else if (request.parkingPlace?.building?.id === 2) {
              morningOccupiedKunigunda++;
            }
          }
          // Egyébként mindkettőre, hiszen a mai nap beleesik a foglalásba és se nem eleje, se nem vége
          if (
            currentDateAsString !== request.start_date &&
            currentDateAsString !== request.end_date
          ) {
            if (request.parkingPlace?.building?.id === 1) {
              morningOccupiedLehel++;
              afternoonOccupiedLehel++;
            } else if (request.parkingPlace?.building?.id === 2) {
              morningOccupiedKunigunda++;
              afternoonOccupiedKunigunda++;
            }
          }
        }
      }

      this.lehelOccupancyData.push({
        date: format(new Date(currentDate), 'MM/dd'),
        morning: this.lehelMax - morningOccupiedLehel,
        afternoon: this.lehelMax - afternoonOccupiedLehel,
      });

      this.kunigundaOccupancyData.push({
        date: format(new Date(currentDate), 'MM/dd'),
        morning: this.kunigundaMax - morningOccupiedKunigunda,
        afternoon: this.kunigundaMax - afternoonOccupiedKunigunda,
      });

      currentDate = addDays(currentDate, 1);
    }

    console.log('Occupancy data Lehel:', this.lehelOccupancyData);
    console.log('Occupancy data Kunigunda:', this.kunigundaOccupancyData);
  }

  getDataLabels(val: number[], opts: any): string {
    return this.parkingRequests[opts.dataPointIndex].paid ? '' : '$';
  }

  public tooltipFormatter({
    series,
    seriesIndex,
    dataPointIndex,
    w,
  }: {
    series: any[];
    seriesIndex: number;
    dataPointIndex: number;
    w: any;
  }): string {
    return `<div class="inline-block">
      <!-- Popover Content -->
      <div class="max-w-s w-full bg-white border border-gray-100 text-start rounded-xl shadow-md after:absolute after:top-0 after:-start-4 after:w-4 after:h-full">
        <!-- Header -->
        <div class="py-3 px-4 border-b border-gray-200">
        <div class="flex items-center gap-x-3">
          <img class="flex-shrink-0 inline-block size-10 rounded-full ring-2 ring-white" src="assets/img/user-blue.svg" alt="user icon">
          <div class="grow">
          <h4 class="font-semibold text-gray-800">
            ${this.parkingRequests[dataPointIndex].booking?.name ?? 'unknown'}
            ${
              this.parkingRequests[dataPointIndex].paid
                ? ''
                : `<span class="ms-0.5 inline-flex items-center align-middle gap-x-1.5 py-0.5 px-1.5 rounded-md text-[11px] font-medium bg-orange-700 text-white">
              UNPAID
              </span>`
            }
          </h4>
          <p class="text-sm text-gray-500">
            ${this.parkingRequests[dataPointIndex].license_plate ?? ''}
          </p>
          </div>
        </div>
        </div>
        <!-- End Header -->
        <!-- List -->
        <ul class="py-3 px-4 space-y-1">
        <li>
          <div class="inline-flex items-center gap-x-3 text-sm text-gray-800">
          <svg class="flex-shrink-0 size-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z"></path>
            <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2"></path>
            <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2"></path>
            <path d="M10 6h4"></path>
            <path d="M10 10h4"></path>
            <path d="M10 14h4"></path>
            <path d="M10 18h4"></path>
          </svg>
          ${this.formatter.getFormattedFlat(
            this.parkingRequests[dataPointIndex].parkingPlace
          )}
          </div>
        </li>
        <li>
          <div class="inline-flex items-center gap-x-3 text-sm text-gray-800">
          <img class="flex-shrink-0 size-4 text-gray-600" width="24" height="24" src="assets/img/calendar-range.svg" alt="user icon">
          <b>${this.parkingRequests[dataPointIndex].start_date}</b> - <b>${
      this.parkingRequests[dataPointIndex].end_date
    }</b>
          </div>
        </li>
        <li>
          <div class="inline-flex items-center gap-x-3 text-sm text-gray-800">
          <svg class="flex-shrink-0 size-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect width="14" height="20" x="5" y="2" rx="2" ry="2"></rect>
            <path d="M12 18h.01"></path>
          </svg>
          ${this.parkingRequests[dataPointIndex].booking?.phone_number ?? '-'}
          </div>
        </li>
        <li>
          <div class="inline-flex items-center gap-x-3 text-sm text-gray-800">
          <svg class="flex-shrink-0 size-4 text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect width="20" height="16" x="2" y="4" rx="2"></rect>
            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
          </svg>
          ${this.parkingRequests[dataPointIndex].booking?.email_address ?? '-'}
          </div>
        </li>
        </ul>
        <!-- End List -->
      </div>
      <!-- End Popover Content -->
      </div>
      <!-- End User -->`;
  }
}
