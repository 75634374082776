import { Injectable } from '@angular/core';
import { GenericApiClientService } from '../generic-api-client.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../utils/log.service';
import { API_ENDPOINTS, ApiService } from '../api.service';
import { IUnitModel } from './unit-model.interface';

@Injectable({
  providedIn: 'root',
})
export class UnitClientService extends GenericApiClientService<IUnitModel> {
  constructor(http: HttpClient, apiService: ApiService, log: LogService) {
    super(http, apiService, log);
    this.itemsUrl = apiService.getUrlFor(API_ENDPOINTS.units);
    this.singleItemUrl = apiService.getUrlFor(API_ENDPOINTS.unit);
  }
}
