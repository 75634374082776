import { Component, Input, OnChanges, } from '@angular/core';
import { UiSwitchComponent } from '../../atomic-ui-components/switch/ui-switch.component';
import { IBookingModel } from '../../../services/api/booking/booking-model.interface';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CurrencyClientService } from '../../../services/api/currency/currency-client.service';
import { LogService } from '../../../services/utils/log.service';
import { BookingClientService } from '../../../services/api/booking/booking-client.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'payment-tab',
  standalone: true,
  imports: [UiSwitchComponent, NgSelectModule, ReactiveFormsModule],
  templateUrl: './payment-tab.component.html',
  styleUrl: './payment-tab.component.css',
})
export class PaymentTabComponent implements OnChanges{
  @Input() booking?: IBookingModel;

  isLoadingCurrencies = true;
  currencySelectItems: IUiSelectItem[] = [];

  bookingPaymentForm: FormGroup = this.fb.group({
    paid: [''],
    currency: [{value:'', disabled: true}],
    booking_price: [''],
    booking_price_in_default_currency: [''],
    booking_price_exchange_rate: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private currencyService: CurrencyClientService,
    private bookingService: BookingClientService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges() {
    this.log.debug('Booking changed', this.booking);
    if (this.booking) {
      this.bookingPaymentForm.reset();
      this.bookingPaymentForm.patchValue({
        paid: this.booking.paid,
        currency: this.booking.currency?.['@id'],
        booking_price: this.booking.booking_price,
        booking_price_in_default_currency: this.booking.booking_price_in_default_currency,
        booking_price_exchange_rate: this.booking.booking_price_exchange_rate,
      });
    }

  }

  ngOnInit() {
    this.isLoadingCurrencies = true;
    this.currencyService.getCurrencies().subscribe({
      next: (currencies) => {
        this.currencySelectItems = currencies.map((currency) => ({
          val: currency['@id'],
          title: (currency.name ?? currency.code) + ' ' + currency.symbol,
        }));
        this.currencySelectItems = new Array(...this.currencySelectItems);
        this.isLoadingCurrencies = false;
        this.bookingPaymentForm.get('currency')?.enable();
      },
      error: (err) => {
        this.log.error('Error loading currencies:', err);
      },
    });
  }

  saveBookingPaymentInfo() {
    this.log.info('Saving booking payment info');
    if (this.booking && this.bookingPaymentForm.valid) {
      const updatedUntypedBooking: { [key: string]: any } = {};

      // Csak a módosított mezőket küldjük el
      Object.keys(this.bookingPaymentForm.controls).forEach((key) => {
        const control = this.bookingPaymentForm.get(key);
        if (control?.dirty) {
          updatedUntypedBooking[key] = control.value;
        }
      });

      if (Object.keys(updatedUntypedBooking).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedBooking = updatedUntypedBooking as IBookingModel;
      this.log.debug('Saving Booking:', updatedBooking);
      updatedBooking.id = this.booking?.id ?? 0;

      this.bookingService.updateBooking(updatedBooking).subscribe({
        next: (data) => {
          this.toastr.success('Booking updated');
          this.log.debug('Booking updated:', data);
        },
        error: (error) => {
          this.toastr.error('Error while updating booking');
          this.log.error('Error while updating booking:', error);
        },
      });
    }
  }
}
