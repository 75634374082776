import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { TaxTableComponent } from '../../components/tables/tax-table/tax-table.component';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'tax',
  standalone: true,
  imports: [
    LayoutMainComponent,
    TaxTableComponent,
    RouterLink,
],
  templateUrl: './tax.component.html',
  styleUrl: './tax.component.css'
})
export class TaxComponent {

}
