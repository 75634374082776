import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { MeterTableComponent } from '../../components/tables/meter-table/meter-table.component';

@Component({
  selector: 'meters-page',
  standalone: true,
  imports: [
    LayoutMainComponent,
    RouterLink,
    MeterTableComponent,
  ],
  templateUrl: './meters-page.component.html',
  styleUrl: './meters-page.component.css'
})
export class MetersPageComponent {



}
