import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogService } from '../../../services/utils/log.service';
import { TaxClientService } from '../../../services/api/tax/tax-client.service';
import { ITaxModel } from '../../../services/api/tax/tax-model.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tax-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
  ],
  templateUrl: './tax-editor.component.html',
  styleUrl: './tax-editor.component.css'
})
export class TaxEditorComponent {
  @Input() tax!: ITaxModel;
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Output() onSave = new EventEmitter<void>();
  @Output() onCreate = new EventEmitter<void>();

  taxForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    percentage: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private taxService: TaxClientService,
    private toastr: ToastrService
  ) {}

  ngOnChanges() {
    this.log.debug('Tax Editor Changed', this.tax);
    this.taxForm.reset();
    if (this.mode === 'view') {
      this.taxForm.disable();
    } else {
      this.taxForm.enable();
    }
    if (this.mode === 'edit') {
      this.taxForm.patchValue(this.tax);
    }
   }

   saveTax() {
    this.log.info('Saving Tax');
    if (this.taxForm.valid && this.mode !== 'view') {
      const updatedUntypedTax: { [key: string]: any } = {};

      // Csak a módosított mezőket mentjük
      Object.keys(this.taxForm.controls).forEach((key) => {
        const control = this.taxForm.get(key);
        if (control?.dirty) {
          updatedUntypedTax[key] = control.value;
        }
      });
      if (Object.keys(updatedUntypedTax).length === 0) {
        // Nincs változás
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedTax =
        updatedUntypedTax as ITaxModel;
      this.log.debug('Tax request:', updatedTax);

      if (this.mode === 'edit') {
        updatedTax.id = this.tax.id;
        this.taxService
          .updateItem(updatedTax)
          .subscribe({
            next: (data) => {
              this.toastr.success('Tax updated');
              this.log.debug('Tax updated:', data);
              this.onSave.emit();
            },
            error: (err) => {
              this.toastr.error('Error updating Tax');
              this.log.error('Error updating Tax:', err);
            },
          });
      } else {
        this.taxService
          .createItem(updatedTax)
          .subscribe({
            next: (data) => {
              this.toastr.success('Tax created');
              this.log.debug('Tax created:', data);
              this.onCreate.emit();
            },
            error: (err) => {
              this.toastr.error('Error creating Tax ');
              this.log.error('Error creating Tax:', err);
            },
          });
      }
    }
  }

}
