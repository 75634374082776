<div class="py-4 px-5 space-y-4">
  <!-- Empty State -->
  <div
    class="p-5 min-h-[400px] flex flex-col justify-center items-center text-center"
  >
    <svg
      class="w-48 mx-auto"
      width="87"
      height="65"
      viewBox="0 0 87 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="35"
        width="6"
        height="30"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="9"
        y="20"
        width="6"
        height="45"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="18"
        y="25"
        width="6"
        height="40"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="27"
        width="6"
        height="65"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="36"
        y="5"
        width="6"
        height="60"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="45"
        y="40"
        width="6"
        height="25"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="54"
        y="25"
        width="6"
        height="40"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="63"
        y="12"
        width="6"
        height="53"
        fill="currentColor"
        class="fill-gray-200 0"
      />
      <rect
        x="72"
        width="6"
        height="65"
        fill="currentColor"
        class="fill-gray-200"
      />
      <rect
        x="81"
        y="44"
        width="6"
        height="21"
        fill="currentColor"
        class="fill-gray-200"
      />
    </svg>

    <div class="max-w-sm mx-auto">
      <p class="mt-2 font-medium text-gray-800">
        {{ noDataTitle }}
      </p>
      <p class="mb-5 text-sm text-gray-500">
        {{ noDataDescription }}
      </p>
    </div>
    @if(ctaText){
    <button
      type="button"
      class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-indigo-500"
      [attr.data-hs-overlay]="modalIdToOpen"
      (click)="onCtaClick()"
    >
      <svg
        class="hidden sm:block flex-shrink-0 size-3.5"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M5 12h14" />
        <path d="M12 5v14" />
      </svg>
      {{ ctaText }}
    </button>
    }
  </div>
  <!-- End Empty State -->
</div>
