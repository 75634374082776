import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { TenantTableRowComponent } from '../../components/tables/tenant-table-row/tenant-table-row.component';
import { ITenantModel } from '../../services/api/tenant/tenant-model.interface';
import { ToastrService } from 'ngx-toastr';
import { TenantClientService } from '../../services/api/tenant/tenant-client.service';
import { LogService } from '../../services/utils/log.service';
import { UiTablePagerComponent } from '../../components/atomic-ui-components/table-pager/table-pager.component';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { TenantEditorComponent } from '../../components/modal-editors/tenant-editor/tenant-editor.component';

@Component({
  selector: 'tenants',
  standalone: true,
  imports: [
    LayoutMainComponent,
    UiButtonComponent,
    TenantTableRowComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
    ConfirmationDialogComponent,
    TenantEditorComponent,
  ],
  templateUrl: './tenants.component.html',
  styleUrl: './tenants.component.css',
})
export class TenantsComponent {
  isLoading = true;
  isPagerLoading = false;
  totalItems = 0;
  tenants: ITenantModel[] = [];
  selectedTenant!: ITenantModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';

  constructor(
    private tenantService: TenantClientService,
    private log: LogService,
    private toastr: ToastrService
  ) {}

  handleEdit(pitem: ITenantModel) {
    this.selectedTenant = pitem;
    this.editorMode = 'edit';
  }

  handleCreate() {
    // Kezdjünk tiszta lappal
    this.reset();
    this.editorMode = 'create';
  }

  handlePagination(page: number) {
    this.refresh(page);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(pitem: ITenantModel) {
    this.log.debug('Preparing to delete tenant', pitem);
    this.selectedTenant = pitem;
  }

  // tényleges törlés
  performDelete() {
    this.tenantService.deleteItem(this.selectedTenant.id).subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.tenants = this.tenants.filter(
          (item) => item.id !== this.selectedTenant.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.tenants.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Tenant deleted');
      },
      error: (error) => {
        // ha nem, feldobunk egy hibaüzenetet
        this.toastr.error('Error deleting tenant!');
        this.log.error('Error deleting tenant!');
      },
    });
  }

  ngOnInit() {
    this.reset();
    this.refresh();
  }

  private reset() {
    this.selectedTenant = {
      id: 0,
      name: '',
      company_name: '',
      tax_number: '',
    } as ITenantModel;
  }

  refresh(page?: number) {
    this.log.info('Refreshing tenants');
    this.isLoading = true;
    this.tenantService.getItems(page).subscribe((data) => {
      this.tenants = data;
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
      setTimeout(() => {
        window.HSStaticMethods.autoInit();
      }, 100);
    });
    // lekérjük az összes elem számát is a lapozónak
    this.isPagerLoading = true;
    this.tenantService.getTotalItems().subscribe((count) => {
      this.totalItems = count;
      this.isPagerLoading = false;
    });
  }
}
