import { Component, Input, EventEmitter, Output } from '@angular/core';
import { LogService } from '../../../../services/utils/log.service';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { DatePipe, SlicePipe } from '@angular/common';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';
import { IFlatModel } from '../../../../services/api/flat/flat-model.interface';


@Component({
  selector: 'flat-table-row',
  standalone: true,
  imports: [
    UiSwitchComponent,
    UiBadgeComponent,
    DatePipe,
    SlicePipe,
    UiButtonComponent,
    RouterLink,
  ],
  templateUrl: './flat-table-row.component.html',
  styleUrl: './flat-table-row.component.css'
})
export class FlatTableRowComponent {
  @Input() pitem: IFlatModel = {} as IFlatModel;
  @Output() onDelete = new EventEmitter<IFlatModel>();

  constructor(private log: LogService) {}

  handleDelete(pitem: IFlatModel) {
    this.onDelete.emit(pitem);
  }

}
