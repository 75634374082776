import { Component } from '@angular/core';
import { LayoutMainComponent } from '../../components/layout-main/layout-main.component';
import { DocumentInventoryTableRowComponent } from '../../components/tables/document-inventory-table-row/document-inventory-table-row';
import { PropertyAndContractBoxComponent } from '../../components/property-and-contract-box/property-and-contract-box.component';
import { TableEmptyStateComponent } from '../../components/tables/table-empty-state/table-empty-state.component';
import { LogService } from '../../services/utils/log.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FlatClientService } from '../../services/api/flat/flat-client.service';
import { IFlatModel } from '../../services/api/flat/flat-model.interface';
import { ModelFormatterService } from '../../services/utils/model-formatter.service';
import { IContractModel } from '../../services/api/contract/contract-model.interface';
import { ContractClientService } from '../../services/api/contract/contract-client.service';
import { IInventoryItemTypeModel } from '../../services/api/inventory-item-type/inventory-item-type-model.interface';
import { UiButtonComponent } from '../../components/atomic-ui-components/button/ui-button.component';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../../components/modals/confirmation-dialog/confirmation-dialog.component';
import { IInventoryItemModel } from '../../services/api/inventory-item/inventory-item-model.interface';
import { InventoryItemClientService } from '../../services/api/inventory-item/inventory-item-client.service';
import { UploadDocumentsComponent } from '../../components/modals/upload-documents/upload-documents.component';
import { InventoryItemTypeClientService } from '../../services/api/inventory-item-type/inventory-item-type-client.service';

@Component({
  selector: 'document-inventory',
  standalone: true,
  templateUrl: './document-inventory.component.html',
  styleUrl: './document-inventory.component.css',
  imports: [
    LayoutMainComponent,
    DocumentInventoryTableRowComponent,
    UploadDocumentsComponent,
    PropertyAndContractBoxComponent,
    TableEmptyStateComponent,
    RouterLink,
    UiButtonComponent,
    CommonModule,
    ConfirmationDialogComponent,
  ],
})
export class DocumentInventoryComponent {
  contract?: IContractModel;
  categories: IInventoryItemTypeModel[] = [];
  // ez a map tartalmazza, hogy melyik kategóriából (id) hány darab van
  categoryQuantities: Map<number, number> = new Map<number, number>();
  selectedCategoryID: number = 0;
  selectedCategory: IInventoryItemTypeModel | null = null;
  // ebben a leszűrt inventoryItemek lesznek
  selectedInventoryItems: IInventoryItemModel[] = [];
  // ez meg a törlésre kijelölt
  selectedInventoryItem!: IInventoryItemModel;

  flat!: IFlatModel;
  propertyTitle = '';
  isLoading = true;
  isLoadingContract = true;

  constructor(
    private log: LogService,
    private route: ActivatedRoute,
    private router: Router,
    private flatService: FlatClientService,
    private contractService: ContractClientService,
    private inventoryService: InventoryItemClientService,
    private inventoryCategoryService: InventoryItemTypeClientService,
    private formatter: ModelFormatterService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    //kiszedjük a lakás id-jét a paraméterek közül
    this.route.params.subscribe((params) => {
      let id = params['id'];
      //lekérjük a lakást
      this.flatService.getFlat(id).subscribe({
        next: (flat) => {
          this.flat = flat;
          this.propertyTitle = this.formatter.getFormattedFlat(
            this.flat,
            false
          );
          // akkor most az inventory kategóriákat kérjük le
          this.inventoryCategoryService.getAllItems().subscribe({
            next: (categories) => {
              this.categories = categories;
              this.log.debug('Inventory categories loaded', categories);
              this.isLoading = false;
            },
            error: (err) => {
              this.log.error('Could not load inventory categories', err);
            },
          });
          this.log.debug('Flat loaded', flat);
        },
        error: (err) => {
          //irány a 404
          this.router.navigate(['/error']);
        },
      });
    });
  }

  handleContractChange(contractItem: string, keepCategory: boolean = false) {
    this.log.debug('Contract ID received', contractItem);
    if (contractItem && this.flat.contracts) {
      this.isLoadingContract = true;
      if (!keepCategory) {
        this.selectedCategoryID = 0;
        this.selectedCategory = null;
      }
      this.selectedInventoryItems = [];
      this.contractService.getItemByIri(contractItem).subscribe({
        next: (contract) => {
          this.contract = contract;
          this.log.debug('Contract loaded', contract);
          if (!keepCategory) {
            this.handleCategoryChange(this.categories[0].id);
          } else {
            this.handleCategoryChange(this.selectedCategoryID);
          }
          this.countItemsPerCategory();
        },
        error: (err) => {
          this.log.error('Could not load contract', err);
          this.isLoadingContract = false;
        },
      });
    }
  }

  private countItemsPerCategory() {
    this.categoryQuantities.clear();
    if (this.contract?.inventoryItems) {
      this.contract.inventoryItems.forEach((item) => {
        if (item.inventoryItemType) {
          const categoryId = item.inventoryItemType.id;
          const quantity = this.categoryQuantities.get(categoryId) ?? 0;
          this.categoryQuantities.set(categoryId, quantity + 1);
        }
      });
    }
    this.isLoadingContract = false;
  }

  handleCategoryChange(catID: number) {
    this.log.debug('Category selected', catID);
    this.selectedCategoryID = catID;
    this.selectedCategory = this.categories.find((c) => c.id === catID) ?? null;
    this.selectedInventoryItems =
      this.contract?.inventoryItems?.filter(
        (item) => item.inventoryItemType?.id === catID
      ) ?? [];

    setTimeout(() => {
      window.HSStaticMethods.autoInit('overlay');
    }, 100);
  }

  // kijelölésre kerül, hogy melyiket szándékozunk törölni
  prepareDelete(item: IInventoryItemModel) {
    this.selectedInventoryItem = item;
  }

  // tényleges törlés
  performDelete() {
    this.inventoryService
      .deleteInventoryItem(this.selectedInventoryItem.id)
      .subscribe({
        next: () => {
          this.log.info('Inventory item deleted');
          this.toastr.success('Inventory item deleted');
          this.refresh();
        },
        error: (err) => {
          this.toastr.error('Error deleting Inventory item');
          this.log.error('Error deleting Inventory item');
        },
      });
  }

  refresh() {
    this.handleContractChange(this.contract?.['@id'] ?? '', true);
  }
}
