import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiSwitchComponent } from '../../../atomic-ui-components/switch/ui-switch.component';
import { IInvoiceModel } from '../../../../services/api/invoice/invoice-model.interface';
import { DecimalPipe } from '@angular/common';
import { CurrencyValueCorrectorPipe } from '../../../../services/utils/currency-value-corrector.pipe';
import { LogService } from '../../../../services/utils/log.service';
import { UiButtonComponent } from '../../../atomic-ui-components/button/ui-button.component';
import { RouterLink } from '@angular/router';
import { UiBadgeComponent } from '../../../atomic-ui-components/badge/ui-badge.component';
import { BillingAddressPipe } from '../../../../services/utils/billing-address.pipe';

@Component({
  selector: 'invoice-table-row',
  standalone: true,
  templateUrl: './invoice-table-row.component.html',
  styleUrl: './invoice-table-row.component.css',
  imports: [
    UiSwitchComponent,
    DecimalPipe,
    CurrencyValueCorrectorPipe,
    UiButtonComponent,
    RouterLink,
    UiBadgeComponent,
    BillingAddressPipe,
  ],
})
export class InvoiceTableRowComponent {
  @Input() pitem: IInvoiceModel = {} as IInvoiceModel;
  @Input() pindex: any = 0;
  @Output() onDelete = new EventEmitter<IInvoiceModel>();
  @Output() onEdit = new EventEmitter<IInvoiceModel>();
  @Output() onView = new EventEmitter<IInvoiceModel>();
  constructor(private log: LogService) {}

  handleEdit(pitem: IInvoiceModel) {
    this.onEdit.emit(pitem);
  }

  handleView(pitem: IInvoiceModel) {
    this.onView.emit(pitem);
  }

  handleDelete(pitem: IInvoiceModel) {
    this.onDelete.emit(pitem);
  }
}
