<layout-main id="content" role="main">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span
        class="flex items-center text-sm cursor-default focus:outline-none focus:text-blue-600 leading-5"
      >
        Long-term leasing
      </span>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Overview
    </li>
  </ol>
  <!-- BREADCRUMB -->

  <!-- Dashboard content here -->
  <div>
    <!-- GRID DASHBOARD -->
    <div class="">
      <!-- GRID -->
      <div class="text-gray-800">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4"
        >
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/gauge.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto stroke-white fill-white"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >occupancy ratio
                    </span>
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">96%</div>
                    <div
                      class="rounded-xl bg-green-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <img
                        src="assets/img/arr.svg"
                        alt=""
                        class="inline-block h-4 leading-4"
                      />
                      <span class="text-xs text-red-900 leading-4">12.5%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/clipboard-check.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Open issues</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">8</div>
                    <div
                      class="rounded-xl bg-red-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <img
                        src="assets/img/arr.svg"
                        alt=""
                        class="inline-block h-4 leading-4"
                      />
                      <span class="text-xs leading-4 text-red-900">12.5%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/building-2.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Properties</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">211</div>
                    <div
                      class="rounded-xl bg-green-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <img
                        src="assets/img/arr.svg"
                        alt=""
                        class="inline-block h-4 leading-4"
                      />
                      <span class="text-xs leading-4 text-red-900">3.4%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200 neutral-700">
                    <img
                      src="assets/img/alert-triangle.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Unpaid rental fees</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">3</div>
                    <div
                      class="rounded-xl bg-yellow-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <span class="text-xs leading-4 text-red-900"
                        >1.500 €</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/plug-zap-2.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >High utility usage</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">12</div>
                    <!--     <div class="rounded-xl bg-green-200 space-x-4 px-2 py-1 leading-4 flex  items-center">
                                                   <img src="assets/img/arr.svg" alt="" class="inline-block h-4  leading-4">
                                                   <span class="text-xs   leading-4">12.5%</span>
                                               </div>  -->
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/calendar-clock.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Contracts ending soon</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">4</div>
                    <!--  <div class="rounded-xl bg-green-200 space-x-4 px-2 py-1  leading-4 flex  items-center">
                                                <img src="assets/img/arr.svg" alt="" class="inline-block h-4  leading-4">
                                                <span class="text-xs   leading-4">12.5%</span>
                                            </div>  -->
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/gauge.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap">
                      Overdue Meter INFO
                    </span>
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">4</div>
                    <div
                      class="rounded-xl bg-yellow-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <span class="text-xs leading-4 text-red-900"
                        >meters in 2 properties</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
          <!-- CARD -->
          <div class="flex flex-col bg-white border shadow-sm rounded-xl">
            <div class="p-2 md:p-2">
              <div class="grid grid-cols-4 gap-4">
                <div class="">
                  <div class="p-3 rounded-lg bg-gray-200">
                    <img
                      src="assets/img/dollar-sign.svg"
                      alt="occupancy ratio "
                      class="w-full h-auto"
                    />
                  </div>
                  <!-- clipboard-check.svg -->
                </div>
                <div class="col-span-3">
                  <div class="uppercase text-xs mb-1 leading-4 flex space-x-1">
                    <span class="overflow-hidden whitespace-nowrap"
                      >Unleased Properties</span
                    >
                    <span class="grow-0 shrink-0">
                      <img
                        src="assets/img/help-circle.svg"
                        alt="help"
                        class="inline-block h-3 stroke-gray-500 leading-4"
                      />
                    </span>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="text-2xl font-bold">2</div>
                    <div
                      class="rounded-xl bg-red-200 space-x-1 px-2 py-1 leading-4 flex items-center"
                    >
                      <img
                        src="assets/img/arr.svg"
                        alt=""
                        class="inline-block h-4 leading-4"
                      />
                      <span class="text-xs text-red-900 leading-4">9.8%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <a href="#" class="p-2 flex justify-between">
              <span class="text-sm">View Reports</span>
              <span class=""
                ><img
                  src="assets/img/forward.svg"
                  alt="forward"
                  class="inline-block h-5 leading-4"
              /></span>
            </a>
          </div>
          <!--  /CARD -->
        </div>
      </div>
    </div>
    <!-- // GRID DASHBOARD -->
  </div>
  <div
    class="p-4 bg-white border border-gray-200 shadow-sm rounded-xl text-gray-800"
  >
    <properties-table></properties-table>
  </div>
</layout-main>
