import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
  IApiQueryDateRange,
} from '../api.service';
import { ICleaningScheduleModel } from './cleaning-schedule-model.interface';
import { CleaningScheduleTransformService } from './cleaning-schedule-transform.service';

/**
 * A takarítási beosztások lekérdezéséhez használt query paraméterek
 * @param start_date A takarítási beosztás kezdő dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 * @param end_date A takarítási beosztás befejező dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 */
export interface ICleaningScheduleQueryParams {
  cleaning_date?: string | IApiQueryDateRange;
  // képes lehetne done és user alapján is szűrni
}

@Injectable({
  providedIn: 'root',
})
export class CleaningScheduleClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private transformer: CleaningScheduleTransformService
  ) {}

  /**
   * A takarítási beosztások lekérése a szerverről nyers formában
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A takarítási beosztások lekért oldala, nyers válaszban
   */
  private fetchCleaningSchedules(
    queryParams: ICleaningScheduleQueryParams | undefined | null,
    page?: number
  ) {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (queryParams) {
      params = this.apiService.appendDateParams(
        params,
        'cleaning_date',
        queryParams.cleaning_date
      );
    }
    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.cleaningSchedules), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a takarítási beosztások első oldalát, hogy megtudja az összes takarítási beosztás számát
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns Az összes takarítási beosztás számossága
   */
  getTotalItems(
    queryParams?: ICleaningScheduleQueryParams
  ): Observable<number> {
    if (!this.totalItems || queryParams) {
      return this.fetchCleaningSchedules(queryParams).pipe(
        map((response: any) => {
          if (!queryParams) {
            this.totalItems = response['hydra:totalItems'];
          }
          return response['hydra:totalItems'];
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Lekéri a takarítási beosztásokat nyers formában
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A takarítási beosztások nyers formában, nem átalakítva
   */
  private getRawCleaningSchedules(
    queryParams?: ICleaningScheduleQueryParams | undefined | null,
    page?: number
  ): Observable<ICleaningScheduleModel[]> {
    return this.fetchCleaningSchedules(queryParams, page).pipe(
      map((response: any) => {
        const cleaningSchedules = response['hydra:member'];
        return cleaningSchedules;
      })
    );
  }

  /**
   * Lekéri a takarítási beosztásokat
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A takarítási beosztások adatai
   */
  getCleaningSchedules(
    queryParams?: ICleaningScheduleQueryParams | undefined | null,
    page?: number
  ): Observable<ICleaningScheduleModel[]> {
    return this.getRawCleaningSchedules(queryParams, page).pipe(
      map((schedules: any) => {
        return schedules.map((schedule: any) =>
          this.transformer.transformCleaningSchedule(schedule)
        );
      })
    );
  }

  /**
   * Egy takarítási beosztás törlése
   * @param id A törlendő takarítási beosztás azonosítója
   * @returns A törlés eredménye
   */
  deleteCleaningSchedule(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.cleaningSchedule) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy takarítási beosztás létrehozása
   * @param cleaningSchedule A létrehozandó takarítási beosztás adatai
   * @returns A létrehozott takarítási beosztás
   */
  createCleaningSchedule(
    cleaningSchedule: ICleaningScheduleModel
  ): Observable<ICleaningScheduleModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(
        this.apiService.getUrlFor(API_ENDPOINTS.cleaningSchedules),
        this.transformer.serializeCleaningSchedule(cleaningSchedule),
        {
          headers,
        }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as ICleaningScheduleModel;
        })
      );
  }

  /**
   * Az összes takarítási beosztás lekérése
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns Az összes takarítási beosztás adatai
   */
  getAllCleaningSchedules(
    queryParams?: ICleaningScheduleQueryParams
  ): Observable<ICleaningScheduleModel[]> {
    // megtudjuk az összes takarítási beosztás számát
    return this.getTotalItems(queryParams).pipe(
      switchMap((totalItems) => {
        // kiszámoljuk az oldalak számát
        let pages = Math.ceil(totalItems / 30);
        let requests = [];

        if (pages === 0) {
          // nincs egy oldal sem
          return new Observable<ICleaningScheduleModel[]>((observer) => {
            observer.next([]);
            observer.complete();
          });
        }

        // lekérjük az összes oldalt
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getCleaningSchedules(queryParams, i));
        }

        // összefésüljük az eredményeket
        return forkJoin(requests).pipe(
          map((results) => {
            let cleaningSchedules = [] as ICleaningScheduleModel[];
            for (let result of results) {
              cleaningSchedules.push(...result);
            }
            return cleaningSchedules;
          })
        );
      })
    );
  }

  /**
   * Egy takarítási beosztás módosítása
   * @param cleaningSchedule A módosítandó takarítási beosztás adatai
   * @returns A módosított takarítási beosztás
   */
  updateCleaningSchedule(
    cleaningSchedule: ICleaningScheduleModel
  ): Observable<ICleaningScheduleModel> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.cleaningSchedule) +
          cleaningSchedule.id,
        this.transformer.serializeCleaningSchedule(cleaningSchedule),
        { headers }
      )
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((result) => {
          return result as ICleaningScheduleModel;
        })
      );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'CleaningScheduleClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(
      () => new Error('Failed to perform cleaning schedule operation')
    );
  };
}
