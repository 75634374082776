import { Component, ViewChild } from '@angular/core';
import { IssueStatusTableRowComponent } from './issue-status-table-row/issue-status-table-row.component';
import { IssueStatusEditorComponent } from '../../modal-editors/issue-status-editor/issue-status-editor.component';
import { IIssueStatusModel } from '../../../services/api/issue-status/issue-status-model.interface';
import { IssueStatusClientService } from '../../../services/api/issue-status/issue-status-client.service';
import { UiButtonComponent } from '../../atomic-ui-components/button/ui-button.component';
import { UiTablePagerComponent } from '../../atomic-ui-components/table-pager/table-pager.component';
import { ConfirmationDialogComponent } from '../../modals/confirmation-dialog/confirmation-dialog.component';
import { LogService } from '../../../services/utils/log.service';
import { ToastrService } from 'ngx-toastr';
import { TableEmptyStateComponent } from '../table-empty-state/table-empty-state.component';

@Component({
  selector: 'issue-status-table',
  standalone: true,
  imports: [
    IssueStatusTableRowComponent,
    IssueStatusEditorComponent,
    UiButtonComponent, 
    ConfirmationDialogComponent,
    UiTablePagerComponent,
    TableEmptyStateComponent,
  ],
  templateUrl: './issue-status-table.component.html',
  styleUrl: './issue-status-table.component.css'
})
export class IssueStatusTableComponent {
  @ViewChild(IssueStatusEditorComponent)
  editor!: IssueStatusEditorComponent;
  selectedIssueStatus!: IIssueStatusModel;
  editorMode: 'create' | 'edit' | 'view' = 'create';
  issueStatus: IIssueStatusModel[] = [];
  isLoading = false;
  isPagerLoading = true;
  totalItems = 0;

  constructor(
  private issueStatusClientService: IssueStatusClientService,
  private log: LogService,
  private toastr: ToastrService,
) {}

handleEdit(pitem: IIssueStatusModel) {
  this.selectedIssueStatus = pitem;
  this.editorMode = 'edit';
}

handleCreate() {
  // Kezdjünk tiszta lappal
  this.reset();
  this.editorMode = 'create';
}

handleView(pitem: IIssueStatusModel) {
  this.selectedIssueStatus = pitem;
  this.editorMode = 'view';
}

handlePagination(page: number) {
  this.refresh(page);
}

// kijelölésre kerül, hogy melyiket szándékozunk törölni
prepareDelete(pitem: IIssueStatusModel) {
  this.selectedIssueStatus = pitem;
}

 // tényleges törlés
 performDelete() {
/*  this.issueStatusClientService
    .deleteItem(this.selectedIssueStatus.id)
    .subscribe({
      next: () => {
        // ha sikerült, elhisszük, de nem kérünk le mindent újra
        this.log.info('Issue Status deleted');
        this.issueStatus = this.issueStatus.filter(
          (pr) => pr.id !== this.selectedIssueStatus.id
        );
        if (this.totalItems > 0) {
          this.totalItems--;
        }
        if (this.issueStatus.length === 0 && this.totalItems > 0) {
          this.refresh();
        }
        this.toastr.success('Issue Status deleted');
      },
      error: (err) => {
        this.toastr.error('Error deleting Issue Status');
        this.log.error('Error deleting Issue Status', err);
      },
    }); */
}

ngOnInit() {
  this.reset();
  this.refresh();
}

private reset() {
  this.selectedIssueStatus = {
    '@id': '',
    id: 0,
    name: '',
    description: '',
  } as IIssueStatusModel;
}

refresh(page?: number) {
/*  this.isLoading = true;
  this.issueStatusClientService
    .getItems(page)
    .subscribe({
      next: (issueStatus) => {
        this.log.debug('Issue Status requests fetched', issueStatus);
        this.issueStatus = issueStatus;
        setTimeout(() => {
          this.isLoading = false;
        }, 100);

        setTimeout(() => {
          this.log.info('Issue Status table initialized');
          window.HSStaticMethods.autoInit('overlay');
        }, 100);
      },
      error: (err) => {
        this.log.error('Error fetching Issue Status', err);
        this.isLoading = false;
      },
    });
  // lekérjük az összes elem számát is a lapozónak
  this.isPagerLoading = true;
  this.issueStatusClientService.getTotalItems().subscribe((count) => {
    this.totalItems = count;
    this.isPagerLoading = false;
  });  */
}

}
