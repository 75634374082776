import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FlatClientService } from '../../../services/api/flat/flat-client.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IFlatModel } from '../../../services/api/flat/flat-model.interface';
import { IMeterModel } from '../../../services/api/meter/meter-model.interface';
import { LogService } from '../../../services/utils/log.service';
import { LucideAngularModule } from 'lucide-angular';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { IUiSelectItem } from '../../atomic-ui-components/select/ui-select-item.interface';
import { MeterClientService } from '../../../services/api/meter/meter-client.service';
import { SelectItemLoaderService } from '../../../services/utils/select-item-loader.service';

@Component({
  selector: 'property-meters-editor',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LucideAngularModule,
    NgOptionHighlightModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  templateUrl: './property-meters-editor.component.html',
  styleUrl: './property-meters-editor.component.css',
})
export class PropertyMetersEditorComponent {
  @Input() flat?: IFlatModel;
  @Input() selectedMeters: IMeterModel[] = [];
  @Output() onSave = new EventEmitter<IMeterModel[]>();

  meterTypeSelectItems: IUiSelectItem[] = [];
  meterSelectItems: IMeterModel[] = [];
  selectedMeterType?: IUiSelectItem;
  meters: IMeterModel[] = [];
  isMeterTypeLoading = false;
  isMeterLoading = false;

  constructor(
    private log: LogService,
    private toastr: ToastrService,
    private flatService: FlatClientService,
    private meterService: MeterClientService,
    private silo: SelectItemLoaderService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.log.debug('Changes: ', changes);
    if (changes['flat']) {
      //
    }
    if (changes['meters']) {
      //
    }
  }

  ngOnInit() {
    this.log.debug('PropertyMetersEditorComponent init');
    // Betöltjük a mérők típusait
    this.isMeterTypeLoading = true;
    this.silo.getMeterTypeSelectItems().subscribe({
      next: (items) => {
        this.meterTypeSelectItems = items;
        this.isMeterTypeLoading = false;
        this.log.debug('Meter types loaded');
        this.setupMeterSelectors();
      },
      error: (err) => {
        this.log.error('Could not load meter types ', err);
      },
    });

    //betöltjük az összes mérőt is
    this.isMeterLoading = true;
    this.meterService.getAllItems().subscribe({
      next: (items) => {
        this.meters = items;
        this.isMeterLoading = false;
        this.log.debug('Meters loaded');
        this.setupMeterSelectors();
      },
      error: (err) => {
        this.log.error('Could not load meters ', err);
      },
    });
  }

  setupMeterSelectors() {
    if (!this.isMeterTypeLoading && !this.isMeterLoading) {
      // ha mindkét lista betöltött, akkor a selectort is beállítjuk
      this.selectedMeterType = this.meterTypeSelectItems[0];
      this.filterMetersByType();
    }
  }

  filterMetersByType() {
    // ez a metódus kiválogatja a megfelelő mérőket a kiválasztott típus alapján
    if (this.selectedMeterType) {
      this.meterSelectItems = this.meters.filter(
        (meter) => meter.meterType?.['@id'] === this.selectedMeterType?.val
      );
    }
  }

  // ez keresi a megfelelő mérőket a beírt szöveg alapján
  customSearchForSelect(term: string, item: IMeterModel) {
    term = term.toLowerCase();
    return (
      item.id.toString().indexOf(term) > -1 ||
      (item.meterType?.name?.toLowerCase() ?? '').indexOf(term) > -1
    );
  }

  savePropertyMeters() {
    // a kiválasztott mérőket hozzáadjuk a létrehozott ingatlanhoz
    if (this.flat) {
      const updatedProperty = {
        ['@id']: this.flat['@id'],
        id: this.flat.id,
        meters: this.selectedMeters,
      };
      this.log.debug('Adding meters to property');
      this.flatService.updateFlat(updatedProperty).subscribe({
        next: (data) => {
          this.log.debug('Meters added to property: ', data);
          this.toastr.success('Meter settings saved');
          this.onSave.emit(this.selectedMeters);
        },
        error: (err) => {
          this.log.error('Could not add meters to property ', err);
          this.toastr.error('Could not save meter settings');
        },
      });
    }
  }
}
