<layout-main id="content" role="main" class="text-gray-800">
  <!-- BREADCRUMB -->
  <ol
    class="flex items-center whitespace-nowrap mb-4 leading-5 xs:mt-4 sm:mt-0 xs:mx-4 sm:mx-0"
    aria-label="Breadcrumb"
  >
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600"
        routerLink="/"
        >Home
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <span class="flex items-center text-sm cursor-default leading-5">
        Long-term leasing
      </span>
      <span
        class="flex-shrink-0 mx-3 cursor-default overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        routerLink="/properties"
      >
        Properties
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >
    </li>
    <li class="inline-flex items-center leading-5">
      @if(flat) {
      <a
        class="flex items-center text-sm hover:text-blue-600 focus:outline-none focus:text-blue-600 leading-5"
        [routerLink]="'/properties/' + flat.id"
      >
        {{ propertyTitle }}
      </a>
      <span class="flex-shrink-0 mx-3 overflow-visible text-sm leading-5"
        >/</span
      >} @else {
      <span class="flex items-center text-sm leading-5">Properties</span>
      }
    </li>
    <li
      class="inline-flex items-center text-sm font-semibold truncate leading-5"
      aria-current="page"
    >
      Document inventory
    </li>
  </ol>
  <!-- BREADCRUMB -->
  @if(isLoading){
  <div
    class="bg-white border shadow-sm rounded-xl p-16 mb-4 align-middle text-center"
  >
    <div
      class="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
      role="status"
      aria-label="loading"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <p class="text-sm text-gray-500 mt-4">Loading property details...</p>
  </div>
  } @else{
  <!-- TOP INFO  -->
  <property-and-contract-box
    [flat]="flat"
    (contractChanged)="handleContractChange($event)"
  ></property-and-contract-box>
  <!-- TOP INFO  -->
  <!-- Nav And File Table Container -->
  <div class="bg-white border shadow-sm rounded-xl p-4">
    @if(categories.length > 0){
    <div class="flex justify-between border-b border-gray-200">
      <nav class="flex space-x-2">
        @for(category of categories; track category.id;) {
        <button
          type="button"
          class="py-4 px-1 inline-flex items-center gap-x-2 border-b-2 text-sm whitespace-nowrap hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none"
          (click)="handleCategoryChange(category.id)"
          [ngClass]="{
            'font-semibold border-blue-600 text-blue-600':
              selectedCategoryID === category.id,
            'text-gray-500 border-transparent':
              selectedCategoryID !== category.id
          }"
        >
          <!--           <img
            src="assets/img/building.svg"
            alt="Contract Documents"
            class="flex-shrink-0 size-4 grayscale hs-tab-active:grayscale-0"
          /> -->
          {{ category.name }}@if((categoryQuantities.get(category.id) || 0) > 0)
          {
          <span
            class="-ml-4 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-transparent"
            >{{ categoryQuantities.get(category.id) }}</span
          >
          }
        </button>
        }
      </nav>

      <!-- Upload Button -->
      <div class="flex items-center gap-x-2">
        <button
          type="button"
          class="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
          data-hs-overlay="#hs-doc-file-upload-modal"
        >
          <svg
            class="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="17 8 12 3 7 8" />
            <line x1="12" x2="12" y1="3" y2="15" />
          </svg>
          Upload files
        </button>
      </div>
    </div>
    }

    <div class="mt-3">
      <!--*******************************************-->
      <!-- Documents START-->
      <!--*******************************************-->
      <div>
        <div class="flex flex-col">
          <div class="-m-1.5 overflow-x-auto">
            <div class="p-1.5 min-w-full inline-block align-middle">
              <div class="border rounded-lg divide-y divide-gray-200">
                <!--- TABLE -->
                @if(selectedInventoryItems.length > 0){
                <div class="overflow-hidden i11-mh-65">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          File name
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase w-32"
                        >
                          Modified
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase w-32"
                        >
                          Client access
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase w-64"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <!--  contract row START  -->
                    @for (item of selectedInventoryItems; track item.id;) {
                    <document-inventory-table-row
                      [item]="item"
                      (onDelete)="prepareDelete($event)"
                    ></document-inventory-table-row>
                    }
                    <!-- //  contract row END  -->
                  </table>
                </div>
                } @else {
                <!-- EMPTY STATE  -->
                <table-empty-state
                  noDataTitle="This category has no files uploaded yet."
                  noDataDescription="You can upload handover files, photos here."
                  modalIdToOpen="#hs-doc-file-upload-modal"
                  ctaText="Upload Files"
                ></table-empty-state>
                <!-- END EMPTY STATE  -->
                }

                <!-- // TABLE  //  -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Documents END-->
    </div>
  </div>
  }
  <!-- ========== SECONDARY CONTENT ========== -->
  <!-- Modal -->
  @if(flat) {
  <upload-documents
    [inventoryCategory]="selectedCategory"
    [flatId]="flat['@id']"
    [contractId]="contract?.['@id']"
    (onUploadSuccess)="refresh()"
  ></upload-documents>
  }
  <!-- Modal End-->
  <!-- Delete Modal -->
  <confirmation-dialog
    type="negative"
    hsId="hs-delete-inventory-item-confirmation-modal"
    title="Delete confirmation"
    message="Are you sure you want to delete '{{
      selectedInventoryItem
        ? selectedInventoryItem.name ?? 'this item'
        : 'this item'
    }}'? This action cannot be undone."
    confirmButtonText="Yes, Delete"
    (confirmed)="performDelete()"
  ></confirmation-dialog>
  <!-- End Delete Modal -->
  <!-- ========== END SECONDARY CONTENT ========== -->
</layout-main>
