<div class="bg-gray-50">
  <nav-header></nav-header>
  <nav-sidebar></nav-sidebar>
  <main id="content" role="main" class="lg:ps-[260px] pt-[64px]">
    <div class="sm:p-4 md:pt-5 space-y-5">
      <ng-content></ng-content>
      <!-- Placeholder for child content -->
    </div>
  </main>
</div>
