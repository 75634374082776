import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  retry,
  switchMap,
  throwError,
} from 'rxjs';
import { LogService } from '../../utils/log.service';
import {
  API_ENDPOINTS,
  API_CALL_RETRY_COUNT,
  ApiService,
  IApiQueryDateRange,
  IApiQueryOrdering,
} from '../api.service';
import { ICheckinModel } from './checkin-model.interface';
import { BookingTransformService } from '../booking/booking-transform.service';

/**
 * A checkinek lekérdezéséhez használt query paraméterek
 * @param start_date A checkin kezdő dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 * @param end_date A checkin befejező dátuma. Ha string, akkor az egyenlő dátumot keresi, ha IApiQueryDateRange, akkor a megadott intervallumon belülit keres
 */
export interface ICheckinQueryParams {
  createdAt?: string | IApiQueryDateRange;
  order?: IApiQueryOrdering;
}

@Injectable({
  providedIn: 'root',
})
export class CheckinClientService {
  private totalItems!: number;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private log: LogService,
    private bookingtransformer: BookingTransformService
  ) {}

  /**
   * A checkinek lekérése a szerverről nyers formában
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @param queryParams A lekérdezéshez használt query paraméterek
   * @returns A checkinek lekért oldala, nyers válaszban
   */
  private fetchCheckins(
    queryParams: ICheckinQueryParams | undefined | null,
    page?: number
  ) {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (queryParams) {
      params = this.apiService.appendDateParams(
        params,
        'created_at',
        queryParams.createdAt
      );
    }
    this.log.debug('CheckinClientService:fetchCheckins', params);

    return this.http
      .get(this.apiService.getUrlFor(API_ENDPOINTS.checkins), {
        params,
      })
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Lekéri a checkinek első oldalát, hogy megtudja az összes checkin számát
   * @returns Az összes checkin számossága
   */
  getTotalItems(queryParams?: ICheckinQueryParams): Observable<number> {
    if (!this.totalItems || queryParams) {
      return this.fetchCheckins(queryParams).pipe(
        map((response: any) => {
          if (!queryParams) {
            this.totalItems = response['hydra:totalItems'];
          }
          return response['hydra:totalItems'];
        })
      );
    } else {
      return new Observable((observer) => {
        observer.next(this.totalItems);
        observer.complete();
      });
    }
  }

  /**
   * Egy checkin törlése
   * @param id A törlendő checkin azonosítója
   * @returns A törlés eredménye
   */
  deleteCheckin(id: number): Observable<any> {
    return this.http
      .delete(this.apiService.getUrlFor(API_ENDPOINTS.checkin) + id)
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * Egy checkin létrehozása
   * @param checkin A létrehozandó checkin adatai
   * @returns A létrehozott checkin
   */
  createCheckin(checkin: ICheckinModel): Observable<ICheckinModel> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http
      .post(this.apiService.getUrlFor(API_ENDPOINTS.checkins), checkin, {
        headers,
      })
      .pipe(
        retry(API_CALL_RETRY_COUNT),
        catchError(this.handleError),
        map((response: Object) => response as ICheckinModel)
      );
  }

  /**
   * Egy checkin módosítása
   * @param checkin Az módosítandó checkin adatai
   * @returns Az módosított checkin adatai
   */
  updateCheckin(checkin: ICheckinModel): Observable<any> {
    const headers = { 'Content-Type': 'application/merge-patch+json' };
    return this.http
      .patch(
        this.apiService.getUrlFor(API_ENDPOINTS.checkin) + checkin.id,
        checkin,
        { headers }
      )
      .pipe(retry(API_CALL_RETRY_COUNT), catchError(this.handleError));
  }

  /**
   * A checkinek lekérése a szerverről
   * @param page Az oldal száma, 30-as lapozás van, ha nincs megadva, az első oldalt kéri le
   * @returns A checkinek lekért oldala
   */
  getCheckins(
    queryParams?: ICheckinQueryParams | null | undefined,
    page?: number
  ): Observable<ICheckinModel[]> {
    return this.fetchCheckins(queryParams, page).pipe(
      map((response: any) => {
        const checkins = response['hydra:member'];
        return checkins;
      }),
      map(
        // Az összes checkinen belüli booking átalakítása, ha van checkin.booking
        (checkins) => {
          return checkins.map((checkin: ICheckinModel) => {
            if (checkin.booking) {
              checkin.booking = this.bookingtransformer.transformBooking(
                checkin.booking
              );
            }
            return checkin;
          });
        }
      )
    );
  }

  getAllCheckins(
    queryParams?: ICheckinQueryParams
  ): Observable<ICheckinModel[]> {
    // Megtudjuk az összes checkin számát
    return this.getTotalItems(queryParams).pipe(
      switchMap((totalItems) => {
        // kiszámoljuk hány oldalnyi van
        let pages = Math.ceil(totalItems / 30);
        // legyen egy tömbünk az observable-knak
        let requests = [];

        if (pages === 0) {
          // nincs egy oldal sem
          return new Observable<ICheckinModel[]>((observer) => {
            observer.next([]);
            observer.complete();
          });
        }

        // minden oldalra külön request
        for (let i = 1; i <= pages; i++) {
          requests.push(this.getCheckins(queryParams, i));
        }
        // az összes requestet egyesítjük
        return forkJoin(requests).pipe(
          map((results) => {
            let checkins = [] as ICheckinModel[];
            for (let result of results) {
              checkins.push(...result);
            }
            return checkins;
          })
        );
      })
    );
  }

  /**
   * Hiba esetén a hibakezelés, jelenleg csak logolás
   * @param error A hibaüzenet (HttpErrorResponse)
   * @returns Error dobása
   */
  private handleError = (error: HttpErrorResponse) => {
    this.log.error(
      'CheckinClientService:',
      error.status,
      error.error,
      error.message
    );
    //TODO: lokalizálni a hibaüzenetet
    return throwError(() => new Error('Failed to perform checkin operation'));
  };
}
