import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IssueDetailComponent } from '../../pages/issue-detail/issue-detail.component';
import { LayoutMainComponent } from '../layout-main/layout-main.component';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogService } from '../../services/utils/log.service';
import { IIssueModel } from '../../services/api/issue/issue-model.interface';
import { IUiSelectItem } from '../atomic-ui-components/select/ui-select-item.interface';
import { IssueClientService } from '../../services/api/issue/issue-client.service';
import { SelectItemLoaderService } from '../../services/utils/select-item-loader.service';
import { IssueStatusClientService } from '../../services/api/issue-status/issue-status-client.service';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'issue-view-edit',
  standalone: true,
  imports: [
    IssueDetailComponent,
    LayoutMainComponent,
    ReactiveFormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  templateUrl: './issue-view-edit.component.html',
  styleUrl: './issue-view-edit.component.css',
})
export class IssueViewEditComponent implements OnInit, OnChanges {
  @Input() issue!: IIssueModel;
  @Input() mode: 'view' | 'edit' = 'view';
  userSelectItems = [] as IUiSelectItem[];
  delegateUserSelectItems = [] as IUiSelectItem[];
  flatSelectItems: IUiSelectItem[] = [];
  statusSelectItems: IUiSelectItem[] = [];

  issueForm: FormGroup = this.fb.group({
    issuerUser: [''],
    flat: [''],
    issueStatus: ['', Validators.required],
    note: [''],
    issueNotes: [''],
    issueUrgency: [''],
    delegatedUsers: [''],
  });

  constructor(
    private fb: FormBuilder,
    private log: LogService,
    private statusService: IssueStatusClientService,
    private issueService: IssueClientService,
    private silo: SelectItemLoaderService,
    private toastr: ToastrService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.log.debug('IssueViewEditComponent ngOnChanges:', changes);
    // Ellenőrizzük, hogy az issue változott-e, ha igen, akkor frissítjük a formot
    if (changes?.['issue'].currentValue) {
      this.issue = changes['issue'].currentValue;
      this.log.debug('IssueViewEditComponent ngOnChanges issue:', this.issue);
      this.issueForm.patchValue({
        issuerUser: this.issue.issuerUser?.['@id'],
        flat: this.issue.flat?.['@id'],
        issueStatus: this.issue.issueStatus?.['@id'],
        delegatedUsers: this.issue.delegatedUsers?.map((user) => user['@id']),
        note: this.issue.note
      });
    }
  }

  ngOnInit() {
    // Betöltjük a lakásokat a dropdownhoz
    this.silo.getFlatSelectItems().subscribe({
      next: (flats) => {
        this.flatSelectItems = flats;
        this.log.debug('Flats loaded into select:', this.flatSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading flats into select:', err);
      },
    });

    // Betöltjük a felhasználókat a dropdownhoz
    this.silo.getUserSelectItems().subscribe({
      next: (users) => {
        this.userSelectItems = users;
        this.log.debug('Users loaded into select:', this.userSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading users into select:', err);
      },
    });

    this.silo.getDelegateUserSelectItems().subscribe({
      next: (delegateUsers) => {
        this.delegateUserSelectItems = delegateUsers;
        this.log.debug('Delegated users loaded into select:', this.delegateUserSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading delegated users into select:', err);
      },
    });

    //Lekérjük az összes státuszt
    this.statusService.getItems().subscribe({
      next: (statuses) => {
        this.statusSelectItems = statuses.map((status) => ({
          val: status['@id'],
          title: status.name,
        }));
        this.statusSelectItems.sort((a, b) =>
          a.title === undefined ? 0 : a.title.localeCompare(b.title ?? '')
        );
        this.log.debug('Issue statuses loaded:', this.statusSelectItems);
      },
      error: (err) => {
        this.log.error('Error loading issue statuses:', err);
      },
    });
  }

  saveIssue() {
    this.log.info('Saving issue');
    if (this.issueForm.valid && this.mode !== 'view') {
      const updatedUnTypedIssue: { [key: string]: any } = {};

      // Csak a módosított mezőket küldjük
      Object.keys(this.issueForm.controls).forEach((key) => {
        const control = this.issueForm.get(key);
        if (control?.dirty) {
          updatedUnTypedIssue[key] = control.value;
        }
      });
      if (Object.keys(updatedUnTypedIssue).length === 0) {
        // Nincs módosított adat, nem kell menteni
        this.toastr.info('No changes to save');
        this.log.info('No changes to save');
        return;
      }

      const updatedIssue = updatedUnTypedIssue as IIssueModel;
      this.log.debug('Updated issue:', updatedIssue);

      updatedIssue.id = this.issue.id;
      this.issueService.updateIssue(updatedIssue).subscribe({
        next: (data) => {
          this.toastr.success('Issue updated');
          this.log.debug('Issue updated:', data);
        },
        error: (err) => {
          this.toastr.error('Error while updating issue');
          this.log.error('Error updating issue:', err);
        },
      });
    }
  }

}
